import React, { useEffect, useState } from "react";
import Student from "./Student";
import QRCode from "react-qr-code";
// components

export default function Parent({ user }) {
	const [show, setShow] = useState(false);
	const [search, setSearch] = useState("");
	const [students, setStudents] = useState([]);
	useEffect(() => {
		setStudents(user.supervisor.students.filter((item) => item.user.name.toLowerCase().includes(search.toLowerCase())));
	}, [search]);

	useEffect(() => { }, []);
	return (
		<>
			<div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg justify-between items-center">
				<div className=" flex flex-row w-full px-8 py-4  justify-between items-center">
					<h6 className=" font-semibold dark:text-white">My Students</h6>{" "}
					<div className="flex flex-row gap-4 w-1/2">
						<div className="flex p-2 gap-2 items-center flex-row flex-nowrap bg-white rounded-lg shadow w-full ease-linear transition-all duration-150 ">

							<i className="fa-solid fa-search"></i><input
								className="focus:outline-none w-full"
								type="text"
								value={search}
								onChange={(event) => {
									setSearch(event.target.value);
								}}
							></input>
						</div>
					</div>
					<i
						onClick={(event) => {
							event.preventDefault()
							setShow(!show);
						}}
						className={
							show
								? "fa-solid fa-chevron-up text-xl cursor-pointer transition-all duration-200"
								: "fa-solid fa-chevron-up text-xl cursor-pointer -scale-y-100 transition-all duration-200"
						}
					></i>
				</div>

				{show ? (
					<div className="flex flex-col gap-4 p-8 w-full">
						{students.map((student) => {
							return (
								<>
									<div className="gap-4 flex md:flex-row flex-col-reverse p-4 bg-white shadow-lg rounded-lg  w-full justify-between">
										<div className="flex flex-col font-light text-slate-500 ">
											<h4 className=" text-slate-900 font-medium font-mono capitalize ">{student.user.name}</h4>
											<h6>
												{student.user.email} / {student.user.phone_number}
											</h6>
										</div>

										<div className="md:w-32 w-full aspect-square ">
											{student.user.id ? (
												<QRCode
													value={student.user.id}
													style={{
														height: "100%",
														width: "100%",
													}}
												/>
											) : (
												""
											)}
										</div>
									</div>
									<Student student={student} />
								</>
							);
						})}
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
}
