import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "Components/Loaders/Loading";

// components

export default function ExpenseForm({ action, data, getExpenses, hide }) {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const method = action === "add" ? "post" : "put";
	const [type, setType] = useState("");
	const [description, setDescription] = useState("");
	const [total, setTotal] = useState();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const add_expense = () => {
		setLoading(true);

		axios({
			// Endpoint to send files
			url: url + "/expenses",
			method: method,
			data: {
				type,
				total,
				description,
				date: moment().format("YYYY-MM-DD"),
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				hide();
				toast.success("Expense " + action + "ed", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getExpenses();
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (action === "edit") {
			setType(data.type);
			setTotal(data.total);
			setDescription(data.description);
		}
	}, []);
	return (
		<div className="flex-auto  p-4">
			<form>
				<div className="flex flex-wrap">
					<div className=" w-full my-2">
						<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
							Amount
						</label>
						<input
							type="number"
							onWheel={(event) => event.target.blur()}
							className="my-2 p-2  placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Amount"
							onChange={(event) => {
								setTotal(event.target.value);
							}}
							value={total}
						/>
					</div>
					<div className=" w-full my-2">
						<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
							Type
						</label>
						<input
							type="text"
							className="my-2 p-2  placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Type"
							onChange={(event) => {
								setType(event.target.value);
							}}
							value={type}
						/>
					</div>
					<div className=" w-full my-2 ">
						<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
							description
						</label>
						<textarea
							className="my-2 p-2  placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							value={description}
							onChange={(event) => {
								setDescription(event.target.value);
							}}
						></textarea>
					</div>
				</div>
				<div className="flex justify-center w-full my-4">
					<button
						className="w-full px-4 py-2 items-center text-white bg-sky-600 active:bg-sky-700 font-bold uppercase    rounded-lg shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
						type="button"
						onClick={() => add_expense()}
						disabled={loading}
					>
						{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
						Save
					</button>
				</div>
			</form>
		</div>
	);
}
