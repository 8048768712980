import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "Components/Loaders/Loading";

// components

export default function ClientForm({ action, data, getClients, hide }) {
	const base_url = process.env.REACT_APP_API_URL_LIBRARY;
	const token = window.localStorage.getItem("token");
	const url = action === "add" ? base_url + "/clients" : base_url + "/clients/" + data.id;
	const method = action === "add" ? "post" : "put";
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [gender, setGender] = useState("male");
	const [phone_number, setPhone] = useState("");
	const [city, setCity] = useState("");
	const [wilaya, setWilaya] = useState("");
	const [street, setStreet] = useState("");
	const navigate = useNavigate();
	function generateEmail(event) {
		event.preventDefault();
		var formattedName = lastName.toLowerCase().replace(/\s/g, "") + firstName.toLowerCase().replace(/\s/g, "");
		var randomNumber = Math.floor(Math.random() * 9000) + 1000;
		var email = formattedName + randomNumber + "@numidia.com";

		setEmail(email);
	}
	const [loading, setLoading] = useState(false);

	const call = () => {
		setLoading(true);

		axios({
			// Endpoint to send files
			url: url,
			method: method,
			data: {
				email,
				name: action === "add" ? lastName + " " + firstName : name,
				phone_number,
				gender,
				city,
				wilaya,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Client " + action + "ed", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				hide();
				getClients();
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (action === "edit") {
			setName(data.name);
			setGender(data.gender);
			setPhone(data.phone_number);
			setEmail(data.email);
			setWilaya(data.address.wilaya);
			setCity(data.address.city);
			setStreet(data.address.street);
		}
	}, []);
	return (
		<form className="w-fit">
			<div className="w-full flex  flex-row flex-wrap gap-4 justify-center">
				{action === "edit" ? (
					<div className="w-full  ">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Name
						</label>
						<input
							type="text"
							className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Full Name"
							onChange={(event) => {
								setName(event.target.value);
							}}
							value={name}
						/>
					</div>
				) : (
					<>
						<div className="flex-1  ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Last Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Last Name"
								onChange={(event) => {
									setLastName(event.target.value);
								}}
								value={lastName}
							/>
						</div>
						<div className=" flex-1 ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								First Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="First Name"
								onChange={(event) => {
									setFirstName(event.target.value);
								}}
								value={firstName}
							/>
						</div>
					</>
				)}
				<div className="w-full">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						phone number
					</label>
					<input
						type="number"
						onWheel={(event) => event.target.blur()}
						className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						placeholder="phone number"
						onChange={(event) => {
							setPhone(event.target.value);
						}}
					/>
				</div>
				<div className=" w-full">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						Email address
					</label>
					<div className="flex my-2 gap-4">
						<input
							type="email"
							className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Email"
							onChange={(event) => {
								setEmail(event.target.value);
							}}
							value={email}
						/>
						<button className="bg-white rounded-lg shadow p-2 flex items-center justify-center" onClick={generateEmail}>
							<i className="fa-solid fa-gear"></i>{" "}
						</button>
					</div>
				</div>
				<div className="w-full ">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						Gender
					</label>
					<select
						className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						onChange={(event) => {
							setGender(event.target.value);
						}}
						value={gender}
					>
						<option value="">Select gender</option>
						<option value="male">Male</option>
						<option value="female">Female</option>
					</select>
				</div>

				<div className=" w-full ">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						wilaya
					</label>
					<input
						type="text"
						className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						placeholder="wilaya"
						onChange={(event) => {
							setWilaya(event.target.value);
						}}
						value={wilaya}
					/>
				</div>
				<div className=" w-full">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						city
					</label>
					<input
						type="text"
						className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						placeholder="city"
						onChange={(event) => {
							setCity(event.target.value);
						}}
						value={city}
					/>
				</div>
				<div className=" w-full">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						Street
					</label>
					<input
						type="text"
						className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						placeholder="Street"
						onChange={(event) => {
							setStreet(event.target.value);
						}}
						value={street}
					/>
				</div>

				<div className="flex justify-center w-full my-4">
					<button
						className="px-4 py-2 whitespace-nowrap w-full text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
						type="button"
						onClick={call}
						disabled={loading}
					>
						{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
						Register now
					</button>
				</div>
			</div>
		</form>
	);
}
