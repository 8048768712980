import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function StudentAnswers({ answers }) {
	const [url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");

	const navigate = useNavigate();

	return (
		<div className="flex flex-col gap-4">
			<div className="grid xl:grid-cols-4 gap-4 md:grid-cols-2 grid-cols-1">
				{answers.map((answer, index) => {
					return (
						<div
							className={
								answer.score === 1
									? "w-full flex flex-col gap-2  shadow-lg whitespace-nowrap rounded-lg  p-4  bg-white"
									: "w-full flex flex-col gap-2  shadow-lg whitespace-nowrap rounded-lg p-4   bg-red-50"
							}
						>
							<h6 className=" font-bold">Question {index + 1}</h6>
							<h6>{answer.question.content}</h6>
							<h6 className=" uppercase font-medium underline">Choices:</h6>
							{answer.question.choices?.map((choice, id) => {
								return (
									<div className="flex flex-row gap-4 items-center">
										<label>
											Option {id + 1}: {choice.content}
										</label>
									</div>
								);
							})}
							<div className="w-full font-medium">Answer: Option {answer.answer}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
