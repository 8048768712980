import React from 'react';
import { Link } from 'react-router-dom';

const NavLinks = ({ items }) => {
  return (
    <nav className="flex items-center w-full flex-nowrap overflow-x-scroll scrollbar-hide  gap-6  my-4">
      {items.map((item, index) => (
        <Link
          key={index}
          to={item.link}
          className="flex items-center  whitespace-nowrap min-w-fit space-x-2 p-2 text-white hover:bg-sky-600 bg-sky-500 rounded-lg"
        >
          {/* Display the Font Awesome icon */}
          <i className={`fa-solid ${item.icon} text-lg`}></i>
          <span className="text-base capitalize">{item.title}</span>
        </Link>
      ))}
    </nav>
  );
};

export default NavLinks;
