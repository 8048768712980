import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import GroupRegister from "./GroupRegister";
import StudentInscriptionFee from "./StudentInscriptionFee";
import { toast } from "react-toastify";
import CheckoutReceipt from "../../../Components/Receipts/CheckoutReceipt";
import moment from "moment";
import Loading from "Components/Loaders/Loading";

export default function InfoStudent({ student, hide, step, setStep }) {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();

	const [url, setUrl] = useAtom(urlAtom);
	const role = window.localStorage.getItem("role");
	const [groups, setGroups] = useState([]);
	const [receipt, setReceipt] = useState({});
	const [checkouts, setCheckouts] = useState([]);
	const [paid, setPaid] = useState({});
	const [loading, setLoading] = useState(false);

	// Fetch checkouts
	const getCheckouts = async () => {
		const options = {
			method: "get",
			url: `${url}/students/${student.id}/checkouts`,
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setCheckouts(response.data);
			})
			.catch(() => {});
	};

	// Handle payment
	const pay = () => {
		setLoading(true);

		const checkoutsToPay = Object.keys(paid).map((key) => ({
			id: key,
			paid_price: paid[key].paid_price,
			discount: paid[key].discount,
		}));
		axios({
			url: `${url}/checkouts/pay`,
			method: "post",
			data: {
				checkouts: checkoutsToPay,
			},
			headers: {
				Accept: "Application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				setReceipt(response.data);
				toast.success("Checkouts Paid successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setStep("checkout-receipt");
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	// Fetch groups
	const getGroups = () => {
		axios({
			url: `${url}/students/${student.id}/groups`,
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				setGroups(response.data);
			})
			.catch(() => {});
	};

	useEffect(() => {
		getGroups();
	}, []);

	useEffect(() => {
		getCheckouts();
	}, [step]);

	return (
		<div className="flex-auto p-4">
			<div className="block w-full overflow-x-auto">
				{step === "register" ? (
					<GroupRegister setStep={setStep} hide={hide} student={student} />
				) : step === "groups" ? (
					<div>
						<div className="flex flex-row items-center justify-between ">
							<h6 className="text-slate-400 font-bold uppercase">Student's Groups</h6>
							<div className="flex justify-center gap-4 my-4 mx-24">
								{paid.length > 0 && (
									<button
										className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
										type="button"
										onClick={pay}
										disabled={loading}
									>
										{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
										Pay
									</button>
								)}
							</div>
							<div className="flex gap-4">
								{!student.fee_inscription && (
									<Link className="text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700 font-bold uppercase" role="button" onClick={() => setStep("fees")}>
										<i className="fa-solid fa-plus"></i> Inscription Fees
									</Link>
								)}
								<Link className="text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700 font-bold uppercase" role="button" onClick={() => setStep("register")}>
									<i className="fa-solid fa-plus"></i> Register new group
								</Link>
							</div>
						</div>
						<div className="block min-w-0 break-words shadow-lg w-full overflow-x-auto">
							<table className="items-center w-full bg-transparent border-collapse">
								<thead>
									<tr>
										<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Date</th>
										<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Price</th>
										<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Discount</th>
										<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Paid Price</th>
										<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">To Be Paid Price</th>
										<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Teacher</th>
										<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Group</th>
									</tr>
								</thead>
								<tbody>
									{checkouts.map((checkout, id) => (
										<tr key={id} className={checkout.price - checkout.discount - checkout.paid_price <= 0 ? "bg-white hover:bg-slate-100" : "bg-rose-100"}>
											<th className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left flex items-center">
												{checkout.price - checkout.discount - checkout.paid_price > 0 && (
													<input
														type="checkbox"
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
														onChange={(event) => {
															setPaid((prev) => {
																const newPaid = { ...prev };
																if (event.target.checked) {
																	newPaid[checkout.id] = {
																		...checkout,
																		paid_price: checkout.price - checkout.discount - checkout.paid_price,
																	};
																} else {
																	delete newPaid[checkout.id];
																}
																return newPaid;
															});
														}}
													/>
												)}
												<span className="ml-3 font-bold">{moment(checkout.date).format("DD-MMMM hh:mm")}</span>
											</th>
											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">{checkout.price}</td>
											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">
												{paid[checkout.id] ? (
													<input
														type="number"
														onWheel={(event) => event.target.blur()}
														max={checkout.price}
														min={0}
														defaultValue={checkout.discount}
														className="outline-none border-none"
														onChange={(event) => {
															setPaid((prev) => {
																const newPaid = { ...prev };
																newPaid[checkout.id].discount = Number(event.target.value);
																newPaid[checkout.id].paid_price = Math.max(0, checkout.price - Number(event.target.value) - checkout.paid_price);
																return newPaid;
															});
														}}
													/>
												) : (
													checkout.discount
												)}
											</td>
											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">{checkout.paid_price}</td>
											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">
												{paid[checkout.id] ? (
													<input
														type="number"
														onWheel={(event) => event.target.blur()}
														max={checkout.price - paid[checkout.id].discount - checkout.paid_price}
														min={0}
														value={paid[checkout.id].paid_price}
														className="outline-none border-none"
														onChange={(event) => {
															setPaid((prev) => {
																const newPaid = { ...prev };
																newPaid[checkout.id].paid_price = Number(event.target.value);
																return newPaid;
															});
														}}
													/>
												) : (
													checkout.price - checkout.discount - checkout.paid_price
												)}
											</td>
											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">
												{checkout.group.teacher.user.name} {checkout.group.module}
											</td>
											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">{checkout.group.module}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				) : step === "fees" ? (
					<StudentInscriptionFee student={student} hide={() => setStep("groups")} next={() => setStep("register")} />
				) : step === "checkout-receipt" ? (
					<CheckoutReceipt receipt={receipt} next={() => setStep("groups")} />
				) : (
					""
				)}
			</div>
		</div>
	);
}
