import React, { useEffect, useLayoutEffect } from "react";
import { Route, Routes, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider, useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "index.css";
import AboutUs from "Pages/AboutUs";
import ContactUs from "Pages/ContactUs";
import Profile from "Management/Users/Profile/Profile";
import Login from "Auth/Login";
import Logout from "Auth/Logout";
import Register from "Auth/Register";
import Teachers from "Management/Users/Teachers/Teachers";
import Students from "Management/Users/Students/Students";
import Groups from "Management/Groups/Groups";
import Parents from "Management/Users/Parents/Parents";
import Levels from "Management/Levels/Levels";
import Users from "Management/Users/Users/Users";
import ForgotPassword from "Auth/ForgotPassword";
import ResentEmailVerification from "Auth/ResentEmailVerification";
import EmailVerification from "Auth/EmailVerification";
import Index from "Pages/Index";
import Error404 from "Pages/Errors/Error404";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error500 from "Pages/Errors/Error500";
import Sessions from "Management/Sessions/Sessions";
import Financials from "Management/Financials/Financials";
import DashboardLayout from "Layouts/DashboardLayout";
import Settings from "Management/Users/Profile/Settings";
import Cards from "Components/Cards/Cards";
import Posts from "Pages/News/Posts";
import FinancialStats from "Management/Financials/FinancialStats";
import "i18n";
import Inbox from "Management/Users/Profile/Inbox";
import User from "Management/Users/Users/User";
import Post from "Pages/News/Post";
import Level from "Management/Levels/Level";
import Group from "Management/Groups/Group";
import Library from "Pages/Library/Library";
import Products from "Pages/Library/Products/Products";
import PublicLibrary from "Pages/Library/PublicLibrary";
import Orders from "Pages/Library/Orders/Orders";
import Exams from "Management/Exams/Exams";
import ExamForm from "Management/Exams/ExamFrom";
import Exam from "Management/Exams/Exam";
import TakeExam from "Management/Exams/TakeExam";
import StudentExams from "Management/Exams/StudentExams";
import StudentAnswers from "Management/Exams/StudentAnswers";
import MyCheckouts from "Management/Users/Students/MyCheckouts";
import MyExams from "Management/Exams/MyExams";
import MyReceipts from "Management/Users/Users/MyReceipts";
import ResetPassword from "Auth/ResetPassword";
import Dawarat from "Pages/Dawarat/Dawarat";
import ProductDetails from "Pages/Library/ProductDetails";
import CartPage from "Pages/Library/CartPage";
import Order from "Pages/Library/Orders/Order";
import Product from "Pages/Library/Products/Product";
import Attendance from "Management/Sessions/Attendance";
import FinancialCards from "Components/Cards/FinancialCards";
import Program from "Pages/Program";
import OurTeachers from "Pages/OurTeachers";
import School from "Pages/School";
import Profession from "Pages/Profession";
import Academy from "Pages/Academy";
import Clients from "Pages/Library/Clients/Clients";
import { Dashboard as DawaratDashboard } from "Pages/Dawarat/Dashboard";
import { Amphitheaters } from "Pages/Dawarat/Amphitheaters/Amphitheaters";
import { Teachers as DawaratTeachers } from "Pages/Dawarat/Teachers/Teachers";
import { Attendance as DawaratPresences } from "Pages/Dawarat/Sessions/Attendance";
import { Sessions as DawaratSessions } from "Pages/Dawarat/Sessions/Sessions";
import { Tickets } from "Pages/Dawarat/Tickets/Tickets";
import { WaitingList } from "Pages/Dawarat/Tickets/WaitingList";
import { Dashboard } from "Pages/Dashboard";
import Dawarats from "Pages/Dawarat/Dawarat/Dawarats";
import GroupDetail from "Pages/GroupDetail";
import Client from "Pages/Library/Clients/Client";
import BuyProduct from "Pages/Library/BuyProduct";
import "aos/dist/aos.css";
import AOS from "aos";
import ReserveTicket from "Pages/Dawarat/Dawarat/ReserveTicket";
import InscriptionFees from "Management/Financials/InscriptionFees";
import Checkouts from "Management/Financials/Checkouts";
import Transactions from "Management/Financials/Transactions";
import Expenses from "Management/Financials/Expenses";

const departmentLinks = [
	{ icon: "fa-tachometer-alt", link: "/dashboard", title: "Dashboard" },
	{ icon: "fa-user-graduate", link: "/students", title: "Students" },
	{ icon: "fa-calendar-check", link: "/attendance", title: "Attendance" },
	{ icon: "fa-users", link: "/groups", title: "Groups" },
	{ icon: "fa-chalkboard-teacher", link: "/sessions", title: "Sessions" },
	{ icon: "fa-user-tie", link: "/teachers", title: "Teachers" },
];
const financialsLinks = [
	{ icon: "fa-solid fa-file-invoice-dollar", link: "/financials", title: "Financials" },
	{ icon: "fa-solid fa-money-check-dollar", link: "/financials/fees", title: "Inscription Fees" },
	{ icon: "fa-solid fa-shopping-cart", link: "/financials/checkouts", title: "Checkouts" },
	{ icon: "fa-solid fa-money-bill-transfer", link: "/financials/transactions", title: "Transactions" },
	{ icon: "fa-solid fa-wallet", link: "/financials/expenses", title: "Expenses" }
  ]
;
const libraryLinks = [
	{ icon: "fa-book", link: "/library/dashboard", title: "Library Dashboard" },
	{ icon: "fa-box-open", link: "/library/products", title: "Products" },
	{ icon: "fa-shopping-cart", link: "/library/orders", title: "Orders" },
	{ icon: "fa-user", link: "/library/clients", title: "Library Client" },
];
const dawaratLinks = [
	{ icon: "fa-chalkboard", link: "/dawarat/dashboard", title: "Dawarat" },
	{ icon: "fa-building", link: "/dawarat/amphitheaters", title: "Amphitheaters" },
	{ icon: "fa-users", link: "/dawarat/groups", title: "Dawarat Groups" },
	{ icon: "fa-chalkboard-teacher", link: "/dawarat/sessions", title: "Sessions" },
	{ icon: "fa-ticket-alt", link: "/dawarat/tickets", title: "Tickets" },
	{ icon: "fa-clipboard-check", link: "/dawarat/presences", title: "Presence" },
];

const role = localStorage.getItem("role");
let router = createBrowserRouter(
	createRoutesFromElements(
		<>
			{/* Main */}
			<Route path="/" exact element={<Index />} />
			<Route path="/about" exact element={<AboutUs />} />
			<Route path="/contact" exact element={<ContactUs />} />
			<Route path="/school" exact element={<School />} />
			<Route path="/profession" exact element={<Profession />} />
			<Route path="/academy" exact element={<Academy />} />
			<Route
				path="/dashboard"
				exact
				element={
					<DashboardLayout header={role === "admin" ? <Cards /> : <></>}>
						<Dashboard />
					</DashboardLayout>
				}
			/>
			<Route
				path="/news"
				exact
				element={
					<DashboardLayout>
						{" "}
						<Posts />{" "}
					</DashboardLayout>
				}
			/>
			<Route path="/our-teachers" exact element={<OurTeachers />} />
			<Route path="/group/:groupId" element={<GroupDetail />} />
			<Route path="/program" exact element={<Program />} />
			<Route
				path="/posts/:id"
				exact
				element={
					<DashboardLayout>
						{" "}
						<Post />{" "}
					</DashboardLayout>
				}
			/>

			{/* Auth */}
			<Route path="/login/:annex?" exact element={<Login />} />
			<Route path="/register/:annex?" element={<Register />} />
			<Route path="/logout" exact element={<Logout />} />
			<Route path="/forgot.password" exact element={<ForgotPassword />} />
			<Route path="/reset.password" exact element={<ResetPassword />} />
			<Route path="/email.resent.code" exact element={<ResentEmailVerification />} />
			<Route path="/email.verify" exact element={<EmailVerification />} />
			<Route
				path="/settings"
				exact
				element={
					<DashboardLayout>
						{" "}
						<Settings />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/profile"
				exact
				element={
					<DashboardLayout>
						{" "}
						<Profile />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/inbox"
				exact
				element={
					<DashboardLayout>
						{" "}
						<Inbox />{" "}
					</DashboardLayout>
				}
			/>

			{/* Management */}
			<Route
				path="/teachers"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Teachers />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/students"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Students />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/my-checkouts"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<MyCheckouts />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/my-exams"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<MyExams />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/my-receipts"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<MyReceipts />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/parents"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Parents />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/users"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Users />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/users/:id"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<User />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/levels"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Levels />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/levels/:id"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Level />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/groups"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Groups />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/groups/:id"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Group />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/sessions"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Sessions />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/attendance"
				exact
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Attendance />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/financials"
				exact
				element={
					<DashboardLayout links={financialsLinks}>
						{" "}
						<Financials />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/financials"
				exact
				element={
					<DashboardLayout links={financialsLinks}>
						{" "}
						<Financials />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/financials/fees"
				exact
				element={
					<DashboardLayout links={financialsLinks}>
						{" "}
						<InscriptionFees />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/financials/checkouts"
				exact
				element={
					<DashboardLayout links={financialsLinks}>
						{" "}
						<Checkouts />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/financials/transactions"
				exact
				element={
					<DashboardLayout links={financialsLinks}>
						{" "}
						<Transactions />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/financials/expenses"
				exact
				element={
					<DashboardLayout links={financialsLinks}>
						{" "}
						<Expenses />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/stats"
				exact
				element={
					<DashboardLayout header={<FinancialCards />}>
						{" "}
						<FinancialStats />{" "}
					</DashboardLayout>
				}
			/>

			{/* Library */}
			<Route path="/library" element={<PublicLibrary />} />
			<Route path="/library/cart" exact element={<CartPage />} />
			<Route path="/library/cart/:productId" exact element={<CartPage />} />
			<Route path="/library/buy/products/:productId" exact element={<BuyProduct />} />
			<Route path="/products/:id" element={<ProductDetails />} />
			<Route
				path="/library/dashboard"
				exact
				element={
					<DashboardLayout links={libraryLinks}>
						{" "}
						<Library />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/library/orders"
				element={
					<DashboardLayout links={libraryLinks}>
						{" "}
						<Orders />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/library/orders/:id"
				element={
					<DashboardLayout links={libraryLinks}>
						{" "}
						<Order />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/library/clients"
				element={
					<DashboardLayout links={libraryLinks}>
						{" "}
						<Clients />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/library/clients/:id"
				element={
					<DashboardLayout links={libraryLinks}>
						{" "}
						<Client />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/library/orders/:id"
				element={
					<DashboardLayout links={libraryLinks}>
						<Order />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/library/products"
				element={
					<DashboardLayout links={libraryLinks}>
						{" "}
						<Products />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/library/products/:id"
				element={
					<DashboardLayout links={libraryLinks}>
						{" "}
						<Product />{" "}
					</DashboardLayout>
				}
			/>

			{/* Exams */}
			<Route
				path="/exams"
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Exams />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/exams/create"
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<ExamForm />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/exams/:exam_id/take"
				element={
					<DashboardLayout>
						{" "}
						<TakeExam />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/exams/:exam_id"
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<Exam />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/students/:student_id/exams"
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<StudentExams />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/students/:student_id/exams/:exam_id"
				element={
					<DashboardLayout links={departmentLinks}>
						{" "}
						<StudentAnswers />{" "}
					</DashboardLayout>
				}
			/>

			{/* Dawarat */}
			<Route path="/dawarat" element={<Dawarat />} />
			<Route path="/dawarat/:id/register" element={<ReserveTicket />} />
			<Route
				path="/dawarat/dashboard"
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<DawaratDashboard />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/dawarat/groups"
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<Dawarats />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/dawarat/amphitheaters"
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<Amphitheaters />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/dawarat/students"
				exact
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<Students />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/dawarat/teachers"
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<DawaratTeachers />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/dawarat/sessions"
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<DawaratSessions />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/dawarat/tickets"
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<Tickets />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/dawarat/presences"
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<DawaratPresences />{" "}
					</DashboardLayout>
				}
			/>
			<Route
				path="/dawarat/waiting-list"
				element={
					<DashboardLayout links={dawaratLinks}>
						{" "}
						<WaitingList />{" "}
					</DashboardLayout>
				}
			/>

			{/* Error pages */}
			<Route path="/404" exact element={<Error404 />} />
			<Route path="/500" exact element={<Error500 />} />

			{/* Page Not Found */}
			<Route path="*" element={<Error404 />} />
		</>
	)
);

export default function App() {
	useLayoutEffect(() => {
		AOS.init();
	});
	return (
		<>
			<ToastContainer />
			<RouterProvider router={router} />
		</>
	);
}
