import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from "@fullcalendar/rrule";
import adaptivePlugin from "@fullcalendar/adaptive";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";

// components

function calculateDuration(start, end) {
	const startDate = new Date(start);
	const endDate = new Date(end);
	const durationMs = endDate - startDate;
	const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
	const durationMinutes = Math.floor((durationMs / (1000 * 60)) % 60);

	return `${padZero(durationHours)}:${padZero(durationMinutes)}`;
}

function padZero(value) {
	return value.toString().padStart(2, "0");
}
export default function GroupSessions({ group }) {
	function renderEventContent(eventInfo) {
		return (
			<div className="flex p-2 flex-col overflow-auto transition-all ease-linear duration-200 ">
				<b>{eventInfo.timeText}</b>
				<i>{eventInfo.event.title}</i>
			</div>
		);
	}

	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const navigate = useNavigate();
	const calendarRef = useRef();
	const resources = [
		{ id: "A1", title: "A1", eventColor: "#104e84" },
		{ id: "A2", title: "A2", eventColor: "#1631c4" },
		{ id: "S1", title: "S1", eventColor: "#0f5bdd" },
		{ id: "S2", title: "S2", eventColor: "#1290d4" },
		{ id: "CC", title: "CC", eventColor: "#1ec3c0" },
		{ id: "OPTIONAL", title: "OPTIONAL", eventColor: "#b3b3b3" },
	];
	const [sessions, setSessions] = useState([]);
	const [calendarApi, setCalendarApi] = useState();

	const getSessions = () => {
		axios({
			// Endpoint to send files
			url: url + "/groups/" + group.id + "/sessions",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response.data;
				data = data.map((option) => {
					return {
						backgroundColor:
							option.classroom === "A1"
								? "#104e84"
								: option.classroom === "A2"
								? "#1631c4"
								: option.classroom === "S1"
								? "#0f5bdd"
								: option.classroom === "S2"
								? "#1290d4"
								: option.classroom === "CC"
								? "#1ec3c0"
								: option.classroom === "OPTIONAL"
								? "#b3b3b3"
								: "",
						borderColor: "white",
						resourceId: option.classroom,
						title: option.group?.teacher?.user.name + " " + option.group?.module,
						extendedProps: {
							session: {
								...option,
								label: option.group.teacher_id + " " + option.group_id + " " + option.group.level.education + " " + option.group.level.year + " " + option.group.level.specialty,
							},
							exdate: option.repeating === "once" ? [] : option.exceptions.map((exception) => exception.date),
							rrule:
								option.repeating === "once"
									? null
									: {
											freq: option.repeating,
											dtstart: `${option.starts_at}Z`,
									  },
						},
						duration: calculateDuration(`${option.starts_at}Z`, `${option.ends_at}Z`),
						start: new Date(`${option.starts_at}Z`),
						end: new Date(`${option.ends_at}Z`),
						rrule:
							option.repeating === "once"
								? null
								: {
										freq: option.repeating,
										dtstart: `${option.starts_at}Z`,
								  },
						exdate: option.repeating === "once" ? [] : option.exceptions.map((exception) => exception.date),
					};
				});
				setSessions(data);
			})
			.catch(() => {});
	};
	useEffect(() => {
		setCalendarApi(calendarRef.current.getApi());

		getSessions();
	}, []);

	return (
		<>
			<div className=" w-full flex gap-4 flex-col">
				<FullCalendar
					viewClassNames={"bg-white"}
					navLinks={true}
					navLinkDayClick={"resourceTimeline"}
					titleFormat={{ weekday: "long" }}
					dayHeaderFormat={{ weekday: "long", meridiem: "long" }}
					schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY}
					resourceAreaHeaderContent="Classrooms"
					aspectRatio={1}
					ref={calendarRef}
					plugins={[resourceTimelinePlugin, listPlugin, timeGridPlugin, interactionPlugin, adaptivePlugin, resourceTimeGridPlugin, rrulePlugin]}
					slotDuration="00:30:00"
					slotMinTime={"06:00:00"}
					slotMaxTime={"22:00:00"}
					headerToolbar={{
						left: "prev,next today",
						center: "title",
						right: "timeLineWeek,resourceTimeline,timeGridWeek,listWeek",
					}}
					eventContent={renderEventContent}
					initialView="timeLineWeek"
					views={{
						timeLineWeek: {
							type: "resourceTimelineWeek",
							slotDuration: { days: 1 },
							slotLabelFormat: [{ weekday: "long" }],
						},
					}}
					resources={resources}
					events={sessions}
					resourceAreaWidth="10%"
					contentHeight={"auto"}
					allDaySlot={false}
					height={"80vh"}
					timeZone="UTC"
					buttonText={{
						today: "Today",
						week: "Week",
						resourceTimeline: "Day",

						list: "List",
						resourceTimeGrid: "Time Line",
						timeLineWeek: "Week Time Line",
					}}
				/>
			</div>
		</>
	);
}
