import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import Form from "./Form";
import moment from "moment";
import Swal from "sweetalert2";
import FormModal from "Components/Dropdowns/FormModal";
import { toast } from "react-toastify";

export default function Products() {
	const token = localStorage.getItem("token");
	const navigate = useNavigate();

	const url = process.env.REACT_APP_API_URL_LIBRARY;

	const [method, setMethod] = useState("add");
	const [form, setForm] = useState(false);
	const [product, setProduct] = useState({});
	const [products, setProducts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	const getProducts = async () => {
		axios({
			method: "get",
			url: url + "/products",
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setProducts(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => {});
	};

	const showForm = (action, data) => {
		setMethod(action);
		setProduct(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	const destroy = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to delete this product? This action cannot be undone!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				axios({
					url: url + "/products/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
					.then((response) => {
						toast.success("Product deleted successfully", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
						getProducts(); // Refresh product list
					})
					.catch(() => {});
			}
		});
	};

	useEffect(() => {
		getProducts();
	}, []);

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Product Form"}>
					<Form action={method} data={product} hide={hide} getProducts={getProducts}></Form>
				</FormModal>
			) : (
				""
			)}
			<h4 className=" font-bold">Products</h4>
			<div className="break-words w-full shadow-lg border-0">
				<div className="w-full">
					<table className=" w-full border-collapse ">
						<thead className="sticky top-0 whitespace-nowrap">
							<tr>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Name</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Purchase price</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Price</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Qte</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Level</th>

								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>purchase date</th>

								<th className={" uppercase font-semibold flex justify-around transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
									<Link
										className="w-full ease-linear "
										role={"button"}
										onClick={(event) => {
											event.preventDefault();
											showForm("add");
										}}
									>
										Add
										<i class="fa-solid fa-plus "></i>
									</Link>
								</th>
							</tr>
						</thead>
						<tbody>
							{products.map((product, id) => {
								return (
									<tr key={id} className="bg-white hover:bg-slate-100">
										<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
											<span className={"font-bold "}>{product.name}</span>
										</th>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{product.purchase_price}.00 DA</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{product.price}.00 DA</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{product.qte}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{product.level}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{moment(product.purchase_date).format("DD-MMMM hh:mm")}</td>

										<td className={" whitespace-nowrap border border-slate-200 text-left items-center flex justify-evenly gap-2"}>
											<Link
												role={"button"}
												onClick={(event) => {
													event.preventDefault();
													navigate("/library/buy/products/" + product.id);
												}}
												className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-link"></i>
											</Link>
											<Link
												role={"button"}
												onClick={(event) => {
													event.preventDefault();
													navigate("/library/products/" + product.id);
												}}
												className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-info"></i>
											</Link>
											<Link
												role={"button"}
												onClick={(event) => {
													event.stopPropagation();
													showForm("edit", product);
												}}
												className="hover:text-yellow-400 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-pen-to-square"></i>
											</Link>
											<Link
												role={"button"}
												onClick={(event) => {
													event.stopPropagation();
													destroy(product.id);
												}}
												className="hover:text-red-600 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-trash"></i>
											</Link>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="flex flex-row justify-center gap-2 my-4 ">
				<button
					className="px-4 py-2  flex items-center justify-center gap-4 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === 1}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					<i className="fa-solid fa-backward "></i>
					Previous
				</button>
				<button
					className="px-4 py-2 flex items-center justify-center gap-4 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === totalPages}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					Next
					<i className="fa-solid fa-forward "></i>
				</button>
			</div>
		</>
	);
}
