import React, { useEffect, useState } from "react";
import axios from "config/axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import PostForm from "./PostForm";
import moment from "moment/moment";
import { toast } from "react-toastify";
import PortalWrapper from "Layouts/PortalWrapper";
import FormModal from "Components/Dropdowns/FormModal";

// components

export default function Posts() {
	const [posts, setPosts] = useState([]);
	const token = window.localStorage.getItem("token");
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const user_id = window.localStorage.getItem("user_id");

	const navigate = useNavigate();

	const [form, setForm] = useState(false);
	const [post, setPost] = useState({});
	const [method, setMethod] = useState("add");

	const showForm = (action, data) => {
		setMethod(action);
		setPost(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	const destroy = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to delete this post? This action cannot be undone!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				axios({
					url: auth_api_url + "/posts/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
					.then((response) => {
						toast.success("Post deleted successfully", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
						getPosts(); // Refresh posts list
					})
					.catch(() => {});
			}
		});
	};

	const getPosts = async () => {
		let options = {
			method: "get",
			url: auth_api_url + "/posts",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setPosts(response.data);
			})
			.catch(() => {});
	};
	useEffect(() => {
		if (token) {
			getPosts();
		}
	}, []);
	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Level Form"}>
					<PostForm action={method} data={post} hide={hide} getPosts={getPosts}></PostForm>
				</FormModal>
			) : (
				""
			)}
			<div className="flex flex-row justify-between items-center ">
				<h4 className=" font-bold">Latest News</h4>
				<Link
					className="bg-blue-500 px-4 py-2 text-white flex w-fit items-center gap-2 justify-center rounded-lg hover:bg-sky-900 ease-linear transition-all duration-150"
					role={"button"}
					onClick={(event) => {
						event.preventDefault();
						showForm("add");
					}}
				>
					<i class="fa-solid fa-plus"></i>
					<span>Create new post</span>
				</Link>
			</div>
			{posts.map((post, id) => {
				const imgUrl = `${auth_api_url}/files?url=${post.user.profile_picture.url}`;

				return (
					<div className={" flex flex-col gap-4 w-full shadow-lg rounded-lg bg-white p-4 my-4"}>
						<div className="flex flex-row justify-between gap-4">
							<div
								onClick={(event) => {
									event.preventDefault();
									navigate("/posts/" + post.id);
								}}
								className=" font-bold font-mono cursor-pointer"
							>
								{post.title}
							</div>
							<div className="flex gap-4 flex-row">
								{user_id == post.user_id ? (
									<>
										<Link
											className="bg-blue-500 p-2 text-white active:bg-indigo-600 font-bold uppercase rounded-lg outline-none focus:outline-none ease-linear transition-all duration-150 hover:text-slate-700"
											role={"button"}
											onClick={(event) => {
												event.preventDefault();
												showForm("edit", post);
											}}
										>
											<i class="fa-solid fa-edit"></i>
										</Link>
										<Link
											className="bg-blue-500 p-2 text-white active:bg-indigo-600 font-bold uppercase rounded-lg outline-none focus:outline-none ease-linear transition-all duration-150 hover:text-red-600"
											role={"button"}
											onClick={(event) => {
												event.preventDefault();
												destroy(post.id);
											}}
										>
											<i class="fa-solid fa-trash"></i>
										</Link>
									</>
								) : (
									""
								)}
							</div>
						</div>
						<div>{post.content}</div>
						<div className="w-full flex flex-row overflow-auto gap-4 p-4">
							{post.photos.map((photo, index) => {
								const imgUrl = `${auth_api_url}/files?url=${photo.url}`;

								return (
									<div key={index} className="relative min-w-fit ">
										<img className="w-[200px] h-[120px] object-cover rounded-lg shadow-lg" src={imgUrl}></img>
									</div>
								);
							})}
						</div>
						<hr className=" w-full" />
						<div className="mt-5 flex flex-row gap-4 items-end">
							<img className=" w-20 h-20 rounded-full " src={imgUrl}></img>
							<div>
								<div className="font-bold">{post.user.name}</div>
								<div>{moment(post.updated_at).format("Do-MM-YYYY LT")}</div>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
}
