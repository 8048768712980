import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
	const { t } = useTranslation();

	return (
		<footer className=" relative flex w-full flex-col text-white ">
			<div className="relative ">
				<img className="md:w-full object-contain absolute bottom-0" src={require("assets/img/footer.png")} />
			</div>
			<div className="z-40 bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] w-full px-24 py-8">
				<div className="flex flex-col ">
					<div className="flex flex-row flex-wrap my-2">
						<div className="flex flex-col md:w-1/4 w-1/2 gap-4">
							<img className=" object-contain w-32 h-32" src={require("assets/img/Numidia Education Group Logo/png/white.png")} />
							<h5 className="">{t("stayTuned")}</h5>
							<h5 className=" ">{t("becomeStudent")}</h5>
						</div>
						<div className="flex flex-row justify-evenly md:w-3/4 w-full gap-2">
							<div className="flex flex-col md:w-fit w-1/2  gap-2">
								<h5 className=" font-bold">{t("platform")}</h5>
								<ul className="flex flex-col capitalize gap-2">
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("home")}
										</Link>
									</li>
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("about")}
										</Link>
									</li>
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("github")}
										</Link>
									</li>
								</ul>
							</div>
							<div className="flex flex-col md:w-fit w-1/2 gap-2">
								<h5 className=" font-bold">{t("help")}</h5>
								<ul className="flex flex-col capitalize  gap-2">
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("termsConditions")}
										</Link>
									</li>
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("howItWorks")}
										</Link>
									</li>
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("mitLicense")}
										</Link>
									</li>
								</ul>
							</div>
							<div className="flex flex-col md:w-fit w-1/2 gap-2">
								<h5 className=" font-bold">{t("contact")}</h5>
								<ul className="flex flex-col capitalize gap-2">
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("contactForm")}
										</Link>
									</li>
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("phoneNumber")}
										</Link>
									</li>
									<li>
										<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/">
											{t("address")}
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<hr className=" border-b-2 border-white my-2" />
					<div className="flex flex-wrap items-center md:justify-between justify-center  my-4">
						<div className="w-full md:w-4/12 ">
							<div className=" font-semibold text-center md:text-left">
								Copyright © {new Date().getFullYear()}{" "}
								<a href="https://hoceyne.me" target="_blank" className="text-slate-100 hover:text-slate-900 font-semibold ">
									Souala El-Houssine
								</a>
							</div>
						</div>
						<div className="w-full md:w-8/12 ">
							<ul className="flex flex-wrap list-none md:justify-end justify-center">
								<li>
									<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/about">
										<i className="fa-solid fa-info"></i>
									</Link>
								</li>
								<li>
									<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="contact">
										<i className="fa-solid fa-phone"></i>
									</Link>
								</li>
								<li>
									<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="/about">
										<i className="fa-brands fa-facebook"></i>
									</Link>
								</li>
								<li>
									<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="contact">
										<i className="fa-brands fa-instagram"></i>
									</Link>
								</li>
								<li>
									<Link className=" px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] rounded-lg transition-all duration-100 ease-linear " to="contact">
										<i className="fa-brands fa-youtube"></i>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
