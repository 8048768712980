
import TeacherExams from "Management/Users/Teachers/MyExams";
import StudentExams from "Management/Users/Students/MyExams";

// components

export default function MyExams() {
    const role = localStorage.getItem("role");
    return (
        <>
            {role === "student" ? (
                <StudentExams />
            ) : role === "teacher" ? (
                <TeacherExams />
            ) : ""}
        </>
    );
}
