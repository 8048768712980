import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import FooterSmallDark from "Components/Footers/FooterSmallDark";
import Loading from "Components/Loaders/Loading";

export default function ForgotPassword() {
	const url = process.env.REACT_APP_API_URL_AUTH;

	const [email, setEmail] = useState("");
	const [action, setActionButton] = useState("Send Now");
	const [loading, setLoading] = useState(false);

	let navigate = useNavigate();

	const send = () => {
		let data = {
			email,
		};
		setLoading(true);
		axios({
			// Endpoint to send files
			url: url + "/password/forgot",
			method: "POST",
			data: data,
			headers: {
				Accept: "Application/json",
			},

			// Attaching the form data
			data: data,
		})
			// Handle the response from backend here
			.then((response) => {
				Swal.fire({
					title: "email sent successfuly",
					text: "Verify Your Email",
					icon: "success",

					iconColor: "#3dc00c",
				}).then(async () => {
					navigate("/reset.password");
				});
			})
			.catch(() => { })
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => { }, []);

	return (
		<div className="flex flex-col bg-gradient-to-r min-h-screen from-[rgb(1,42,74)] to-sky-600 w-full h-fit justify-between">
			<div className="flex content-center  justify-center z-50 m-auto p-8 w-full">
				<div className="w-full lg:w-1/3 my-24">
					<div className="relative flex flex-col break-words w-full  shadow-lg rounded-3xl bg-slate-200 p-6 ">
						<Link to="/" className="absolute w-24 h-24 left-1/2 -translate-x-1/2 -translate-y-2/3 rounded-full bg-white z-50 shadow-lg ">
							<img className="object-contain w-full h-full" src={require("assets/img/auth-logo.png")} />
						</Link>

						<div className="flex-auto  mt-12 ">
							<div className="flex-auto ">
								<div className="text-center ">
									<h6 className="  font-bold">Password Restore Process</h6>
								</div>
								<div className="text-slate-600 text-center ">
									<small>
										Forgot your password? don't worry just enter your email, and we'll send further instructions. for more clarification{" "}
										<Link className="text-blue-600 underline" to="/contact.us">
											contact us
										</Link>
										.
									</small>
								</div>
								<form>
									<div className="relative w-full my-2">
										<input
											type="email"
											className="p-3  placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											placeholder="Email"
											onChange={(event) => {
												setEmail(event.target.value);
											}}
										/>
									</div>
									<div className="text-center  my-4">
										<button
											className="bg-[rgb(10,58,92)] text-white active:bg-slate-600 font-bold capitalize py-2  rounded-lg shadow hover:shadow-lg outline-none focus:outline-none   w-full ease-linear transition-all duration-150"
											type="button"
											onClick={() => send()}
											disabled={loading}
										>
											{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}

											{action}
										</button>
									</div>
								</form>
							</div>
							<div className="flex flex-wrap   relative">
								<div className="w-1/2 text-left">
									<Link to="/login" className="text-slate-700">
										<small>Go back to login</small>
									</Link>
								</div>
								<div className="w-1/2 text-right">
									<Link to="/reset.password" className="text-slate-700">
										<small>Already got the code?</small>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FooterSmallDark />
		</div>
	);
}
