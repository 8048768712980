import { useState } from "react";

export default function Group() {
	const [group,setGroup] = useState({});
	return (
		<>
			
		</>
	);
}
