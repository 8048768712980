import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Swal from "sweetalert2";
// components

export default function Exams() {
	const [url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");
	const [exams, setExams] = useState([]);
	const [exam, setExam] = useState({});
	const role = localStorage.getItem("role");
	const navigate = useNavigate();
	const getExams = () => {
		axios({
			// Endpoint to send files
			url: url + "/exams",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			setExams(response.data);
		}).catch(() => { });
	};
	const closeExam = (id) => {
		axios({
			// Endpoint to send files
			url: url + "/exams/" + id + "/close",
			method: "delete",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			toast.success("Exam closed successfuly", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			getExams();
		}).catch(() => { });
	};
	const deleteExam = (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you really want to delete this exam? This action cannot be undone!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.isConfirmed) {
				axios({
					// Endpoint to send files
					url: url + "/exams/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
				.then((response) => {
					toast.success("Exam deleted successfully", {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					getExams();  // Refresh exam list
				})
				.catch(() => {
				});
			}
		});
	};
	const openExam = (id) => {
		axios({
			// Endpoint to send files
			url: url + "/exams/" + id + "/open",
			method: "put",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			toast.success("Exam opened successfuly", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			getExams();
		}).catch(() => { });
	};
	useEffect(() => {
		getExams();
	}, []);
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-row justify-between">
				<h4 className=" font-bold">Exams</h4>
				{role !== "student" && role !== "supervisor" ? (
					<button
						onClick={(event) => {
							event.stopPropagation();
							navigate("/exams/create");
						}}
						className="text-white bg-blue-500 hover:bg-sky-900 rounded-lg h-fit px-4 py-2 "
					>
						Create Exam
					</button>
				) : (
					""
				)}
			</div>
			<div className="grid grid-cols-3 gap-4">
				{exams.map((exam, id) => {
					return (
						<div className="rounded-lg bg-white shadow-lg  flex flex-col gap-4 p-4 hover:shadow-sm hover:border-slate-100 hover:border">
							<div className="flex flex-row justify-between">
								<h4 className=" font-medium flex flex-col">
									{exam.title} <span className={exam.status === "closed" ? "text-red-500" : exam.status === "pending" ? "text-yellow-500" : "text-green-500"}>{exam.status}</span>
								</h4>
								<div className="flex flex-row items-center justify-center gap-4 ">
									<button
										onClick={(event) => {
											event.stopPropagation();
											navigate("/exams/" + exam.id);
										}}
									>
										<i className="fa-solid fa-info text-sky-500  hover:bg-sky-100 hover:shadow-md p-2 rounded-full text-center"></i>
									</button>
									{
										/* {role === "teacher"*/ true ? (
											<>
												{exam.status === "opened" ? (
													<button
														onClick={(event) => {
															event.stopPropagation();
															closeExam(exam.id);
														}}
													>
														<i className="fa-solid fa-lock text-yellow-500 hover:bg-yellow-100 hover:shadow-md p-2 rounded-full text-center "></i>
													</button>
												) : (
													<button
														onClick={(event) => {
															event.stopPropagation();
															openExam(exam.id);
														}}
													>
														<i className="fa-solid fa-lock-open text-green-500 hover:bg-green-100 hover:shadow-md p-2 rounded-full text-center "></i>
													</button>
												)}
												<button
													onClick={(event) => {
														event.stopPropagation();
														deleteExam(exam.id);
													}}
												>
													<i className="fa-solid fa-trash text-red-500 hover:bg-red-100 hover:shadow-md p-2 rounded-full text-center "></i>
												</button>
											</>
										) : (
											""
										)
									}
								</div>
							</div>

							<div className="flex flex-col gap-2">
								<div className="flex flex-row gap-2">
									<label>date: </label>
									<p>{moment(exam.date).format("DD-MMMM hh:mm")}</p>
								</div>
								<div className="flex flex-row gap-2">
									<label>duration: </label>
									<p>{exam.duration} min</p>
								</div>
								<div className="flex flex-row gap-2">
									<label>questions: </label>
									<p>{exam.nb_question}</p>
								</div>
							</div>
							{exam.status === "opened" && /*role === "student"*/ true && (
								<div className="flex flex-row w-full">
									<button
										onClick={(event) => {
											event.stopPropagation();
											navigate("/exams/" + exam.id + "/take");
										}}
										className="text-white w-full bg-sky-500 hover:bg-sky-600 rounded-lg  px-4 py-2"
									>
										Take Exam
									</button>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
}
