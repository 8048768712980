import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "Components/Loaders/Loading";

export default function GroupRegister({ student, hide, setStep }) {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();

	const [url, setUrl] = useAtom(urlAtom);
	const [other_groups, setOtherGroups] = useState([]);
	const [groups, setGroups] = useState({});
	const [loading, setLoading] = useState(false);

	const get_other_groups = () => {
		axios({
			// Endpoint to send files
			url: `${url}/students/${student.id}/groups/unenrolled`,
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				setOtherGroups(response.data);
			})
			.catch(() => {});
	};

	const add_group = async () => {
		setLoading(true);
		let okay = true;

		// Loop through groups and handle the confirmation properly
		for (const key of Object.keys(groups)) {
			const group = groups[key];

			// Check if the group has reached its capacity
			if (group.students.length >= group.capacity) {
				const result = await Swal.fire({
					title: "Max class members",
					text: `This group: ${group.teacher.user.name} ${group.module}: ${group.level.year} ${group.level.education} ${group.level.specialty} reached max class members`,
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#00f23d",
					confirmButtonText: "Confirm",
					cancelButtonText: "Cancel",
				});

				// If the user cancels, stop further processing
				if (!result.isConfirmed) {
					okay = false;
					break;
				}
			}
		}

		if (okay) {
			try {
				// Send the groups data to the server
				await axios({
					url: `${url}/students/${student.id}/groups`,
					method: "post",
					data: {
						groups: Object.values(groups),
					},
					headers: {
						Accept: "Application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				hide();
				toast.success("Group added successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			} catch (error) {
				toast.error("Failed to add group", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			} finally {
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		get_other_groups();
	}, []);

	useEffect(() => {
		console.log(groups);
	}, [groups]);

	return (
		<>
			<div className="flex flex-row items-center justify-between ">
				<h6 className="text-slate-400 font-bold uppercase">Registration Form</h6>
				<div className="flex justify-center my-4 ">
					{Object.keys(groups).length>0  && (
						<button
							className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
							type="button"
							onClick={add_group}
							disabled={loading}
						>
							{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
							Register Group Now
						</button>
					)}
				</div>
				<Link className="text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700 font-bold uppercase" role={"button"} onClick={() => setStep("groups")}>
					<>
						<i className="fa-solid fa-close"></i> Cancel
					</>
				</Link>
			</div>
			<div className="overflow-auto max-h-96">
				<table className="items-center w-full bg-transparent border-collapse">
					<thead>
						<tr>
							<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Name</th>
							<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Capacity</th>
							<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Teacher</th>
							<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Price</th>
							<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Number of Rest Sessions</th>
							<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Discount</th>
							<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Total</th>
						</tr>
					</thead>
					<tbody>
						{other_groups.map((other_group, id) => (
							<tr key={id}>
								<th className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left bg-white">
									<input
										type="checkbox"
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
										onChange={(event) => {
											setGroups((prev) => {
												const newGroups = { ...prev };
												if (event.target.checked) {
													newGroups[other_group.id] = {
														...other_group,
														rest_session: other_group.nb_session - other_group.current_nb_session + 1,
														discount: 0,
													};
												} else {
													delete newGroups[other_group.id];
												}
												return newGroups;
											});
										}}
									/>
									<span className="ml-3 font-bold text-slate-600">{other_group.name}</span>
								</th>
								<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left bg-white">
									{other_group.students?.length} / {other_group.capacity}
								</td>
								<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left bg-white">{other_group.teacher.user.name}</td>
								<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left bg-white">{other_group.price_per_month}</td>
								<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left bg-white">{other_group.nb_session - other_group.current_nb_session + 1}</td>
								<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left bg-white">
									{groups[other_group.id] ? (
										<input
											type="number"
											onWheel={(event) => event.target.blur()}
											className="outline-none border"
											defaultValue={0}
											max={other_group.price_per_month}
											min={0}
											onChange={(event) => {
												const discount = event.target.value;
												setGroups((prev) => {
													const newGroups = { ...prev };
													newGroups[other_group.id].discount = discount;
													return newGroups;
												});
											}}
										/>
									) : (
										"Select the group first"
									)}
								</td>
								<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left bg-white">
									{groups[other_group.id]
										? (other_group.price_per_month / other_group.nb_session) * groups[other_group.id].rest_session - groups[other_group.id].discount
										: other_group.price_per_month}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
}
