import { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SelectOption from "Components/Dropdowns/SelectOption";
import Loading from "Components/Loaders/Loading";

// components

export default function TeacherForm({ action, data, getTeachers, hide }) {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const method = action === "add" ? "post" : "put";
	const [email, setEmail] = useState("");

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [name, setName] = useState("");
	const [gender, setGender] = useState("male");
	const [phone_number, setPhoneNumber] = useState("");
	const [module, setModule] = useState("");
	const [module_list, setModuleList] = useState([]);
	const subjects = [
		{ id: "arabic", label: "Arabic" },
		{ id: "french", label: "French" },
		{ id: "english", label: "English" },
		{ id: "mathematics", label: "Mathematics" },
		{ id: "islamic_education", label: "Islamic Education" },
		{ id: "civic_education", label: "Civic Education" },
		{ id: "history", label: "History" },
		{ id: "geography", label: "Geography" },
		{ id: "science", label: "Science" },
		{ id: "physics", label: "Physics" },
		{ id: "chemistry", label: "Chemistry" },
		{ id: "biology", label: "Biology" },
		{ id: "geology", label: "Geology" },
		{ id: "arts", label: "Arts" },
		{ id: "music", label: "Music" },
		{ id: "physical_education", label: "Physical Education" },
		{ id: "technology", label: "Technology" },
		{ id: "computer_science", label: "Computer Science" },
		{ id: "berber_languages", label: "Berber Languages" },
		{ id: "spanish", label: "Spanish" },
		{ id: "italian", label: "Italian" },
		{ id: "german", label: "German" },
		{ id: "philosophy", label: "Philosophy" },
		{ id: "sociology", label: "Sociology" },
		{ id: "economics", label: "Economics" },
		{ id: "psychology", label: "Psychology" },
		{ id: "electrotechnology", label: "Electrotechnology" },
		{ id: "mechanics", label: "Mechanics" },
		{ id: "public_works", label: "Public Works" },
		{ id: "construction", label: "Construction" },
		{ id: "accounting", label: "Accounting" },
		{ id: "agriculture", label: "Agriculture" },
		{ id: "tourism", label: "Tourism" },
		{ id: "health", label: "Health" },
		{ id: "administration", label: "Administration" },
	];

	const [level, setLevel] = useState("");
	const [level_list, setLevelList] = useState([]);
	const [percentage, setPercentage] = useState();
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	function generateEmail(event) {
		event.preventDefault();
		var formattedName = lastName.toLowerCase().replace(/\s/g, "") + firstName.toLowerCase().replace(/\s/g, "");
		var randomNumber = Math.floor(Math.random() * 9000) + 1000;
		var email = formattedName + randomNumber + "@numidia.com";

		setEmail(email);
	}
	const add_teacher = () => {
		setLoading(true);

		axios({
			// Endpoint to send files
			url: action === "add" ? url + "/users" : url + "/users/" + data.user.id,
			method: method,
			data: {
				role: "teacher",
				email,
				name: action === "edit" ? name : lastName + " " + firstName,
				phone_number,
				gender,
				modules: module_list,
				levels: level_list,
				percentage,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				getTeachers();
				toast.success("Teacher " + action + "ed", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				hide();
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (action === "edit") {
			setName(data.user.name);
			setGender(data.user.gender);
			setPhoneNumber(data.user.phone_number);
			setEmail(data.user.email);
			setPercentage(data.percentage);
			setModuleList(data.modules.split("|"));
			setLevelList(data.levels.split("|"));
		}
	}, []);
	return (
		<div className="p-4 ">
			<form>
				<div className="flex flex-wrap gap-4">
					{action === "edit" ? (
						<div className="w-full  ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="First Name"
								onChange={(event) => {
									setName(event.target.value);
								}}
								value={name}
							/>
						</div>
					) : (
						<>
							<div className="flex-1  ">
								<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
									Last Name
								</label>
								<input
									type="text"
									className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="Last Name"
									onChange={(event) => {
										setLastName(event.target.value);
									}}
									value={lastName}
								/>
							</div>
							<div className=" flex-1 ">
								<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
									First Name
								</label>
								<input
									type="text"
									className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="First Name"
									onChange={(event) => {
										setFirstName(event.target.value);
									}}
									value={firstName}
								/>
							</div>
						</>
					)}

					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Gender
						</label>
						<select
							className="my-2 p-2 placeholder-blueGray-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							default="male"
							onChange={(event) => {
								setGender(event.target.value);
							}}
						>
							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>
					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Phone Number
						</label>
						<input
							type="number"
							className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Phone Number"
							onChange={(event) => {
								setPhoneNumber((prev) => event.target.value);
							}}
							onWheel={(event) => event.target.blur()}
						/>
					</div>

					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Email address
						</label>
						<div className="flex my-2 gap-4">
							<input
								type="email"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Email"
								onChange={(event) => {
									setEmail(event.target.value);
								}}
								value={email}
							/>
							<button className="bg-white rounded-lg shadow p-2 flex items-center justify-center" onClick={generateEmail}>
								<i className="fa-solid fa-gear"></i>{" "}
							</button>
						</div>
					</div>

					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Percentage
						</label>
						<input
							type="number"
							onWheel={(event) => event.target.blur()}
							className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Percentage"
							onChange={(event) => {
								setPercentage(event.target.value);
							}}
							value={percentage}
						/>
					</div>
					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Levels
						</label>
						<div className="w-full flex flex-row gap-4 my-2">
							<select
								className=" p-2   text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								onChange={(event) => {
									event.preventDefault();
									const l = event.target.value;
									if (l.trim() !== "") {
										setLevelList((prev) => {
											// Add only if the level `l` is not already in the list
											if (!prev.includes(l)) {
												return [...prev, l];
											}
											return prev;
										});
									}
									event.target.value = "";
								}}
							>
								<option value="">Select Education</option>

								<option value="preschool">Preschool</option>
								<option value="primary school">Primary School</option>
								<option value="middle school">Middle School</option>
								<option value="high school">High School</option>
								<option value="higher education">Higher Education</option>
							</select>
						</div>
						<ul className="flex flex-row gap-2 w-full flex-wrap">
							{level_list.map((item, index) => (
								<li key={index} className="p-2 flex flex-row items-center gap-2 bg-white shadow rounded-lg">
									{item}
									<button
										onClick={(event) => {
											event.preventDefault();
											const updatedLevelList = [...level_list];
											updatedLevelList.splice(index, 1);
											setLevelList(updatedLevelList);
										}}
										className="text-center text-slate-800 hover:bg-slate-100 rounded-full aspect-square w-8"
									>
										<i className="fa-solid fa-close"></i>
									</button>
								</li>
							))}
						</ul>
					</div>
					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Module
						</label>
						<div className="w-full flex flex-row gap-4 my-2">
							<SelectOption
								onChange={(option) => {
									setModuleList((prev) => {
										// Check if the item already exists in the list
										if (!prev.includes(option.id) && option.id) {
											return [...prev, option.id];
										}
										return prev; // If it exists, return the previous state unchanged
									});
								}}
								data={subjects}
							/>
						</div>
						<ul className="flex flex-row gap-2 w-full flex-wrap">
							{module_list.map((item, index) => (
								<li key={index} className="p-2 flex flex-row items-center gap-2 bg-white shadow rounded-lg">
									{item}
									<button
										onClick={(event) => {
											event.preventDefault();
											const updatedModuleList = [...module_list];
											updatedModuleList.splice(index, 1);
											setModuleList(updatedModuleList);
										}}
										className="text-center text-slate-800 hover:bg-slate-100 rounded-full aspect-square w-8"
									>
										<i className="fa-solid fa-close"></i>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="flex justify-center my-4 w-full">
					<button
						className=" text-white px-4 w-full py-2 bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
						type="button"
						onClick={() => add_teacher()}
						disabled={loading}
					>
						{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
						Save
					</button>
				</div>
			</form>
		</div>
	);
}
