import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NumidiaTeachers from "Components/Carousels/NumidiaTeachers";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Footer from "Components/Footers/Footer.js";
import { useNavigate } from "react-router-dom";

// Updated dummy data with IDs for teachers and groups
const teachers = [
	{
		id: 1,
		name: "John Doe",
		image: "../../assets/img/pepole.jpeg",
		details: "PhD in Physics, 10 years of experience",
		groups: [
			{
				id: 101,
				type: "special",
				level: "2nd year high school",
				module: "Math",
				sessions: [
					{ day: "Monday", date: "2024-08-05", startTime: "10:00", endTime: "11:00" },
					{ day: "Monday", date: "2024-08-12", startTime: "10:00", endTime: "11:00" },
					{ day: "Monday", date: "2024-08-19", startTime: "10:00", endTime: "11:00" },
					{ day: "Monday", date: "2024-08-26", startTime: "10:00", endTime: "11:00" },
				],
				remark: "open",
			},
			{
				id: 102,
				type: "normal",
				level: "3rd year high school",
				module: "Physics",
				sessions: [
					{ day: "Tuesday", date: "2024-08-06", startTime: "09:00", endTime: "10:00" },
					{ day: "Tuesday", date: "2024-08-13", startTime: "09:00", endTime: "10:00" },
					{ day: "Tuesday", date: "2024-08-20", startTime: "09:00", endTime: "10:00" },
					{ day: "Tuesday", date: "2024-08-27", startTime: "09:00", endTime: "10:00" },
				],
				remark: "closed",
			},
			{
				id: 103,
				type: "individual",
				level: "1st year high school",
				module: "Chemistry",
				sessions: [
					{ day: "Friday", date: "2024-08-09", startTime: "12:00", endTime: "13:00" },
					{ day: "Friday", date: "2024-08-16", startTime: "12:00", endTime: "13:00" },
					{ day: "Friday", date: "2024-08-23", startTime: "12:00", endTime: "13:00" },
					{ day: "Friday", date: "2024-08-30", startTime: "12:00", endTime: "13:00" },
				],
				remark: "open",
			},
			{
				id: 104,
				type: "special",
				level: "2nd year high school",
				module: "Arabic",
				sessions: [
					{ day: "Wednesday", date: "2024-08-07", startTime: "14:00", endTime: "15:00" },
					{ day: "Wednesday", date: "2024-08-14", startTime: "14:00", endTime: "15:00" },
					{ day: "Wednesday", date: "2024-08-21", startTime: "14:00", endTime: "15:00" },
					{ day: "Wednesday", date: "2024-08-28", startTime: "14:00", endTime: "15:00" },
				],
				remark: "open",
			},
		],
	},
	{
		id: 2,
		name: "Jane Smith",
		image: "../../assets/img/pepole.jpeg",
		details: "MSc in Biology, 5 years of experience",
		groups: [
			{
				id: 201,
				type: "normal",
				level: "1st year middle school",
				module: "Biology",
				sessions: [
					{ day: "Monday", date: "2024-08-05", startTime: "09:00", endTime: "10:00" },
					{ day: "Monday", date: "2024-08-12", startTime: "09:00", endTime: "10:00" },
					{ day: "Monday", date: "2024-08-19", startTime: "09:00", endTime: "10:00" },
					{ day: "Monday", date: "2024-08-26", startTime: "09:00", endTime: "10:00" },
				],
				remark: "open",
			},
			{
				id: 202,
				type: "special",
				level: "2nd year middle school",
				module: "Math",
				sessions: [
					{ day: "Tuesday", date: "2024-08-06", startTime: "10:00", endTime: "11:00" },
					{ day: "Tuesday", date: "2024-08-13", startTime: "10:00", endTime: "11:00" },
					{ day: "Tuesday", date: "2024-08-20", startTime: "10:00", endTime: "11:00" },
					{ day: "Tuesday", date: "2024-08-27", startTime: "10:00", endTime: "11:00" },
				],
				remark: "closed",
			},
		],
	},
];

export default function OurTeachers() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [searchInput, setSearchInput] = useState("");
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [selectedTeacher, setSelectedTeacher] = useState(null);

	const filteredTeachers = teachers.filter((teacher) => teacher.name.toLowerCase().includes(searchInput.toLowerCase()));

	const handleGroupClick = (teacher, group) => {
		setSelectedTeacher(teacher);
		setSelectedGroup(group);
	};

	const handleBackClick = () => {
		setSelectedGroup(null);
		setSelectedTeacher(null);
	};

	return (
		<div className="flex justify-center items-center flex-col w-full">
			<IndexNavbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>
			<section className="w-full">
				<NumidiaTeachers fade={"fade-left"} t={t} />
			</section>
			<main className="w-full min-h-screen">
				<div className="sticky top-0 w-full flex flex-col z-20">
					<div className="flex flex-row w-full items-center justify-between bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] gap-4 px-24 py-4 h-fit">
						<div className="relative flex items-center max-w-lg flex-1">
							<input
								type="text"
								placeholder="Search for a teacher"
								className="border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1"
								value={searchInput}
								onChange={(e) => setSearchInput(e.target.value)}
							/>
							<div className="absolute inset-y-0 right-0 flex items-center pointer-events-none mx-4">
								<i className="fa-solid fa-search text-gray-400"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full h-auto">
					<h2 className="px-20 font-poppins font-semibold bg-gradient-to-r bg-clip-text from-[rgb(1,42,74)] to-[rgb(42,111,150)] text-transparent text-left w-fit">Numidia Teachers</h2>
					<div className="space-y-6 px-10">
						{filteredTeachers.length > 0 ? (
							filteredTeachers.map((teacher) => (
								<div
									key={teacher.id}
									className="flex flex-col md:flex-row md:items-start bg-white shadow-lg rounded-lg overflow-hidden my-6 w-full hover:shadow-xl transition-shadow duration-300"
								>
									<div className="md:w-1/2 p-6 flex flex-col items-center">
										<h2 className="text-2xl font-bold text-gray-900">{teacher.name}</h2>
										<div className="w-full flex justify-center items-center my-4">
											<img className="object-cover rounded-full w-48 h-48" src={require("assets/img/program-illustration.png")} alt={teacher.name} />
										</div>
										<p className="text-gray-700">{teacher.details}</p>
									</div>
									<div className="flex-1 p-6 bg-gray-50 flex flex-col min-h-full">
										{selectedTeacher?.id === teacher.id && selectedGroup ? (
											<div>
												<h3 className="text-xl font-semibold text-gray-800">Group Details</h3>
												<p className="text-gray-800">
													<strong>Group:</strong> {selectedGroup.level} {selectedGroup.module} {selectedGroup.type} ({selectedGroup.remark})
												</p>
												<h4 className="text-lg font-semibold text-gray-800 mt-4">Schedule:</h4>
												<table className="w-full mt-2">
													<thead>
														<tr>
															<th className="border-b p-2 text-left">Day</th>
															<th className="border-b p-2 text-left">Date</th>
															<th className="border-b p-2 text-left">Start Time</th>
															<th className="border-b p-2 text-left">End Time</th>
														</tr>
													</thead>
													<tbody>
														{selectedGroup.sessions.map((session, index) => (
															<tr key={index}>
																<td className="border-b p-2">{session.day}</td>
																<td className="border-b p-2">{session.date}</td>
																<td className="border-b p-2">{session.startTime}</td>
																<td className="border-b p-2">{session.endTime}</td>
															</tr>
														))}
													</tbody>
												</table>
												<button className="mt-4 p-2 bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] text-white rounded-lg " onClick={handleBackClick}>
													Back to Groups
												</button>
											</div>
										) : (
											<>
												<h3 className="text-xl font-semibold text-gray-800">Groups:</h3>
												<ul className="mt-4 space-y-3 flex-1">
													{teacher.groups.map((group) => (
														<li
															key={group.id}
															className="p-4 bg-white rounded-lg shadow-md cursor-pointer hover:bg-blue-50 transition duration-200"
															onClick={() => handleGroupClick(teacher, group)}
														>
															<p className="text-gray-800">
																<strong>Group:</strong> {group.level} {group.module} {group.type} ({group.remark})
															</p>
														</li>
													))}
												</ul>
											</>
										)}
									</div>
								</div>
							))
						) : (
							<p className="text-center text-gray-500">No teachers found</p>
						)}
					</div>
				</div>
			</main>
			<div className="h-96"></div>
			<Footer />
		</div>
	);
}
