import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios.js";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { toast } from "react-toastify";
import moment from "moment";
import FormModal from "Components/Dropdowns/FormModal.js";
import AmphiForm from "./AmphiForm.js";
import Swal from "sweetalert2";

export function Amphitheaters() {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [amphitheater, setAmphitheater] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [group_id, setGroupId] = useState("");
	const [student_id, setStudentId] = useState("");
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");
	const [start_date, setStartDate] = useState("");
	const [end_date, setEndDate] = useState("");
	const [type, setType] = useState("all");
	const navigate = useNavigate();

	const [amphitheaters, setAmphitheaters] = useState([]);

	const getAmphitheaters = async () => {
		let options = {
			method: "get",
			url: url + "/amphitheaters",
			params: {
				sortBy,
				sortDirection,
				search,
				group_id,
				student_id,
				start_date,
				end_date,
				type,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setAmphitheaters(response?.data?.data);
		}).catch(() => { });
	};

	const [method, setMethod] = useState("add");
	const [form, setForm] = useState(false);
	const destroy = (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you really want to delete this amphitheater? This action cannot be undone!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.isConfirmed) {
				axios({
					// Endpoint to send files
					url: url + "/amphitheaters/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
				.then((response) => {
					toast.success("Amphitheater deleted successfully", {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					getAmphitheaters();  // Refresh amphitheater list
				})
				.catch(() => {
				});
			}
		});
	};
	const showForm = (action, data) => {
		setMethod(action);
		setAmphitheater(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
		getAmphitheaters();
	};
	var ComponentRef = useRef();

	useEffect(() => {
		getAmphitheaters();
	}, [sortBy, sortDirection, group_id, search, student_id]);
	const print = () => { };

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Amphitheater Form"}>
					<AmphiForm action={method} data={amphitheater} hide={hide} getAmphitheaters={getAmphitheaters}></AmphiForm>
				</FormModal>
			) : (
				""
			)}
			<div className="bg-white shadow-lg rounded-lg p-8 border ">
				<h4 className=" font-bold">Amphitheaters</h4>

				<div className="break-words my-4 w-full shadow-lg h-fit overflow-auto   border-0">
					<div className="w-full">
						{/* Projects table */}
						<table className=" w-full border-collapse ">
							<thead className="sticky top-0 whitespace-nowrap">
								<tr>

									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>name</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>capacity</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>location</th>
									<th className={"    uppercase  font-semibold flex justify-around   transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
										<Link
											className="w-full ease-linear   "
											role={"button"}
											onClick={(event) => {
												event.preventDefault()
												showForm("add");
											}}
										>
											Add
											<i class="fa-solid fa-plus  "></i>
										</Link>
									</th>
								</tr>
							</thead>
							<tbody>
								{amphitheaters?.map((amphitheater, id) => {
									return (
										<tr key={id} className=" bg-white hover:bg-slate-100 ">

											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{amphitheater.name}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{amphitheater.capacity}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{amphitheater.location}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left items-center flex justify-evenly gap-2"}>
												<Link
													role={"button"}
													onClick={(event) => {
														event.preventDefault()
														showForm("edit", amphitheater);
													}}
													className="hover:text-yellow-400 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
												>
													<i class="fa-solid fa-pen-to-square"></i>
												</Link>
												<Link
													role={"button"}
													onClick={() => destroy(amphitheater.id)}
													className="hover:text-red-600 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
												>
													<i class="fa-solid fa-trash"></i>
												</Link>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
