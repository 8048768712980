import { atom } from "jotai";

export const formVisibilityAtom = atom(false);
export const nameAtom = atom("");
export const emailAtom = atom("");
export const phoneNumAtom = atom("");
export const cityAtom = atom("");
export const wilayaAtom = atom("");
export const streetAtom = atom("");
export const genderAtom = atom("");
export const cartCountAtom = atom(0);
export const cartProductsAtom = atom([]);
