/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Translate from "Translate";
import { useTranslation } from "react-i18next";
import axios from "axios";
import UserDropdown from "Components/Dropdowns/UserDropdown";
import Loading from "Components/Loaders/Loading";
import Loader from "Components/Loaders/Loader";
// components

export default function Navbar({ className }) {
	const { t } = useTranslation();

	const token = window.localStorage.getItem("token");
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [profile, setProfile] = useState("loading");
	const [isOpenPages, setIsOpenPages] = useState(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const verifyToken = () => {
		if (token) {
			fetch(auth_api_url + "/verify-token", {
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
				.then((response) => {
					setProfile("loaded");
				})
				.catch(() => {
					setProfile("unauthenticated");
					window.localStorage.clear();
					window.sessionStorage.clear();
				})
				.catch(() => {});
		} else {
			setProfile("unauthenticated");
			window.localStorage.clear();
			window.sessionStorage.clear();
		}
	};
	useEffect(() => {
		verifyToken();
	}, []);

	return (
		<>
			{isSidebarOpen ? (
				<div className="z-[100] w-full h-screen fixed top-0 bg-black bg-opacity-50">
					<nav className="z-20 relative h-full !w-96 flex flex-col flex-wrap  navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-8 py-4">
						<div className="w-full flex-col flex flex-wrap gap-4">
							<div className=" flex w-full ">
								<Link to="/" className="m-auto my-8 text-white leading-relaxed flex items-center whitespace-nowrap capitalize cursor-pointer">
									<img className="w-20 h-20 object-contain" src={require("assets/img/Numidia Education Group Logo/png/white.png")}></img>
								</Link>
							</div>
							<hr></hr>
							<ul className="flex flex-col list-none gap-4 ">
								<li className="flex items-center">
									<Link
										className=" flex items-center capitalize text-white px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear"
										to={"/"}
									>
										{t("home")}
									</Link>
								</li>

								<li className="flex items-center">
									<Link
										className=" flex items-center capitalize text-white  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear"
										to={"/library"}
									>
										{t("library")}
									</Link>
								</li>
								{/* <li className="flex items-center">
									<Link
										className=" flex items-center capitalize text-white  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear"
										to={"/dawarat"}
									>
										{t("dawarat")}
									</Link>
								</li>
								<li className="flex items-center">
									<Link
										className=" flex items-center capitalize text-white  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear "
										to={"/our-teachers"}
									>
										{t("teachers")}
									</Link>
								</li>

								<li className="flex items-center">
									<Link
										className=" flex items-center capitalize text-white  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear "
										to={"/program"}
									>
										{t("program")}
									</Link>
								</li> */}
								<li className="flex items-center">
									<Link
										className=" flex items-center capitalize text-white  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear "
										to={"/contact"}
									>
										{t("contact")}
									</Link>
								</li>
								<li className="flex items-center">
									<Translate />
								</li>
							</ul>
						</div>
					</nav>
					<div onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="w-full h-full fixed top-0 z-10 cursor-pointer"></div>
				</div>
			) : (
				""
			)}
			<nav className={className}>
				<div className="w-full flex-row flex flex-wrap items-center justify-between">
					<div className=" md:flex hidden justify-between ">
						<Link to="/" className=" text-white leading-relaxed flex items-center whitespace-nowrap capitalize cursor-pointer">
							<img className="w-20 h-20 object-contain" src={require("assets/img/Numidia Education Group Logo/png/white.png")}></img>
						</Link>
					</div>
					<li className="flex items-center md:hidden">
						<div className="relative flex flex-col w-fit">
							<Link
								onClick={() => setIsSidebarOpen(!isSidebarOpen)}
								className="flex gap-2 w-full px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear whitespace-nowrap bg-transparent items-center"
							>
								<i className="fa-solid fa-bars text-2xl"></i>
							</Link>
						</div>
					</li>
					<ul className="md:flex flex-row hidden list-none gap-4 items-center">
						<li className="flex items-center">
							<Link
								className=" flex items-center capitalize text-white px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear"
								to={"/"}
							>
								{t("home")}
							</Link>
						</li>

						<li className="flex items-center">
							<Link
								className=" flex items-center capitalize text-white  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear"
								to={"/library"}
							>
								{t("library")}
							</Link>
						</li>
						{/* <li className="flex items-center">
							<div className="relative flex flex-col w-fit">
								<Link
									onClick={() => setIsOpenPages(!isOpenPages)}
									className={
										"flex gap-2 w-full px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear whitespace-nowrap bg-transparent items-center"
									}
								>
									{" "}
									{t("pages")} {isOpenPages ? <i className=" fa-solid fa-caret-up text-base " /> : <i className=" fa-solid fa-caret-down text-base " />}
								</Link>
								{isOpenPages ? (
									<div className="flex flex-col bg-white rounded-lg shadow-lg absolute left-0 top-full z-50 text-slate-900  m-2">
										<li className="flex items-center">
											<Link
												className=" flex items-center capitalize text-black  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear "
												to={"/our-teachers"}
											>
												{t("teachers")}
											</Link>
										</li>

										<li className="flex items-center">
											<Link
												className=" flex items-center capitalize text-black  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear "
												to={"/program"}
											>
												{t("program")}
											</Link>
										</li>
									</div>
								) : (
									""
								)}
							</div>
						</li> */}
						<li className="flex items-center">
							<Link
								className=" flex items-center capitalize text-white  px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear "
								to={"/contact"}
							>
								{t("contact")}
							</Link>
						</li>
						<li className="flex items-center">
							<Translate />
						</li>
					</ul>
					<div className="flex items-center">
						<ul className="flex flex-row list-none gap-4">
							<li className="flex items-center">
								<Link className=" flex items-center capitalize text-white ">
									<img className="w-8 h-8 object-contain" src={require("assets/img/search-icon.png")}></img>
								</Link>
							</li>
							<li className="flex items-center">
								{profile === "unauthenticated" ? (
									<Link className=" rounded-full px-4 py-2 bg-white flex items-center capitalize text-[rgb(1,42,74)] hover:shadow-lg " to={"/Login"}>
										{t("login")}
									</Link>
								) : profile === "loaded" ? (
									<UserDropdown />
								) : (
									<Loading width="32px" height={"32px"} color="white" weight={"3px"} />
								)}
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}
