import { userAtom } from "Components/Atoms/AuthAtoms";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function AdminSidebar({ sidebar, setSidebar }) {
	let location = useLocation();
	const [isExpanded, setISExpanded] = useState([false, false, false, false, false]);
	const [user, setUser] = useAtom(userAtom);
	const [permissions, setPermissions] = useState([]);
	useEffect(() => {
	}, [isExpanded]);

	useEffect(() => {
		setPermissions((prev) => {
			return user?.permissions?.map((_) => {
				return _.name;
			});
		});
	}, [user])

	useEffect(() => {
		console.log(permissions);
	}, [permissions]);
	return (
		<>
			{sidebar === "flex" ? (
				<div
					onClick={(event) => {
						event.preventDefault();
						if (sidebar === "hidden") {
							setSidebar("flex");
						} else {
							setSidebar("hidden");
						}
					}}
					className="fixed  2xl:hidden text-white left-0  top-0 w-full bg-black bg-opacity-70 z-30  h-screen"
				></div>
			) : (
				""
			)}

			<nav data-aos={"fade-right"} className={"md:sticky fixed !w-96 top-0 left-0 bg-white " + sidebar + "   flex-wrap  z-40   h-screen overflow-auto p-4"}>
				<div className="  flex flex-col  w-full  gap-4">
					<Link to="/" className="text-slate-700  font-medium leading-relaxed flex items-center w-full justify-center  whitespace-nowrap capitalize cursor-pointer my-2">
						<img className="w-20 h-auto shadow hover:shadow-lg rounded-full" src={require("assets/img/Numidia Education Group Logo/png/primary.png")}></img>
					</Link>

					{/* Divider */}
					<hr className="w-full" />
					{/* Heading */}
					<p
						className={
							isExpanded[0]
								? "w-full font-bold rounded-lg capitalize bg-sky-800 text-white p-3 cursor-pointer no-underline text-sm"
								: "w-full font-bold text-slate-500  capitalize cursor-pointer no-underline text-sm"
						}
						onClick={() =>
							setISExpanded((prev) => {
								return prev.map((item, index) => (index === 0 ? !item : item));
							})
						}
					>
						<i className="fa-solid fa-house mr-4"></i>
						Main pages
					</p>
					{/* Navigation */}

					{isExpanded[0] ? (
						<ul data-aos="fade-up" className="pl-4 transition-all duration-300 md:flex-col w-full flex flex-col list-none">
							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/dashboard" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/dashboard"
								>
									<i className={"fa-solid text-base fa-gauge-high   " + (location.pathname == "/dashboard" ? "" : "text-slate-300")}></i> Dashboard
								</Link>
							</li>
							{permissions?.includes("financials") && (
								<li className="items-center">
									<Link
										className={
											" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
											(location.pathname == "/financials" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
										}
										to="/financials"
									>
										<i className={"fa-solid text-base fa-hand-holding-dollar   " + (location.pathname == "/financials" ? "" : "text-slate-300")}></i> financials
									</Link>
								</li>
							)}
							{permissions?.includes("statistics") && (
								<li className="items-center">
									<Link
										className={
											" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
											(location.pathname == "/stats" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
										}
										to="/stats"
									>
										<i className={"fa-solid text-base fa-chart-pie   " + (location.pathname == "/stats" ? "" : "text-slate-300")}></i> Statistics
									</Link>
								</li>
							)}
							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/inbox" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/inbox"
								>
									<i className={"fa-solid text-base fa-bell   " + (location.pathname == "/inbox" ? "" : "text-slate-300")}></i> Notifications
								</Link>
							</li>
							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/profile" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/profile"
								>
									<i className={"fa-solid text-base fa-circle-user   " + (location.pathname == "/profile" ? "" : "text-slate-300")}></i> Profile
								</Link>
							</li>

							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/settings" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/settings"
								>
									<i className={"fa-solid text-base fa-gear   " + (location.pathname == "/settings" ? "" : "text-slate-300")}></i> settings
								</Link>
							</li>
							{permissions?.includes("news") && (
								<li className="items-center">
									<Link
										className={
											" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
											(location.pathname == "/news" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
										}
										to="/news"
									>
										<i className={"fa-solid text-base fa-newspaper   " + (location.pathname == "/news" ? "" : "text-slate-300")}></i> News
									</Link>
								</li>
							)}
						</ul>
					) : (
						""
					)}

					{permissions?.includes("user-management") && (
						<>
							{" "}
							<hr className="  w-full" />
							<p
								className={
									isExpanded[1]
										? "w-full rounded-lg font-bold capitalize bg-sky-800 text-white p-3 cursor-pointer no-underline text-sm"
										: "w-full font-bold text-slate-500  capitalize cursor-pointer no-underline text-sm"
								}
								onClick={() =>
									setISExpanded((prev) => {
										return prev.map((item, index) => (index === 1 ? !item : item));
									})
								}
							>
								<i className="fa-solid fa-user-gear mr-4"></i>
								User Management
							</p>
							{/* Navigation */}
							{isExpanded[1] ? (
								<ul data-aos="fade-up" className="pl-4 transition-all duration-300 md:flex-col w-full flex flex-col list-none">
									{permissions?.includes("users") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/users" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/users"
											>
												<i className={"fa-solid text-base fa-users-gear   " + (location.pathname == "/users" ? "" : "text-slate-300")}></i> Users
											</Link>
										</li>
									)}
									{permissions?.includes("teachers") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/teachers" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/teachers"
											>
												<i className={"fa-solid text-base fa-chalkboard-user   " + (location.pathname == "/teachers" ? "" : "text-slate-300")}></i> Teachers
											</Link>
										</li>
									)}
									{permissions?.includes("students") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/students" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/students"
											>
												<i className={"fa-solid text-base fa-users   " + (location.pathname == "/students" ? "" : "text-slate-300")}></i> Students
											</Link>
										</li>
									)}
									{permissions?.includes("parents") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/parents" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/parents"
											>
												<i className={"fa-solid text-base fa-person   " + (location.pathname == "/parents" ? "" : "text-slate-300")}></i> Parents
											</Link>
										</li>
									)}
								</ul>
							) : (
								""
							)}
						</>
					)}

					{/* Divider */}
					{permissions?.includes("department-management") && (
						<>
							{" "}
							<hr className="  w-full" />
							<p
								className={
									isExpanded[2]
										? "w-full font-bold rounded-lg capitalize bg-sky-800 text-white p-3 cursor-pointer no-underline text-sm"
										: "w-full font-bold text-slate-500  capitalize cursor-pointer no-underline text-sm"
								}
								onClick={() =>
									setISExpanded((prev) => {
										return prev.map((item, index) => (index === 2 ? !item : item));
									})
								}
							>
								<i className="fa-solid fa-folder-tree mr-4"></i>
								Department Management
							</p>
							{/* Navigation */}
							{isExpanded[2] ? (
								<ul data-aos="fade-up" className="pl-4 transition-all duration-300 md:flex-col w-full flex flex-col list-none">
									{permissions?.includes("groups") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/groups" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/groups"
											>
												<i className={"fa-solid text-base fa-users-rectangle " + (location.pathname == "/groups" ? "" : "text-slate-300")}></i> Groups
											</Link>
										</li>
									)}
									{permissions?.includes("levels") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/levels" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/levels"
											>
												<i className={"fa-solid text-base fa-layer-group   " + (location.pathname == "/levels" ? "" : "text-slate-300")}></i> Levels
											</Link>
										</li>
									)}

									{permissions?.includes("sessions") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/sessions" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/sessions"
											>
												<i className={"fa-solid text-base fa-calendar-days   " + (location.pathname == "/sessions" ? "" : "text-slate-300")}></i> Sessions
											</Link>
										</li>
									)}
									{permissions?.includes("attendance") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/attendance" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/attendance"
											>
												<i className={"fa-solid text-base fa-calendar   " + (location.pathname == "/attendance" ? "" : "text-slate-300")}></i> Attendance
											</Link>
										</li>
									)}
									{permissions?.includes("exams") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/exams" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/exams"
											>
												<i className={"fa-solid text-base fa-file-pen   " + (location.pathname == "/exams" ? "" : "text-slate-300")}></i> Exams
											</Link>
										</li>
									)}
								</ul>
							) : (
								""
							)}
						</>
					)}

					{/* Divider */}
					{permissions?.includes("library-management") && (
						<>
							{" "}
							<hr className="  w-full" />
							<p
								className={
									isExpanded[3]
										? "w-full font-bold rounded-lg capitalize bg-sky-800 text-white p-3 cursor-pointer no-underline text-sm"
										: "w-full font-bold text-slate-500  capitalize cursor-pointer no-underline text-sm"
								}
								onClick={() =>
									setISExpanded((prev) => {
										return prev.map((item, index) => (index === 3 ? !item : item));
									})
								}
							>
								<i className="fa-solid fa-book mr-4"></i>
								Library Management
							</p>
							{/* Navigation */}
							{isExpanded[3] ? (
								<ul data-aos="fade-up" className="pl-4 transition-all duration-300 md:flex-col w-full flex flex-col list-none">
									{permissions?.includes("library") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/library/dashboard" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/library/dashboard"
											>
												<i className={"fa-solid text-base fa-book-open   " + (location.pathname == "/library/dashboard" ? "" : "text-slate-300")}></i> Library
											</Link>
										</li>
									)}
									{permissions?.includes("clients") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/library/clients" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/library/clients"
											>
												<i className={"fa-solid text-base fa-users   " + (location.pathname == "/library/clients" ? "" : "text-slate-300")}></i> Clients
											</Link>
										</li>
									)}
									{permissions?.includes("orders") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/library/orders" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/library/orders"
											>
												<i className={"fa-solid text-base fa-file-lines   " + (location.pathname == "/library/orders" ? "" : "text-slate-300")}></i> Orders
											</Link>
										</li>
									)}
									{permissions?.includes("products") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/library/products" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/library/products"
											>
												<i className={"fa-solid text-base fa-book   " + (location.pathname == "/library/products" ? "" : "text-slate-300")}></i> Products
											</Link>
										</li>
									)}
								</ul>
							) : (
								""
							)}
						</>
					)}

					{/* Divider */}
					{permissions?.includes("dawarat-management") && (
						<>
							<hr className="  w-full" />

							<p
								className={
									isExpanded[4]
										? "w-full rounded-lg font-bold capitalize bg-sky-800 text-white p-3 cursor-pointer no-underline text-sm"
										: "w-full font-bold text-slate-500  capitalize cursor-pointer no-underline text-sm"
								}
								onClick={() =>
									setISExpanded((prev) => {
										return prev.map((item, index) => (index === 4 ? !item : item));
									})
								}
							>
								<i className="fa-solid fa-building-columns mr-4"></i>
								Dawarat Management
							</p>
							{/* Navigation */}
							{isExpanded[4] ? (
								<ul data-aos="fade-up" className="pl-4 transition-all duration-300 md:flex-col w-full flex flex-col list-none">
									{permissions?.includes("dawarat") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/dashboard" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/dashboard"
											>
												<i className={"fa-solid text-base fa-building-columns  " + (location.pathname == "/dawarat/dashboard" ? "" : "text-slate-300")}></i> Dawarat
											</Link>
										</li>
									)}

									{permissions?.includes("amphis") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/amphitheaters" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/amphitheaters"
											>
												<i className={"fa-solid text-base fa-people-roof   " + (location.pathname == "/dawarat/amphitheaters" ? "" : "text-slate-300")}></i> Amphitheatre
											</Link>
										</li>
									)}
									{permissions?.includes("students") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/students" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/students"
											>
												<i className={"fa-solid text-base fa-users   " + (location.pathname == "/dawarat/students" ? "" : "text-slate-300")}></i> Students
											</Link>
										</li>
									)}
									{permissions?.includes("teachers") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/teachers" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/teachers"
											>
												<i className={"fa-solid text-base fa-chalkboard-user   " + (location.pathname == "/dawarat/teachers" ? "" : "text-slate-300")}></i> Teachers
											</Link>
										</li>
									)}
									{permissions?.includes("groups") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/groups" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/groups"
											>
												<i className={"fa-solid text-base fa-users-rectangle  " + (location.pathname == "/dawarat/groups" ? "" : "text-slate-300")}></i> Dawarat Groups
											</Link>
										</li>
									)}
									{permissions?.includes("dawarat-sessions") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/sessions" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/sessions"
											>
												<i className={"fa-solid text-base fa-calendar " + (location.pathname == "/dawarat/sessions" ? "" : "text-slate-300")}></i> Sessions
											</Link>
										</li>
									)}
									{permissions?.includes("tickets") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/tickets" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/tickets"
											>
												<i className={"fa-solid text-base fa-ticket   " + (location.pathname == "/dawarat/tickets" ? "" : "text-slate-300")}></i> Tickets
											</Link>
										</li>
									)}
									{permissions?.includes("dawarat-attendance") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/presences" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/presences"
											>
												<i className={"fa-solid text-base fa-calendar-check   " + (location.pathname == "/dawarat/presences" ? "" : "text-slate-300")}></i> Presence
											</Link>
										</li>
									)}

									{permissions?.includes("waiting-list") && (
										<li className="items-center">
											<Link
												className={
													" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
													(location.pathname == "/dawarat/waiting-list" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
												}
												to="/dawarat/waiting-list"
											>
												<i className={"fa-solid text-base fa-clipboard-list   " + (location.pathname == "/dawarat/waiting-list" ? "" : "text-slate-300")}></i> Waiting List
											</Link>
										</li>
									)}
								</ul>
							) : (
								""
							)}
						</>
					)}

					<hr className="  w-full" />

					<br />
					<br />
					<br />
				</div>
			</nav>
		</>
	);
}
