import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "Components/Loaders/Loading";
import SelectOption from "Components/Dropdowns/SelectOption";
import FormModal from "Components/Dropdowns/FormModal";
import ClientForm from "../Clients/ClientForm";

// components

export default function Form({ hide,getOrders, data }) {
	const token = localStorage.getItem("token");
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const method = "post";
	const navigate = useNavigate();
	const [products, setProducts] = useState([{ id: "", price: "", qte: 1 }]);
	const [availableProducts, setAvailableProducts] = useState([]);
	const [loading, setLoading] = useState(false);

	const [form, setForm] = useState(false);
	const [client, setClient] = useState({});
	const [clients, setClients] = useState([]);
	const [clientPage, setClientPage] = useState(1);
	const [productPage, setProductPage] = useState(1);
	const [lastClientPage, setLastClientPage] = useState(1);
	const [lastProductPage, setLastProductPage] = useState(1);
	const handleInputChange = (index, field, value) => {
		const updatedProducts = [...products];
		updatedProducts[index][field] = value;
		setProducts(updatedProducts);
	};
	const handleAddProduct = () => {
		setProducts([...products, { id: "", price: "", qte: 1 }]);
	};
	const handleRemoveProduct = (index) => {
		const updatedProducts = products.filter((_, i) => i !== index);
		setProducts(updatedProducts);
	};

	const getClients = () => {
		axios({
			url: url + "/clients",
			method: "get",
			params: { page: clientPage },
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {
				const data = response?.data?.data.map((item) => ({
					...item,
					label: item.name,
				}));
				setClients(data);
				setLastClientPage(response?.data?.last_page);
			})
			.catch(() => {});
	};
	const getProducts = () => {
		axios({
			url: url + "/products",
			method: "get",
			params: { page: productPage },
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {
				const data = response?.data?.data.map((item) => ({
					...item,
					label: item.name,
				}));
				setAvailableProducts(data);
				setLastProductPage(response?.data?.last_page);
			})
			.catch(() => {});
	};
	const add_order = () => {
		setLoading(true);

		axios({
			url: url + "/orders/clients/" + client.id,
			method: "post",
			data: {
				products: products,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {
				getOrders()
				hide()
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getClients();
		getProducts();
	}, [productPage, clientPage]);
	return (
		<div className="w-full p-4">
			{form ? (
				<FormModal
					hide={() => {
						setForm(false);
						getClients();
					}}
					title={"Client Form"}
				>
					<ClientForm
						action={"add"}
						data={client}
						hide={() => {
							setForm(false);
							getClients();
						}}
						getClients={getClients}
					></ClientForm>
				</FormModal>
			) : (
				""
			)}
			<form>
				<div className="w-full  flex flex-wrap gap-4">
					<div className="flex flex-row gap-4 w-full">
						<div className="flex-1">
							<SelectOption
								data={clients}
								onChange={(option) => setClient(option)}
								page={clientPage}
								setPage={setClientPage}
								withPagination={true}
								lastPage={lastClientPage}
							></SelectOption>
						</div>
						<button type="button" onClick={() => setForm(true)} className="px-4 py-2 bg-sky-500 hover:bg-sky-600 rounded-lg text-white w-fit whitespace-nowrap">
							Add Client
						</button>
					</div>
					<div className=" w-full ">
						<div className="flex flex-row justify-between items-center ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Products
							</label>
							<button type="button" onClick={handleAddProduct} className="px-4 py-2 bg-sky-500 hover:bg-sky-600 rounded-lg text-white">
								Add Product
							</button>
						</div>

						{products.map((product, index) => (
							<div key={index} className="flex gap-4 items-center">
								<SelectOption
									data={availableProducts}
									onChange={(option) => {handleInputChange(index, "id", option.id);handleInputChange(index, "price", option.price)}}
									page={productPage}
									setPage={setProductPage}
									withPagination={true}
									lastPage={lastProductPage}
								></SelectOption>

								<input
									type="number"
									onWheel={(event) => event.target.blur()}
									className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="Price"
									value={product.price}
									onChange={(e) => handleInputChange(index, "price", e.target.value)}
								/>
								<input
									type="number"
									onWheel={(event) => event.target.blur()}
									className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="Quantity"
									value={product.qte}
									onChange={(e) => handleInputChange(index, "qte", e.target.value)}
								/>
								<button
									type="button"
									className="p-2 hover:text-red-600 transition duration-150 ease-in-out bg-white rounded-lg shadow-md hover:shadow-lg min-w-fit flex items-center"
									onClick={() => handleRemoveProduct(index)}
								>
									<i className="fa-solid fa-trash"></i>
								</button>
							</div>
						))}
					</div>
				</div>

				<div className="flex justify-center w-full my-4">
					<button
						className="py-2 px-4 w-full text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
						type="button"
						onClick={() => add_order()}
						disabled={loading}
					>
						{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
						Save
					</button>
				</div>
			</form>
		</div>
	);
}
