import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { Link, useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import Loading from "Components/Loaders/Loading";
import moment from "moment";

// components

export default function Form({ action, data, getProducts, hide }) {
	const token = localStorage.getItem("token");
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const method = action === "add" ? "post" : "put";
	const [loading, setLoading] = useState({
		submit: false,
		images: false,
	});
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [purchase_price, setPurchasePrice] = useState("");
	const [price, setPrice] = useState("");
	const [qte, setQte] = useState("");
	const [purchase_date, setPurchaseDate] = useState();
	const [level, setLevel] = useState("");
	const [tags, setTags] = useState("");
	const [uploaded_images, setUploadedImages] = useState([]);

	const navigate = useNavigate();
	const compressImage = async (file) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
		};
		await imageCompression(file, options)
			.then((compressedFile) => {
				setUploadedImages((prev) => {
					return [...prev, compressedFile];
				});
			})
			.catch((error) => {});
	};
	const add_product = () => {
		setLoading((prev) => {
			return {
				submit: true,
				images: false,
			};
		});
		const base_url = action === "edit" ? url + "/products/" + data.id : url + "/products";
		axios({
			// Endpoint to send files
			url: base_url,
			method: "post",
			data: {
				name,
				description,
				purchase_price,
				price,
				qte,
				purchase_date,
				tags,
				level,
				pictures: uploaded_images,
			},
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				getProducts();
				hide();
			})
			.catch((error) => {})
			.finally(() => {
				setLoading((prev) => {
					return {
						submit: false,
						images: false,
					};
				});
			});
	};

	const handleFileUpload = async (event) => {
		event.preventDefault();
		setLoading((prev) => {
			return {
				submit: true,
				images: true,
			};
		});
		const files = event.target.files;

		for (let i = 0; i < files.length; i++) {
			await compressImage(files[i]).then(() => {});
		}
		setLoading((prev) => {
			return {
				submit: false,
				images: false,
			};
		});
	};

	const handleRemoveImage = (event, index) => {
		event.preventDefault();
		const updatedImages = [...uploaded_images];
		updatedImages.splice(index, 1);
		setUploadedImages(updatedImages);
	};
	const convertUrlToFile = async (fileUrl, filename) => {
		try {
			// Fetch the image from the URL
			const response = await axios.get(`${url}/files?url=${fileUrl}`, { responseType: "blob" });

			// Create a File object from the Blob
			const file = new File([response.data], filename, { type: response.data.type });

			return file;
		} catch (error) {
			console.error("Failed to convert URL to File:", error);
			return null;
		}
	};
	useEffect(() => {
		if (action === "edit") {
			setName(data.name);
			setPrice(data.price);
			setPurchasePrice(data.purchase_price);
			setQte(data.qte);
			setDescription(data.description);
			setPurchaseDate(data.purchase_date);
			setLevel(data.level);
			setTags(data.tags);
			const initializeImages = async () => {
				const imageFiles = await Promise.all(
					data.pictures.map(async (photo) => {
						const file = await convertUrlToFile(photo.url, `image-${photo.id}.png`);
						return file;
					})
				);
				setUploadedImages(imageFiles.filter((file) => file));
			};

			initializeImages();
		}else{
			setPurchaseDate(moment().format("YYYY-MM-DD"));
		}
	}, []);
	return (
		<div className="flex-auto w-full p-4 ">
			<form>
				<div className="w-full grid grid-cols-2 gap-4">
						<div className="w-full col-span-2">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Name
							</label>
							<input
								type="text"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Name"
								onChange={(event) => {
									setName(event.target.value);
								}}
								value={name}
							/>
						</div>
						<div className="w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Purchase price
							</label>
							<input
								type="number"
								onWheel={(event) => event.target.blur()}
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Purchase price"
								onChange={(event) => {
									setPurchasePrice(event.target.value);
								}}
								value={purchase_price}
							/>
						</div>
						<div className="w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Purchase Date
							</label>
							<input
								type="date"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Purchase Date"
								onChange={(event) => {
									setPurchaseDate(event.target.value);
								}}
								value={purchase_date}
							/>
						</div>
						<div className="w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Price
							</label>
							<input
								type="number"
								onWheel={(event) => event.target.blur()}
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Price"
								onChange={(event) => {
									setPrice(event.target.value);
								}}
								value={price}
							/>
						</div>
						<div className="w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Quantity
							</label>
							<input
								type="number"
								onWheel={(event) => event.target.blur()}
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Quantity"
								onChange={(event) => {
									setQte(event.target.value);
								}}
								value={qte}
							/>
						</div>
						
						<div className="w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Level
							</label>
							<input
								type="text"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Level"
								onChange={(event) => {
									setLevel(event.target.value);
								}}
								value={level}
							/>
						</div>
						<div className="w-full ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Categories
							</label>
							<input
								type="text"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Categories separated by commas"
								onChange={(event) => {
									setTags(event.target.value);
								}}
								value={tags}
							/>
						</div>
						<div className="w-full col-span-2">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Description
							</label>
							<textarea
								type="text"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 h-20"
								placeholder="Description"
								onChange={(event) => {
									setDescription(event.target.value);
								}}
								value={description}
							/>
						</div>
				</div>
				<div className="my-2 ">
					<label className="block uppercase text-slate-600 font-bold ">Upload Photos</label>
					<div className="flex flex-row gap-4 w-full overflow-auto ">
						<div>
							<label className="text-slate-500 ">
								<div className="w-[200px] h-[120px] rounded-lg border-dashed border-2 border-slate-200 text-slate-500 flex justify-center items-center hover:cursor-pointer hover:border-blue-500 transition-all duration-200 ease-in-out hover:text-blue-500 hover:border-solid">
									{loading.images ? (
										<>
											{" "}
											<Loading width="20px" height={"20px"} color="black" weight={"2px"}></Loading>Uploading Image
										</>
									) : (
										<>
											<i className="fa-solid fa-add "></i>Add Image
										</>
									)}
								</div>
								<input type="file" multiple accept="image/*" onChange={handleFileUpload} className="hidden" />
							</label>
						</div>
						{uploaded_images.map((image, index) => (
							<div key={index} className="relative min-w-fit ">
								<img src={URL.createObjectURL(image)} className="w-[200px] h-[120px] object-cover rounded-lg " alt={`uploaded-image-${index}`} />
								<button
									className="absolute top-0 right-0 rounded-full bg-black bg-opacity-50 text-white !w-8 h-8 hover:text-red-600 transition-all duration-200"
									onClick={(event) => handleRemoveImage(event, index)}
								>
									<i className="fa-solid fa-close"></i>
								</button>
							</div>
						))}
					</div>
				</div>
				<div className="flex justify-center w-full my-4">
					<button
						className="py-2 px-4 w-full text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
						type="button"
						disabled={loading.submit}
						onClick={() => add_product()}
					>
						{loading.submit & !loading.images ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}Save
					</button>
				</div>
			</form>
		</div>
	);
}
