import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import FooterSmallDark from "Components/Footers/FooterSmallDark";
import Loading from "Components/Loaders/Loading";

export default function ResentEmailVerification() {
	const url = process.env.REACT_APP_API_URL_AUTH;

	const [email, setEmail] = useState("");
	const token = window.localStorage.getItem("token");
	const [loading, setLoading] = useState(false);

	let navigate = useNavigate();

	const resend = () => {
		let data = {
			email,
		};
		setLoading(false);
		axios({
			// Endpoint to send files
			url: url + "/email/resent/code",
			method: "POST",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
				"Access-Control-Allow-Origin": "*",
			},

			// Attaching the form data
			data: data,
		})
			// Handle the response from backend here
			.then((response) => {
				Swal.fire({
					title: "Go to dashboard",
					text: "You are successfuly logged in .",
					icon: "success",

					iconColor: "#3dc00c",
				}).then(async () => {
					navigate("/email.verify");
				});
			})
			.catch(() => { })
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => { }, []);

	return (
		<div className="flex flex-col bg-gradient-to-r min-h-screen from-[rgb(1,42,74)] to-sky-600 w-full h-fit justify-between">
			<div className="flex content-center  justify-center z-50 w-full m-auto p-8 ">
				<div className="w-full lg:w-1/3 my-24 ">
					<div className="relative flex flex-col break-words w-full  shadow-lg rounded-3xl bg-slate-200 p-6 ">
						<Link to="/" className="absolute w-24 h-24 left-1/2 -translate-x-1/2 -translate-y-2/3 rounded-full bg-white z-50 shadow-lg ">
							<img className="object-contain w-full h-full" src={require("assets/img/auth-logo.png")} />
						</Link>
						<div className="flex-auto mt-12">
							<div className="text-center ">
								<h6 className="  font-bold">Verify Your Email</h6>
							</div>
							<div className="text-slate-600 text-center ">
								<small>Almost there, just enter your email please then check your inbox you'll find a verification code from {process.env.REACT_APP_EMAIL}</small>
							</div>
							<form>
								<div className="relative w-full my-2">
									<input
										type="email"
										className="my-2 p-3  placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										placeholder="Email"
										onChange={(event) => {
											setEmail(event.target.value);
										}}
									/>
								</div>
								<div className="text-center  my-4 flex flex-row">
									<button
										className="bg-[rgb(1,42,74)] text-white active:bg-slate-600 py-2  font-bold uppercase   rounded-lg shadow hover:shadow-lg outline-none focus:outline-none   w-full ease-linear transition-all duration-150"
										type="button"
										onClick={(event) => resend(event)}
										disabled={loading}
									>
										{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
										Resend
									</button>
								</div>
							</form>
						</div>
						<div className="flex flex-wrap   relative ">
							<Link className=" text-slate-700 text-center w-full" to="/email.verify">
								<small>received a code? verify your email here.</small>
							</Link>
							.
						</div>
					</div>
				</div>
			</div>
			<FooterSmallDark />
		</div>
	);
}
