import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterSmallDark from "Components/Footers/FooterSmallDark";

export default function Register() {
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [email, setEmail] = useState("");
	const [firstname, SetFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [message, setMessage] = useState("");
	const [phone_number, setPhoneNumber] = useState("");

	let navigate = useNavigate();

	const submit = (event) => {
		event.preventDefault();

		let data = {
			email: email,
			name: firstname + " " + lastname,
			message,
			phone_number,
		};
		axios({
			// Endpoint to send files
			url: auth_api_url + "/contacts",
			method: "POST",

			headers: {
				Accept: "Application/json",
				"Access-Control-Allow-Origin": "*",
			},

			data: data,
		})
			.then(async (response) => {
				Swal.fire({
					title: "You message sent successfully",
					text: "will check on you ASAP",
					icon: "success",
					iconColor: "#3dc00c",
				}).then(async () => {
					navigate("/");
				});
			})
			.catch(() => {});
	};
	useEffect(() => {}, []);

	return (
		<div className="flex flex-col bg-gradient-to-r min-h-screen from-[rgb(1,42,74)] to-sky-600 w-full h-fit justify-between">
			<div className="flex content-center  justify-center z-50 w-full p-8 m-auto">
				<div className="w-full lg:w-1/3  my-24">
					<div className="relative flex flex-col break-words w-full  shadow-lg rounded-3xl bg-slate-200 p-6 ">
						<Link to="/" className="absolute w-24 h-24 left-1/2 -translate-x-1/2 -translate-y-2/3 rounded-full bg-white z-50 shadow-lg ">
							<img className="object-contain w-full h-full" src={require("assets/img/auth-logo.png")} />
						</Link>

						<div className="flex-auto mt-12">
							<form>
								<div className="transition-all">
									<div className="relative w-full flex flex-row gap-2 my-2">
										<div className=" w-1/2 ">
											<label className="block capitalize text-blueGray-600  font-bold ">First Name</label>
											<input
												type="text"
												className="my-2 p-3   placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
												placeholder="firstname"
												value={firstname}
												onChange={(event) => {
													SetFirstname(event.target.value);
												}}
											/>
										</div>

										<div className=" w-1/2">
											<label className="block capitalize text-blueGray-600  font-bold ">Family Name</label>
											<input
												type="text"
												className="p-3 my-2   placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
												placeholder="Lastname"
												value={lastname}
												onChange={(event) => {
													setLastname(event.target.value);
												}}
											/>
										</div>
									</div>
									<div className="relative w-full flex flex-col gap-2 my-2">
										<label className="block capitalize text-blueGray-600  font-bold ">Phone Number</label>
										<input
											type="number"
											onWheel={(event) => event.target.blur()}
											className="my-2 p-3  placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											placeholder="Phone Number"
											onChange={(event) => {
												setPhoneNumber(event.target.value);
											}}
										/>
									</div>
									<div className="relative w-full flex flex-col gap-2 my-2">
										<label className="block capitalize text-blueGray-600  font-bold ">Email</label>
										<input
											type="email"
											value={email}
											className="my-2 p-3  placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											placeholder="Email"
											onChange={(event) => {
												setEmail(event.target.value);
											}}
										/>
									</div>

									<div className="relative w-full flex flex-col gap-2 my-2">
										<label className="block capitalize text-blueGray-600  font-bold ">Message</label>
										<textarea
											type="text"
											className="p-3 my-2   placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											placeholder="Message"
											value={message}
											onChange={(event) => {
												setMessage(event.target.value);
											}}
										/>
									</div>
									<div className="text-center  my-4 ">
										<button
											className="bg-[rgb(10,58,92)] flex items-center justify-center w-full text-white active:bg-slate-600  font-bold capitalize py-2 gap-2  rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
											type="button"
											onClick={(event) => submit(event)}
										>
											Send
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<FooterSmallDark />
		</div>
	);
}
