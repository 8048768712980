import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "Components/Loaders/Loading";

// components

export default function ParentForm({ action, data, getParents, className, hide }) {
	const [base_url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");
	const url = base_url + "/users";
	const method = action === "add" ? "post" : "put";
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [name, setName] = useState("");
	const [gender, setGender] = useState("male");
	const [phone_number, setPhoneNumber] = useState("");
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	function generateEmail(event) {
		event.preventDefault();
		var formattedName = lastName.toLowerCase().replace(/\s/g, "") + firstName.toLowerCase().replace(/\s/g, "");
		var randomNumber = Math.floor(Math.random() * 9000) + 1000;
		var email = formattedName + randomNumber + "@numidia.com";

		setEmail(email);
	}
	const add_parent = () => {
		setLoading(true);

		axios({
			// Endpoint to send files
			url: url,
			method: method,
			data: {
				role: "supervisor",
				email,
				name: action === "edit" ? name : lastName + " " + firstName,
				phone_number,
				gender,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Parent " + action + "ed", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				hide();
				getParents();
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (action === "edit") {
			setEmail(data.email);
			setName(data.name);
			setPhoneNumber(data.phone_number);
			setGender(data.gender);
		}
	}, []);
	return (
		<form>
			<div className="flex flex-wrap gap-4">
				{action === "edit" ? (
					<div className="flex-1  ">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Name
						</label>
						<input
							type="text"
							className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Name"
							onChange={(event) => {
								setName(event.target.value);
							}}
							value={name}
						/>
					</div>
				) : (
					<>
						<div className="flex-1  ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Last Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Last Name"
								onChange={(event) => {
									setLastName(event.target.value);
								}}
								value={lastName}
							/>
						</div>
						<div className=" flex-1 ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								First Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="First Name"
								onChange={(event) => {
									setFirstName(event.target.value);
								}}
								value={firstName}
							/>
						</div>
					</>
				)}

				<div className=" w-full">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						Gender
					</label>
					<select
						className="my-2 p-2 placeholder-blueGray-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						default="male"
						onChange={(event) => {
							setGender(event.target.value);
						}}
					>
						<option value="male">Male</option>
						<option value="female">Female</option>
					</select>
				</div>
				<div className=" w-full">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						Phone Number
					</label>
					<input
						type="number"
						className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
						placeholder="Phone Number"
						onChange={(event) => {
							setPhoneNumber(event.target.value);
						}}
						onWheel={(event) => event.target.blur()}
					/>
				</div>

				<div className=" w-full">
					<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
						Email address
					</label>
					<div className="flex my-2 gap-4">
						<input
							type="email"
							className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Email"
							onChange={(event) => {
								setEmail(event.target.value);
							}}
							value={email}
						/>
						<button className="bg-white rounded-lg shadow p-2 flex items-center justify-center" onClick={generateEmail}>
							<i className="fa-solid fa-gear"></i>{" "}
						</button>
					</div>
				</div>
			</div>
			<div className="flex justify-center w-full my-4">
				<button
					className=" text-white w-full py-2 px-4 bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
					type="button"
					onClick={() => add_parent()}
					disabled={loading}
				>
					{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
					Save
				</button>
			</div>
		</form>
	);
}
