import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function VerifyToken() {
	const token = window.localStorage.getItem("token");
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	let navigate = useNavigate();
	const verifyToken = () => {
		axios
			.get(auth_api_url + "/verify-token", {
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {})
			.catch(() => {
				Swal.fire({
					title: "Please sign in",
					text: "You are not signed in",
					icon: "error",
				}).then(() => {
					window.localStorage.clear();
					navigate("/login");
				});
			});
	};
	useEffect(() => {
		verifyToken();
	}, []);
	return <></>;
}
