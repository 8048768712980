import React, { useEffect, useState } from "react";
import Sidebar from "Components/Navbars/Sidebar";
import Navbar from "Components/Navbars/Navbar";
import FooterSmall from "Components/Footers/FooterSmall";
import VerifyToken from "Auth/VerifyToken";
import NavLinks from "Components/Navbars/NavLinks";
// Import your FooterSmall component

const DashboardLayout = (props) => {
	const [sidebar, setSidebar] = useState("hidden");
	useEffect(() => {});
	return (
		<div className="h-screen relative w-full flex flex-row">
			<Sidebar sidebar={sidebar} setSidebar={setSidebar} />
			<div className="w-full overflow-x-hidden">
				<Navbar sidebar={sidebar} setSidebar={setSidebar} />
				{props.header}
				<div className="px-12 py-4 w-full">
					{props.links?.length > 0 && <NavLinks items={props.links}></NavLinks>}
					{props.children}
					<FooterSmall />
				</div>
			</div>
			<VerifyToken />
		</div>
	);
};

export default DashboardLayout;
