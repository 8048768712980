import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Swal from "sweetalert2";
// components

export default function MyExams() {
	const [url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");
	const [exams, setExams] = useState([]);
	const navigate = useNavigate();
	const getExams = () => {
		axios({
			// Endpoint to send files
			url: url + "/user/exams",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			setExams(response.data);
		}).catch(() => { });
	};
	const closeExam = (id) => {
		axios({
			// Endpoint to send files
			url: url + "/exams/" + id + "/close",
			method: "delete",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			toast.success("Exam closed successfuly", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			getExams();
		}).catch(() => { });
	};
	const deleteExam = (id) => {
		axios({
			// Endpoint to send files
			url: url + "/exams/" + id,
			method: "delete",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			toast.success("Exam deleted successfully", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			getExams();
		}).catch(() => { });
	};
	const openExam = (id) => {
		axios({
			// Endpoint to send files
			url: url + "/exams/" + id + "/open",
			method: "put",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		}).then((response) => {
			toast.success("Exam opened successfuly", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			getExams();
		}).catch(() => { });
	};
	useEffect(() => {
		getExams();
	}, []);
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-row justify-between">
				<h1 className=" font-bold">Exams</h1>
				<button
					onClick={(event) => {
						event.stopPropagation();
						navigate("/exams/create");
					}}
					className="text-white bg-sky-500 hover:bg-sky-600 rounded-lg "
				>
					Create Exam
				</button>
			</div>
			<div className="grid grid-cols-3 gap-4">
				{exams.map((exam, id) => {
					return (
						<div
							onClick={(event) => {
								event.preventDefault()
								navigate("/exams/" + exam.id);
							}}
							className="rounded-lg bg-white shadow-lg flex flex-col gap-4"
						>
							<div className="flex flex-row justify-between">
								<h1 className=" font-medium flex flex-col">
									{exam.title} <span className={exam.status === "closed" ? "text-red-500" : exam.status === "pending" ? "text-yellow-500" : "text-green-500"}>{exam.status}</span>
								</h1>
								<div className="flex flex-col ">
									{exam.status !== "opened" ? (
										<button
											onClick={(event) => {
												event.stopPropagation();
												openExam(exam.id);
											}}
										>
											<i className="fa-solid fa-lock-open text-green-500 hover:bg-slate-100 rounded-full text-center "></i>
										</button>
									) : exam.status === "opened" ? (
										<button
											onClick={(event) => {
												event.stopPropagation();
												closeExam(exam.id);
											}}
										>
											<i className="fa-solid fa-lock text-red-500 hover:bg-slate-100 rounded-full text-center "></i>
										</button>
									) : (
										""
									)}
									<button
										onClick={(event) => {
											event.stopPropagation();
											navigate("/exams/" + exam.id);
										}}
									>
										<i className="fa-solid fa-info text-sky-500 hover:bg-slate-100 rounded-full text-center "></i>
									</button>
									<button
										onClick={(event) => {
											event.stopPropagation();
											deleteExam(exam.id);
										}}
									>
										<i className="fa-solid fa-trash text-red-500 hover:bg-slate-100 rounded-full text-center "></i>
									</button>
								</div>
							</div>
							<div className="flex flex-col gap-2">
								<div className="flex flex-row gap-2">
									<label>date: </label>
									<p>{moment(exam.date).format("DD-MMMM hh:mm")}</p>
								</div>
								<div className="flex flex-row gap-2">
									<label>duration: </label>
									<p>{exam.duration}</p>
								</div>
								<div className="flex flex-row gap-2">
									<label>questions: </label>
									<p>{exam.nb_question}</p>
								</div>
							</div>
							{exam.status !== "closed" && (
								<div className="flex flex-row-reverse">
									<button
										onClick={(event) => {
											event.stopPropagation();
											navigate("/exams/" + exam.id + "/take");
										}}
										className="text-white bg-sky-500 hover:bg-sky-600 rounded-lg "
									>
										Take Exam
									</button>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
}
