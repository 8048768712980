import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import DawaratSessions from "./DawaratSessions";
import { toast } from "react-toastify";
import ProgramEditing from "../Sessions/ProgramEditing";
import Swal from "sweetalert2";
import FormModal from "Components/Dropdowns/FormModal";
import Loading from "Components/Loaders/Loading";
import { PayTicket, TicketReceipt } from "../../../Components/Receipts/TicketReceipt";
import TicketForm from "../Tickets/TicketForm";

// components

export default function InfoDawarat({ id }) {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();

	const [url, setUrl] = useAtom(urlAtom);
	const role = window.localStorage.getItem("role");
	const [option, setOption] = useState("tickets");
	const [step, setStep] = useState("");
	const [dawarat, setDawarat] = useState({});
	const [receipt, setReceipt] = useState({});
	const getDawarat = () => {
		axios({
			// Endpoint to send files
			url: url + "/dawarat/" + id,
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				setDawarat(response.data);
			})
			.catch(() => {});
	};
	const [ticket, setTicket] = useState(1);

	const pay = async (ticket) => {
		let options = {
			method: "post",
			url: url + "/tickets/" + ticket.id + "/pay",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				toast.success("Ticket paid successful", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getDawarat();
			})
			.catch(() => {});
	};

	const cancel = async (ticket) => {
		let options = {
			method: "delete",
			url: url + "/tickets/" + ticket.id + "/cancel",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				toast.success("Ticket canceled successful", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getDawarat();
			})
			.catch(() => {});
	};
	const [method, setMethod] = useState("add");
	const [form, setForm] = useState(false);
	const destroy = (id) => {
		axios({
			// Endpoint to send files
			url: url + "/tickets/" + id,
			method: "delete",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Ticket deleted successful", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getDawarat();
			})
			.catch(() => {});
	};
	const showForm = (action, data) => {
		setMethod(action);
		setTicket(data);
		setForm(true);
	};

	const [printTicket, setPrintTicket] = useState(false);

	useEffect(() => {
		getDawarat();
	}, []);
	const hideForm = () => {
		setForm(false);
	};
	return (
		<>
			{form ? (
				<FormModal hide={hideForm} title={"Ticket Form"}>
					<TicketForm action={method} data={ticket} hide={hideForm} getDawarat={getDawarat}></TicketForm>
				</FormModal>
			) : (
				""
			)}
			{printTicket ? (
				<FormModal hide={() => setPrintTicket(false)} title={"Print Ticket"}>
					<TicketReceipt ticket={ticket} hide={() => setPrintTicket(false)}></TicketReceipt>
				</FormModal>
			) : (
				""
			)}
			<div className="flex gap-4 w-full flex-col p-4 ">
				{/* Projects table */}
				<div className="flex flex-row items-center justify-between ">
					<div className="flex flex-row gap-4">
						<Link
							className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700  font-bold uppercase"
							role={"button"}
							onClick={(event) => {
								event.preventDefault();
								setOption("tickets");
							}}
						>
							Dawarat Tickets
						</Link>
						<Link
							className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700   font-bold uppercase"
							role={"button"}
							onClick={(event) => {
								event.preventDefault();
								setOption("sessions");
							}}
						>
							Dawarat Sessions
						</Link>
						<Link
							className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700   font-bold uppercase"
							role={"button"}
							onClick={(event) => {
								event.preventDefault();
								setOption("editing_sessions");
							}}
						>
							Edit Dawarat Sessions
						</Link>
					</div>
				</div>
				<div className="overflow-auto p-4 bg-white rounded-lg">
					{option === "tickets" ? (
						<table className=" w-full border-collapse ">
							<thead className="sticky top-0 whitespace-nowrap">
								<tr>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>student</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>status</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>price</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>discount</th>
									<th className={"    uppercase  font-semibold flex justify-around   transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
										<Link
											className="w-full ease-linear   "
											role={"button"}
											onClick={(event) => {
												event.preventDefault();
												showForm("add");
											}}
										>
											Add
											<i class="fa-solid fa-plus  "></i>
										</Link>
									</th>
								</tr>
							</thead>
							<tbody>
								{dawarat.tickets?.map((ticket, id) => {
									return (
										<tr key={id} className=" bg-white hover:bg-slate-100 ">
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{ticket?.student?.user?.name}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{ticket.status}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{ticket.price}.00 DA</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{ticket.discount}.00 DA</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left items-center flex justify-evenly gap-2"}>
												{ticket.status === "waiting" ? (
													<Link
														role={"button"}
														onClick={(event) => {
															event.preventDefault();
															pay(ticket);
														}}
														className="hover:text-green-500 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-check"></i>
													</Link>
												) : (
													""
												)}
												{ticket.status === "waiting" || ticket.status === "paid" ? (
													<Link
														role={"button"}
														onClick={(event) => {
															event.preventDefault();
															cancel(ticket);
														}}
														className="hover:text-red-600 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-close"></i>
													</Link>
												) : (
													""
												)}
												{ticket.status === "waiting" ? (
													<Link
														role={"button"}
														onClick={(event) => {
															event.preventDefault();
															showForm("edit", ticket);
														}}
														className="hover:text-yellow-400 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-pen-to-square"></i>
													</Link>
												) : (
													""
												)}
												{ticket.status === "canceled" ? (
													<Link
														role={"button"}
														onClick={() => destroy(ticket.id)}
														className="hover:text-red-600 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-trash"></i>
													</Link>
												) : (
													""
												)}
												{ticket.status === "paid" ? (
													<Link
														role={"button"}
														onClick={() => {
															setTicket(ticket);
															setPrintTicket(true);
														}}
														className="hover:text-slate-600 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-print"></i>
													</Link>
												) : (
													""
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : option === "sessions" ? (
						<DawaratSessions dawarat={dawarat} />
					) : option === "editing_sessions" ? (
						<ProgramEditing defaultDawarat={dawarat} />
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
}
