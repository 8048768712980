import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SelectOption from "Components/Dropdowns/SelectOption";
import Loading from "Components/Loaders/Loading";

// components

export default function StudentForm({ action, hide, data }) {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();
	const [url, setUrl] = useAtom(urlAtom);

	const method = action === "add" ? "post" : "put";
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [gender, setGender] = useState("male");
	const [phone_number, setPhoneNumber] = useState("");
	const [level_id, setLevelId] = useState("");
	const [loading, setLoading] = useState(false);
	const [levels, setLevels] = useState([]);
	const [levelPage, setLevelPage] = useState(1);
	const [levelLastPage, setLevelLastPage] = useState(1);
	const [levelSearch, setLevelSearch] = useState("");

	function generateEmail(event) {
		event.preventDefault();
		var formattedName = lastName.toLowerCase().replace(/\s/g, "") + firstName.toLowerCase().replace(/\s/g, "");
		var randomNumber = Math.floor(Math.random() * 9000) + 1000;
		var email = formattedName + randomNumber + "@numidia.com";

		setEmail(email);
	}
	const add_student = () => {
		setLoading(true);
		axios({
			// Endpoint to send files
			url: action === "add" ? url + "/users" : url + "/users/" + data.user.id,
			method: method,
			data: {
				role: "student",
				email,
				name: action === "add" ? lastName + " " + firstName : name,
				phone_number,
				level_id,
				gender,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Student " + action + "ed", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				hide(response.data.user_id);
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};
	const getLevels = async () => {
		const response = await axios
			.get(`${url}/levels`, {
				params: {
					page: levelPage,
					search: levelSearch,
				},
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: `${item.education ? item.education + " " : ""}${item.year ? item.year + " " : ""}${item.specialty ? item.specialty : ""}`,
		}));
		setLevels(data);
		setLevelLastPage(response?.data?.last_page);
	};
	useEffect(() => {
		if (data) {
			setName(data.user.name);
			setGender(data.user.gender);
			setPhoneNumber(data.user.phone_number);
			setEmail(data.user.email);
			setLevelId(data.level_id);
		}
	}, []);
	useEffect(() => {
		getLevels();
	}, [levelPage, levelSearch]);
	return (
		<div className="p-4 ">
			<form>
				<div className="grid grid-cols-2 gap-4">
					{action === "edit" ? (
						<div className="w-full  ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="First Name"
								onChange={(event) => {
									setName(event.target.value);
								}}
								value={name}
							/>
						</div>
					) : (
						<>
							<div className="flex-1  ">
								<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
									Last Name
								</label>
								<input
									type="text"
									className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="Last Name"
									onChange={(event) => {
										setLastName(event.target.value);
									}}
									value={lastName}
								/>
							</div>
							<div className=" flex-1 ">
								<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
									First Name
								</label>
								<input
									type="text"
									className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="First Name"
									onChange={(event) => {
										setFirstName(event.target.value);
									}}
									value={firstName}
								/>
							</div>
						</>
					)}
					<div className="flex-1">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Gender
						</label>
						<select
							className="my-2 p-2 placeholder-blueGray-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							default="male"
							onChange={(event) => {
								setGender(event.target.value);
							}}
						>
							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>
					<div className="flex-1">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Email address
						</label>
						<div className="flex my-2 gap-4">
							<input
								type="email"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Email"
								onChange={(event) => {
									setEmail(event.target.value);
								}}
								value={email}
							/>
							<button className="bg-white rounded-lg shadow p-2 flex items-center justify-center" onClick={generateEmail}>
								<i className="fa-solid fa-gear"></i>{" "}
							</button>
						</div>
					</div>
					<div className="flex-1">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Phone Number
						</label>
						<input
							type="number"
							onWheel={(e) => e.target.blur()}
							className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Phone Number"
							onChange={(event) => {
								setPhoneNumber(event.target.value);
							}}
							
						/>
					</div>

					<div className="flex-1">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Levels
						</label>
						<div className="my-2">
							<SelectOption
								onChange={(option) => {
									setLevelId(option.id);
								}}
								data={levels}
								page={levelPage}
								setPage={setLevelPage}
								withPagination={true}
								lastPage={levelLastPage}
								setSearch={setLevelSearch}
							/>
						</div>
					</div>
				</div>
				<div className="flex justify-center w-full my-4">
					<button
						className="w-full px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
						type="button"
						onClick={(event) => {
							event.preventDefault();
							add_student();
						}}
						disabled={loading}
					>
						{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
						Save
					</button>
				</div>
			</form>
		</div>
	);
}
