// components

import axios from "config/axios";
import SelectOption from "Components/Dropdowns/SelectOption";
import Loading from "Components/Loaders/Loading";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { urlAtom } from "index";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import moment from "moment/moment";

export default function SessionForm({ selectedInfo,  setForm, getSessions, defaultGroup }) {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const navigate = useNavigate();
	const [repeating, setRepeating] = useState("");
	const [group, setGroup] = useState({});
	const [groups, setGroups] = useState([]);
	const [groupPage, setGroupPage] = useState(1);
	const [lastGroupPage, setLastGroupPage] = useState(1);
	const [groupSearch, setGroupSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const addEvent = async () => {
		await addSession({
			starts_at: selectedInfo.startStr,
			ends_at: selectedInfo.endStr,
			repeating,
			group_id: group,
		}).then(() => {
			setForm(false);
		});
		await getSessions();
	};
	const addSession = async (session) => {
		let id;
		setLoading(true);
		await axios({
			// Endpoint to send files
			url: url + "/dawarat/" + group.id + "/sessions",
			method: "post",
			data: session,
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("New Session Added", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				id = response?.data?.id;
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});

		return id;
	};
	const getGroups = () => {
		axios({
			// Endpoint to send files
			url: url + "/dawarat/all",
			method: "get",
			params: {
				page: groupPage,
				search: groupSearch,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return {
						...item,
						label:
							(item.teacher?.user?.name ?? "") +
							" " +
							(item.module ?? "") +
							" " +
							(item.type ?? "") +
							" " +
							(item.level?.education ?? "") +
							" " +
							(item.level?.year ?? "") +
							" " +
							(item.level?.specialty ?? ""),
					};
				});
				setGroups(data);
				setLastGroupPage(response?.data?.last_page);
			})
			.catch(() => {});
	};
	useEffect(() => {
		if (!defaultGroup) {
			getGroups();
		}else{
			setGroup(defaultGroup)
		}
	}, [groupSearch, groupPage]);
	return (
		<>
			<div className="flex-auto p-4 min-w-96">
				<form>
					<div className="flex flex-wrap">
						<h6 className="text-slate-400 uppercase font-semibold">
							{moment(selectedInfo.startStr).format("dddd HH:mm")}
							{" - "}
							{moment(selectedInfo.endStr).format("HH:mm")}
						</h6>
						<div className="w-full my-2">
							{defaultGroup ? (
								""
							) : (
								<div className=" w-full ">
									<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
										Dawarat
									</label>
									<SelectOption
										onChange={(option) => {
											setGroup(option);
										}}
										data={groups}
										page={groupPage}
										setPage={setGroupPage}
										withPagination={true}
										lastPage={lastGroupPage}
										setSearch={setGroupSearch}
									/>
								</div>
							)}
							<div className=" w-full my-2">
								<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
									repeating
								</label>
								<select
									className="p-2 my-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									onChange={(event) => {
										setRepeating(event.target.value);
									}}
									value={repeating}
								>
									<option value="">Select Session Type</option>
									<option value="weekly">Weekly</option>
									<option value="monthly">Monthly</option>
									<option value={"once"}>One-time</option>
								</select>
							</div>
						</div>
					</div>
					<div className="flex justify-center w-full my-4">
						<button
							className="w-full px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
							type="button"
							onClick={(event) => {
								event.preventDefault();
								addEvent();
							}}
							disabled={loading}
						>
							{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
							add session
						</button>
					</div>
				</form>
			</div>
		</>
	);
}
