import Navbar from "Components/Navbars/IndexNavbar.js";
import axios from "config/axios.js";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formVisibilityAtom, cartProductsAtom, cartCountAtom } from "Components/Atoms/CartFormAtoms.js";
import CartForm from "./Components/CartForm";
import Footer from "Components/Footers/Footer";

import Products from "./Products";
import FormModal from "Components/Dropdowns/FormModal.js";
import ProductPhotos from "Components/Carousels/ProductPhotos";
import Loading from "Components/Loaders/Loading";
import { toast } from "react-toastify";

export default function ProductDetails() {
	const navigate = useNavigate();
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [form, setForm] = useAtom(formVisibilityAtom);
	const [products, setProducts] = useState([]);
	const [cartCount, setCartCount] = useState(1);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [product, setProduct] = useState({});
	const [gender, setGender] = useState("male");
	const [email, setEmail] = useState("");
	const [phone_number, setPhone] = useState();
	const [loading, setLoading] = useState(false);
	const [qte, setQte] = useState(1);

	const { productId } = useParams();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	const { id } = useParams();

	const showForm = () => {
		setForm(true);
	};

	const getProduct = async () => {
		axios({
			method: "get",
			url: url + "/products/" + id,
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setProduct(response.data);
			})
			.catch(() => {});
	};
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	const getProducts = async () => {
		axios({
			method: "get",
			url: url + "/products",
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setProducts(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => {});
	};

	const handleSearch = (event) => {
		setSearchQuery(event.target.value);
	};
	const hide = () => {
		setForm(false);
	};
	const create_order = async () => {
		setLoading(true);

		axios({
			method: "post",
			url: url + "/guest-order",
			data: {
				name: lastName + " " + firstName,
				email,
				phone_number,
				gender,
				// city,
				// wilaya,
				// street,
				products: [{ ...product, qte: qte }],
			},
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setForm(false);
				toast.success("Ordered successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				navigate("/library");
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		getProduct();
		getProducts();
	}, []);
	const filteredProducts = products.filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()));
	return (
		<div className="flex relative justify-center items-center flex-col w-full">
			<Navbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>

			<div className="flex flex-col items-center w-full p-8 pb-16 m-auto bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)]">
				<div className="relative w-full sm:w-4/5 shadow-lg rounded-3xl bg-slate-200 p-6">
					<div className="flex flex-col lg:flex-row gap-10 w-full items-start">
						{/* Left Column: Cart Products */}
						<div className="w-full lg:w-1/2 z-40">
							<div className="flex flex-col w-full rounded-lg bg-white shadow-md hover:shadow-lg p-4 gap-4 z-50" key={product.id}>
								<div className="aspect-square">
									<ProductPhotos product={product} />
								</div>
								<div className="flex flex-col flex-1 justify-between items-start gap-4">
									<div className="flex justify-between items-start w-full">
										<p className="text-s font-bold text-gray-800">{product.name}</p>
										<p className="text-s font-bold text-sky-600">{product.price}.00 DA</p>
									</div>
									<div className="flex items-center w-full gap-2">
										<label className="whitespace-nowrap">Quantity:</label>
										<input
											type="number"
											onWheel={(event) => event.target.blur()}
											value={qte}
											onChange={(e) => setQte(e.target.value)}
											className="p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow flex-1"
										/>
									</div>
								</div>
							</div>
						</div>

						{/* Right Column: User Details and Order */}
						<div className="lg:w-1/2 lg:sticky w-full bottom-0 h-fit">
							<div className="w-full flex flex-wrap gap-4 justify-center">
								<div className="flex-1">
									<label className="block uppercase text-slate-600 font-bold">Last Name</label>
									<input
										type="text"
										className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Last Name"
										onChange={(e) => setLastName(e.target.value)}
										value={lastName}
									/>
								</div>
								<div className="flex-1">
									<label className="block uppercase text-slate-600 font-bold">First Name</label>
									<input
										type="text"
										className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="First Name"
										onChange={(e) => setFirstName(e.target.value)}
										value={firstName}
									/>
								</div>
								<div className="w-full">
									<label className="block uppercase text-slate-600 font-bold">Phone Number</label>
									<input
										type="number"
										onWheel={(event) => event.target.blur()}
										className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Phone Number"
										onChange={(e) => setPhone(e.target.value)}
									/>
								</div>
								<div className="w-full">
									<label className="block uppercase text-slate-600 font-bold">Email</label>
									<input
										type="email"
										className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Email"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
									/>
								</div>
								<div className="w-full">
									<label className="block uppercase text-slate-600 font-bold">Gender</label>
									<select
										className="my-2 p-3 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										onChange={(e) => setGender(e.target.value)}
										value={gender}
									>
										<option value="">Select Gender</option>
										<option value="male">Male</option>
										<option value="female">Female</option>
									</select>
								</div>
								<div className="flex justify-center w-full my-4">
									<button
										className="px-4 py-2 w-full text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md transition-all duration-150"
										type="button"
										onClick={create_order}
										disabled={loading}
									>
										{loading ? <Loading width="20px" height="20px" color="white" weight="2px" /> : "Order now"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*search bar section */}
			<div className="sticky top-0 w-full flex flex-col z-20 ">
				<div className="flex flex-row w-full items-center justify-between bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] gap-4 px-24 py-4 h-fit">
					<div className="flex items-center space-x-4 flex-1 justify-end relative">
						<Link
							to={"/library/cart"}
							className=" transition duration-150 ease-in-out text-white font-semibold text-center relative w-fit p-3   hover:bg-white hover:text-[rgb(1,42,74)] rounded-full"
						>
							Go To Cart Page
						</Link>
						<div className="relative flex items-center max-w-lg flex-1 ">
							<input type="text" placeholder="Search..." className=" border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1" onChange={handleSearch} />
							<div className="absolute inset-y-0 right-0 flex items-center pointer-events-none mx-4">
								<i className="fa-solid fa-search text-gray-400 "></i>
							</div>
						</div>

						<button
							type="button"
							className=" transition duration-150 ease-in-out text-gray-300 text-center relative p-3  aspect-square  hover:bg-white hover:text-[rgb(1,42,74)] rounded-full"
							onClick={showForm}
						>
							<i className="fa-solid fa-cart-shopping text-lg"></i>
							{cartCount > 0 && (
								<div className="bg-red-500 text-white p-1 text-sm rounded-full aspect-square text-center absolute font-bold top-0 right-0 -translate-y-1/4">{cartCount}</div>
							)}
						</button>
					</div>
				</div>
			</div>
			{/*form section */}
			{form ? (
				<FormModal hide={hide} title="Cart">
					<CartForm />
				</FormModal>
			) : (
				""
			)}
			<main className="w-full px-24 my-8 min-h-screen">
				<div className=" w-full ">
					<h2 className=" font-poppins font-semibold bg-gradient-to-r bg-clip-text from-[rgb(1,42,74)] to-[rgb(42,111,150)] text-transparent text-left w-fit">Numidia Library</h2>
				</div>
				<Products products={filteredProducts} setProducts={setProducts} setCurrentPage currentPage totalPages />
			</main>
			<Footer />
		</div>
	);
}
