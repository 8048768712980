import React, { useEffect, useRef, useState } from "react";

import { useAtom } from "jotai";
import { urlAtom } from "index";
import CardLevels from "./CardLevels";

// components

export default function Levels() {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const role = window.localStorage.getItem("role");

	return (
		<>
			<CardLevels data-title="All levels" />
		</>
	);
}
