import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

export default function NumidiaProfession({ fade, t }) {
	return (
		<div data-aos={fade} className="flex flex-wrap justify-center text-white w-full transition-all duration-300 ">
			<div className="w-full md:w-1/2 flex flex-col justify-evenly items-start aspect-square">
				<div className="flex flex-col items-start justify-around ">
					<h3 className=" font-semibold leading-normal w-full">{t("professionalInstituteTitle")}</h3>
					<h5 className=" font-light leading-relaxed w-full">{t("professionalInstituteSubtitle")}</h5>
				</div>

				<Link to={"/register/profession"} className=" bg-white text-[rgb(1,42,74)] rounded-full py-2 px-4">
					{t("getStartedButton")}
				</Link>
			</div>

			<div className="w-full md:w-1/2 aspect-square  p-8">
				<img className="object-contain aspect-square w-full h-full" src={require("assets/img/numidia-profession-small.png")} />
			</div>
		</div>
	);
}
