import React, { useRef } from "react";

const Chair = ({ onMouseDown, onMouseUp, onMouseEnter,onClick, row, col, chairs }) => {
	const chairRef = useRef(null);
	return (
		<div
			ref={chairRef}
			onMouseUp={() => onMouseUp(row, col)}
			onClick={() => onClick(row, col)}
			onMouseDown={() => onMouseDown(row, col)}
			onMouseEnter={() => onMouseEnter(row, col, chairRef)}
			style={{
				backgroundColor: chairs[row][col].color,
			}}
			className={`!w-12 !h-12 border text-3xl cursor-pointer rounded flex items-center justify-center select-none ${
				chairs[row][col].selecting ? "bg-blue-100" : chairs[row][col].selected ? "" : "bg-gray-100"
			}`}
		>
			{chairs[row][col].selected ? <i className="fa-solid fa-chair" /> : ""}
		</div>
	);
};

export default Chair;
