import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
// components

export default function MyGroups() {
	const [url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");
	const [groups, setGroups] = useState([]);
	const getGroups = async () => {
		let options = {
			method: "get",
			url: url + "/user/groups",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setGroups(response.data);
		}).catch(() => { });
	};
	useEffect(() => {
		getGroups();
	}, []);
	return (
		<>
			<div className="grid grid-cols-2 gap-4">
				<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg">
					<h3 className=" font-semibold dark:text-white">Groups</h3>
					<div className="break-words w-full shadow-lg overflow-auto border-0">
						<div className="w-full">
							{/* Projects table */}
							<table className=" w-full border-collapse ">
								<thead className="sticky top-0 whitespace-nowrap">
									<tr>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Module</th>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>teacher</th>
									</tr>
								</thead>
								<tbody>
									{groups.map((group, id) => {
										return (
											<tr key={id} className=" bg-white hover:bg-slate-100 ">
												<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
													<span className={"font-bold "}>{group.module}</span>
												</th>
												<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{group.teacher.user.name}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
