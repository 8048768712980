import { useEffect } from "react";
import ReactDOM from 'react-dom';

export default function PortalWrapper({ children }) {
	const portalContainer = document.createElement("div");

	useEffect(() => {
		document.body.appendChild(portalContainer);

		return () => {
			document.body.removeChild(portalContainer);
		};
	}, [portalContainer]);

	return ReactDOM.createPortal(<>{children}</>, portalContainer);
}
