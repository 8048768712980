import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import chroma from "chroma-js";
import Loading from "Components/Loaders/Loading";
import SelectOption from "Components/Dropdowns/SelectOption";
import Chair from "../Amphitheaters/Chair";
import FormModal from "Components/Dropdowns/FormModal";
import StudentForm from "Management/Users/Students/StudentForm";
import TicketChair from "../Amphitheaters/TicketChair";

export default function TicketForm({ action, data, getTickets, hide }) {
	const token = window.localStorage.getItem("token");
	const [url] = useAtom(urlAtom);
	const method = action === "add" ? "post" : "put";

	const [row, setRow] = useState(null);
	const [seat, setSeat] = useState(null);
	const [studentForm, setStudentForm] = useState(false);
	const [location, setLocation] = useState("");
	const [discount, setDiscount] = useState(0);
	const [dawarat, setDawarat] = useState({});
	const [student_id, setStudentId] = useState("");

	const [groups, setGroups] = useState([]);
	const [students, setStudents] = useState([]);
	const [groupPage, setGroupPage] = useState(1);
	const [studentPage, setStudentPage] = useState(1);
	const [lastStudentPage, setLastStudentPage] = useState(1);
	const [lastGroupPage, setLastGroupPage] = useState(1);

	const [chairs, setChairs] = useState([]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const addTicket = () => {
		setLoading(true);
		axios({
			url: action === "edit" ? `${url}/tickets/${data.id}` : `${url}/tickets`,
			method,
			data: {
				row,
				seat,
				discount,
				location: "",
				student_id,
				dawarat_id: dawarat.id,
			},
			headers: {
				Accept: "Application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then(() => {
				hide();
				toast.success(`Ticket ${action}ed`, {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getTickets();
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const getStudents = () => {
		axios({
			url: `${url}/students`,
			method: "get",
			params: { page: studentPage },
			headers: {
				Accept: "Application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				const data = response?.data?.data.map((item) => ({
					...item,
					label: item.user.name ? `${item.user.name} ` : "",
				}));
				setLastStudentPage(response?.data?.last_page);
				setStudents(data);
			})
			.catch(() => {});
	};

	const getGroups = () => {
		axios({
			url: `${url}/dawarat/all`,
			method: "get",
			params: { page: groupPage },
			headers: {
				Accept: "Application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				const data = response?.data?.data.map((item) => ({
					...item,
					label: `${item.module ? item.module + " " : ""}${item.teacher.user.name ? item.teacher.user.name + " " : ""}`,
				}));
				setLastGroupPage(response?.data?.last_page);
				setGroups(data);
			})
			.catch(() => {});
	};
	const [levelPage, setLevelPage] = useState(1);
	const [levels, setLevels] = useState([]);
	const [lastLevelPage, setLastLevelPage] = useState(1);
	const getLevels = () => {
		axios({
			// Endpoint to send files
			url: url + "/levels",
			method: "get",
			params: {
				page: levelPage,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return { ...item, label: (item.year ? item.year + " " : "") + (item.education ? item.education + " " : "") + (item.specialty ? item.specialty : "") };
				});
				setLevels(data);
				setLastLevelPage(response?.data?.last_page);
			})
			.catch(() => {});
	};
	const handleClick = (r, c) => {
		setChairs((prev) => {
			// Create a copy of the previous state
			const updatedChairs = prev.map((row, rIndex) =>
				row.map((chair, cIndex) => {
					// Reset the selected state for all chairs
					chair.selecting = false;

					// Check if this chair should be selected or selecting
					if (rIndex === r && cIndex === c && chair.selected) {
						chair.selecting = true;
					}

					return chair;
				})
			);

			// Set the updated state
			setRow(r);
			setSeat(c);

			return updatedChairs;
		});
	};

	useEffect(() => {
		getStudents();
		getGroups();
		getLevels();
	}, [studentPage, groupPage, levelPage]);

	useEffect(() => {
		if (dawarat?.amphi) {
			let updatedChairs = Array.from({ length: dawarat.amphi.nb_rows }, () =>
				Array.from({ length: dawarat.amphi.nb_columns }, () => ({
					selected: false,
					selecting: false,
					color: null,
				}))
			);
			const randomHue = Math.random() * (230 - 120) + 120;
			dawarat.amphi?.sections?.forEach((section) => {
				const color = chroma.hsl(randomHue, 0.8, 0.45).hex().toUpperCase();
				for (let i = section.starting_row; i <= section.ending_row; i++) {
					for (let j = section.starting_column; j <= section.ending_column; j++) {
						if (i < dawarat.amphi.nb_rows && j < dawarat.amphi.nb_columns) {
							updatedChairs[i][j].color = chairs[i]?.[j]?.color || color;
							updatedChairs[i][j].selected = true;
						}
					}
				}
			});

			if (action === "edit" && data.row && data.seat) {
				updatedChairs[data.row][data.seat].selecting = true;
			}
			Object.entries(dawarat.tickets).map((ticket) => {

				if (ticket[1].status === "paid" && updatedChairs[ticket[1].row]?.[ticket[1].seat]) {
					updatedChairs[ticket[1].row][ticket[1].seat].selected = false;
					updatedChairs[ticket[1].row][ticket[1].seat].color = null;
				}
			});

			console.log(updatedChairs);
			

			setChairs(updatedChairs);
		}
	}, [dawarat]);
	useEffect(() => {
		if (action === "edit") {
			setRow(data.row);
			setSeat(data.seat);
			setStudentId(data.student_id);
			setDawarat(data.dawarat);
			setLocation(data.location);
			setDiscount(data.discount);
		}
	}, []);

	return (
		<>
			{studentForm ? (
				<FormModal
					hide={() => {
						setStudentForm(false);
					}}
					title="Student Form"
				>
					<StudentForm
						hide={() => {
							setStudentForm(false);
							getStudents();
						}}
						action={"add"}
					></StudentForm>
				</FormModal>
			) : (
				""
			)}
			<div className="flex-auto p-4">
				<form>
					<div className="flex flex-wrap">
						<div className="w-full my-2">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="dawarat">
								Dawarat
							</label>
							<SelectOption onChange={setDawarat} data={groups} page={groupPage} setPage={setGroupPage} withPagination lastPage={lastGroupPage} />
						</div>
						<div className="w-full my-2">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="student">
								Student
							</label>
							<div className="flex flex-row gap-4 items-center">
								<SelectOption onChange={(option) => setStudentId(option.id)} data={students} page={studentPage} setPage={setStudentPage} withPagination lastPage={lastStudentPage} />
								<button
									className="w-fit px-4 py-2 items-center text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
									type="button"
									onClick={() => setStudentForm(true)}
								>
									Add
								</button>
							</div>
						</div>
						<div className="w-full my-2">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="discount">
								Discount | {dawarat ? `(NB: The Price: ${dawarat.price_per_month}.00 DA)` : ""}
							</label>
							<input
								maxNumber={dawarat.price_per_month ?? 0}
								minNumber={0}
								placeholder="Discount"
								className="my-2 p-2 placeholder-blueGray-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								value={discount}
								onChange={(event) => setDiscount(event.target.value)}
								type="number"
								onWheel={(event) => event.target.blur()}
							/>
						</div>
						{dawarat?.amphi && (
							<div className="w-full my-2">
								<label className="block uppercase text-slate-600 font-bold" htmlFor="amphi">
									Amphi
								</label>
								<div className="overflow-auto w-full my-4 h-full">
									<div className="flex scroll-auto m-auto flex-col flex-nowrap overflow-auto w-fit">
										{chairs?.map((rows, rowIndex) => (
											<div key={rowIndex} className="flex">
												{rows.map((col, colIndex) => (
													<TicketChair key={colIndex} row={rowIndex} col={colIndex} onClick={handleClick} chairs={chairs} />
												))}
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="flex justify-center w-full my-4">
						<button
							className="w-full px-4 py-2 items-center text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
							type="button"
							onClick={addTicket}
							disabled={loading}
						>
							{loading ? <Loading width="20px" height="20px" color="white" weight="2px" /> : "Save"}
						</button>
					</div>
				</form>
			</div>
		</>
	);
}
