import React from "react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import axios from "config/axios.js";
import { formVisibilityAtom, nameAtom, emailAtom, phoneNumAtom, cityAtom, wilayaAtom, cartProductsAtom, cartCountAtom, genderAtom, streetAtom } from "Components/Atoms/CartFormAtoms.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductPhotos from "Components/Carousels/ProductPhotos.js";
import { toast } from "react-toastify";
import Loading from "Components/Loaders/Loading";
import FooterSmallDark from "Components/Footers/FooterSmallDark";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";

const BuyProduct = ({}) => {
	const [form, setForm] = useAtom(formVisibilityAtom);
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [gender, setGender] = useAtom(genderAtom);
	const [email, setEmail] = useAtom(emailAtom);
	const [phone_number, setPhone] = useAtom(phoneNumAtom);
	const [city, setCity] = useAtom(cityAtom);
	const [wilaya, setWilaya] = useAtom(wilayaAtom);
	const [street, setStreet] = useAtom(streetAtom);
	const [loading, setLoading] = useState(false);
	const [qte, setQte] = useState(1);
	const [product, setProduct] = useState({});

	const { productId } = useParams();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	const navigate = useNavigate();

	const create_order = async () => {
		setLoading(true);

		axios({
			method: "post",
			url: url + "/guest-order",
			data: {
				name: lastName + " " + firstName,
				email,
				phone_number,
				gender,
				// city,
				// wilaya,
				// street,
				products: [{ ...product, qte: qte }],
			},
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setForm(false);
				toast.success("Ordered successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				navigate("/library");
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};
	const getProduct = async () => {
		axios({
			method: "get",
			url: url + "/products/" + productId,
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setProduct(response.data);
			})
			.catch(() => {});
	};
	useEffect(() => {
		if (productId) {
			getProduct();
		}
	}, []);
	return (
		<div className="flex flex-col bg-gradient-to-r min-h-screen from-[rgb(1,42,74)] to-sky-600 w-full justify-between">
			<IndexNavbar className="z-50 w-full flex items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-sky-600 font-poppins px-12 py-4" />

			<div className="flex justify-center w-full p-8 m-auto">
				<div className="relative w-full sm:w-4/5 shadow-lg rounded-3xl bg-slate-200 p-6">
					<div className="flex flex-col lg:flex-row gap-10 w-full items-start">
						{/* Left Column: Cart Products */}
						<div className="w-full lg:w-1/2 z-40">
							<div className="flex flex-col w-full rounded-lg bg-white shadow-md hover:shadow-lg p-4 gap-4 z-50" key={product.id}>
								<div className="aspect-square">
									<ProductPhotos product={product} />
								</div>
								<div className="flex flex-col flex-1 justify-between items-start gap-4">
									<div className="flex justify-between items-start w-full">
										<p className="text-s font-bold text-gray-800">{product.name}</p>
										<p className="text-s font-bold text-sky-600">{product.price}.00 DA</p>
									</div>
									<div className="flex items-center w-full gap-2">
										<label className="whitespace-nowrap">Quantity:</label>
										<input
											type="number"
											onWheel={(event) => event.target.blur()}
											value={qte}
											onChange={(e) => setQte(e.target.value)}
											className="p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow flex-1"
										/>
									</div>
								</div>
							</div>
						</div>

						{/* Right Column: User Details and Order */}
						<div className="lg:w-1/2 lg:sticky w-full bottom-0 h-fit">
						<h4 className="font-bold mb-4">Buy Product Now</h4>
							<div className="w-full flex flex-wrap gap-4 justify-center">
								<div className="flex-1">
									<label className="block uppercase text-slate-600 font-bold">Last Name</label>
									<input
										type="text"
										className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Last Name"
										onChange={(e) => setLastName(e.target.value)}
										value={lastName}
									/>
								</div>
								<div className="flex-1">
									<label className="block uppercase text-slate-600 font-bold">First Name</label>
									<input
										type="text"
										className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="First Name"
										onChange={(e) => setFirstName(e.target.value)}
										value={firstName}
									/>
								</div>
								<div className="w-full">
									<label className="block uppercase text-slate-600 font-bold">Phone Number</label>
									<input
										type="number"
										onWheel={(event) => event.target.blur()}
										className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Phone Number"
										onChange={(e) => setPhone(e.target.value)}
									/>
								</div>
								<div className="w-full">
									<label className="block uppercase text-slate-600 font-bold">Email</label>
									<input
										type="email"
										className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Email"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
									/>
								</div>
								<div className="w-full">
									<label className="block uppercase text-slate-600 font-bold">Gender</label>
									<select
										className="my-2 p-3 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										onChange={(e) => setGender(e.target.value)}
										value={gender}
									>
										<option value="">Select Gender</option>
										<option value="male">Male</option>
										<option value="female">Female</option>
									</select>
								</div>
								<div className="flex justify-center w-full my-4">
									<button
										className="px-4 py-2 w-full text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md transition-all duration-150"
										type="button"
										onClick={create_order}
										disabled={loading}
									>
										{loading ? <Loading width="20px" height="20px" color="white" weight="2px" /> : "Order now"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<FooterSmallDark />
		</div>
	);
};

export default BuyProduct;
