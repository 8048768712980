// AdminSidebar.js
import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import NavMenu from "Components/Dropdowns/NavMenu";

export default function AdminSidebar({ sidebar, setSidebar }) {
	useLayoutEffect(() => {
		AOS.init();
	}, [sidebar]);

	return (
		<>
			{sidebar === "flex" && (
				<div
					onClick={(event) => {
						event.preventDefault();
						setSidebar((prev) => (prev === "hidden" ? "flex" : "hidden"));
					}}
					className="fixed 2xl:hidden text-white left-0 top-0 w-full bg-black bg-opacity-70 z-30 h-screen"
				></div>
			)}
			<nav className={`2xl:sticky fixed !w-96 top-0 left-0 bg-white ${sidebar} flex-wrap z-40 h-screen overflow-y-scroll p-4`} data-aos="fade-right">
				<div className="flex flex-col w-full">
					<Link to="/" className="text-slate-700 font-medium leading-relaxed flex items-center w-full justify-center whitespace-nowrap capitalize cursor-pointer my-4">
						<img className="w-20 h-auto shadow hover:shadow-lg rounded-full" src={require("assets/img/Numidia Education Group Logo/png/primary.png")} alt="Logo" />
					</Link>

					<NavMenu
						title="Main Pages"
						links={[
							{ to: "/dashboard", label: "Dashboard", icon: "fa-solid fa-gauge-high" },
							{ to: "/financials", label: "Financials", icon: "fa-solid fa-hand-holding-dollar" },
							{ to: "/stats", label: "Statistics", icon: "fa-solid fa-chart-pie" },
							{ to: "/inbox", label: "Notifications", icon: "fa-solid fa-bell" },
							{ to: "/profile", label: "Profile", icon: "fa-solid fa-circle-user" },
							{ to: "/settings", label: "Settings", icon: "fa-solid fa-gear" },
							{ to: "/news", label: "News", icon: "fa-solid fa-newspaper" },
						]}
					/>

					<NavMenu
						title="User Management"
						links={[
							{ to: "/users", label: "Users", icon: "fa-solid fa-users-gear" },
							{ to: "/teachers", label: "Teachers", icon: "fa-solid fa-chalkboard-user" },
							{ to: "/students", label: "Students", icon: "fa-solid fa-users" },
							{ to: "/parents", label: "Parents", icon: "fa-solid fa-person" },
						]}
					/>

					<NavMenu
						title="Department Management"
						links={[
							{ to: "/groups", label: "Groups", icon: "fa-solid fa-users-rectangle" },
							{ to: "/levels", label: "Levels", icon: "fa-solid fa-layer-group" },
							{ to: "/sessions", label: "Sessions", icon: "fa-solid fa-calendar-days" },
							{ to: "/attendance", label: "Attendance", icon: "fa-solid fa-calendar" },
							{ to: "/exams", label: "Exams", icon: "fa-solid fa-file-pen" },
						]}
					/>
					<NavMenu
						title="Library Management"
						links={[
							{ to: "/library/dashboard", icon: "fa-solid fa-book-open", label: "Library" },
							{ to: "/library/clients", icon: "fa-solid fa-users", label: "Clients" },
							{ to: "/library/orders", icon: "fa-solid fa-file-lines", label: "Orders" },
							{ to: "/library/products", icon: "fa-solid fa-book", label: "Products" },
						]}
					/>
					<NavMenu
						title="Dawarat Management"
						links={[
							{ to: "/dawarat/dashboard", icon: "fa-solid fa-building-columns", label: "Dawarat" },
							{ to: "/dawarat/amphitheaters", icon: "fa-solid fa-people-roof", label: "Amphitheatre" },
							{ to: "/dawarat/teachers", icon: "fa-solid fa-chalkboard-user", label: "Teachers" },
							{ to: "/dawarat/groups", icon: "fa-solid fa-users-rectangle", label: "Dawarat Groups" },
							{ to: "/dawarat/sessions", icon: "fa-solid fa-calendar", label: "Sessions" },
							{ to: "/dawarat/tickets", icon: "fa-solid fa-ticket", label: "Tickets" },
							{ to: "/dawarat/presences", icon: "fa-solid fa-calendar-check", label: "Presence" },
							{ to: "/dawarat/waiting-list", icon: "fa-solid fa-clipboard-list", label: "Waiting List" },
						]}
					/>
				</div>
			</nav>
		</>
	);
}
