import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import moment from "moment";
import ReactToPrint from "react-to-print";

export default function PresenceSheet({ getPresences, presence, setIds, ids }) {
	const token = window.localStorage.getItem("token");

	const [url, setUrl] = useAtom(urlAtom);
	const [students, setStudents] = useState(presence.students);

	const navigate = useNavigate();

	const cancel_presence = (id) => {
		let temp = ids.filter((item) => item !== id);
		setIds(temp);
	};
	const markPresence = async (presence, student) => {
		let options = {
			method: "post",
			url: url + "/dawarat/attendance/mark/presence",
			data: {
				presence,
				student,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				if (response?.data?.message) {
					toast.error(response?.data?.message, {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
				} else {
					toast.success("Action Completed successfuly", {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
				}
				getPresences();
			})
			.catch(() => {});
	};
	const cancel_session = async (presence) => {
		let options = {
			method: "delete",
			url: url + "/dawarat/attendance/sessions/" + presence.id + "/cancel",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				toast.success("Action Completed successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				cancel_presence(presence.id);
			})
			.catch(() => {});
	};
	const removePresence = async (presence, student) => {
		let options = {
			method: "post",
			url: url + "/dawarat/attendance/remove/presence",
			data: {
				presence,
				student,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				toast.success("Action Completed successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getPresences();
			})
			.catch(() => {});
	};

	const filter = (input) => {
		let s = presence.students.filter((item) => item.user.name.toLowerCase().includes(input.toLowerCase()));
		setStudents(s);
	};
	useEffect(() => {
		setStudents(presence.students);
	}, [presence.students]);
	var ComponentRef = useRef();

	return (
		<div className="p-2 flex flex-col gap-2  my-2  w-full bg-white shadow-lg rounded-lg overflow-x-auto">
			<div className="flex flex-col justify-between w-full ">
				<div className="w-full flex flex-row justify-between">
					<div>
						<h6 className=" p-0 m-0  uppercase  font-semibold text-left  text-slate-500  ">
							{presence.group.module} {presence.group.type}
						</h6>
						<p className="   uppercase  font-semibold text-left  text-slate-500  ">{presence.group.teacher.user.name}</p>
					</div>

					<div className="flex items-start gap-2">
						<button onClick={() => cancel_session(presence)} className=" text-red-600 bg-white rounded-lg  shadow hover:bg-slate-100 w-8 h-8 ease-linear transition-all duration-150">
							<i className="fa-solid fa-close" />
						</button>
						<button
							className="  text-green-600 bg-white rounded-lg  shadow hover:bg-slate-100 w-8 h-8 ease-linear transition-all duration-150"
							onClick={(event) => {
								event.preventDefault();
								cancel_presence(presence.id);
							}}
						>
							<i className="fa-solid fa-check" />
						</button>
					</div>
				</div>
				<div className="flex flex-row w-full justify-between font-bold mt-4">
					<span>
						{moment(presence.starts_at).format("dddd HH:mm")}
						{" - "}
						{moment(presence.ends_at).format("HH:mm")}
					</span>
					<span>
						{presence.present_count} / {presence.students.length}
					</span>
				</div>
			</div>
			<div className="flex flex-row gap-2 w-full">
				<input
					type="text"
					className="border  flex-1 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg  focus:outline-none focus:ring ease-linear transition-all duration-150"
					placeholder="Search"
					onChange={(event) => {
						filter(event.target.value);
					}}
				/>

				<ReactToPrint
					trigger={() => {
						return (
							<button className="border p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150">
								<i className="fa-solid fa-print" />
							</button>
						);
					}}
					onAfterPrint={() => {
						toast.success("Printing completed successful", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
					}}
					content={() => ComponentRef}
				/>
			</div>
			<div className="hidden">
				<table className=" w-full border-collapse border" ref={(el) => (ComponentRef = el)}>
					<thead className="sticky top-0">
						<tr>
							<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>Student</th>
							<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Presence</th>
						</tr>
					</thead>
					<tbody>
						{students.map((student, id) => {
							return (
								<tr key={id} className="bg-white hover:bg-slate-100 ">
									<th className={" whitespace-nowrap border border-slate-200  text-left align-middle"}>
										<span className={"font-bold "}>{student.user.name}</span>
									</th>
									<td className={"  border border-slate-200  h-full"}>
										<input
											type="checkbox"
											defaultChecked={student.pivot.status === "present"}
											class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus-ring-2"
											onChange={(event) => {
												if (event.target.checked) {
													markPresence(presence.id, student.id);
												} else {
													removePresence(presence.id, student.id);
												}
											}}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="max-h-36 overflow-auto">
				<table className=" w-full border-collapse border">
					<thead className="sticky top-0">
						<tr>
							<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>Student</th>
							<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Presence</th>
						</tr>
					</thead>
					<tbody>
						{students.map((student, id) => {
							return (
								<tr key={id} className="bg-white hover:bg-slate-100 ">
									<th className={" whitespace-nowrap border border-slate-200  text-left align-middle"}>
										<span className={"font-bold "}>{student.user.name}</span>
									</th>
									<td className={"  border border-slate-200 align-middle h-full"}>
										<input
											type="checkbox"
											defaultChecked={student.pivot.status === "present"}
											class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
											onChange={(event) => {
												if (event.target.checked) {
													markPresence(presence.id, student.id);
												} else {
													removePresence(presence.id, student.id);
												}
											}}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}
