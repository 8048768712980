import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from "@fullcalendar/rrule";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { toast } from "react-toastify";

// components
function renderEventContent(eventInfo) {
	return (
		<div className="flex p-2 flex-col h-[inherit] overflow-auto transition-all ease-linear duration-200 ">
			<b>{eventInfo.timeText}</b>
			<i>{eventInfo.event.title}</i>
		</div>
	);
}

function calculateDuration(start, end) {
	const startDate = new Date(start);
	const endDate = new Date(end);
	const durationMs = endDate - startDate;
	const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
	const durationMinutes = Math.floor((durationMs / (1000 * 60)) % 60);

	return `${padZero(durationHours)}:${padZero(durationMinutes)}`;
}

function padZero(value) {
	return value.toString().padStart(2, "0");
}
export default function PresenceForm({presences,  setIds, ids }) {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();
	const calendarRef = useRef();

	const resources = [
		{ id: "A1", title: "A1", eventColor: "#104e84" },
		{ id: "A2", title: "A2", eventColor: "#1631c4" },
		{ id: "S1", title: "S1", eventColor: "#0f5bdd" },
		{ id: "S2", title: "S2", eventColor: "#1290d4" },
		{ id: "CC", title: "CC", eventColor: "#1ec3c0" },
		{ id: "OPTIONAL", title: "OPTIONAL", eventColor: "#b3b3b3" },
	];
	const [url, setUrl] = useAtom(urlAtom);
	const [search, setSearch] = useState("");
	const [sessions, setSessions] = useState([]);

	const getSessions = () => {
		axios({
			// Endpoint to send files
			url: url + "/sessions",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response.data;
				data = data.map((option) => {
					return {
						backgroundColor:
							option.classroom === "A1"
								? "#104e84"
								: option.classroom === "A2"
								? "#1631c4"
								: option.classroom === "S1"
								? "#0f5bdd"
								: option.classroom === "S2"
								? "#1290d4"
								: option.classroom === "CC"
								? "#1ec3c0"
								: option.classroom === "OPTIONAL"
								? "#b3b3b3"
								: "",
						borderColor: "white",
						resourceId: option.classroom,
						title:
							(option.group?.teacher?.user?.name ?? "") +
							" " +
							(option.group?.module ?? "") +
							" " +
							(option.group?.type ?? "") +
							" " +
							(option.group?.level?.education ?? "") +
							" " +
							(option.group?.level?.year ?? "") +
							" " +
							(option.group?.level?.specialty ?? ""),
						extendedProps: {
							session: { ...option },
							exdate: option.repeating === "once" ? [] : option.exceptions.map((exception) => exception.date),
							rrule:
								option.repeating === "once"
									? null
									: {
											freq: option.repeating,
											dtstart: `${option.starts_at}Z`,
									  },
						},
						duration: calculateDuration(`${option.starts_at}Z`, `${option.ends_at}Z`),
						start: new Date(`${option.starts_at}Z`),
						end: new Date(`${option.ends_at}Z`),
						rrule:
							option.repeating === "once"
								? null
								: {
										freq: option.repeating,
										dtstart: `${option.starts_at}Z`,
								  },
						exdate: option.repeating === "once" ? [] : option.exceptions.map((exception) => exception.date),
					};
				});
				setSessions(data);
			})
			.catch(() => {});
	};
	const createPresenceSheet = (eventInfo) => {
		const event = eventInfo.event;
		axios({
			// Endpoint to send files
			url: url + "/attendance/presence",
			method: "post",
			data: {
				session_id: event.extendedProps.session.id,
				group_id: event.extendedProps.session.group_id,
				starts_at: event.startStr,
				ends_at: event.endStr,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let presence = response.data;
				if (!presences.some((item) => item.id === presence.id)) {
					setIds([...ids, presence.id]);
				}
				toast.success("Presence Sheet Created", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			})
			.catch(() => {});
	};

	const filter = () => {
		setSessions((prev) => {
			return prev.filter((item) => item.group?.module.toLowerCase().includes(search.toLowerCase()) || item.group?.teacher.user.name.toLowerCase().includes(search.toLowerCase()));
		});
	};

	useEffect(() => {
		getSessions();
	}, []);

	useEffect(() => {
		filter();
	}, [search]);
	return (
		<div className="min-w-full  p-4">
			<div className=" w-full flex gap-4 flex-col">
				<FullCalendar
					schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY}
					ref={calendarRef}
					aspectRatio={1}
					navLinks={true}
					navLinkDayClick={"resourceTimeline"}
					plugins={[resourceTimelinePlugin, listPlugin, timeGridPlugin, resourceTimeGridPlugin, rrulePlugin]}
					slotDuration="00:30:00"
					slotMinTime={"06:00:00"}
					slotMaxTime={"22:00:00"}
					headerToolbar={{
						left: "prev,next today",
						center: "title",
						right: "resourceTimeline,timeLineWeek,timeGridWeek,listDay",
					}}
					eventClassNames={"hover:cursor-pointer"}
					eventContent={renderEventContent}
					titleFormat={{ weekday: "long" }}
					dayHeaderFormat={{ weekday: "long", meridiem: "long" }}
					initialView="timeGridWeek"
					views={{
						timeLineWeek: {
							type: "resourceTimelineWeek",
							slotDuration: { days: 1 },
							slotLabelFormat: [
								// top level of text
								{ weekday: "long" }, // lower level of text
							],
						},
					}}
					resources={resources}
					resourceAreaHeaderContent="Classrooms"
					events={sessions}
					resourceAreaWidth="10%"
					contentHeight={"auto"}
					allDaySlot={false}
					height={"80vh"}
					timeZone="UTC"
					buttonText={{
						today: "Today",
						week: "Week",
						resourceTimeline: "Day",

						list: "List",
						resourceTimeGrid: "Time Line",
						timeLineWeek: "Week Time Line",
					}}
					eventClick={createPresenceSheet}
				/>
			</div>
		</div>
	);
}
