import React from "react";

import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Footer from "Components/Footers/Footer.js";

export default function Error500() {
	return (
		<div className=" flex justify-center items-center flex-col w-full">
			<IndexNavbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>
			<main className="bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)]  w-full px-24  z-40 min-h-screen">
				<img className="w-full" src={require("assets/img/500.svg").default} />
			</main>
			<Footer />
		</div>
	);
}
