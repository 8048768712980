import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "Components/Loaders/Loading";

export default function ExamForm() {
	const [url, setUrl] = useAtom(urlAtom);
	const [department, setDepartment] = useAtom(departmentAtom);
	const token = window.localStorage.getItem("token");
	const [nb_question, setNbQuestion] = useState(0);
	const [title, setTitle] = useState("");
	const [date, setDate] = useState("");
	const [duration, setDuration] = useState(0);
	const [questions, setQuestions] = useState([]);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const create_exam = () => {
		setLoading(true);
		axios({
			// Endpoint to send files
			url: url + "/exams",
			method: "post",
			data: {
				nb_question,
				date,
				department,
				title,
				duration,
				questions,
			},
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Exam created successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				navigate("/exams");
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {}, [questions]);
	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-row justify-between items-center">
				<button
					onClick={(event) => {
						event.stopPropagation();
						navigate("/exams");
					}}
					className="text-white bg-sky-500 hover:bg-sky-600 rounded-lg h-fit py-2 px-4  flex items-center gap-2"
				>
					<i className="fa-solid fa-arrow-left "></i>Return to Exams
				</button>
				<h4 className=" font-bold">Create New Exams</h4>
			</div>
			<form className="flex flex-col py-4 gap-4">
				<div className="w-full grid grid-cols-4 gap-4 ">
					<div className="my-2">
						<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
							title
						</label>
						<input
							className="my-2 p-3   text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							onChange={(event) => {
								setTitle(event.target.value);
							}}
							value={title}
							placeholder="Title"
							type="text"
						></input>
					</div>
					<div className="my-2">
						<label className="block  uppercase text-slate-600  font-bold " htmlFor="grid-password">
							date
						</label>
						<input
							type="datetime-local"
							className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Date"
							onChange={(event) => {
								setDate(event.target.value);
							}}
							value={date}
						/>
					</div>
					<div className="my-2">
						<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
							Number of Questions
						</label>
						<input
							type="number"
							onWheel={(event) => event.target.blur()}
							min="0"
							className="my-2 p-3  placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Number Of questions"
							onChange={(event) => {
								const parsedValue = parseInt(event.target.value, 10);
								if (!isNaN(parsedValue)) {
									setNbQuestion(parsedValue);
									const newQuestions = Array.from({ length: parsedValue }, (_, index) => ({
										audio: null,
										audio_url: "",
										content: "",
										answer: "",
										nb_choice: 4,
										choices: [{ content: "" }, { content: "" }, { content: "" }, { content: "" }],
									}));
									setQuestions(newQuestions);
								} else {
									setNbQuestion(0);
									setQuestions([]);
								}
							}}
						/>
					</div>
					<div className="my-2">
						<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
							Duration in minutes
						</label>
						<input
							type="number"
							onWheel={(event) => event.target.blur()}
							className="my-2 p-3   placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Duration"
							onChange={(event) => {
								setDuration(event.target.value);
							}}
						/>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-4 w-full ">
					{questions.map((question, index) => {
						return (
							<div key={index} className="w-full p-4 flex flex-col gap-4 bg-white shadow-lg rounded-lg  ">
								<h5 className=" font-bold">Question {index + 1}</h5>
								<div className="w-full my-2">
									<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
										Question
									</label>
									<textarea
										className="my-2 p-3 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										onChange={(event) => {
											let temp = [...questions];
											temp[index].content = event.target.value;
											setQuestions(temp);
										}}
										defaultValue={questions[index].content}
										placeholder="Question"
										type="text"
									></textarea>
									<div className="flex w-full gap-4 items-center my-2">
										<label>
											<div className="h-[54px] rounded-full border-dashed border text-slate-500 flex justify-center gap-4 w-fit p-4 items-center hover:cursor-pointer hover:border-blue-500 transition-all duration-200 ease-in-out hover:text-blue-500 hover:border-solid">
												<i className="fa-solid fa-add "></i>Upload Audio
											</div>
											<input
												className="hidden"
												type="file"
												onChange={(event) => {
													event.preventDefault();
													const file = event.target.files[0];
													if (file) {
														let temp = [...questions];
														temp[index].audio = file;
														const audioURL = URL.createObjectURL(file);
														temp[index].audio_url = audioURL;
														setQuestions(temp);
													}
												}}
												accept="audio/*"
											/>
										</label>
										<audio controls src={question.audio_url} className="flex-1">
											Your browser does not support the audio element.
										</audio>
									</div>
								</div>
								<div className="grid grid-cols-2 w-full gap-4">
									<div className="my-2">
										<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
											Number of Choices
										</label>
										<input
											type="number"
											onWheel={(event) => event.target.blur()}
											min="0"
											defaultValue={question.nb_choice}
											className="my-2 p-3   placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											placeholder="Number Of questions"
											onChange={(event) => {
												let parsedValue = parseInt(event.target.value, 10);
												const newQuestions = [...questions];
												if (!isNaN(parsedValue)) {
													newQuestions[index].nb_choice = parsedValue;
													newQuestions[index].choices = Array.from(
														{
															length: parsedValue,
														},
														() => ({
															content: "",
														})
													);
													setQuestions(newQuestions);
												}
											}}
										/>
									</div>
									<div className="my-2">
										<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
											Answer
										</label>
										<select
											className="my-2 p-3 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											onChange={(event) => {
												let temp = [...questions];
												temp[index].answer = event.target.value;
												setQuestions(temp);
											}}
										>
											<option defaultValue="">Select an option</option>
											{Array.from(
												{
													length: question.nb_choice,
												},
												(_, optionIndex) => (
													<option key={optionIndex} value={optionIndex + 1}>
														Option {optionIndex + 1}
													</option>
												)
											)}
										</select>
									</div>
								</div>
								<label className="block my-2 uppercase text-slate-600  font-bold" htmlFor="grid-password">
									Choices
								</label>
								{question.choices.map((choice, id) => {
									return (
										<input
											className=" my-2 p-3  text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											onChange={(event) => {
												let temp = [...questions];
												temp[index].choices[id].content = event.target.value;
												setQuestions(temp);
											}}
											defaultValue={choice.content}
											placeholder={"Choice " + parseInt(id + 1, 10)}
											type="text"
										></input>
									);
								})}
							</div>
						);
					})}
				</div>

				<div className="flex justify-center my-2 w-full">
					<button
						className=" text-white w-full py-2 bg-sky-600 active:bg-sky-700 font-bold uppercase  px-4  rounded-lg shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
						type="button"
						onClick={() => create_exam()}
						disabled={loading}
					>
						{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
						Save
					</button>
				</div>
			</form>
		</div>
	);
}
