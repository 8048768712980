import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import axios from "config/axios";
import { urlAtom } from "index";
import { useAtom } from "jotai";
import Checkouts from "Management/Users/Users/Checkouts";
import CardGroups from "Management/Groups/CardGroups";
import Calendar from "react-calendar";
import styled from "styled-components";
import FormModal from "Components/Dropdowns/FormModal";
import Fees from "Management/Users/Users/Fees";
import NumidiaReceipt from "Components/Receipts/NumidiaReceipt";
import { Link } from "react-router-dom";
import InfoStudent from "Management/Users/Students/InfoStudent";
import StudentForm from "Management/Users/Students/StudentForm";

export default function EmployeeDashboard() {
	var UserRef = useRef();
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [date, setDate] = useState(moment());
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [user, setUser] = useState(null);
	const [group, setGroup] = useState(null);
	const [users, setUsers] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");
	const [option, setOption] = useState("");
	const [info, setInfo] = useState("none");
	const [step, setStep] = useState(false);
	const [form, setForm] = useState(false);
	const [levels, setLevels] = useState([]);
	const [program, setProgram] = useState([]);
	const [levelPage, setLevelPage] = useState(1);
	const getProgram = () => {
		axios({
			// Endpoint to send files
			url: url + "/program",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				setProgram(response.data);
			})
			.catch(() => {});
	};

	const getLevels = () => {
		axios({
			// Endpoint to send files
			url: url + "/levels",
			method: "get",
			params: {
				levelPage,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return { ...item, label: (item.year ? item.year + " " : "") + (item.education ? item.education + " " : "") + (item.specialty ? item.specialty : "") };
				});
				setLevels(data);
			})
			.catch(() => {});
	};

	const showInfo = () => {
		setStep("register");
	};
	const showForm = () => {
		setForm(true);
	};
	const hide = () => {
		if (user) {
			getUser(user.id);
		}
		setForm(false);
	};
	const hideInfo = () => {
		setStep(false);
		getUser(user.id);
	};
	const getUsers = async () => {
		let options = {
			method: "get",
			url: url + "/users",
			params: {
				page: currentPage,
				sortBy,
				sortDirection,
				perPage,
				search,
				role: "student",
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setUsers(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => {});
	};

	const getUser = async (id) => {
		let options = {
			method: "get",
			url: url + "/users/" + id,
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setUser(response.data);
			})
			.catch(() => {});
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	useEffect(() => {
		getUsers();
	}, [currentPage, sortBy, sortDirection, perPage, search]);
	useEffect(() => {
		getLevels();
	}, []);
	return (
		<>
			{info === "fees" ? (
				<FormModal
					hide={() => {
						getUser();
						setInfo("none");
					}}
				>
					<Fees
						student={user.student}
						hide={() => {
							getUser();
							setInfo("none");
						}}
					/>
				</FormModal>
			) : (
				""
			)}
			{form ? (
				<FormModal hide={hide} title={"Student Form"}>
					<StudentForm action={"add"} data={user?.student} hide={hide}></StudentForm>
				</FormModal>
			) : (
				""
			)}
			{option != "" ? (
				<FormModal
					hide={() => {
						getUser();
						setOption("");
					}}
				>
					<NumidiaReceipt
						user={user}
						hide={() => {
							getUser();
							setOption("");
						}}
						option={option}
					></NumidiaReceipt>
				</FormModal>
			) : (
				""
			)}
			{step ? (
				<FormModal hide={hideInfo} title={"Student's Info"}>
					<InfoStudent student={user?.student} hide={hideInfo} step={step} setStep={setStep}></InfoStudent>
				</FormModal>
			) : (
				""
			)}
			<div className="grid  grid-flow-row md:grid-flow-col grid-cols-1 md:grid-cols-4 gap-4 w-full">
				<div className="rounded-lg shadow-lg p-2 text-xl">
					<CalendarContainer>
						<Calendar onChange={(newValue) => setDate(newValue)} value={date} />
					</CalendarContainer>
				</div>
				<div className="rounded-lg shadow-lg p-2">
					<CardGroups minimal={true} />
				</div>
				<div className="rounded-lg shadow-lg p-2 md:col-span-2 md:row-span-2">
					{user && (
						<>
							<div className="flex flex-col gap-4">
								<div
									className={
										user.wallet?.balance >= 0
											? "flex flex-row flex-auto p-4 bg-white shadow-lg rounded-lg gap-4 w-full justify-between items-center"
											: "flex flex-row flex-auto p-4 bg-red-100 shadow-lg rounded-lg gap-4 w-full justify-between items-center"
									}
								>
									<div>
										<h6 className=" ">Student Balance:</h6>
										<h6 className="font-play font-bold">{user.wallet?.balance}.00 DA</h6>
									</div>
									<div className="flex flex-col w-fit gap-2">
										<button
											onClick={(event) => {
												event.preventDefault();
												setOption("deposit");
											}}
											className="bg-white px-4 py-2 shadow-lg rounded-lg hover:bg-slate-100 capitalize"
										>
											deposit
										</button>
										<button
											onClick={(event) => {
												event.preventDefault();
												setOption("withdraw");
											}}
											className="bg-white  px-4 py-2 shadow-lg rounded-lg hover:bg-slate-100 capitalize"
										>
											withdraw
										</button>
									</div>
								</div>
								<div className="flex flex-row gap-4 justify-between">
									<div className="flex flex-row gap-4 justify-star">
										<div className="relative w-28 aspect-square">
											<img className="w-full aspect-square rounded-lg shadow object-cover cursor-pointer" src={`${auth_api_url}/files?url=${user?.profile_picture?.url}`}></img>
										</div>

										<div className="flex flex-col font-light text-slate-500 ">
											<div className="flex flex-row items-center gap-4 text-slate-900 font-medium font-mono capitalize">
												<h6>{user.name}</h6>
												<ReactToPrint
													trigger={() => {
														return (
															<button className="ml-4 border px-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150">
																<i className="fa-solid fa-print" />
															</button>
														);
													}}
													onAfterPrint={() => {
														toast.success("Printing completed successful", {
															position: "top-center",
															autoClose: 3000,
															hideProgressBar: true,
															closeOnClick: true,
															pauseOnHover: true,
															draggable: true,
															progress: undefined,
															theme: "light",
														});
													}}
													content={() => UserRef}
												/>
												{!user.student.fee_inscription?.paid ? (
													<Link
														data-title="Pay the inscription fee"
														role={"button"}
														onClick={(event) => {
															event.stopPropagation();
															setInfo("fees");
														}}
														className="justify-center text-red-500 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 flex items-center"
													>
														<i class="fa-solid fa-dollar"></i>
													</Link>
												) : (
													<div
														data-title="Inscription fee is paid"
														className="justify-center text-green-500 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 flex items-center"
													>
														<i class="fa-solid fa-check"></i>
													</div>
												)}
											</div>
											<p>{user.email}</p>
											<p>{user.phone_number}</p>
											{user.role === "student" ? (
												<p>
													{user.student.level.year} {user.student.level.education} {user.student.level.specialty}
												</p>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="flex flex-row justify-between items-center">
								<h6 className="font-semibold my-2 dark:text-white">Groups</h6>
								<button
									className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
									type="button"
									onClick={showInfo}
								>
									Register New Group
								</button>
							</div>

							<div className="break-words w-full shadow-lg overflow-auto border-0">
								<div className="w-full">
									{/* Projects table */}
									<table className="w-full border-collapse">
										<thead className="sticky top-0 whitespace-nowrap">
											<tr>
												<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Module</th>
												<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Teacher</th>
												<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Current Month / Session</th>
												<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Debt</th>
											</tr>
										</thead>
										<tbody>
											{user?.student?.groups?.map((group, id) => (
												<tr
													onClick={() => setGroup(group)}
													key={id}
													className={group.pivot.status === "active" ? "bg-white hover:bg-slate-100 cursor-grab" : "bg-red-100 hover:bg-red-300 cursor-grab"}
												>
													<th className={"whitespace-nowrap border border-slate-200 text-left align-middle"}>
														<span className={"font-bold"}>{group.module}</span>
													</th>
													<td className={"whitespace-nowrap border border-slate-200 text-left align-middle"}>{group.teacher.user.name}</td>
													<td className={"whitespace-nowrap border border-slate-200 text-left align-middle"}>
														{group.current_month} / {group.current_nb_session}
													</td>
													<td className={"whitespace-nowrap border border-slate-200 text-left align-middle"}>{group.pivot.debt}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>

							<h6 className="font-semibold my-2 dark:text-white">Checkouts</h6>
							<Checkouts student={user.student} groupId={group?.id} />

							<h6 className="font-semibold dark:text-white">Presences</h6>
							<div className="break-words w-full shadow-lg overflow-auto border-0">
								<div className="w-full">
									{/* Projects table */}
									<table className="w-full border-collapse">
										<thead className="sticky top-0 whitespace-nowrap">
											<tr>
												<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Group</th>
												<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 w-full"}>Presence (Month / Session)</th>
											</tr>
										</thead>
										<tbody>
											{user?.student?.groups?.map((group, id) => (
												<tr key={id} className="bg-white. hover:bg-slate-100 cursor-grab">
													<th className={"whitespace-nowrap border border-slate-200 text-left align-middle"}>
														<span className={"font-bold"}>
															{group.module} {group.teacher.user.name}
														</span>
													</th>
													<td className={"whitespace-nowrap border border-slate-200 text-left align-middle p-0 flex"}>
														{group.presences?.map((item, id) => (
															<div key={id} className="h-full border-x p-2 flex flex-col items-center">
																<span className="font-bold">
																	{moment()
																		.month(item.month - 1)
																		.format("MMMM")}{" "}
																	/ {item.session_number}
																</span>
																<span>{item.pivot.status}</span>
															</div>
														))}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</>
					)}
				</div>
				<div className="rounded-lg shadow-lg p-2">
					<div className="flex flex-row justify-between items-center">
						<h6 className="font-semibold my-2 dark:text-white">Students</h6>
						<button
							className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
							type="button"
							onClick={showForm}
						>
							Register
						</button>
					</div>
					<div className="flex w-full justify-between align-middle items-center gap-2">
						<div className="flex flex-row flex-nowrap overflow-auto w-3/4 gap-2 whitespace-nowrap scrollbar-hide ">
							<div className="flex flex-col my-2">
								<label>Users Per Page:</label>
								<select
									className="my-2 p-2 bg-white rounded-lg shadow ease-linear transition-all duration-150 "
									value={perPage}
									onChange={(event) => {
										setPerPage(event.target.value);
									}}
								>
									<option value={totalPages * perPage}>All</option>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="50">50</option>
								</select>
							</div>
						</div>
						<div className="flex flex-col my-2">
							<label>Search</label>
							<div className="flex p-2 my-2 items-center flex-row flex-nowrap bg-white rounded-lg shadow w-full ease-linear transition-all duration-150 ">
								<input
									className="focus:outline-none"
									type="text"
									value={search}
									onChange={(event) => {
										setSearch(event.target.value);
									}}
								></input>
								<i className="fa-solid fa-search"></i>
							</div>
						</div>
					</div>
					<div className="break-words w-full shadow-lg overflow-auto border-0">
						<div className="w-full">
							{/* Projects table */}
							<table className=" w-full border-collapse ">
								<thead className="sticky top-0 whitespace-nowrap">
									<tr>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
											<button
												className=""
												onClick={(event) => {
													event.preventDefault();
													setSortBy("name");
													if (sortDirection === "asc") {
														setSortDirection("desc");
													} else {
														setSortDirection("asc");
													}
												}}
											>
												<i className="fa-solid fa-filter"></i>
											</button>
											Name
										</th>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Phone Number</th>
									</tr>
								</thead>
								<tbody>
									{users?.map((user, id) => {
										return (
											<tr onClick={() => getUser(user.id)} key={id} className="bg-white hover:bg-slate-100 cursor-grab">
												<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
													<span className={"font-bold "}>{user.name}</span>
												</th>
												<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{user.phone_number}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
					<div className="flex flex-row justify-center gap-4 my-4 ">
						<button
							className=" shadow hover:shadow-lg px-4 py-2 gap-4 flex items-center bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
							disabled={currentPage === 1}
							onClick={() => handlePageChange(currentPage - 1)}
						>
							<i className="fa-solid fa-backward "></i>
							Previous
						</button>
						<button
							className=" shadow hover:shadow-lg px-4 py-2 gap-4 flex items-center bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
							disabled={currentPage === totalPages}
							onClick={() => handlePageChange(currentPage + 1)}
						>
							Next
							<i className="fa-solid fa-forward "></i>
						</button>
					</div>
				</div>
				<div className="rounded-lg shadow-lg p-2 start-3"></div>
			</div>
		</>
	);
}

const CalendarContainer = styled.div`
	/* ~~~ container styles ~~~ */
	max-width: 100%;
	margin: auto;
	background-color: white;
	border-radius: 3px;
	color: black; /* Set text color to black */

	.react-calendar__navigation {
		display: flex;

		.react-calendar__navigation__label {
			font-weight: bold;
			color: white; /* Ensure navigation label text is black */
			background-color: #027de8;
		}

		.react-calendar__navigation__arrow {
			flex-grow: 0.333;
		}
	}

	.react-calendar__month-view__weekdays {
		text-align: center;
		color: black; /* Set weekday labels to black */
	}

	button {
		margin: 6px;
		background-color: white; /* Set button background to white */
		border: 1px solid #dedede; /* Set button border to slate */
		border-radius: 3px;
		color: black; /* Set button text to black */
		padding: 8px;

		&:hover {
			background-color: #f0f0f0; /* Slightly darker on hover */
		}

		&:active {
			background-color: #039dfc; /* Set active button background to sky blue */
			color: white; /* Set active button text color to white */
		}
	}

	.react-calendar__month-view__days {
		display: grid !important;
		grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

		.react-calendar__tile {
			max-width: initial !important;
			color: black; /* Ensure day text is black */
		}

		.react-calendar__tile--now {
			color: #039dfc !important; /* Set the current date text color to black */
			font-weight: 900;
			background-color: #dedede;
		}

		.react-calendar__tile--active {
			background-color: #039dfc !important; /* Set selected date background to sky blue */
			color: white !important; /* Set selected date text color to white */
		}
	}

	.react-calendar__month-view__days__day--neighboringMonth {
		color: slategray; /* Set dates outside the current month to slate color */
		opacity: 0.7;
		background-color: #dedede;
	}

	.react-calendar__month-view__days__day--weekend {
		color: slategray; /* Set weekend dates to slate color */
	}

	.react-calendar__tile--range {
		box-shadow: 0 0 6px 2px black;
	}

	.react-calendar__year-view__months,
	.react-calendar__decade-view__years,
	.react-calendar__century-view__decades {
		display: grid !important;
		grid-template-columns: 20% 20% 20% 20% 20%;

		&.react-calendar__year-view__months {
			grid-template-columns: 33.3% 33.3% 33.3%;
		}

		.react-calendar__tile {
			max-width: initial !important;
			color: black; /* Ensure tile text is black */
		}
	}
`;
