import axios from "config/axios.js";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import { formVisibilityAtom, cartProductsAtom, cartCountAtom } from "Components/Atoms/CartFormAtoms.js";

import ProductPhotos from "Components/Carousels/ProductPhotos";

export default function Products({ products, setProducts, setCurrentPage, currentPage, totalPages }) {
	const navigate = useNavigate();
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [cartCount, setCartCount] = useAtom(cartCountAtom);
	const [cartProducts, setCartProducts] = useAtom(cartProductsAtom);
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	const handleAddButton = (data) => {
		const existingProduct = cartProducts.find((product) => product.id === data.id);

		if (existingProduct) {
			const updatedCartProducts = cartProducts.map((product) => {
				if (product.id === data.id) {
					return { ...product, qte: product.qte + 1 };
				}
				return product;
			});
			setCartProducts(updatedCartProducts);
		} else {
			setCartProducts([...cartProducts, { ...data, qte: 1 }]);
		}
		setCartCount(cartCount + 1);
	};
	return (
		<>
			<div className="grid lg:grid-cols-4 md:grid-cols-2 w-full gap-6 z-10">
				{/*products section */}
				{products && (
					<>
						{products.map((product) => (
							<div className="w-full rounded-md bg-slate-50 shadow-md hover:shadow-2xl p-4 z-10" key={product.id}>
								<ProductPhotos product={product} />
								<div>
									<div className="flex justify-between items-start hover:cursor-pointer my-2">
										<Link to={"/products/" + product.id} className="flex-1">
											<p className="text-s font-bold text-gray-800 overflow-hidden overflow-ellipsis h-[3.35em]">{product.name}</p>
										</Link>
										<p className="text-s font-bold text-sky-600 self-start">{product.price}.00 DA</p>
									</div>

									<div className="my-4 flex-warp flex flex-row gap-4 h-fit">
										{product.tags?.split(",").map((tag, index) => (
											<>
												<span className="w-fit rounded-lg bg-white-700 text-slate-500 border p-2">{tag.trim()}</span>
											</>
										))}
									</div>

									<button
										type="button"
										className="transition duration-150 ease-in-out  px-4 py-2 text-slate-100 bg-sky-700 rounded-lg w-full flex items-center justify-center gap-4 hover:scale-105"
										onClick={(event) => {
											handleAddButton(product);
										}}
									>
										<p>
											Add to cart <i className="fa-solid fa-plus"></i>
										</p>
									</button>
								</div>
							</div>
						))}
					</>
				)}
			</div>

			<div className="flex flex-row justify-center gap-2 mt-4 mb-24">
				<button
					className="px-4 py-2  flex items-center justify-center gap-4 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === 1}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					<i className="fa-solid fa-backward "></i>
					Previous
				</button>
				<button
					className="px-4 py-2 flex items-center justify-center gap-4 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === totalPages}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					Next
					<i className="fa-solid fa-forward "></i>
				</button>
			</div>
		</>
	);
}
