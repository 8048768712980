import { useEffect, useState } from "react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import axios from "axios"; // Ensure you have axios installed

const useEcho = () => {
	const [echoInstance, setEchoInstance] = useState(null);

	useEffect(() => {
		const PusherClient = new Pusher(process.env.REACT_APP_REVERB_APP_KEY, {
			wsHost: process.env.REACT_APP_REVERB_HOST,
			wsPort: process.env.REACT_APP_REVERB_PORT,
			wssPort: process.env.REACT_APP_REVERB_PORT,
			forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? "https") === "https",
			enabledTransports: ["ws", "wss"],
			authEndpoint: `${process.env.REACT_APP_API_URL_AUTH}/broadcasting/auth`,
			disableStats: true,
			cluster: "mt1",
			authorizer: (channel) => ({
				authorize: (socketId, callback) => {
					const token = localStorage.getItem("token");
					axios
						.post(
							`${process.env.REACT_APP_API_URL_AUTH}/broadcasting/auth`,
							{
								socket_id: socketId,
								channel_name: channel.name,
							},
							{
								headers: {
									Authorization: `Bearer ${token}`,
								},
							}
						)
						.then((response) => {
							callback(null, response.data);
						})
						.catch((error) => {
							callback(error);
						});
				},
			}),
		});

		// Create Echo instance
		const echo = new Echo({
			broadcaster: "reverb",
			client: PusherClient,
		});

		setEchoInstance(echo);

		return () => {
			if (echo) {
				echo.disconnect();
			}
		};
	}, []);

	return echoInstance;
};

export default useEcho;
