import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from "@fullcalendar/rrule";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import SelectOption from "Components/Dropdowns/SelectOption";

// components

function calculateDuration(start, end) {
	const startDate = new Date(start);
	const endDate = new Date(end);
	const durationMs = endDate - startDate;
	const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
	const durationMinutes = Math.floor((durationMs / (1000 * 60)) % 60);

	return `${padZero(durationHours)}:${padZero(durationMinutes)}`;
}

function padZero(value) {
	return value.toString().padStart(2, "0");
}
export default function ProgramCalendar() {
	function renderEventContent(eventInfo) {
		return (
			<div className="flex p-2 flex-col h-[inherit] overflow-auto transition-all ease-linear duration-200 ">
				<b>{eventInfo.timeText}</b>
				<i>{eventInfo.event.title}</i>
			</div>
		);
	}

	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const navigate = useNavigate();
	const calendarRef = useRef();
	const resources = [
		{ id: "A1", title: "A1", eventColor: "#104e84" },
		{ id: "A2", title: "A2", eventColor: "#1631c4" },
		{ id: "S1", title: "S1", eventColor: "#0f5bdd" },
		{ id: "S2", title: "S2", eventColor: "#1290d4" },
		{ id: "CC", title: "CC", eventColor: "#1ec3c0" },
		{ id: "OPTIONAL", title: "OPTIONAL", eventColor: "#b3b3b3" },
	];
	const [teachers, setTeachers] = useState([]);
	const [levelOption, setLevelOption] = useState("");
	const [teacherOption, setTeacherOption] = useState("");
	const [levels, setLevels] = useState([]);
	const [groupOption, setGroupOption] = useState("");
	const [groups, setGroups] = useState([]);
	const [sessions, setSessions] = useState([]);
	const [events, setEvents] = useState([]);
	const [calendarApi, setCalendarApi] = useState();
	const [levelPage, setLevelPage] = useState(1);
	const [groupPage, setGroupPage] = useState(1);
	const [teacherPage, setTeacherPage] = useState(1);
	const [lastTeacherPage, setLastTeacherPage] = useState(1);
	const [lastLevelPage, setLastLevelPage] = useState(1);
	const [lastGroupPage, setLastGroupPage] = useState(1);
	const getSessions = () => {
		axios({
			// Endpoint to send files
			url: url + "/sessions",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response.data;
				data = data.map((option) => {
					return {
						resourceId: option.classroom,
						title:
							(option.group?.teacher?.user?.name ?? "") +
							" " +
							(option.group?.module ?? "") +
							" " +
							(option.group?.type ?? "") +
							" " +
							(option.group?.level?.education ?? "") +
							" " +
							(option.group?.level?.year ?? "") +
							" " +
							(option.group?.level?.specialty ?? ""),
						extendedProps: {
							session: {
								...option,
								label:
									(option.group?.teacher?.user?.name ?? "") +
									" " +
									(option.group?.module ?? "") +
									" " +
									(option.group?.type ?? "") +
									" " +
									(option.group?.level?.education ?? "") +
									" " +
									(option.group?.level?.year ?? "") +
									" " +
									(option.group?.level?.specialty ?? ""),
							},
							exdate: option.repeating === "once" ? [] : option.exceptions.map((exception) => exception.date),
							rrule:
								option.repeating === "once"
									? null
									: {
											freq: option.repeating,
											dtstart: `${option.starts_at}Z`,
									  },
						},
						duration: calculateDuration(`${option.starts_at}Z`, `${option.ends_at}Z`),
						start: new Date(`${option.starts_at}Z`),
						end: new Date(`${option.ends_at}Z`),
						rrule:
							option.repeating === "once"
								? null
								: {
										freq: option.repeating,
										dtstart: `${option.starts_at}Z`,
								  },
						exdate: option.repeating === "once" ? [] : option.exceptions.map((exception) => exception.date),
					};
				});
				setSessions(data);
				setEvents(data);
			})
			.catch(() => {});
	};
	const getGroups = () => {
		axios({
			// Endpoint to send files
			url: url + "/groups/all",
			method: "get",
			params: {
				page: groupPage,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return {
						...item,
						label:
							(item.group?.teacher?.user?.name ?? "") +
							" " +
							(item.group?.module ?? "") +
							" " +
							(item.group?.type ?? "") +
							" " +
							(item.group?.level?.education ?? "") +
							" " +
							(item.group?.level?.year ?? "") +
							" " +
							(item.group?.level?.specialty ?? ""),
					};
				});
				setGroups(data);
				setLastGroupPage(response?.data?.last_page);
			})
			.catch(() => {});
	};
	const getLevels = () => {
		axios({
			// Endpoint to send files
			url: url + "/levels",
			method: "get",
			params: {
				page: levelPage,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return { ...item, label: item.education ? item.education + " " : "" + item.year ? item.year + " " : "" + item.specialty ? item.specialty : "" };
				});
				setLevels(data);
				setLastLevelPage(response?.data?.last_page);
			})
			.catch(() => {});
	};
	const getTeachers = () => {
		axios({
			// Endpoint to send files
			url: url + "/teachers",
			method: "get",
			params: { level: levelOption, page: teacherPage },
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return { ...item, label: item.user.name };
				});
				setTeachers(data);
				setLastTeacherPage(response?.data?.last_page);
			})
			.catch(() => {});
	};

	useEffect(() => {
		setCalendarApi(calendarRef.current.getApi());

		getSessions();
		getGroups();
		getLevels();
		getTeachers();
	}, [levelPage, groupPage, teacherPage]);

	useEffect(() => {
		setSessions(
			events.filter(
				(item) => item.extendedProps.session.label.includes(levelOption) && item.extendedProps.session.label.includes(groupOption) && item.extendedProps.session.label.includes(teacherOption)
			)
		);
	}, [levelOption, teacherOption, groupOption]);
	return (
		<>
			<div className=" w-full flex gap-4 flex-col bg-white rounded-lg p-4">
				<FullCalendar
					eventMaxStack={1}
					viewClassNames={"bg-white"}
					schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY}
					ref={calendarRef}
					aspectRatio={1}
					navLinks={true}
					navLinkDayClick={"resourceTimeline"}
					plugins={[resourceTimelinePlugin, listPlugin, timeGridPlugin, resourceTimeGridPlugin, rrulePlugin]}
					slotDuration="00:30:00"
					slotMinTime={"06:00:00"}
					slotMaxTime={"22:00:00"}
					headerToolbar={{
						left: "prev,next today",
						center: "title",
						right: "timeLineWeek,resourceTimeline,timeGridWeek,listWeek",
					}}
					eventContent={renderEventContent}
					titleFormat={{ weekday: "long" }}
					dayHeaderFormat={{ weekday: "long", meridiem: "long" }}
					initialView="timeLineWeek"
					views={{
						timeLineWeek: {
							type: "resourceTimelineWeek",
							slotDuration: { days: 1 },
							slotLabelFormat: [
								// top level of text
								{ weekday: "long" }, // lower level of text
							],
						},
					}}
					resources={resources}
					resourceAreaHeaderContent="Classrooms"
					events={sessions}
					resourceAreaWidth="10%"
					contentHeight={"auto"}
					allDaySlot={false}
					height={"80vh"}
					timeZone="UTC"
					buttonText={{
						today: "Today",
						week: "Week",
						resourceTimeline: "Day",

						list: "Week List",
						resourceTimeGrid: "Time Line",
						timeLineWeek: "Week Time Line",
					}}
				/>
			</div>
		</>
	);
}
