import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function AdminSidebar({ sidebar, setSidebar }) {
	let location = useLocation();
	const [isExpanded, setISExpanded] = useState([false, false, false, false, false]);
	useEffect(() => {
	}, [isExpanded]);
	return (
		<>
			{sidebar === "flex" ? (
				<div
					onClick={(event) => {
						event.preventDefault();
						if (sidebar === "hidden") {
							setSidebar("flex");
						} else {
							setSidebar("hidden");
						}
					}}
					className="fixed  2xl:hidden text-white left-0  top-0 w-full bg-black bg-opacity-70 z-30  h-screen"
				></div>
			) : (
				""
			)}

			<nav data-aos={"fade-right"} className={"md:sticky fixed !w-96 top-0 left-0 bg-white " + sidebar + "   flex-wrap  z-40   h-screen overflow-auto p-4"}>
				<div className="  flex flex-col  w-full  gap-4">
					<Link to="/" className="text-slate-700  font-medium leading-relaxed flex items-center w-full justify-center  whitespace-nowrap capitalize cursor-pointer my-2">
						<img className="w-20 h-auto shadow hover:shadow-lg rounded-full" src={require("assets/img/Numidia Education Group Logo/png/primary.png")}></img>
					</Link>

					{/* Divider */}
					<hr className="w-full" />
					{/* Heading */}
					<p
						className={
							isExpanded[0]
								? "w-full font-bold rounded-lg capitalize bg-sky-800 text-white p-3 cursor-pointer no-underline text-sm"
								: "w-full font-bold text-slate-500  capitalize cursor-pointer no-underline text-sm"
						}
						onClick={() =>
							setISExpanded((prev) => {
								return prev.map((item, index) => (index === 0 ? !item : item));
							})
						}
					>
						<i className="fa-solid fa-house mr-4"></i>
						Main pages
					</p>
					{/* Navigation */}

					{isExpanded[0] ? (
						<ul data-aos="fade-up" className="pl-4 transition-all duration-300 md:flex-col w-full flex flex-col list-none">
							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/dashboard" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/dashboard"
								>
									<i className={"fa-solid text-base fa-gauge-high   " + (location.pathname == "/dashboard" ? "" : "text-slate-300")}></i> Dashboard
								</Link>
							</li>
							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/inbox" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/inbox"
								>
									<i className={"fa-solid text-base fa-bell   " + (location.pathname == "/inbox" ? "" : "text-slate-300")}></i> Notifications
								</Link>
							</li>
							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/profile" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/profile"
								>
									<i className={"fa-solid text-base fa-circle-user   " + (location.pathname == "/profile" ? "" : "text-slate-300")}></i> Profile
								</Link>
							</li>

							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/settings" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/settings"
								>
									<i className={"fa-solid text-base fa-gear   " + (location.pathname == "/settings" ? "" : "text-slate-300")}></i> settings
								</Link>
							</li>
							<li className="items-center">
								<Link
									className={
										" capitalize  font-medium  transition-all ease-in-out duration-300 p-3 flex items-center gap-3 text-sm " +
										(location.pathname == "/news" ? "text-white bg-sky-600 rounded-lg " : "text-slate-700 hover:text-slate-500")
									}
									to="/news"
								>
									<i className={"fa-solid text-base fa-newspaper   " + (location.pathname == "/news" ? "" : "text-slate-300")}></i> News
								</Link>
							</li>
						</ul>
					) : (
						""
					)}

					<hr className="  w-full" />

					<br />
					<br />
					<br />
				</div>
			</nav>
		</>
	);
}
