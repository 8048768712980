import React, { useEffect, useState } from "react";
import Checkouts from "./Checkouts";
import moment from "moment";
// components

export default function Student({ student }) {
    const [groupId, setGroupId] = useState("");

    useEffect(() => {}, []);

    return (
        <>
            <div className="flex flex-row flex-wrap w-full gap-4">
                {student.supervisor && (
                    <div className="flex flex-row flex-auto justify-between ">
                        <div className="flex flex-col p-4 bg-white shadow-lg rounded-lg gap-4 w-full justify-between ">
                            <h4 className="font-bold">Supervisor:</h4>
                            <h4 className="font-play font-bold">{student.supervisor?.user.name}</h4>
                            <h4 className="font-play font-bold">{student.supervisor?.user.email}</h4>
                        </div>
                    </div>
                )}
            </div>
            <div className="grid grid-cols-1 bg-white shadow-lg rounded-lg p-4 my-2 gap-4">
                <div className="flex flex-col">
                    <h6 className="font-semibold dark:text-white">Groups</h6>
                    <div className="break-words w-full shadow-lg overflow-auto border-0">
                        <div className="w-full">
                            {/* Projects table */}
                            <table className="w-full border-collapse">
                                <thead className="sticky top-0 whitespace-nowrap">
                                    <tr>
                                        <th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Group Name</th>
                                        <th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Debt</th>
                                        <th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 w-full"}>Presence (Month / Session)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {student?.groups?.map((group, id) => (
                                        <tr
                                            onClick={() => setGroupId(group.id)}
                                            key={id}
                                            className={group.pivot.status === "active" ? "bg-white hover:bg-slate-100 cursor-grab" : "bg-red-100 hover:bg-red-300 cursor-grab"}
                                        >
                                            <th className={"whitespace-nowrap border border-slate-200 text-left align-middle"}>
                                                <span className={"font-bold"}>{group.module} {group.type} {group.teacher.user.name}</span>
                                            </th>
                                            <td className={"whitespace-nowrap border border-slate-200 text-left align-middle"}>{group.pivot.debt}</td>
                                            <td className={"whitespace-nowrap border border-slate-200 text-left align-middle p-0 flex"}>
                                                {group.presences?.map((item, id) => (
                                                    <div key={id} className="h-full border-x p-2 flex flex-col items-center">
                                                        <span className="font-bold">{moment().month(item.month - 1).format("MMMM")} / {item.session_number}</span>
                                                        <span>{item.pivot.status}</span>
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-4 flex flex-col bg-white shadow-lg rounded-lg">
                <h4 className="font-semibold dark:text-white">Checkouts</h4>
                <Checkouts student={student} groupId={groupId} />
            </div>
        </>
    );
}
