/*eslint-disable*/
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import AdminSidebar from "./AdminSidebar";
import StudentSidebar from "./StudentSidebar";
import ParentSidebar from "./ParentSidebar";
import TeacherSidebar from "./TeacherSidebar";
import StaffSidebar from "./StaffSidebar";

export default function Sidebar({ sidebar, setSidebar }) {
	let location = useLocation();
	const role = localStorage.getItem("role");

	return (
		<>
			{role === "admin" ? (
				<AdminSidebar sidebar={sidebar} setSidebar={setSidebar} />
			) : role === "student" ? (
				<StudentSidebar sidebar={sidebar} setSidebar={setSidebar} />
			) : role === "parent" ? (
				<ParentSidebar sidebar={sidebar} setSidebar={setSidebar} />
			) : role === "teacher" ? (
				<TeacherSidebar sidebar={sidebar} setSidebar={setSidebar} />
			) : role === "employee" ? (
				<StaffSidebar sidebar={sidebar} setSidebar={setSidebar} />
			) : (
				""
			)}
		</>
	);
}
