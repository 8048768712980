import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import adaptivePlugin from "@fullcalendar/adaptive";
import listPlugin from "@fullcalendar/list";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import scrollgridPlugin from "@fullcalendar/scrollgrid";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import SessionForm from "./SessionForm";
import PortalWrapper from "Layouts/PortalWrapper";
import SelectOption from "Components/Dropdowns/SelectOption";
import FormModal from "Components/Dropdowns/FormModal";

function calculateDuration(start, end) {
	const startDate = new Date(start);
	const endDate = new Date(end);
	const durationMs = endDate - startDate;
	const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
	const durationMinutes = Math.floor((durationMs / (1000 * 60)) % 60);

	return `${padZero(durationHours)}:${padZero(durationMinutes)}`;
}

function padZero(value) {
	return value.toString().padStart(2, "0");
}

export default function ProgramEditing({ defaultGroup }) {
	function renderEventContent(eventInfo) {
		const label = eventInfo.event.extendedProps.session.label;
		return (
			<div className="overflow-y-auto p-2 h-fit whitespace-break-spaces  transition-all ease-linear duration-200">
				<b>{eventInfo.timeText}</b>&nbsp;
				<p>{label}</p>
			</div>
		);
	}
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const navigate = useNavigate();
	const calendarRef = useRef();

	const [calendarApi, setCalendarApi] = useState();
	const [selectedInfo, setSelectedInfo] = useState("");
	const [form, setForm] = useState(false);
	const [sessions, setSessions] = useState([]);
	const [events, setEvents] = useState([]);
	const handleEventClick = (eventInfo) => {
		const event = eventInfo.event;
		Swal.fire({
			title: "Are you sure?",
			text: "This will remove the event and all its recurring events.",
			icon: "warning",
			confirmButtonColor: "#d33",
			denyButtonColor: "#3085d6",
			confirmButtonText: "Delete",
			denyButtonText: "Delete this",
			showDenyButton: true,
			showCloseButton: true,
			showLoaderOnConfirm: true,
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result) => {
			if (result.isConfirmed) {
				event.remove();
				deleteSession(event.extendedProps.session.id);
			} else if (result.isDenied) {
				calendarApi.addEvent({
					resourceId: event.resourceId,
					title: event.title,
					start: event.startStr,
					end: event.endStr,
					allDay: event.allDay,
					rrule: event.extendedProps.rrule,
					duration: calculateDuration(event.startStr, event.endStr),
					exdate: [...event.extendedProps.exdate, event.startStr],
					extendedProps: {
						session: event.extendedProps.session,
						rrule: event.extendedProps.rrule,
						exdate: [...event.extendedProps.exdate, event.startStr],
					},
				});
				addExceptionSession(event.extendedProps.session.id, event.startStr);
				event.remove();
			} else if (result.dismiss) {
			}
		});
	};
	const handleEventResize = async (info) => {
		await axios({
			// Endpoint to send files
			url: url + "/sessions/" + info.event.extendedProps.session.id,
			method: "put",
			params: {
				starts_at: info.event.startStr,
				ends_at: info.event.endStr,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success(" Session Updated", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			})
			.catch(() => {});
	};
	const handleEventDrop = async (info) => {
		await axios({
			// Endpoint to send files
			url: url + "/sessions/" + info.event.extendedProps.session.id,
			method: "put",
			params: {
				starts_at: info.event.startStr,
				ends_at: info.event.endStr,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success(" Session Updated", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			})
			.catch(() => {});
	};
	const handleDateSelect = (selectInfo) => {
		setSelectedInfo(selectInfo);
		setForm(true);
	};
	const addExceptionSession = async (id, date) => {
		await axios({
			// Endpoint to send files
			url: url + "/sessions/" + id + "/exception",
			method: "post",
			data: {
				date,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Session Deleted", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			})
			.catch(() => {});
	};
	const deleteSession = async (id) => {
		await axios({
			// Endpoint to send files
			url: url + "/sessions/" + id,
			method: "delete",

			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Session Deleted", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			})
			.catch(() => {});
	};
	const getSessions = async () => {
		await axios({
			// Endpoint to send files
			url: url + "/dawarat/sessions",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response.data;
				data = data.map((option) => {
					return {
						title: (option.group?.teacher?.user?.name ?? "") + " " + (option.group?.module ?? "") + " " + (option.group?.type ?? ""),
						duration: calculateDuration(`${option.starts_at}Z`, `${option.ends_at}Z`),
						start: new Date(`${option.starts_at}Z`),
						end: new Date(`${option.ends_at}Z`),
						borderColor: "white",
						exdate: option.repeating === "once" ? [] : option.exceptions.map((exception) => exception.date),
						rrule:
							option.repeating === "once"
								? null
								: {
										freq: option.repeating,
										dtstart: `${option.starts_at}Z`,
								  },
						extendedProps: {
							session: {
								...option,
								label:
									(option.group?.teacher?.user?.name ?? "") +
									" " +
									(option.group?.module ?? "") +
									" " +
									(option.group?.type ?? "") +
									" " +
									(option.group?.level?.education ?? "") +
									" " +
									(option.group?.level?.year ?? "") +
									" " +
									(option.group?.level?.specialty ?? ""),
							},
							exdate: option.repeating === "once" ? [] : option.exceptions.map((exception) => exception.date),
							rrule:
								option.repeating === "once"
									? null
									: {
											freq: option.repeating,
											dtstart: `${option.starts_at}Z`,
									  },
						},
					};
				});
				setSessions(data);
			})
			.catch(() => {});
	};

	useEffect(() => {
		setCalendarApi(calendarRef.current.getApi());
		getSessions();
	}, []);
	return (
		<>
			{form ? (
				<FormModal
					hide={() => {
						setForm(false);
					}}
					title={"Session Form"}
				>
					<SessionForm setForm={setForm} defaultGroup={defaultGroup} selectedInfo={selectedInfo}  getSessions={getSessions} />{" "}
				</FormModal>
			) : (
				""
			)}
			<div className=" whitespace-nowrap min-w-fit overflow-auto gap-4 bg-white rounded-lg p-4">
				<FullCalendar
					contentHeight={"auto"}
					viewClassNames={"bg-white"}
					navLinks={true}
					stickyFooterScrollbar={true}
					resourceAreaWidth={"10%"}
					navLinkDayClick={"resourceTimeline"}
					titleFormat={{ weekday: "long" }}
					datesAboveResources={true}
					dayHeaderFormat={{ weekday: "long", meridiem: "long" }}
					dayMinWidth={220}
					selectable={true}
					editable={true}
					allDaySlot={false}
					schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY}
					ref={calendarRef}
					plugins={[adaptivePlugin, resourceTimelinePlugin, scrollgridPlugin, resourceTimeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
					headerToolbar={{
						left: "prev,next today",
						center: "title",
						right: "timeGrid,timeGridWeek,listWeek",
					}}
					initialView="timeGridWeek"
					slotDuration="00:30:00"
					slotMinTime={"06:00:00"}
					slotMaxTime={"22:00:00"}
					events={sessions}
					timeZone="UTC"
					eventContent={renderEventContent}
					buttonText={{
						today: "Today",
						timeGrid: "Day",
						week: "Week",
						listWeek: "Week List",
					}}
					select={handleDateSelect}
					eventClick={handleEventClick}
					eventResize={handleEventResize}
					eventDrop={handleEventDrop}
					eventOverlap={false}
					selectOverlap={false}
					aspectRatio={1}
				/>
			</div>
		</>
	);
}
