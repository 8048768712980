import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckoutReceipt from "../../../Components/Receipts/CheckoutReceipt";
import moment from "moment";
import Loading from "Components/Loaders/Loading";
import ReactToPrint from "react-to-print";

export default function Checkouts({ student, getUser, groupId }) {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();

	const [receipts, setReceipts] = useState([]);
	const [step, setStep] = useState("checkouts");
	const [url, setUrl] = useAtom(urlAtom);
	const [checkouts, setCheckouts] = useState([]);
	const [groups, setGroups] = useState([]);
	const [paid, setPaid] = useState([]);
	const [loading, setLoading] = useState(false);
	var ComponentRef = useRef(null);
	
	const getCheckouts = async () => {
		let options = {
			method: "get",
			url: url + "/students/" + student.id + "/checkouts",
			params: { group_id: groupId },
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setCheckouts(response.data);
			})
			.catch(() => {});
	};
	const payByDebt = async () => {
		setLoading(true);

		const checkoutsToPay = Object.keys(paid).map((key) => ({
			id: key,
			paid_price: paid[key].paid_price,
			discount: paid[key].discount,
		}));
		if (checkoutsToPay.length > 0) {
			await axios({
				// Endpoint to send files
				url: url + "/checkouts/pay",
				method: "post",
				data: {
					checkouts: checkoutsToPay,
				},
				headers: {
					Accept: "Application/json",
					Authorization: "Bearer " + token,
				},
			})
				// Handle the response from backend here
				.then((response) => {
					toast.success("Checkouts Paid successfully", {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					setReceipts((prev) => [...prev, response.data]);
					setStep("checkout-receipt");
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
					setGroups({});
					setPaid({});
				});
		}
	};
	const payBySession = async () => {
		setLoading(true);

		const groupsToPay = Object.keys(groups).map((key) => ({
			id: key,
			paid_price: groups[key].paid_price,
			nb_paid_session: groups[key].nb_paid_session,
		}));

		if (groupsToPay.length > 0) {
			await axios({
				// Endpoint to send files
				url: url + "/checkouts/pay_by_sessions/" + student.id,
				method: "post",
				data: {
					groups: groupsToPay,
				},
				headers: {
					Accept: "Application/json",
					Authorization: "Bearer " + token,
				},
			})
				// Handle the response from backend here
				.then((response) => {
					toast.success("Additional Payment successfully", {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					setReceipts((prev) => [...prev, response.data]);
					setStep("checkout-receipt");
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
					setGroups({});
					setPaid({});
				});
		}
	};
	useEffect(() => {
		getCheckouts();
	}, [groupId, student]);
	return (
		<div className="block w-full">
			{/* Projects table */}

			{step === "checkouts" ? (
				<>
					<div className="block min-w-0 break-words border shadow-lg w-full overflow-x-auto ">
						<table className="items-center w-full bg-transparent border-collapse">
							<thead>
								<tr>
									<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Group Name</th>
									<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Price</th>
									<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Discount</th>
									<th className="align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100">Balance</th>
								</tr>
							</thead>
							<tbody>
								{checkouts.map((checkout, id) => {
									return (
										<tr
											key={id}
											className={
												checkout.balance > 0 ? "bg-white hover:bg-slate-100" : checkout.balance < 0 ? "bg-rose-100  hover:bg-rose-200" : "bg-amber-100  hover:bg-amber-200"
											}
										>
											<th className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap text-left flex items-center">
												{checkout.price - checkout.discount - checkout.paid_price > 0 ? (
													<input
														type="checkbox"
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
														onChange={(event) => {
															setPaid((prev) => {
																const newPaid = { ...prev };
																if (event.target.checked) {
																	newPaid[checkout.id] = {
																		...checkout,
																		paid_price: checkout.price - checkout.discount - checkout.paid_price,
																	};
																} else {
																	delete newPaid[checkout.id];
																}
																return newPaid;
															});
														}}
													/>
												) : (
													<input
														type="checkbox"
														className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
														onChange={(event) => {
															setGroups((prev) => {
																const newGroup = { ...prev };
																if (event.target.checked) {
																	newGroup[checkout.group.id] = {
																		paid_price: checkout.group.price_per_month,
																		nb_paid_session: checkout.group.nb_session,
																	};
																} else {
																	delete newGroup[checkout.group.id];
																}
																return newGroup;
															});
														}}
													/>
												)}
												<span className="ml-3 font-bold">
													{checkout.group.module} {checkout.group.type} {checkout.group.teacher.user.name}
												</span>
											</th>

											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">
												{paid[checkout.id] ? (
													<div className="flex items-center">
														{/* Decrement Button */}
														<button
															onClick={() => {
																setPaid((prev) => {
																	const newPaid = { ...prev };
																	const newValue = Math.max(0, newPaid[checkout.id].paid_price - checkout.price / checkout.group.nb_session);
																	newPaid[checkout.id].paid_price = newValue;
																	return newPaid;
																});
															}}
															className="bg-slate-50 hover:bg-slate-100 px-2"
														>
															-
														</button>

														{/* Input Field */}
														<input
															type="number"
															onWheel={(event) => event.target.blur()}
															max={checkout.price - paid[checkout.id].discount - checkout.paid_price}
															min={0}
															value={paid[checkout.id].paid_price}
															className="outline-none border-none text-center w-20"
															onChange={(event) => {
																setPaid((prev) => {
																	const newPaid = { ...prev };
																	newPaid[checkout.id].paid_price = Number(event.target.value);
																	return newPaid;
																});
															}}
														/>

														{/* Increment Button */}
														<button
															onClick={() => {
																setPaid((prev) => {
																	const newPaid = { ...prev };
																	const newValue = Math.min(
																		checkout.price - paid[checkout.id].discount,
																		newPaid[checkout.id].paid_price + checkout.price / checkout.group.nb_session
																	);
																	newPaid[checkout.id].paid_price = newValue;
																	return newPaid;
																});
															}}
															className="bg-slate-50 hover:bg-slate-100 px-2"
														>
															+
														</button>
													</div>
												) : groups[checkout.group.id] ? (
													<div className="flex items-center">
														{/* Decrement Button */}
														<button
															onClick={() => {
																setGroups((prev) => {
																	const newGroups = { ...prev };
																	const newValue = Math.max(0, newGroups[checkout.group.id].paid_price - checkout.group.price_per_month / checkout.group.nb_session);
																	newGroups[checkout.group.id].paid_price = newValue;

																	newGroups[checkout.group.id].nb_paid_session = Math.max(0, newGroups[checkout.group.id].nb_paid_session - 1);
																	return newGroups;
																});
															}}
															className="bg-slate-50 hover:bg-slate-100 px-2"
														>
															-
														</button>

														{/* Input Field */}
														<p className="outline-none border-none text-center w-20">{groups[checkout.group.id].paid_price}</p>

														{/* Increment Button */}
														<button
															onClick={() => {
																setGroups((prev) => {
																	const newGroups = { ...prev };
																	const newValue = Math.max(0, newGroups[checkout.group.id].paid_price + checkout.group.price_per_month / checkout.group.nb_session);
																	newGroups[checkout.group.id].paid_price = newValue;

																	newGroups[checkout.group.id].nb_paid_session = Math.max(0, newGroups[checkout.group.id].nb_paid_session + 1);
																	return newGroups;
																});
															}}
															className="bg-slate-50 hover:bg-slate-100 px-2"
														>
															+
														</button>
													</div>
												) : (
													checkout.price - checkout.discount - checkout.paid_price
												)}
											</td>

											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">
												{paid[checkout.id] ? (
													<input
														type="number"
														onWheel={(event) => event.target.blur()}
														max={checkout.price}
														min={0}
														defaultValue={checkout.discount}
														className="outline-none border-none"
														onChange={(event) => {
															setPaid((prev) => {
																const newPaid = { ...prev };
																newPaid[checkout.id].discount = Number(event.target.value);
																newPaid[checkout.id].paid_price = Math.max(0, checkout.price - Number(event.target.value) - checkout.paid_price);
																return newPaid;
															});
														}}
													/>
												) : (
													checkout.discount
												)}
											</td>
											<td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap">{checkout.balance}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className="flex justify-center gap-4 mt-4">
						{(Object.keys(paid).length>0 || Object.keys(groups).length>0) && <button
							className="px-4 py-2 text-white w-full flex justify-center items-center gap-4 bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
							type="button"
							onClick={async (event) => {
								event.preventDefault();
								await payByDebt();
								await payBySession();
							}}
							disabled={loading}
						>
							{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
							Pay
						</button>}
					</div>
				</>
			) : step == "checkout-receipt" ? (
				<>
					<div ref={(el) => (ComponentRef = el)} className="w-full flex flex-col gap-4">
						{receipts.map((receipt, index) => (
							<div className="w-full">
								<div key={index}>
									<CheckoutReceipt
										receipt={receipt}
										next={() => {
											setStep("checkouts");
											getUser();
										}}
									/>
								</div>
							</div>
						))}
					</div>
					<div className="flex flex-row w-full gap-4">
						<ReactToPrint
							trigger={() => {
								return (
									<button className="px-4 mt-4 py-2 text-white w-full flex justify-center items-center gap-4 bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
										Print
									</button>
								);
							}}
							onAfterPrint={() => {
								toast.success("Printing completed successful", {
									position: "top-center",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "light",
								});
								getCheckouts();
								setStep("checkouts");
								setReceipts([]);
							}}
							content={() => ComponentRef}
						/>
					</div>
				</>
			) : (
				""
			)}
		</div>
	);
}
