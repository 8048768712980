import React, { useState, useEffect } from "react";
import axios from "config/axios";
import Swal from "sweetalert2";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import moment from "moment";
import Inbox from "./Inbox";
import { XAxis, YAxis, CartesianGrid, Tooltip, Bar, BarChart, ResponsiveContainer, ReferenceLine, Brush, Cell, LineChart, Line } from "recharts";
import { UAParser } from "ua-parser-js";

export default function Profile() {
	const token = window.localStorage.getItem("token");
	const token_id = window.localStorage.getItem("access_token_id");
	const [url, setUrl] = useAtom(urlAtom);
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [transactions, setTransactions] = useState([]);
	const [chart, setChart] = useState("bar");

	const [period, setPeriod] = useState("all");
	const [domain, setDomain] = useState([]);
	const [grouping, setGrouping] = useState("all");
	const [user, setUser] = useState({});
	const [activities, setActivities] = useState([]);
	const navigate = useNavigate();
	const getProfile = async () => {
		let options = {
			method: "get",
			url: url + "/profile",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setUser(response.data);
			setActivities([...response?.data?.activities]);
		}).catch(() => { });
	};

	const clear_activities = () => {
		let options = {
			method: "delete",
			url: auth_api_url + "/activities/clear",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).catch(() => { });

		getProfile();
	};
	const revoke = (id) => {
		let options = {
			method: "delete",
			url: auth_api_url + "/activities/revoke/" + id,
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			getProfile();
		}).catch(() => { });
	};
	const getTransactions = async () => {
		let options = {
			method: "get",
			url: auth_api_url + "/transactions",
			params: { period, grouping },
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			const totalValues = response?.data?.map((transaction) => parseInt(transaction.total));
			const minValue = Math.min(...totalValues);
			const maxValue = Math.max(...totalValues);
			setDomain([minValue - 100, maxValue + 100]);
			setTransactions(response.data);
		}).catch(() => { });
	};
	useEffect(() => {
		getTransactions();
	}, [grouping, period]);
	useEffect(() => {
		getProfile();
	}, []);

	return (
		<>
			<div className="flex flex-col w-full gap-4 ">
				<div className="flex md:flex-row flex-col-reverse p-4 h-full bg-white shadow-lg rounded-lg gap-4 w-full justify-between">
					<div className="flex flex-row gap-4 h-full">
						<img className="aspect-square w-32 rounded-lg shadow object-cover " src={window.localStorage.getItem("profile_picture_url")}></img>

						<div className="flex flex-col font-light text-slate-500 ">
							<h5 className=" text-slate-900 font-medium font-mono capitalize ">
								{user.name} / <span className="font-light text-slate-500 text-lg">{user.role}</span>
							</h5>
							<h6>
								{user.email} / {user.phone_number}
							</h6>
							<h5 className=" text-slate-900 font-mono capitalize ">
								Balance:<span className="font-play font-bold text-lg">{user.wallet?.balance}.00 DA</span>
							</h5>
						</div>
					</div>

					<div className="md:w-32 w-full aspect-square ">{user.id ? <QRCode value={user.id} style={{ height: "100%", width: "100%" }} /> : ""}</div>
				</div>
				<div className="flex p-4 flex-col bg-white shadow-lg rounded-lg  gap-4 w-full justify-between h-auto">
					<div className="flex  gap-4 flex-row">
						<h6>User transactions </h6>
						{chart === "bar" ? (
							<button
								onClick={(event) => {
									event.preventDefault()
									setChart("line");
								}}
								className="bg-white hover:bg-slate-50 rounded-lg shadow-lg px-4 py-2"
							>
								Change To Line Chart
							</button>
						) : (
							<button
								onClick={(event) => {
									event.preventDefault()
									setChart("bar");
								}}
								className="bg-white hover:bg-slate-50 rounded-lg shadow-lg px-4 py-2"
							>
								Change To Bar Chart
							</button>
						)}
					</div>
					<div className=" overflow-x-auto w-full  ">
						<div className="flex flex-nowrap  h-full  w-fit  rounded-lg  bg-white">
							<button
								onClick={(event) => {
									event.preventDefault()
									setPeriod("all");
								}}
								className="w-fit  rounded-l-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								all the time
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setPeriod("1h");
								}}
								className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								past 1 hour
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setPeriod("day");
								}}
								className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								past day
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setPeriod("week");
								}}
								className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								the last week
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setPeriod("10days");
								}}
								className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								past 10 days
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setPeriod("month");
								}}
								className="w-fit rounded-r-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								the last month
							</button>
						</div>
					</div>
					<div className="overflow-x-auto   w-full">
						<div className="flex flex-nowrap whitespace-nowrap h-full  w-fit  rounded-lg  bg-white">
							<button
								onClick={(event) => {
									event.preventDefault()
									setGrouping("all");
								}}
								className="w-fit  rounded-l-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								individually
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setGrouping("1h");
								}}
								className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								by 1 hour
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setGrouping("day");
								}}
								className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								by day
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setGrouping("week");
								}}
								className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								by week
							</button>
							<button
								onClick={(event) => {
									event.preventDefault()
									setGrouping("month");
								}}
								className="w-fit rounded-r-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
							>
								by month
							</button>
						</div>
					</div>
					<div className="w-full h-auto  xl:aspect-[5/2] md:aspect-[3/1] aspect-[1/1]">
						{chart === "bar" ? (
							<ResponsiveContainer>
								<BarChart data={transactions} margin={{ top: 20, bottom: 20, right: 0, left: 0 }}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="date" padding={{ top: 0, bottom: 0, right: 20, left: 20 }} />
									<YAxis domain={domain} />
									<Tooltip />
									<ReferenceLine y={0} stroke="#000" />
									<Brush dataKey="name" height={30} stroke="#3483eb" />
									<Bar dataKey="total">
										{transactions.map((transaction, entry, index) => (
											<Cell key={`cell-${index}`} fill={transaction.total > 0 ? "#3483eb" : "#f71111"} />
										))}
									</Bar>
								</BarChart>
							</ResponsiveContainer>
						) : (
							<ResponsiveContainer>
								<LineChart data={transactions} margin={{ top: 20, bottom: 20, right: 0, left: 0 }}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="date" padding={{ top: 0, bottom: 0, right: 20, left: 20 }} />
									<YAxis domain={domain} />
									<Tooltip />
									<ReferenceLine y={0} stroke="#000" />
									<Brush dataKey="name" height={30} stroke="#3483eb" />
									<Line type="monotone" dataKey="total" stroke="#06c416" strokeWidth={3} activeDot={{ r: 8 }} />
								</LineChart>
							</ResponsiveContainer>
						)}
					</div>
				</div>

				<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg">
					<div className="flow-root">
						<div className="w-full flex flex-row justify-between items-center">
							<h4 className="font-bold dark:text-white">Activities</h4>
							<button
								onClick={(event) => {
									event.preventDefault()
									clear_activities();
								}}
								className=" font-medium text-center px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
							>
								Clear All Sessions
							</button>
						</div>

						<ul className="divide-y divide-gray-200 dark:divide-gray-700">
							{user.activities?.map((activity, id) => {
								let parser = new UAParser;
								let user_agent = parser.setUA(activity.user_agent)
								return (
									<li className="my-2 " key={id}>
										<div className="flex items-center gap-4 space-x-4 my-2">
											<div className="flex-shrink-0">
												{user_agent.getDevice()?.type === "mobile" ? (
													<i className="fa-solid fa-mobile text-xl"></i>
												) : user_agent.getDevice()?.type === "tablet" ? (
													<i className="fa-solid fa-tablet text-xl"></i>
												) : (
													<i className="fa-solid fa-computer text-xl"></i>
												)}
											</div>
											<div className="flex-1 items-center gap-2">
												<div className="flex flex-row gap-2 items-center">
													{activity.status === "active" ? <div className="w-2 h-2 bg-green-500 rounded-full"></div> : ""}
													<p className=" font-semibold text-gray-900 truncate dark:text-white ">
														{user_agent.getOS()?.name} / {user_agent.getBrowser()?.name}
													</p>
												</div>
												<p className=" font-normal text-gray-500 truncate dark:text-gray-400">{moment(activity.date).format("MMMM Do YYYY, h:mm")}</p>
												<p className=" font-normal text-gray-500 truncate dark:text-gray-400">
													{activity.location} / {activity.ip_address}
												</p>
											</div>
											<div className="inline-flex items-center">
												{activity.access_token_id !== token_id && activity.status === "active" ? (
													<button
														onClick={(event) => {
															event.preventDefault()
															revoke(activity.access_token_id);
														}}
														className=" font-medium text-center px-4 py-2 text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
													>
														Revoke
													</button>
												) : (
													""
												)}
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<Inbox />
			</div>{" "}
		</>
	);
}
