import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import StudentAnswers from "./StudentAnswers";
import QRCode from "react-qr-code";
import PortalWrapper from "Layouts/PortalWrapper";
import Swal from "sweetalert2";
// components

export default function StudentExams() {
	const [url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");
	const [student, setStudent] = useState({});
	const [answers, setAnswers] = useState([]);
	const [info, setInfo] = useState(false);
	const navigate = useNavigate();

	const { student_id } = useParams();

	const showInfo = (answers) => {
		setAnswers(answers);
		setInfo(true);
	};
	const getExam = () => {
		axios({
			// Endpoint to send files
			url: url + "/exams/student/" + student_id,
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				setStudent(response.data);
			}).catch(() => { });
	};

	useEffect(() => {
		getExam();
	}, []);

	return (
		<>
			{info ? (
				<PortalWrapper>
					<div className="fixed bg-black bg-opacity-25 top-0 left-0 z-50 w-full h-screen  flex justify-center items-center">
						<div className=" bg-slate-100 w-full rounded-lg max-h-[calc(100vh-3rem)] h-auto flex flex-col align-middle overflow-auto scrollbar-hide gap-4">
							<div className="flex flex-row items-center  justify-between p-4 bg-white rounded-t-lg  ">
								<h6 className="text-slate-700  font-bold capitalize">Student Answers </h6>
								<button
									className=" hover:text-white hover:bg-red-600 text-gray-500 w-8 h-8 rounded-full hover:shadow-lg outline-none  ease-linear transition-all duration-150"
									type="button"
									onClick={(event) => {
										event.preventDefault()
										setInfo(false);
									}}
								>
									<i className="fa-solid fa-close"></i>
								</button>
							</div>
							<div className="  ">
								<StudentAnswers answers={answers}></StudentAnswers>
							</div>
						</div>
					</div>
				</PortalWrapper>
			) : (
				""
			)}
			<div
				onClick={(event) => {
					event.preventDefault()
					navigate("/users/" + student.user_id);
				}}
				className=" cursor-pointer flex md:flex-row flex-col-reverse p-4 bg-white shadow-lg rounded-lg gap-4  w-full justify-between hover:bg-slate-100 transition-all duration-150"
			>
				<div className="flex flex-row gap-4 ">
					<div className="flex flex-col  font-light text-slate-500 ">
						<h5 className=" text-slate-900 font-medium font-mono capitalize ">{student.user?.name}</h5>
						<h6>
							{student.user?.email} / {student.user?.phone_number}
						</h6>
						<h6 className="capitalize">Student</h6>
					</div>
				</div>
				<div className="md:w-20 sm:h-20  w-full aspect-square ">{student.user_id ? <QRCode value={student.user_id} style={{ height: "100%", width: "100%" }} /> : ""}</div>
			</div>

			<div className="break-words w-full shadow-lg overflow-auto  ">
				<table className=" w-full border-collapse ">
					<thead className="sticky top-0 whitespace-nowrap">
						<tr>
							<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>Exam</th>
							<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Date</th>
							<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Questions</th>
							<th className={"  align-middle    uppercase    font-semibold text-left bg-slate-50 text-slate-500 "}>Score</th>
						</tr>
					</thead>
					<tbody>
						{student.exams?.map((exam, id) => {
							return (
								<tr key={id} className=" bg-white hover:bg-slate-100 ">
									<th className={" whitespace-nowrap  border border-slate-200  text-left align-middle flex items-center gap-2"}>
										<Link
											role={"button"}
											onClick={(event) => {
												event.stopPropagation();
												showInfo(exam.answers);
											}}
											className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
										>
											<i class="fa-solid fa-info"></i>
										</Link>
										<span className={"font-bold "}>{exam.title}</span>
									</th>
									<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{moment(exam.date).format("DD-MMMM hh:mm")}</td>
									<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{exam.nb_question}</td>
									<td className={" font-bold whitespace-nowrap  border border-slate-200  text-left align-middle"}>
										{(exam.answers.reduce((total, answer) => total + answer.score, 0) / exam.nb_question) * 100} %
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
}
