import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import DawaratForm from "./DawaratForm";
import InfoDawarat from "./InfoDawarat";
import { toast } from "react-toastify";
import FormModal from "Components/Dropdowns/FormModal";
import SelectOption from "Components/Dropdowns/SelectOption";

export default function Dawarats({ minimal }) {
	const token = window.localStorage.getItem("token");
	const role = window.localStorage.getItem("role");
	const navigate = useNavigate();
	const [url] = useAtom(urlAtom);

	const [form, setForm] = useState(false);
	const [dawarat, setDawarat] = useState({});
	const [infoCard, setInfoCard] = useState(false);
	const [method, setMethod] = useState("add");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("3");
	const [level, setLevel] = useState({});
	const [teacherId, setTeacherId] = useState("");
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");
	const [data, setData] = useState([]);
	const [levels, setLevels] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [levelPage, setLevelPage] = useState(1);
	const [teacherPage, setTeacherPage] = useState(1);
	const [lastLevelPage, setLastLevelPage] = useState(1);
	const [lastTeacherPage, setLastTeacherPage] = useState(1);
	const [teacherSearch, setTeacherSearch] = useState("");
	const [levelSearch, setLevelSearch] = useState("");

	const getDawarats = async () => {
		const response = await axios
			.get(`${url}/dawarat`, {
				params: {
					page: currentPage,
					sortBy,
					sortDirection,
					perPage,
					search,
					level_id: level.id,
					teacher_id: teacherId,
				},

				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "Application/json",
				},
			})
			.catch(() => {});
		setData(response?.data?.data);
		setTotalPages(response?.data?.last_page);
	};

	const getDawarat = async (method, id) => {
		const response = await axios
			.get(`${url}/dawarat/${id}`, {
				params: { role },
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: "Application/json",
				},
			})
			.catch(() => {});
		showForm(method, response.data);
	};

	const getLevels = async () => {
		const response = await axios
			.get(`${url}/levels`, {
				params: {
					page: levelPage,
					search: levelSearch,
				},
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: `${item.education ? item.education + " " : ""}${item.year ? item.year + " " : ""}${item.specialty ? item.specialty : ""}`,
		}));
		setLevels(data);
		setLastLevelPage(response?.data?.last_page);
	};

	const getTeachers = async () => {
		const response = await axios
			.get(`${url}/teachers`, {
				params: { level: level.label, page: teacherPage, search: teacherSearch },
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: item.user.name,
		}));
		setTeachers(data);
		setLastTeacherPage(response?.data?.last_page);
	};

	const destroy = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				await axios
					.delete(`${url}/dawarat/${id}`, {
						params: { role },
						headers: {
							Accept: "Application/json",
							Authorization: `Bearer ${token}`,
						},
					})
					.catch(() => {});

				toast.success("Dawarat deleted successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});

				getDawarats();

				Swal.fire("Deleted!", "The dawarat has been deleted.", "success");
			}
		});
	};

	const showForm = (action, data) => {
		setMethod(action);
		setDawarat(data);
		setForm(true);
	};

	const hide = () => {
		getDawarats();
		setForm(false);
		setInfoCard(false);
	};
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	useEffect(() => {
		getDawarats();
	}, [currentPage, sortBy, sortDirection, perPage, level, search]);

	useEffect(() => {
		getLevels();
	}, [levelPage, levelSearch]);

	useEffect(() => {
		getTeachers();
	}, [level, teacherPage, teacherSearch]);

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Dawarat Form"}>
					<DawaratForm action={method} hide={hide} data={dawarat} getDawarats={getDawarats}></DawaratForm>
				</FormModal>
			) : infoCard ? (
				<FormModal hide={hide} title={"Dawarat Info"}>
					<InfoDawarat id={dawarat.id} hide={hide}></InfoDawarat>
				</FormModal>
			) : (
				""
			)}
			<div className="flex w-full  justify-between align-middle items-center gap-2">
				{minimal ? (
					<div></div>
				) : (
					<div className="flex flex-row py-4 flex-nowrap w-3/4 gap-2 whitespace-nowrap scrollbar-hide ">
						<div className="flex flex-col">
							<label>Level Filter:</label>
							<SelectOption
								onChange={(option) => {
									setLevel(option);
								}}
								data={levels}
								page={levelPage}
								setPage={setLevelPage}
								withPagination={true}
								setSearch={setLevelSearch}
								lastPage={lastLevelPage}
							></SelectOption>
						</div>
						<div className="flex flex-col ">
							<label>Teacher Filter:</label>
							<SelectOption
								onChange={(option) => {
									setTeacherId(option.id);
								}}
								data={teachers}
								page={teacherPage}
								setPage={setTeacherPage}
								withPagination={true}
								setSearch={setTeacherSearch}
								lastPage={lastTeacherPage}
							></SelectOption>
						</div>
					</div>
				)}
				<div className="flex flex-col ">
					<label>Search</label>
					<div className="flex flex-row flex-nowrap p-2 items-center bg-white rounded-lg  shadow  w-full ease-linear transition-all duration-150  ">
						<input
							className="focus:outline-none"
							type="text"
							value={search}
							onChange={(event) => {
								setSearch(event.target.value);
							}}
						></input>
						<i className="fa-solid fa-search"></i>
					</div>
				</div>
			</div>
			<div className="my-2">
				<div className="break-words w-full shadow-lg overflow-auto   border-0">
					<div className="w-full">
						{/* Projects table */}
						<table className=" w-full border-collapse ">
							<thead className="sticky top-0 whitespace-nowrap">
								<tr>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>
										<button
											className=""
											onClick={(event) => {
												event.preventDefault();
												setSortBy("module");
												if (sortDirection === "asc") {
													setSortDirection("desc");
												} else {
													setSortDirection("asc");
												}
											}}
										>
											<i className="fa-solid fa-filter"></i>
										</button>
										Dawarat
									</th>
									{!minimal && (
										<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>
											<button
												className=""
												onClick={(event) => {
													event.preventDefault();
													setSortBy("capacity");
													if (sortDirection === "asc") {
														setSortDirection("desc");
													} else {
														setSortDirection("asc");
													}
												}}
											>
												<i className="fa-solid fa-filter"></i>
											</button>
											Capacity
										</th>
									)}
									{!minimal && (
										<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>
											<button
												className=""
												onClick={(event) => {
													event.preventDefault();
													setSortBy("type");
													if (sortDirection === "asc") {
														setSortDirection("desc");
													} else {
														setSortDirection("asc");
													}
												}}
											>
												<i className="fa-solid fa-filter"></i>
											</button>
											Type
										</th>
									)}

									<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Level</th>
									<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}>Price</th>

									{minimal ? (
										<th className={"align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500"}></th>
									) : (
										<th className={"    uppercase  font-semibold flex justify-around   transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
											<Link
												className="w-full ease-linear   "
												role={"button"}
												onClick={(event) => {
													event.preventDefault();
													showForm("add");
												}}
											>
												Add
												<i className="fa-solid fa-plus  "></i>
											</Link>
										</th>
									)}
								</tr>
							</thead>
							<tbody>
								{data.map((teacher, _) => {
									return (
										<>
											{teacher.groups.length > 0 && (
												<>
													<tr
														key={_}
														className=" bg-slate-300 hover:bg-slate-400 hover:cursor-grab"
														onClick={(event) => {
															event.preventDefault();
															navigate("/users/" + teacher.user_id);
														}}
													>
														<th className={" whitespace-nowrap  border border-slate-200  text-left align-middle "} colSpan={6}>
															{teacher.user.name} / {teacher.user.phone_number}
														</th>
													</tr>
													{teacher.groups.map((dawarat, id) => {
														return (
															<tr key={id} className=" bg-white hover:bg-slate-100">
																<th className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
																	<span className={"font-bold "}>
																		{dawarat.module} {dawarat.name}
																	</span>
																</th>
																{!minimal && (
																	<th className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
																		<span className={"font-bold "}> {dawarat.capacity}</span>
																	</th>
																)}
																<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{dawarat.type}</td>
																<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
																	{dawarat.level.year} {dawarat.level.education} {dawarat.level.specialty}
																</td>
																<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{dawarat.price_per_month}</td>

																<td className={" whitespace-nowrap  border border-slate-200  text-left items-center flex justify-evenly gap-2"}>
																	<Link
																		to={"/dawarat/" + dawarat.id+"/register"}
																		className="hover:text-slate-500 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
																	>
																		<i className="fa-solid fa-link"></i>
																	</Link>
																	<Link
																		role={"button"}
																		onClick={(event) => {
																			event.stopPropagation();
																			setDawarat(dawarat);
																			setInfoCard(true);
																		}}
																		className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
																	>
																		<i className="fa-solid fa-info"></i>
																	</Link>
																	<Link
																		role={"button"}
																		onClick={(event) => {
																			event.stopPropagation();
																			getDawarat("edit", dawarat.id);
																		}}
																		className="hover:text-yellow-400 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
																	>
																		<i className="fa-solid fa-pen-to-square"></i>
																	</Link>
																	<Link
																		role={"button"}
																		onClick={(event) => {
																			event.stopPropagation();
																			destroy(dawarat.id);
																		}}
																		className="hover:text-red-600 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
																	>
																		<i className="fa-solid fa-trash"></i>
																	</Link>
																</td>
															</tr>
														);
													})}
												</>
											)}
										</>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="flex flex-row justify-center gap-2 py-4">
				<button
					className=" px-4 py-2 flex items-center justify-center gap-4 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === 1}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					<i className="fa-solid fa-backward "></i>
					Previous
				</button>
				<button
					className=" px-4 py-2 flex items-center justify-center gap-4 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === totalPages}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					Next
					<i className="fa-solid fa-forward "></i>
				</button>
			</div>
		</>
	);
}
