import Navbar from "Components/Navbars/IndexNavbar.js";
import axios from "config/axios.js";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import { formVisibilityAtom, cartProductsAtom, cartCountAtom } from "Components/Atoms/CartFormAtoms.js";
import CartForm from "./Components/CartForm";
import { PublicLibrary as PublicLibraryCarousel } from "Components/Carousels/PublicLibrary.js";
import Footer from "Components/Footers/Footer";

import Products from "./Products";
import FormModal from "Components/Dropdowns/FormModal.js";

export default function PublicLibrary() {
	const navigate = useNavigate();
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [form, setForm] = useAtom(formVisibilityAtom);
	const [products, setProducts] = useState([]);
	const [cartCount, setCartCount] = useAtom(cartCountAtom);
	const [searchQuery, setSearchQuery] = useState("");
	const [cartProducts, setCartProducts] = useAtom(cartProductsAtom);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const showForm = () => {
		setForm(true);
	};
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	const getProducts = async () => {
		axios({
			method: "get",
			url: url + "/products",
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setProducts(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => {});
	};

	const handleSearch = (event) => {
		setSearchQuery(event.target.value);
	};
	const hide = () => {
		setForm(false);
	};

	useEffect(() => {
		getProducts();
	}, []);
	const filteredProducts = products.filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()));
	return (
		<div className="flex relative justify-center items-center flex-col w-full">
			<Navbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>
			<PublicLibraryCarousel />
			{/*search bar section */}
			<div className="sticky top-0 w-full flex flex-col z-20 ">
				<div className="flex flex-row w-full items-center justify-between bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] gap-4 px-24 py-4 h-fit">
					<div className="flex items-center space-x-4 flex-1 justify-end relative">
						<Link
							to={"/library/cart"}
							className=" transition duration-150 ease-in-out text-white font-semibold text-center relative w-fit p-3   hover:bg-white hover:text-[rgb(1,42,74)] rounded-full"
						>
							Go To Cart Page
						</Link>
						<div className="relative flex items-center max-w-lg flex-1 ">
							<input type="text" placeholder="Search..." className=" border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1" onChange={handleSearch} />
							<div className="absolute inset-y-0 right-0 flex items-center pointer-events-none mx-4">
								<i className="fa-solid fa-search text-gray-400 "></i>
							</div>
						</div>

						<button
							type="button"
							className=" transition duration-150 ease-in-out text-gray-300 text-center relative p-3  aspect-square  hover:bg-white hover:text-[rgb(1,42,74)] rounded-full"
							onClick={showForm}
						>
							<i className="fa-solid fa-cart-shopping text-lg"></i>
							{cartCount > 0 && (
								<div className="bg-red-500 text-white p-1 text-sm rounded-full aspect-square text-center absolute font-bold top-0 right-0 -translate-y-1/4">{cartCount}</div>
							)}
						</button>
					</div>
				</div>
			</div>
			{/*form section */}
			{form ? (
				<FormModal hide={hide} title="Cart">
					<CartForm />
				</FormModal>
			) : (
				""
			)}
			<main className="w-full px-24 my-8 min-h-screen">
				<div className=" w-full ">
					<h2 className=" font-poppins font-semibold bg-gradient-to-r bg-clip-text from-[rgb(1,42,74)] to-[rgb(42,111,150)] text-transparent text-left w-fit">Numidia Library</h2>
				</div>
				<Products products={filteredProducts} setProducts={setProducts} setCurrentPage currentPage totalPages />
			</main>
			<Footer />
		</div>
	);
}
