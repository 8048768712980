import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import ClientForm from "./ClientForm";
import { toast } from "react-toastify";
import PortalWrapper from "Layouts/PortalWrapper";
import FormModal from "Components/Dropdowns/FormModal";

export default function Clients() {
	const token = window.localStorage.getItem("token");
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [form, setForm] = useState(false);
	const [client, setClient] = useState({});
	const [clients, setClients] = useState([]);
	const [method, setMethod] = useState("add");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");

	const showForm = (action, data) => {
		setMethod(action);
		setClient(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};

	const navigate = useNavigate();

	const getClients = async () => {
		let options = {
			method: "get",
			url: url + "/clients",
			params: {
				page: currentPage,
				sortBy,
				sortDirection,
				perPage,
				search,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setClients(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => {});
	};
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	useEffect(() => {
		getClients();
	}, [currentPage, sortBy, sortDirection, perPage, search]);

	return (
		<>
			{form ? (
				<FormModal hide={hide} title="Client Form">
					<ClientForm action={method} data={client} hide={hide} getClients={getClients}></ClientForm>
				</FormModal>
			) : (
				""
			)}
			<h4 className=" font-bold">Clients</h4>
			<div className="flex w-full justify-between align-middle items-center gap-2">
				<div className="flex flex-row flex-nowrap overflow-auto w-3/4 py-4 gap-2 whitespace-nowrap scrollbar-hide ">
					<div className="flex flex-col ">
						<label>Clients Per Page:</label>
						<select
							className="bg-white p-2 rounded-lg shadow ease-linear transition-all duration-150 "
							value={perPage}
							onChange={(event) => {
								setPerPage(event.target.value);
							}}
						>
							<option value={totalPages * perPage}>All</option>
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="50">50</option>
						</select>
					</div>
				</div>
				<div className="flex flex-col my-4 ">
					<label>Search</label>
					<div className="flex flex-row flex-nowrap p-2 bg-white rounded-lg shadow w-full ease-linear transition-all duration-150 ">
						<input
							className="focus:outline-none"
							type="text"
							value={search}
							onChange={(event) => {
								setSearch(event.target.value);
							}}
						></input>
						<i className="fa-solid fa-search"></i>
					</div>
				</div>
			</div>
			<div className="break-words w-full shadow-lg overflow-auto border-0">
				<div className="w-full">
					{/* Projects table */}
					<table className=" w-full border-collapse ">
						<thead className="sticky top-0 whitespace-nowrap">
							<tr>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("name");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Name
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("email");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Email
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("phone_number");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Phone Number
								</th>
								<th className={" uppercase font-semibold flex justify-around transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
									<Link
										className="w-full ease-linear "
										role={"button"}
										onClick={(event) => {
											event.preventDefault();
											showForm("add");
										}}
									>
										Add
										<i class="fa-solid fa-plus "></i>
									</Link>
								</th>
							</tr>
						</thead>
						<tbody>
							{clients.map((client, id) => {
								return (
									<tr key={id} className="bg-white hover:bg-slate-100">
										<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
											<span className={"font-bold "}>{client.name}</span>
										</th>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{client.email}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{client.phone_number}</td>

										<td className={" whitespace-nowrap border border-slate-200 text-left items-center flex justify-evenly gap-2"}>
											<Link
												role={"button"}
												onClick={(event) => {
													event.preventDefault();
													navigate("/library/clients/" + client.id);
												}}
												className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-info"></i>
											</Link>
											<Link
												role={"button"}
												onClick={(event) => {
													event.stopPropagation();
													showForm("edit", client);
												}}
												className="hover:text-yellow-400 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-pen-to-square"></i>
											</Link>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="flex flex-row justify-center gap-2 my-4">
				<button
					className=" flex items-center justify-center gap-4 px-4 py-2 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === 1}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					<i className="fa-solid fa-backward "></i>
					Previous
				</button>
				<button
					className="flex items-center justify-center gap-4 px-4 py-2 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === totalPages}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					Next
					<i className="fa-solid fa-forward "></i>
				</button>
			</div>
		</>
	);
}
