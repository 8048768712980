import CardTeachers from "./CardTeachers";

// components

export default function Teachers() {
	return (
		<>
			<CardTeachers data-title="All Teachers" />
		</>
	);
}
