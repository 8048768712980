import React, { useState, useEffect } from "react";
import axios from "config/axios";
import Swal from "sweetalert2";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import NumidiaReceipt from "../../../Components/Receipts/NumidiaReceipt";

export default function MyReceipts({}) {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [receipts, setReceipts] = useState([]);
	const navigate = useNavigate();
	const getReceipts = async () => {
		let options = {
			method: "get",
			url: url + "/user/receipts",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setReceipts(response.data);
			})
			.catch(() => {});
	};
	useEffect(() => {
		getReceipts();
	}, []);
	return (
		<>
			<div className="flex flex-col w-full gap-4 ">
				<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg hover:bg-slate-100-xl">
					<h3 className=" font-semibold dark:text-white">Receipts</h3>
					<div className="break-words w-full shadow-lg overflow-auto border-0">
						<div className="w-full">
							{/* Projects table */}
							<table className=" w-full border-collapse ">
								<thead className="sticky top-0 whitespace-nowrap">
									<tr>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Amount</th>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>date</th>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Type</th>
									</tr>
								</thead>
								<tbody>
									{receipts?.map((receipt, id) => {
										return (
											<tr key={id} className=" bg-white hover:bg-slate-100 ">
												<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
													<span className={"font-bold "}>{receipt.total}.00 DA</span>
												</th>
												<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
													<span className={"font-bold "}>{receipt.created_at}</span>
												</th>
												<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{receipt.type}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
