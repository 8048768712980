import React, { useEffect, useState } from "react";
import axios from "config/axios.js";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import CardLink from "./CardLink.js";
import CardLevels from "Management/Levels/CardLevels.js";
import CardUsers from "Management/Users/Users/CardUsers.js";
import CardStudents from "Management/Users/Students/CardStudents.js";
import CardTeachers from "Management/Users/Teachers/CardTeachers.js";
import CardParents from "Management/Users/Parents/CardParents.js";
import CardGroups from "Management/Groups/CardGroups.js";
import Posts from "Pages/News/Posts.js";
import Sessions from "Management/Sessions/Sessions.js";
import Financials from "Management/Financials/Financials.js";
import Departments from "Components/Departments.js";
import Attendance from "Management/Sessions/Attendance.js";
import Exams from "Management/Exams/Exams.js";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function FinancialCards() {
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [title, setTitle] = useState("financials");

	const [financials, setFinancials] = useState("0.00 DA");

	const [students, setStudents] = useState(0);
	const [teachers, setTeachers] = useState(0);

	const [groups, setGroups] = useState(0);

	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);

	const navigate = useNavigate();

	const getStats = () => {
		let options = {
			method: "get",
			url: url + "/statistics/dashboard",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				const data = response.data;

				setFinancials(data.financials.balance + ".00 DA");
				setStudents(data.students);
				setTeachers(data.teachers);
				setGroups(data.groups);
			})
			.catch(() => {});
	};

	useEffect(() => {
		getStats();
	}, []);
	useEffect(() => {}, [modalIsOpen]);
	return (
		<>
			{modalIsOpen ? (
				<div className="fixed bg-black bg-opacity-25 top-0 left-0 z-50 w-full h-screen  flex justify-center items-center px-12">
					<div
						data-aos="zoom-in-down"
						className=" bg-slate-50 bg-opacity-80 backdrop-blur-lg w-full rounded-lg h-[calc(100vh-3rem)]  flex flex-col align-middle overflow-auto scrollbar-hide gap-4 p-4"
					>
						<div className="flex flex-row-reverse items-center  justify-between ">
							<button
								className="text-center hover:text-white hover:bg-red-600 text-gray-500 w-8 h-8 rounded-full hover:shadow-lg outline-none flex items-center justify-center ease-linear transition-all duration-150"
								type="button"
								onClick={(event) => {
									event.preventDefault();
									getStats();
									setModalIsOpen(false);
								}}
							>
								<i className="fa-solid fa-close text-base"></i>
							</button>
						</div>
						<div className="mb-8 p-4">
							{title === "financials" ? (
								<Financials />
							) : title === "departments" ? (
								<Departments
									hide={() => {
										setModalIsOpen(false);
									}}
								/>
							) : title === "levels" ? (
								<CardLevels />
							) : title === "Exams" ? (
								<Exams />
							) : title === "users" ? (
								<CardUsers />
							) : title === "students" ? (
								<CardStudents />
							) : title === "parents" ? (
								<CardParents />
							) : title === "teachers" ? (
								<CardTeachers />
							) : title === "groups" ? (
								<CardGroups />
							) : title === "sessions" ? (
								<Sessions />
							) : title === "news" ? (
								<Posts />
							) : title === "attendance" ? (
								<Attendance />
							) : (
								""
							)}
						</div>
					</div>
				</div>
			) : (
				""
			)}
			{/* Header */}
			<div className="flex flex-col  gap-4">
				<div className="bg-sky-600 px-12 py-8 w-full">
					<div className="grid grid-cols-2 xl:grid-cols-4 gap-8">
						<div
							onClick={(event) => {
								event.preventDefault();
								setTitle("financials");
								setModalIsOpen(true);
							}}
						>
							<CardLink statSubtitle="financials" statTitle={financials} statIconName="fa-solid fa-hand-holding-dollar" statIconColor="bg-green-500" />
						</div>

						<div
							onClick={(event) => {
								event.preventDefault();
								setTitle("students");
								setModalIsOpen(true);
							}}
						>
							<CardLink statSubtitle="students" statTitle={students} statIconName="fa-solid fa-users" statIconColor="bg-blue-900" />
						</div>

						<div
							onClick={(event) => {
								event.preventDefault();
								setTitle("teachers");
								setModalIsOpen(true);
							}}
						>
							<CardLink statSubtitle="Teachers" statTitle={teachers} statIconName="fa-solid fa-chalkboard-user" statIconColor="bg-sky-500" />
						</div>
						<div
							onClick={(event) => {
								event.preventDefault();
								setTitle("groups");
								setModalIsOpen(true);
							}}
						>
							<CardLink statSubtitle="groups" statTitle={groups} statIconName="fa-solid fa-user-group" statIconColor="bg-cyan-500" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
