import { Link } from "react-router-dom";

import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Footer from "Components/Footers/Footer.js";
import { Index as IndexCarousel } from "Components/Carousels/Index.js";
import { useTranslation } from "react-i18next";

export default function Index() {
	const { t } = useTranslation();
	return (
		<div className="flex justify-center items-center flex-col w-full">
			<IndexNavbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>
			<IndexCarousel />
			<section className="relative flex flex-wrap justify-between items-center w-full px-24 py-12">
				<div className="w-full md:w-1/2 aspect-square p-8">
					<img className="object-contain aspect-square" src={require("assets/img/benifits-illustration.png")} />
				</div>
				<div className="w-full md:w-1/2 flex flex-col justify-evenly items-start md: font-poppins p-8">
					<div className="flex flex-col capitalize justify-around items-center gap-4">
						<h3 className=" font-semibold leading-normal w-full ">{t("benefitsTitle")}</h3>
						<ul className="list-none font-medium flex flex-col w-full gap-4 my-4">
							<li className="flex flex-row items-center gap-4">
								<img className="w-10 h-10 object-contain" src={require("assets/img/computer.jpeg")}></img>
								<p className=" ">{t("benefits1")}</p>
							</li>
							<li className="flex flex-row items-center gap-4">
								<img className="w-10 h-10 object-contain" src={require("assets/img/pepole.jpeg")}></img>
								<p className=" ">{t("benefits2")}</p>
							</li>
							<li className="flex flex-row items-center gap-4">
								<img className="w-10 h-10 object-contain" src={require("assets/img/recycle.jpeg")}></img>
								<p className=" ">{t("benefits3")}</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="flex justify-center items-center flex-col w-full px-24 py-12 gap-8">
				<div className="w-full md:w-1/2 justify-center text-center flex flex-wrap font-poppins gap-4">
					<h3 className="font-semibold ">{t("departmentsTitle")}</h3>
					<p className=" font-medium leading-relaxed ">{t("departmentsText")}</p>
				</div>
				<div className=" justify-center flex flex-wrap">
					<div className="w-full ">
						<div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
							<div className="w-full ">
								<h5 className=" font-semibold  text-center my-4">Numidia School</h5>
								<Link to={"/school"} className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150">
									<img alt="..." className="align-middle rounded-lg" src={require("assets/img/school.jpg")} />
								</Link>
							</div>

							<div className="w-full">
								<h5 className=" font-semibold  text-center my-4">Numidia Academy</h5>
								<Link to={"/academy"} className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150">
									<img alt="..." className="align-middle rounded-lg" src={require("assets/img/academy.jpg")} />
								</Link>
							</div>

							<div className="w-full">
								<h5 className=" font-semibold  text-center my-4">Numidia Profession</h5>
								<Link to={"/profession"} className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150">
									<img alt="..." className="align-middle rounded-lg" src={require("assets/img/profession.jpg")} />
								</Link>
							</div>
							<div className="w-full">
								<h5 className=" font-semibold  text-center my-4">Numidia Library</h5>
								<Link to={"/library"} className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150">
									<img alt="..." className="align-middle rounded-lg" src={require("assets/img/library.jpg")} />
								</Link>
							</div>

							<div className="w-full xl:col-span-2">
								<h5 className=" font-semibold  text-center my-4">Numidia Dawarat</h5>
								<Link to={"/dawarat"} className="hover:-mt-4  flex flex-col break-words bg-white w-full shadow-lg rounded-lg ease-linear transition-all duration-150">
									<img alt="..." className="align-middle rounded-lg xl:h-auto xl:aspect-[3/1] xl:w-full xl:object-cover" src={require("assets/img/dawarat.jpg")} />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="flex justify-center items-center flex-col w-full px-24 py-12">
				<div className="relative flex flex-wrap justify-center gap-8 md:flex-nowrap items-center">
					<div className="w-full md:w-1/2 flex flex-col items-start font-poppins justify-center">
						<div className="flex flex-col items-start justify-evenly gap-8">
							<h3 className=" font-semibold leading-normal w-full">{t("educationGroupTitle")}</h3>
							<small className=" font-medium leading-relaxed w-full ">{t("educationGroupText")}</small>
						</div>

						<Link to={"/register"} className=" text-white bg-[rgb(1,42,74)] rounded-full my-8 py-2 px-4">
							{t("getStartedButton")}
						</Link>
					</div>

					<div className="w-full md:w-1/2 flex items-center aspect-square">
						<img src={require("assets/img/become-student-illustration.png")} />
					</div>
				</div>
			</section>
			<section className="flex justify-center items-center flex-col w-full px-24 py-12">
				<div className="flex flex-wrap justify-center w-full my-6 items-center">
					<div className="w-full md:w-1/2 flex flex-col items-start justify-center gap-8">
						<div className="flex flex-col items-start font-poppins gap-4">
							<h3 className=" font-semibold leading-normal w-full ">{t("getItNowTitle")}</h3>
							<p className=" font-medium leading-relaxed w-full 2">{t("getItNowText")}</p>
							<p className=" font-medium leading-relaxed w-full text-slate-600">{t("appDescription")}</p>
						</div>

						<Link className=" bg-slate-100 text-[rgb(1,42,74)] rounded-lg flex flex-row shadow-lg hover:shadow-2xl z-50 px-4  py-2">
							<img className="w-8 h-auto object-contain" src={require("assets/img/Social Media/google-play-logo.png")}></img>
							<div className=" text-slate-600 font-play">
								<p className="uppercase ! font-semibold text-slate-900 ">Get it now on</p>
								Google Play
							</div>
						</Link>
					</div>

					<div className="w-full md:w-1/2 flex items-center aspect-square">
						<img className="object-contain w-full h-auto scale-110" src={require("assets/img/numidia-app.svg").default} />
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}
