import React, { useEffect, useRef, useState } from 'react'
import axios from 'config/axios'
import { useAtom } from 'jotai'
import { urlAtom } from 'index'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from 'Components/Loaders/Loading'

// components

export default function LevelForm({
  action,
  data,
  getLevels,
  hide,
}) {
  const token = window.localStorage.getItem('token')
  const navigate = useNavigate()

  const [base_url, setUrl] = useAtom(urlAtom)

  const url =
    action === 'add' ? base_url + '/levels' : base_url + '/levels/' + data.id
  const method = action === 'add' ? 'post' : 'put'
  const [specialty, setSpecialty] = useState('')
  const [education, setEducation] = useState('')
  const [year, setYear] = useState('')
  const [loading, setLoading] = useState(false)

  const add_level = () => {
    setLoading(true)

    axios({
      // Endpoint to send files
      url: url,
      method: method,
      data: {
        specialty,
        education,
        year,
      },
      headers: {
        Accept: 'Application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      // Handle the response from backend here
      .then((response) => {
        toast.success('Level ' + action + 'ed', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        getLevels()
        hide()
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (action == 'edit') {
      setEducation(data.education)
      setYear(data.year)
      setSpecialty(data.specialty)
    }
  }, [])
  return (
    <div className="p-4 min-w-96">
      <form>
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className=" w-full my-2">
              <label
                className="block uppercase text-slate-600  font-bold "
                htmlFor="grid-password"
              >
                education
              </label>
              <select
                className="my-2 p-2   text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                onChange={(event) => {
                  setEducation(event.target.value)
                }}
                value={education}
              >
                <option value="">Select Education</option>

                <option value="preschool">Preschool</option>
                <option value="primary school">Primary School</option>
                <option value="middle school">Middle School</option>
                <option value="high school">High School</option>
                <option value="higher education">Higher Education</option>
              </select>
            </div>
            <div className=" w-full my-2">
              <label
                className="block uppercase text-slate-600  font-bold "
                htmlFor="grid-password"
              >
                Year
              </label>
              <select
                className="my-2 p-2   text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                onChange={(event) => {
                  setYear(event.target.value)
                }}
                value={year}
              >
                <option value="">Select Year</option>
                <option value="1">Year 1</option>
                <option value="2">Year 2</option>
                <option value="3">Year 3</option>
                {education === 'high school' ? (
                  ''
                ) : (
                  <option value="4">Year 4</option>
                )}
                {education === 'middle school' ||
                education === 'high school' ? (
                  ''
                ) : (
                  <option value="5">Year 5</option>
                )}
              </select>
            </div>
            {education === 'high school' ? (
              <div className=" w-full my-2">
                <label
                  className="block uppercase text-slate-600  font-bold "
                  htmlFor="grid-password"
                >
                  specialty
                </label>
                <select
                  className="my-2 p-2   placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={specialty}
                  onChange={(event) => {
                    setSpecialty(event.target.value)
                  }}
                >
                  <option value="">Select Specialty</option>
                  <option value="CCST">
                    Common Core Science and Technology
                  </option>
                  <option value="ES">Experimental Sciences</option>
                  <option value="TS">Technical Science</option>
                  <option value="mathematics">Mathematics</option>
                  <option value="CCL">Common Core Literature</option>
                  <option value="literature">Literature</option>
                  <option value="languages">Foreign Languages</option>
                  <option value="philosophy">Philosophy</option>
                </select>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex justify-center w-full my-4">
          <button
            className="px-4 py-2 w-full text-white bg-sky-600 active:bg-sky-700 font-bold uppercase    rounded-lg shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={() => add_level()}
            disabled={loading}
          >
            {loading ? (
              <Loading
                width="20px"
                height={'20px'}
                color="white"
                weight={'2px'}
              ></Loading>
            ) : (
              ''
            )}
            Save
          </button>
        </div>
      </form>
    </div>
  )
}
