import React, { useEffect, useRef, useState } from "react";

const SelectOption = ({ onChange, data, page, setPage, withPagination, lastPage, setSearch }) => {
	const [value, setValue] = useState({});
	const [showMenu, setShowMenu] = useState(false);
	const [options, setOptions] = useState([]);

	const menuRef = useRef(null);

	const onItemClick = (option) => {
		if (isSelected(option)) {
			setValue({});
			onChange({ id: "" });
		} else {
			setValue(option);
			onChange(option);
			setShowMenu(false);
		}
	};

	const isSelected = (option) => {
		return value.id === option.id;
	};

	useEffect(() => {
		setOptions(data);
	}, [data]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setShowMenu(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<>
			<div className="relative w-full flex overflow-visible" ref={menuRef}>
				<div
					onClick={(event) => {
						event.preventDefault();
						setShowMenu(!showMenu);
					}}
					className="flex flex-row items-center justify-between bg-white p-2 rounded-lg shadow  cursor-pointer w-full"
				>
					<div className="flex flex-row flex-wrap">{!value?.id ? "Select..." : value.label}</div>
					<div className="text-slate-700 font-bold">
						<i className="fa-solid fa-chevron-down"></i>
					</div>
				</div>
				{showMenu && (
					<div className="absolute top-0  bg-white p-2 gap-2  rounded-lg shadow ease-linear transition-all duration-150  z-50 flex flex-col min-w-fit  w-full   ">
						<div>
							<input
								onChange={(event) => {
									if (withPagination) {
										setSearch(event.target.value);
									} else {
										const temp = data.filter((option) => option?.label.toLowerCase().includes(event.target.value.toLowerCase()));
										setOptions(temp);
									}
								}}
								className="rounded-lg border p-2  w-full"
								autoFocus={true}
							/>
							{options && options.length !== 0 ? "" : <div className="  ">No results...</div>}
						</div>
						<div className="max-h-48 py-2 overflow-y-auto min-w-fit w-full whitespace-nowrap flex gap-2 flex-col">
							{options?.map((option, id) => (
								<div
									onClick={(e) => {
										onItemClick(option);
									}}
									key={id}
									className={` border p-2 w-full rounded-lg hover:bg-slate-100 cursor-pointer ${isSelected(option) && "text-white !bg-blue-500"}`}
								>
									{option.label}
								</div>
							))}
						</div>
						{withPagination && (
							<div className="flex justify-between items-center gap-4">
								<p>{page}</p>
								<div className="flex gap-2">
									<button
										onClick={(event) => {
											event.preventDefault();
											setPage((prev) => {
												return prev - 1;
											});
										}}
										className="flex items-center justify-center p-2 rounded-lg shadow bg-white"
										disabled={page <= 1}
									>
										<i className="fa-solid fa-chevron-left"></i>
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();

											setPage((prev) => {
												return prev + 1;
											});
										}}
										className="flex items-center justify-center p-2 rounded-lg shadow bg-white"
										disabled={page >= lastPage}
									>
										<i className="fa-solid fa-chevron-right"></i>
									</button>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default SelectOption;
