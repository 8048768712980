import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import axios from "config/axios";
import { urlAtom } from "index";
import { useAtom } from "jotai";
import Checkouts from "Management/Users/Users/Checkouts";
import FormModal from "Components/Dropdowns/FormModal";
import Fees from "Management/Users/Users/Fees";
import NumidiaReceipt from "Components/Receipts/NumidiaReceipt";
import { Link } from "react-router-dom";
import InfoStudent from "Management/Users/Students/InfoStudent";
import StudentForm from "Management/Users/Students/StudentForm";
import SelectOption from "Components/Dropdowns/SelectOption";
import UserPrint from "../Users/UserPrint";

export default function CardStudents() {
	var UserRef = useRef();
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [date, setDate] = useState(moment());
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [user, setUser] = useState(null);
	const [group, setGroup] = useState(null);
	const [users, setUsers] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");
	const [option, setOption] = useState("");
	const [info, setInfo] = useState("none");
	const [step, setStep] = useState(false);
	const [form, setForm] = useState(false);
	const [userSearch, setUserSearch] = useState("");

	const [loadingUser, setLoadingUser] = useState(false);

	const showInfo = () => {
		setStep("register");
	};
	const showForm = () => {
		setForm(true);
	};
	const hide = (id = null) => {
		if (id) {
			getUser(id);
		}
		setForm(false);
	};
	const hideInfo = () => {
		setStep(false);
		if (user) {
			getUser(user.id);
		}
	};
	const getUsers = async () => {
		let options = {
			method: "get",
			url: url + "/users",
			params: {
				page: currentPage,
				sortBy,
				sortDirection,
				perPage,
				search: userSearch,
				role: "student",
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				const data = response?.data?.data.map((item) => ({
					...item,
					label: item.name,
				}));
				setUsers(data);
				setTotalPages(response?.data?.last_page);
			})

			.catch(() => {});
	};

	const getUser = async (id) => {
		setLoadingUser(true);
		let options = {
			method: "get",
			url: url + "/users/" + id,
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setUser(response.data);
				setLoadingUser(false);
			})
			.catch(() => {
				setLoadingUser(false);
			});
	};
	useEffect(() => {
		getUsers();
	}, [currentPage, sortBy, sortDirection, perPage, search, userSearch]);

	return (
		<>
			{info === "fees" ? (
				<FormModal
					hide={() => {
						getUser(user.id);
						setInfo("none");
					}}
				>
					<Fees
						student={user.student}
						hide={() => {
							getUser(user.id);
							setInfo("none");
						}}
					/>
				</FormModal>
			) : (
				""
			)}
			{form ? (
				<FormModal hide={hide} title={"Student Form"}>
					<StudentForm action={"add"} data={user?.student} hide={hide}></StudentForm>
				</FormModal>
			) : (
				""
			)}
			{option != "" ? (
				<FormModal
					hide={() => {
						getUser(user.id);
						setOption("");
					}}
				>
					<NumidiaReceipt
						user={user}
						hide={() => {
							getUser(user.id);
							setOption("");
						}}
						option={option}
					></NumidiaReceipt>
				</FormModal>
			) : (
				""
			)}
			{step ? (
				<FormModal hide={hideInfo} title={"Student's Info"}>
					<InfoStudent student={user?.student} hide={hideInfo} step={step} setStep={setStep}></InfoStudent>
				</FormModal>
			) : (
				""
			)}
			<div className="flex flex-row justify-between items-center">
				<h5 className="font-semibold my-2 dark:text-white">Students</h5>
				<button
					className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
					type="button"
					onClick={showForm}
				>
					Register
				</button>
			</div>
			<div className="grid grid-flow-row md:grid-flow-col grid-cols-1 md:grid-cols-3 gap-4 w-full">
				<div className="rounded-lg bg-white shadow-lg p-4 md:col-span-2">
					{user && (
						<>
							<div className="flex flex-row justify-between items-center mb-4">
								<h6 className="font-semibold my-2 dark:text-white">Groups</h6>
								<button
									className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
									type="button"
									onClick={showInfo}
								>
									Register New Group
								</button>
							</div>
							<Checkouts getUser={() => getUser(user.id)} student={user.student} groupId={group?.id} />
						</>
					)}
				</div>
				<div className="rounded-lg bg-white shadow-lg p-2">
					<div className="flex flex-col my-2">
						<SelectOption
							onChange={(option) => getUser(option.id)}
							data={users}
							page={currentPage}
							setPage={setCurrentPage}
							withPagination={true}
							setSearch={setUserSearch}
							lastPage={totalPages}
						/>
					</div>

					<div className="rounded-lg p-2   sticky top-0 md:col-span-2">
						{loadingUser ? (
							<p>Loading user details...</p>
						) : user ? (
							<>
								<div className="flex flex-col w-full gap-4">
									<div className="flex flex-row w-full gap-4 justify-between">
										<div className="flex flex-col w-full gap-4 justify-center items-center">
											<div className="relative w-28 aspect-square">
												<img
													className="w-full aspect-square rounded-lg shadow object-cover cursor-pointer"
													src={`${auth_api_url}/files?url=${user?.profile_picture?.url}`}
												></img>
											</div>

											<div className="flex w-full flex-col font-light text-slate-500 ">
												<div className="flex flex-rowitems-center gap-4 text-slate-900 font-medium font-mono capitalize">
													<h6 className="flex-1">{user.name}</h6>
													<ReactToPrint
														trigger={() => {
															return (
																<button className="ml-4 border px-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow hover:bg-slate-100 h-8 w-fit ease-linear transition-all duration-150">
																	<i className="fa-solid fa-print" />
																</button>
															);
														}}
														onAfterPrint={() => {
															toast.success("Printing completed successful", {
																position: "top-center",
																autoClose: 3000,
																hideProgressBar: true,
																closeOnClick: true,
																pauseOnHover: true,
																draggable: true,
																progress: undefined,
																theme: "light",
															});
														}}
														content={() => UserRef}
													/>
													{!user.student.fee_inscription || !user.student.fee_inscription?.paid ? (
														<Link
															data-title="Pay the inscription fee"
															role={"button"}
															onClick={(event) => {
																event.stopPropagation();
																setInfo("fees");
															}}
															className="justify-center text-red-500 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 flex items-center"
														>
															<i class="fa-solid fa-dollar"></i>
														</Link>
													) : (
														<div
															data-title="Inscription fee is paid"
															className="justify-center text-green-500 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 flex items-center"
														>
															<i class="fa-solid fa-check"></i>
														</div>
													)}
												</div>
												<p>
													<i className="fa-solid fa-envelope mr-2"></i>
													{user.email}
												</p>
												<p>
													<i className="fa-solid fa-phone mr-2"></i>
													{user.phone_number}
												</p>
												{user.role === "student" ? (
													<p>
														<i className="fa-solid fa-layer-group mr-2"></i>
														{user.student.level.year} {user.student.level.education} {user.student.level.specialty}
													</p>
												) : (
													""
												)}
											</div>
										</div>
									</div>
									<div className="flex flex-row flex-auto w-full justify-between items-center">
										<div>
											<h6 className=" ">To Be Paid Price:</h6>
											<h6
												className={
													user.wallet?.balance > 0
														? "font-play font-bold text-green-600"
														: user.wallet?.balance === 0
														? "font-play font-bold text-yellow-500"
														: "font-play font-bold text-red-600"
												}
											>
												{user.wallet?.balance}.00 DA
											</h6>
										</div>
										{user.wallet?.balance >= 0 && (
											<div className="flex flex-col w-fit gap-2">
												<button
													onClick={(event) => {
														event.preventDefault();
														setOption("deposit");
													}}
													className="bg-white p-2 shadow-lg rounded-lg hover:bg-slate-100 capitalize"
												>
													<i className="fa-solid fa-plus"></i>
												</button>
												<button
													onClick={(event) => {
														event.preventDefault();
														setOption("withdraw");
													}}
													className="bg-white  p-2 shadow-lg rounded-lg hover:bg-slate-100 capitalize"
												>
													<i className="fa-solid fa-minus"></i>
												</button>
											</div>
										)}
									</div>
								</div>
								<div className="hidden">
									<div className="flex flex-col w-full gap-4 " ref={(el) => (UserRef = el)}>
										<UserPrint user={user} />
									</div>
								</div>
							</>
						) : (
							<p>No user selected</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
