import React, { useEffect, useState } from "react";

import UserDropdown from "../Dropdowns/UserDropdown.js";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { departmentAtom } from "index";
import NotificationDropdown from "../Dropdowns/NotificationDropdown.js";

export default function Navbar({ sidebar, setSidebar }) {
	const [department, setDepartment] = useAtom(departmentAtom);

	useEffect(() => {}, []);

	return (
		<>
			<nav className="sticky top-0 w-full z-20 flex-row flex-nowrap justify-start flex items-center bg-sky-600 py-2 px-12">
				<div className="w-full  items-center flex justify-between">
					<button
						className="flex  text-white text-3xl"
						onClick={(event) => {
							event.preventDefault();
							if (sidebar === "hidden") {
								setSidebar("flex");
							} else {
								setSidebar("hidden");
							}
						}}
					>
						{sidebar === "hidden" ? <i className="fa-solid fa-bars text-xl"></i> : <i className="fa-solid fa-arrow-left text-xl"></i>}
					</button>
					<Link className="text-white uppercase inline-block text-lg font-semibold" to={"/dashboard"}>
						Numidia {department}
					</Link>
					<div className="flex-row items-center flex gap-4">
						<NotificationDropdown />
						<UserDropdown />
					</div>
				</div>
			</nav>
		</>
	);
}
