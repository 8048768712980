import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";

const UserDropdown = () => {
	// dropdown props
	const [dropDown, setDropdown] = useState(false);
	const dropdownRef = useRef(null);
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdown(false); // Hide dropdown if click outside
		}
	};
	const showDropDown = () => {
		setDropdown(!dropDown);
	};
	useEffect(() => {
		if (dropDown) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropDown]);
	useLayoutEffect(() => {
		AOS.init();
	}, [dropDown]);
	return (
		<>
			<div className="dropdown relative">
				{dropDown && (
					<div  ref={dropdownRef}  data-aos="fade-up" className="bg-white p-4 flex flex-col gap-4  z-20 float-left  list-none text-left rounded-lg shadow-lg min-w-48 dropdown-menu absolute top-full right-0  my-2 -mx-2 ">
						<Link to="/dashboard" className={"   text-base flex items-center gap-2  w-full whitespace-nowrap bg-transparent text-slate-700"}>
							<i className="fa-solid text-base fa-clipboard " />
							Dashboard
						</Link>
						<Link to="/profile" className={"   text-base flex items-center gap-2  w-full whitespace-nowrap bg-transparent text-slate-700"}>
							<i className="fa-solid text-base fa-circle-user " />
							Profile
						</Link>
						<Link to={"/settings"} className={"   text-base flex items-center gap-2  w-full whitespace-nowrap bg-transparent text-slate-700"}>
							<i className="fa-solid text-base fa-gear " />
							Settings
						</Link>
						<div className="h-0 border border-solid border-slate-100" />
						<Link to={"/logout"} className={"   text-base flex items-center gap-2  w-full whitespace-nowrap bg-transparent text-slate-700"}>
							<i className="fa-solid text-base fa-right-from-bracket " />
							Logout
						</Link>
					</div>
				)}
				<a className="text-slate-500   cursor-pointer dropdown-toggle " onClick={() => showDropDown()}>
					<div className="items-center flex ">
						<span className="w-14 h-14 flex items-center justify-center rounded-full shadow-lg">
							<img alt="..." className="w-full rounded-full align-middle border-none  aspect-square object-cover" src={window.localStorage.getItem("profile_picture_url")} />
						</span>
					</div>
				</a>
			</div>
		</>
	);
};

export default UserDropdown;
