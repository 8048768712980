import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios.js";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { toast } from "react-toastify";
import moment from "moment";
import FormModal from "Components/Dropdowns/FormModal.js";
import TicketForm from "./TicketForm.js";
import Swal from "sweetalert2";
import TicketReceipt from "./TicketReceipt.js";

export function Tickets() {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [ticket, setTicket] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [group_id, setGroupId] = useState("");
	const [student_id, setStudentId] = useState("");
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");
	const [start_date, setStartDate] = useState("");
	const [end_date, setEndDate] = useState("");
	const [type, setType] = useState("all");
	const navigate = useNavigate();

	const [tickets, setTickets] = useState([]);

	const getTickets = async () => {
		let options = {
			method: "get",
			url: url + "/tickets",
			params: {
				sortBy,
				sortDirection,
				search,
				group_id,
				student_id,
				start_date,
				end_date,
				type,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setTickets(response?.data?.data);
			})
			.catch(() => {});
	};
	const pay = async (ticket) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to mark this ticket as paid? This action cannot be undone!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, pay it!",
			cancelButtonText: "Cancel",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let options = {
						method: "post",
						url: url + "/tickets/" + ticket.id + "/pay",
						headers: {
							Authorization: "Bearer " + token,
							Accept: "Application/json",
						},
					};
					await axios(options);
					toast.success("Ticket paid successfully", {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					getTickets(); // Refresh tickets list
				} catch (error) {}
			}
		});
	};

	const cancel = async (ticket) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to cancel this ticket? This action cannot be undone!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, cancel it!",
			cancelButtonText: "Cancel",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let options = {
						method: "delete",
						url: url + "/tickets/" + ticket.id + "/cancel",
						headers: {
							Authorization: "Bearer " + token,
							Accept: "Application/json",
						},
					};
					await axios(options);
					toast.success("Ticket canceled successfully", {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					getTickets(); // Refresh tickets list
				} catch (error) {}
			}
		});
	};
	const [method, setMethod] = useState("add");
	const [form, setForm] = useState(false);
	const destroy = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to delete this ticket? This action cannot be undone!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				axios({
					url: url + "/tickets/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
					.then((response) => {
						toast.success("Ticket deleted successfully", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
						getTickets(); // Refresh tickets list
					})
					.catch(() => {});
			}
		});
	};

	const showForm = (action, data) => {
		setMethod(action);
		setTicket(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	var ComponentRef = useRef();

	useEffect(() => {
		getTickets();
	}, [sortBy, sortDirection, group_id, search, student_id]);
	const [printTicket, setPrintTicket] = useState(false);

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Ticket Form"}>
					<TicketForm action={method} data={ticket} hide={hide} getTickets={getTickets}></TicketForm>
				</FormModal>
			) : (
				""
			)}
			{printTicket ? (
				<FormModal hide={() => setPrintTicket(false)} title={"Print Ticket"}>
					<TicketReceipt ticket={ticket} hide={() => setPrintTicket(false)}></TicketReceipt>
				</FormModal>
			) : (
				""
			)}
			<div className="bg-white shadow-lg rounded-lg p-8 border ">
				<h4 className=" font-bold">Tickets</h4>

				<div className="break-words my-4 w-full shadow-lg h-fit overflow-auto   border-0">
					<div className="w-full">
						{/* Projects table */}
						<table className=" w-full border-collapse ">
							<thead className="sticky top-0 whitespace-nowrap">
								<tr>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>dawarat</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>student</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>status</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>price</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>discount</th>
									<th className={"    uppercase  font-semibold flex justify-around   transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
										<Link
											className="w-full ease-linear   "
											role={"button"}
											onClick={(event) => {
												event.preventDefault();
												showForm("add");
											}}
										>
											Add
											<i class="fa-solid fa-plus  "></i>
										</Link>
									</th>
								</tr>
							</thead>
							<tbody>
								{tickets?.map((ticket, id) => {
									return (
										<tr key={id} className=" bg-white hover:bg-slate-100 ">
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
												{ticket.dawarat?.module} {ticket.dawarat?.teacher?.user?.name} {ticket.dawarat?.level.year} {ticket.dawarat?.level?.specialty}{" "}
												{ticket.dawarat?.level?.education}
											</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{ticket?.student?.user?.name}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{ticket.status}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{ticket.price}.00 DA</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{ticket.discount}.00 DA</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left items-center flex justify-evenly gap-2"}>
												{ticket.status === "waiting" ? (
													<Link
														role={"button"}
														onClick={(event) => {
															event.preventDefault();
															pay(ticket);
														}}
														className="hover:text-green-500 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-check"></i>
													</Link>
												) : (
													""
												)}
												{ticket.status === "waiting" || ticket.status === "paid" ? (
													<Link
														role={"button"}
														onClick={(event) => {
															event.preventDefault();
															cancel(ticket);
														}}
														className="hover:text-red-600 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-close"></i>
													</Link>
												) : (
													""
												)}
												{ticket.status === "waiting" ? (
													<Link
														role={"button"}
														onClick={(event) => {
															event.preventDefault();
															showForm("edit", ticket);
														}}
														className="hover:text-yellow-400 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-pen-to-square"></i>
													</Link>
												) : (
													""
												)}
												{ticket.status === "canceled" ? (
													<Link
														role={"button"}
														onClick={() => destroy(ticket.id)}
														className="hover:text-red-600 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-trash"></i>
													</Link>
												) : (
													""
												)}
												{ticket.status === "paid" ? (
													<Link
														role={"button"}
														onClick={() => {
															setTicket(ticket);
															setPrintTicket(true);
														}}
														className="hover:text-slate-600 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i class="fa-solid fa-print"></i>
													</Link>
												) : (
													""
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
