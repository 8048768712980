import React, { useState, useEffect } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import chroma from "chroma-js";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Bar, BarChart, AreaChart, Area, ResponsiveContainer, ReferenceLine, Brush, Cell } from "recharts";

export default function FinancialStats() {
	const token = window.localStorage.getItem("token");
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [url, setUrl] = useAtom(urlAtom);
	const [checkouts, setCheckoutsStats] = useState([]);
	const [students_checkouts, setStudentsCheckouts] = useState([]);
	const [students_fees, setStudentsFees] = useState([]);
	const [employee, setEmployeeStats] = useState([]);
	const [employees_register, setEmployeeRegister] = useState([]);
	const [expenses, setExpensesStats] = useState([]);
	const [fees, setFeesStats] = useState([]);
	const [chart, setChart] = useState("bar");
	const [transactions, setTransactions] = useState([]);
	const [period, setPeriod] = useState("all");
	const [domain, setDomain] = useState([]);
	const [grouping, setGrouping] = useState("all");

	const getCheckoutsStats = async () => {
		let options = {
			method: "get",
			url: url + "/statistics/checkouts",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			let data = response.data?.stats?.map((stat) => {
				const paidEntry = response?.data?.paid.find((paid) => paid.date === stat.date);
				const notPaidEntry = response?.data?.not_paid.find((notPaid) => notPaid.date === stat.date);

				return {
					date: stat.date,
					total_price: parseInt(stat.total_price, 10),
					total_price_paid: paidEntry ? parseInt(paidEntry.total_price) : 0,
					total_price_not_paid: notPaidEntry ? parseInt(notPaidEntry.total_price) : 0,
				};
			});
			setCheckoutsStats(data);
		}).catch(() => { });
	};
	const getStudentStats = async () => {
		let options = {
			method: "get",
			url: url + "/statistics/students",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			let data1 = [
				{
					fill: "rgb(10, 145, 255)",
					name: "paid",
					value: parseInt(response?.data?.paid_checkouts, 10),
				},
				{
					fill: "rgb(239, 92, 0)",
					name: "not_paid",
					value: parseInt(response?.data?.not_paid_checkouts, 10),
				},
			];
			let data2 = [
				{
					fill: "rgb(10, 145, 255)",
					name: "paid",
					value: parseInt(response?.data?.paid_fees, 10),
				},
				{
					fill: "rgb(239, 92, 0)",
					name: "not_paid",
					value: parseInt(response?.data?.not_paid_fees, 10),
				},
			];

			setStudentsCheckouts(data1);
			setStudentsFees(data2);
		}).catch(() => { });
	};
	const getEmployeeRegister = async () => {
		let options = {
			method: "get",
			url: url + "/statistics/employees/register",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			let data = response?.data?.map((employee) => {
				let color = chroma.scale(["#0091ff", "#12003d"]);
				return {
					name: employee.user.name,
					value: employee.total,
					fill: color(Math.random()).hex(),
				};
			});
			setEmployeeRegister(data);
		}).catch(() => { });
	};
	const getFeesStats = async () => {
		let options = {
			method: "get",
			url: url + "/statistics/inscription_fees",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			let data = response?.data?.total?.map((stat) => {
				const paidEntry = response?.data?.paid.find((paid) => paid.date === stat.date);
				const notPaidEntry = response?.data?.not_paid.find((notPaid) => notPaid.date === stat.date);

				return {
					date: stat.date,
					total_price: parseInt(stat.total_price, 10),
					total_price_paid: paidEntry ? parseInt(paidEntry.total_price) : 0,
					total_price_not_paid: notPaidEntry ? parseInt(notPaidEntry.total_price) : 0,
				};
			});
			setFeesStats(data);
		}).catch(() => { });
	};
	const getExpensesStats = async () => {
		let options = {
			method: "get",
			url: url + "/statistics/expenses",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			let data = response?.data?.expenses?.map((stat) => {
				return {
					date: stat.date,
					total_price: parseInt(stat.total_price, 10),
				};
			});
			setExpensesStats(data);
		}).catch(() => { });
	};
	const getEmployeeStats = async () => {
		let options = {
			method: "get",
			url: url + "/statistics/employees",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			let data = response?.data?.employees.map((employee) => {
				return {
					name: employee.user.name,
					checkouts_paid: parseInt(employee.checkouts_paid, 10),
					expenses: parseInt(employee.expenses, 10),
					fees_paid: parseInt(employee.fees_paid, 10),
				};
			});
			setEmployeeStats(data);
		}).catch(() => { });
	};
	const getTransactions = async () => {
		let options = {
			method: "get",
			url: auth_api_url + "/transactions",
			params: { period, grouping },
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			const totalValues = response?.data?.map((transaction) => parseInt(transaction.total));
			const minValue = Math.min(...totalValues);
			const maxValue = Math.max(...totalValues);
			setDomain([minValue - 100, maxValue + 100]);
			setTransactions(response.data);
		}).catch(() => { });
	};
	useEffect(() => {
		getTransactions();
	}, [grouping, period]);
	useEffect(() => {
		getCheckoutsStats();
		getStudentStats();
		getEmployeeRegister();
		getExpensesStats();
		getFeesStats();
		getEmployeeStats();
		getCheckoutsStats();
	}, []);

	return (
		<div className="w-full flex flex-col capitalize gap-12 ">
			<div className="flex p-4 flex-col bg-white shadow-lg rounded-lg  gap-4 w-full justify-between h-auto">
				<div className="flex  gap-4 flex-row">
					<h6>User transactions </h6>
					{chart === "bar" ? (
						<button
							onClick={(event) => {
								event.preventDefault()
								setChart("line");
							}}
							className="bg-white hover:bg-slate-50 rounded-lg shadow-lg px-4 py-2"
						>
							Change To Line Chart
						</button>
					) : (
						<button
							onClick={(event) => {
								event.preventDefault()
								setChart("bar");
							}}
							className="bg-white hover:bg-slate-50 rounded-lg shadow-lg px-4 py-2"
						>
							Change To Bar Chart
						</button>
					)}
				</div>
				<div className=" overflow-x-auto w-full  ">
					<div className="flex flex-nowrap  h-full  w-fit  rounded-lg  bg-white">
						<button
							onClick={(event) => {
								event.preventDefault()
								setPeriod("all");
							}}
							className="w-fit  rounded-l-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							all the time
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setPeriod("1h");
							}}
							className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							past 1 hour
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setPeriod("day");
							}}
							className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							past day
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setPeriod("week");
							}}
							className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							the last week
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setPeriod("10days");
							}}
							className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							past 10 days
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setPeriod("month");
							}}
							className="w-fit rounded-r-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							the last month
						</button>
					</div>
				</div>
				<div className="overflow-x-auto   w-full">
					<div className="flex flex-nowrap whitespace-nowrap h-full  w-fit  rounded-lg  bg-white">
						<button
							onClick={(event) => {
								event.preventDefault()
								setGrouping("all");
							}}
							className="w-fit  rounded-l-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							individually
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setGrouping("1h");
							}}
							className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							by 1 hour
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setGrouping("day");
							}}
							className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							by day
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setGrouping("week");
							}}
							className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							by week
						</button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setGrouping("month");
							}}
							className="w-fit rounded-r-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
						>
							by month
						</button>
					</div>
				</div>
				<div className="w-full h-auto  xl:aspect-[5/2] md:aspect-[3/1] aspect-[1/1]">
					{chart === "bar" ? (
						<ResponsiveContainer>
							<BarChart data={transactions} margin={{ top: 20, bottom: 20, right: 0, left: 0 }}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date" padding={{ top: 0, bottom: 0, right: 20, left: 20 }} />
								<YAxis domain={domain} />
								<Tooltip />
								<ReferenceLine y={0} stroke="#000" />
								<Brush dataKey="name" height={30} stroke="#3483eb" />
								<Bar dataKey="total">
									{transactions.map((transaction, entry, index) => (
										<Cell key={`cell-${index}`} fill={transaction.total > 0 ? "#3483eb" : "#f71111"} />
									))}
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					) : (
						<ResponsiveContainer>
							<LineChart data={transactions} margin={{ top: 20, bottom: 20, right: 0, left: 0 }}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="date" padding={{ top: 0, bottom: 0, right: 20, left: 20 }} />
								<YAxis domain={domain} />
								<Tooltip />
								<ReferenceLine y={0} stroke="#000" />
								<Brush dataKey="name" height={30} stroke="#3483eb" />
								<Line type="monotone" dataKey="total" stroke="#06c416" strokeWidth={3} activeDot={{ r: 8 }} />
							</LineChart>
						</ResponsiveContainer>
					)}
				</div>
			</div>
			<div className="w-full flex gap-12 md:flex-row flex-col justify-center items-center">
				<div className="flex w-full gap-4 flex-col h-auto aspect-square bg-white shadow-lg rounded-lg p-4">
					<h6>Number of Student's Checkouts Paid and Not Paid </h6>
					<ResponsiveContainer>
						<PieChart>
							<Tooltip />
							<Legend />
							<Pie dataKey="value" data={students_checkouts} label />
						</PieChart>
					</ResponsiveContainer>
				</div>
				<div className="flex w-full gap-4 flex-col  h-auto aspect-square bg-white shadow-lg rounded-lg p-4">
					<h6>Number of Student's Fees Paid and Not Paid </h6>

					<ResponsiveContainer>
						<PieChart>
							<Tooltip />
							<Legend />
							<Pie dataKey="value" data={students_fees} label />
						</PieChart>
					</ResponsiveContainer>
				</div>
				<div className="flex w-full gap-4 flex-col  h-auto aspect-square bg-white shadow-lg rounded-lg p-4">
					<h6>Budget Allocation for Each Employee </h6>

					<ResponsiveContainer>
						<PieChart>
							<Tooltip />
							<Legend />
							<Pie dataKey="value" data={employees_register} label />
						</PieChart>
					</ResponsiveContainer>
				</div>
			</div>
			<div className="w-full grid md:grid-cols-2 gap-12 grid-cols-1 justify-center items-center">
				<div className="flex w-full gap-4 flex-col  h-auto aspect-[2/1]  bg-white shadow-lg rounded-lg p-4">
					<h6>Employee's Daily Budget Expenditure </h6>
					<ResponsiveContainer>
						<BarChart data={employee} stackOffset="sign">
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="name"
								padding={{
									right: 50,
									left: 50,
								}}
							/>
							<YAxis
								padding={{
									top: 50,
									bottom: 50,
								}}
							/>
							<Tooltip />
							<Legend />
							<ReferenceLine y={0} stroke="#000" />
							<Bar dataKey="expenses" fill="#f71111" />
							<Bar dataKey="checkouts_paid" name="paid checkouts" stackId="a" fill="#71da00" />
							<Bar dataKey="fees_paid" stackId="b" name="paid inscription fees" fill="#0077ff" />
						</BarChart>
					</ResponsiveContainer>
				</div>
				<div className="flex w-full gap-4 flex-col  h-auto aspect-[2/1] bg-white shadow-lg rounded-lg p-4">
					<h6>Daily Student's Checkouts </h6>
					<ResponsiveContainer>
						<LineChart data={checkouts}>
							<XAxis dataKey="date" padding={{ left: 50, right: 50 }} />
							<YAxis />
							<CartesianGrid strokeDasharray="3 3" />
							<Tooltip />
							<Legend />
							<Line type="monotone" dataKey="total_price" name="Total" stroke="black" />
							<Line type="monotone" dataKey="total_price_paid" name="Paid" stroke="green" />
							<Line type="monotone" dataKey="total_price_not_paid" name="Not Paid" stroke="red" />
						</LineChart>
					</ResponsiveContainer>
				</div>
				<div className="flex w-full gap-4 flex-col  h-auto aspect-[2/1] bg-white shadow-lg rounded-lg p-4">
					<h6>Daily Student's Fees </h6>
					<ResponsiveContainer>
						<LineChart data={fees}>
							<XAxis dataKey="date" padding={{ left: 50, right: 50 }} />
							<YAxis />
							<CartesianGrid strokeDasharray="3 3" />
							<Tooltip />
							<Legend />
							<Line type="monotone" dataKey="total_price" name="Total" stroke="black" />
							<Line type="monotone" dataKey="total_price_paid" name="Paid" stroke="green" />
							<Line type="monotone" dataKey="total_price_not_paid" name="Not Paid" stroke="red" />
						</LineChart>
					</ResponsiveContainer>
				</div>
				<div className="flex w-full gap-4 flex-col  h-auto aspect-[2/1] bg-white shadow-lg rounded-lg p-4">
					<h6>Daily Expenses</h6>
					<ResponsiveContainer>
						<AreaChart data={expenses}>
							<XAxis dataKey="date" padding={{ left: 50, right: 50 }} />
							<YAxis />
							<CartesianGrid strokeDasharray="3 3" />
							<Tooltip />
							<Legend />
							<Area type="monotone" dataKey="total_price" name="Total" stroke="black" />
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
}
