import React, { useState, useEffect } from "react";
import axios from "config/axios";
import Swal from "sweetalert2";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import Loading from "Components/Loaders/Loading";

export default function Settings() {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [user, setUser] = useState({});
	const [name, setName] = useState("");
	const [phone_number, setPhoneNumber] = useState();
	const [gender, setGender] = useState("");
	const [old_password, setOldPassword] = useState("");
	const [password, setPassword] = useState("");
	const [password_confirmation, setPasswordConfirmation] = useState("");
	const [loading, setLoading] = useState({ password: false, profile: false, photo: false });

	const navigate = useNavigate();
	const getProfile = async () => {
		let options = {
			method: "get",
			url: url + "/profile",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				const imgUrl = `${auth_api_url}/files?url=${response?.data?.profile_picture?.url}`;

				window.localStorage.setItem("profile_picture_url", imgUrl);
				setUser(response.data);
				setName(response.data.name);
				setPhoneNumber(response.data.phone_number);
				setGender(response.data.gender);
			})
			.catch(() => {});
	};
	const change_password = async (event) => {
		event.preventDefault();
		setLoading((prev) => {
			return { ...prev, password: true };
		});
		let options = {
			method: "post",
			url: auth_api_url + "/password/change",
			data: {
				old_password,
				password,
				password_confirmation,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				getProfile();
			})
			.catch(() => {})
			.finally(() => {
				setLoading((prev) => {
					return { ...prev, password: false };
				});
			});
	};
	const update = async (event) => {
		event.preventDefault();
		setLoading((prev) => {
			return { ...prev, profile: true };
		});
		let options = {
			method: "put",
			url: url + "/profile",
			params: {
				name,
				phone_number,
				gender,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				getProfile();
			})
			.catch(() => {})
			.finally(() => {
				setLoading((prev) => {
					return { ...prev, profile: false };
				});
			});
	};
	const change_photo = async (photo) => {
		setLoading((prev) => {
			return { ...prev, photo: true };
		});
		let options = {
			method: "post",
			url: auth_api_url + "/picture/change",
			data: {
				profile_picture: photo,
			},
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		};
		axios(options)
			.then((response) => {
				getProfile();
			})
			.catch(() => {})
			.finally(() => {
				setLoading((prev) => {
					return { ...prev, photo: false };
				});
			});
	};
	useEffect(() => {
		getProfile();
	}, []);
	return (
		<div className="flex flex-col w-full gap-4 ">
			<div className="flex md:flex-row flex-col-reverse items-center p-4 bg-white shadow-lg rounded-lg gap-4 w-full justify-between">
				<div className="flex flex-row gap-4">
					<div className="relative">
						<img className="w-32 aspect-square rounded-lg shadow object-cover cursor-pointer" src={window.localStorage.getItem("profile_picture_url")}></img>
						<div className="w-32 aspect-square rounded-lg bg-black bg-opacity-60 z-10 absolute top-0 flex items-center justify-center">
							{loading.photo ? <Loading width="40px" height={"40px"} color="white" weight={"4px"}></Loading> : <i className="fa-solid fa-camera text-white"></i>}
						</div>
						<input
							type="file"
							accept="image/*"
							onChange={(event) => {
								change_photo(event.target.files[0]);
							}}
							disabled={loading.photo}
							className=" opacity-0 w-32 aspect-square absolute top-0 cursor-pointer z-20"
						/>
					</div>

					<div className="flex flex-col font-light text-slate-500 ">
						<h5 className=" text-slate-900 font-medium font-mono capitalize ">{user.name}</h5>
						<h6>
							{user.email} / {user.phone_number}
						</h6>
						<h6 className="capitalize">{user.role}</h6>
					</div>
				</div>

				<div className="md:w-32 w-full aspect-square ">{user.id ? <QRCode value={user.id} style={{ height: "100%", width: "100%" }} /> : ""}</div>
			</div>
			<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg">
				<h4 className=" font-semibold dark:text-white">General information</h4>
				<form action="#">
					<div className="flex flex-col gap-6">
						<div className="w-full my-2">
							<label htmlFor="Name" className="block font-medium text-gray-900 dark:text-white">
								Name
							</label>
							<input
								type="text"
								name="Name"
								id="Name"
								className="shadow-sm my-2 p-2 bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder="Name"
								required
								value={name}
								onChange={(event) => {
									setName(event.target.value);
								}}
							/>
						</div>
						<div className="w-full my-2">
							<label htmlFor="gender" className=" block font-medium text-gray-900 dark:text-white">
								Gender
							</label>
							<select
								className="shadow-sm my-2 p-2 bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								default="male"
								onChange={(event) => {
									setGender(event.target.value);
								}}
							>
								<option value="male">Male</option>
								<option value="female">Female</option>
							</select>
						</div>
						<div className="w-full my-2">
							<label htmlFor="phone_number" className="block font-medium text-gray-900 dark:text-white">
								Phone Number
							</label>
							<input
								type="number"
								onWheel={(event) => event.target.blur()}
								name="phone_number"
								id="phone_number"
								className=" my-2 p-2 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder="Phone number"
								required
								onChange={(event) => {
									setPhoneNumber(event.target.value);
								}}
							/>
						</div>
						<div className="flex w-full items-center justify-center">
							<button
								onClick={(event) => {
									update(event);
								}}
								disabled={loading.profile}
								className="px-4 py-2 font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
							>
								{loading.profile ? <Loading width="20px" height={"20px"} color="black" weight={"2px"}></Loading> : ""}
								Submit
							</button>
						</div>
					</div>
				</form>
			</div>
			<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg">
				<h4 className=" font-semibold dark:text-white">Password information</h4>
				<form>
					<div className="flex flex-col gap-6">
						<div className="w-full my-2">
							<label htmlFor="old_password" className="block font-medium text-gray-900 dark:text-white">
								Current password
							</label>
							<input
								type="text"
								name="old_password"
								id="old_password"
								className="shadow-sm my-2 p-2 bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder="••••••••"
								required
								value={old_password}
								onChange={(event) => {
									setOldPassword(event.target.value);
								}}
							/>
						</div>
						<div className="w-full my-2">
							<label htmlFor="password" className="block font-medium text-gray-900 dark:text-white">
								New password
							</label>
							<input
								data-popover-target="popover-password"
								data-popover-placement="bottom"
								type="password"
								id="password"
								className="bg-gray-50 my-2 p-2 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								placeholder="••••••••"
								required
								value={password}
								onChange={(event) => {
									setPassword(event.target.value);
								}}
							/>
						</div>
						<div className="w-full my-2">
							<label htmlFor="password_confirmation" className="block font-medium text-gray-900 dark:text-white">
								Confirm password
							</label>
							<input
								type="text"
								name="password_confirmation"
								id="password_confirmation"
								className="shadow-sm my-2 p-2 bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder="••••••••"
								required
								value={password_confirmation}
								onChange={(event) => {
									setPasswordConfirmation(event.target.value);
								}}
							/>
						</div>
						<div className="flex w-full items-center justify-center">
							<button
								onClick={(event) => {
									change_password(event);
								}}
								disabled={loading.password}
								className="py-2 px-4 font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
							>
								{loading.password ? <Loading width="20px" height={"20px"} color="black" weight={"2px"}></Loading> : ""}
								Submit
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
