// LinksGroup.js
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function NavMenu({ title, links }) {
	const location = useLocation();
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className="w-full">
			<div className="w-full my-2 relative">
				{/* Toggle Button */}
				<p
					className="w-full bg-slate-100 rounded-lg p-2 flex justify-between items-center font-bold text-slate-500 capitalize cursor-pointer no-underline text-sm transition-colors duration-200 ease-in-out"
					onClick={() => setIsExpanded(!isExpanded)}
				>
					{title}
					<i className={`fa-solid ${isExpanded ? "fa-chevron-up" : "fa-chevron-down"} transform transition-transform duration-300`} />
				</p>

				{/* Animated List */}
				<ul className={`pl-4 my-2 flex flex-col list-none overflow-hidden transition-all duration-500 ease-in-out ${isExpanded ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}`}>
					{links.map((link) => (
						<li key={link.to} className="items-center">
							<Link
								className={`capitalize font-medium transition-all ease-in-out duration-500 p-3 flex items-center gap-3 text-sm ${
									location.pathname === link.to ? "text-white bg-sky-600 rounded-lg" : "text-slate-700 hover:text-slate-500"
								}`}
								to={link.to}
							>
								<i className={`${link.icon} text-base ${location.pathname === link.to ? "" : "text-slate-300"}`}></i>
								{link.label}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
