import { Link } from "react-router-dom";

import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Footer from "Components/Footers/Footer.js";
import { useTranslation } from "react-i18next";
import NumidiaSchool from "Components/Carousels/NumidiaSchool";

export default function School() {
	const { t } = useTranslation();
	return (
		<div className="flex justify-center items-center flex-col w-full">
			<IndexNavbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>
			<section className="flex justify-center items-center flex-col w-full relative bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)]">
				<div className="flex flex-col relative px-24 w-full gap-8">
					<div className="flex flex-row">
						<NumidiaSchool fade={"fade-left"} t={t} />
					</div>
				</div>
			</section>
			<div className="relative w-full h-24">
				<img className="w-full absolute top-0" src={require("assets/img/index-header.png")}></img>
			</div>
			<section className="flex justify-center items-center flex-col w-full p-24">
				<div className="flex flex-wrap justify-center w-full my-6 items-center">
					<div className="w-full md:w-1/2 flex flex-col items-start justify-center gap-8">
						<div className="flex flex-col items-start font-poppins gap-4">
							<h3 className=" font-semibold leading-normal w-full ">{t("getItNowTitle")}</h3>
							<p className=" font-medium leading-relaxed w-full 2">{t("getItNowText")}</p>
							<p className=" font-medium leading-relaxed w-full text-slate-600">{t("appDescription")}</p>
						</div>

						<Link className=" bg-slate-100 text-[rgb(1,42,74)] rounded-lg flex flex-row shadow-lg hover:shadow-2xl z-50 px-4  py-2">
							<img className="w-8 h-auto object-contain" src={require("assets/img/Social Media/google-play-logo.png")}></img>
							<div className=" text-slate-600 font-play">
								<p className="uppercase ! font-semibold text-slate-900 ">Get it now on</p>
								Google Play
							</div>
						</Link>
					</div>

					<div className="w-full md:w-1/2 flex items-center aspect-square">
						<img className="object-contain w-full h-auto scale-110" src={require("assets/img/numidia-app.svg").default} />
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}
