import React from "react";
import { Link } from "react-router-dom";

export default function FooterSmall() {
	return (
		<footer className="relative flex w-full flex-col my-8">
			<hr className="  border-b-1 border-slate-300 my-4" />
			<div className="flex flex-wrap items-center md:justify-between justify-center">
				<div className="w-full md:w-4/12">
					<div className=" text-slate-500 font-semibold  text-center md:text-left">
						Copyright © {new Date().getFullYear()}{" "}
						<a href="https://hoceyne.me" target="_blank" className="text-slate-500 hover:text-slate-900  font-semibold ">
							Souala El-Houssine
						</a>
					</div>
				</div>
				<div className="w-full md:w-8/12">
					<ul className="flex flex-wrap list-none md:justify-end  justify-center gap-4">
						<li>
							<Link to="/about" className="text-slate-500  font-semibold   ">
								<i className="fa-solid fa-info"></i>
							</Link>
						</li>
						<li>
							<Link to="contact" className="text-slate-500  font-semibold   ">
								<i className="fa-solid fa-phone"></i>
							</Link>
						</li>
						<li>
							<Link to="/about" className="text-slate-500  font-semibold   ">
								<i className="fa-brands fa-facebook"></i>
							</Link>
						</li>
						<li>
							<Link to="contact" className="text-slate-500  font-semibold   ">
								<i className="fa-brands fa-instagram"></i>
							</Link>
						</li>
						<li>
							<Link to="contact" className="text-slate-500  font-semibold   ">
								<i className="fa-brands fa-youtube"></i>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
}
