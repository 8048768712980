import React from "react";

export default function CardLink({ statSubtitle, statTitle, statIconName, statIconColor }) {
	return (
		<>
			<div className=" flex flex-col w-full px-4 py-12 break-words bg-slate-50 rounded-lg shadow-lg h-full  hover:cursor-pointer hover:bg-white hover:scale-105 transition-all  ">
				<div className="flex flex-nowrap gap-4">
					<div className=" w-full gap-2 max-w-full flex-grow flex-1">
						<h5 className="text-slate-400 capitalize font-bold">{statSubtitle}</h5>
						<p className="font-semibold text-md text-slate-700">{statTitle} </p>
					</div>
						<div className=" w-auto  flex-initial">
							<div className={"text-white  text-center inline-flex items-center justify-center w-fit p-4 h-auto aspect-square shadow-lg  rounded-full " + statIconColor}>
								<i className={statIconName}></i>
							</div>
						</div>
				</div>
			</div>
		</>
	);
}
