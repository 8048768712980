import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentAnswers from "Management/Exams/StudentAnswers";
import moment from "moment";
import PortalWrapper from "Layouts/PortalWrapper";
import FormModal from "Components/Dropdowns/FormModal";
// components

export default function MyExams() {
	const [url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");
	const [exams, setExams] = useState([]);
	const [answers, setAnswers] = useState([]);
	const [info, setInfo] = useState(false);
	const navigate = useNavigate();

	const user_id = localStorage.getItem("id");

	const showInfo = (answers) => {
		setAnswers(answers);
		setInfo(true);
	};
	const getExams = () => {
		axios({
			// Endpoint to send files
			url: url + "/user/exams",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				setExams(response.data);
			}).catch(() => { });
	};

	useEffect(() => {
		getExams();
	}, []);

	return (
		<>
			{info ? (
				<FormModal hide={() => setInfo(false)} title={"Exam Info"}>
					<StudentAnswers answers={answers}></StudentAnswers>
				</FormModal>
			) : (
				""
			)}
			<div className="break-words w-full shadow-lg overflow-auto ">
				<table className=" w-full border-collapse ">
					<thead className="sticky top-0 whitespace-nowrap">
						<tr>
							<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Exam</th>
							<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Date</th>
							<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Questions</th>
							<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Score</th>
						</tr>
					</thead>
					<tbody>
						{exams?.map((exam, id) => {
							return (
								<tr key={id} className=" bg-white hover:bg-slate-100 ">
									<th className={" whitespace-nowrap border border-slate-200 text-left align-middle flex items-center gap-2"}>
										<Link
											role={"button"}
											onClick={(event) => {
												event.stopPropagation();
												showInfo(exam.answers);
											}}
											className="hover:text-blue-500 transition duration-150 ease-in-out bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
										>
											<i class="fa-solid fa-info"></i>
										</Link>
										<span className={"font-bold "}>{exam.title}</span>
									</th>
									<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{moment(exam.date).format("DD-MMMM hh:mm")}</td>
									<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{exam.nb_question}</td>
									<td className={" font-bold whitespace-nowrap border border-slate-200 text-left align-middle"}>
										{(exam.answers.reduce((total, answer) => total + answer.score, 0) / exam.nb_question) * 100} %
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
}
