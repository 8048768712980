import ParentStudents from "Management/Users/Parents/MyStudents";
import TeacherStudents from "Management/Users/Teachers/MyStudents";
import CardStudents from "./CardStudents";

// components

export default function Students() {
	const role = localStorage.getItem("role");
	return <>{role === "supervisor" ? <ParentStudents /> : role === "teacher" ? <TeacherStudents /> : <CardStudents data-title="All Students" />}</>;
}
