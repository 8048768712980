import axios from "config/axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Orders from "./Orders/Orders";
import ProductPhotos from "Components/Carousels/ProductPhotos";
import Form from "./Products/Form";
import Swal from "sweetalert2";
import FormModal from "Components/Dropdowns/FormModal";

export default function Library() {
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [searchQuery, setSearchQuery] = useState("");
	const [products, setProducts] = useState([]);
	const [method, setMethod] = useState("add");
	const [form, setForm] = useState(false);
	const [product, setProduct] = useState({});
	const token = localStorage.getItem("token");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	const navigate = useNavigate();
	const getProducts = async () => {
		axios({
			method: "get",
			url: url + "/products",
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setProducts(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => { });
	};

	const destroy = (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you really want to delete this product? This action cannot be undone!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if (result.isConfirmed) {
				axios({
					url: url + "/products/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
				.then((response) => {
					getProducts(); // Refresh product list
				}).catch(()=>{});
			}
		});
	};
	const showForm = (action, data) => {
		setMethod(action);
		setProduct(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	useEffect(() => {
		getProducts();
	}, []);
	const filteredProducts = products.filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()));

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Product Form"}>
					<Form action={method} data={product} hide={hide} getProducts={getProducts}></Form>
				</FormModal>
			) : (
				""
			)}
			<div className="flex flex-col items-center w-full">
				<div className="flex flex-col w-full gap-4">
					<Orders />
					<h4 className=" font-bold">Products</h4>
					<div className="flex justify-between items-center">
						<div className="relative flex items-center max-w-lg flex-1 ">
							<input
								type="text"
								placeholder="Search..."
								className=" border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1"
								onChange={(event) => setSearchQuery(event.target.value)}
							/>
							<div className="absolute inset-y-0 right-0 flex items-center pointer-events-none mx-4">
								<i className="fa-solid fa-search text-gray-400 "></i>
							</div>
						</div>

						<button
							type="button"
							className="flex items-center font-semibold transition duration-150 ease-in-out text-white text-center relative px-8 py-3 gap-2 hover:bg-sky-900 bg-blue-500 "
							onClick={() => showForm("add", {})}
						>
							<i className="fa-solid fa-plus text-lg"></i> Add Product
						</button>
					</div>

					<div className="grid lg:grid-cols-4 md:grid-cols-1 w-full gap-6 z-10">
						{/*products section */}
						{products && (
							<>
								{filteredProducts.map((product) => (
									<div className="w-full rounded-md bg-slate-50 shadow-md hover:shadow-2xl p-4 z-10" key={product.id}>
										<ProductPhotos product={product} />

										<div>
											<div className="flex justify-between items-start hover:cursor-pointer my-2">
											<Link
													to={"/library/products/" + product.id} className="flex-1">
													<p className="text-s font-bold text-gray-800 overflow-hidden overflow-ellipsis h-[3.35em]">{product.name}</p>
												</Link>
												<p className="text-s font-bold text-sky-600 self-start">{product.price}.00 DA</p>
											</div>

											<div className="my-4 flex-warp flex flex-row gap-4 h-fit">
												{product.tags?.split(",").map((tag, index) => (
													<>
														<span className="w-fit rounded-lg bg-white-700 text-slate-500 border p-2">{tag.trim()}</span>
													</>
												))}
											</div>
											<div className="flex gap-4 items-center justify-end">
												<Link
													to={"/library/products/" + product.id}
													className="hover:text-blue-500 p-2 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg  text-center"
												>
													<i class="fa-solid fa-info"></i>
												</Link>
												<Link
													role={"button"}
													onClick={(event) => {
														event.stopPropagation();
														showForm("edit", product);
													}}
													className="hover:text-yellow-400 p-2 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg  text-center"
												>
													<i class="fa-solid fa-pen-to-square"></i>
												</Link>
												<Link
													role={"button"}
													onClick={(event) => {
														event.stopPropagation();
														destroy(product.id);
													}}
													className="hover:text-red-600 p-2 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg  text-center"
												>
													<i class="fa-solid fa-trash"></i>
												</Link>
											</div>
										</div>
									</div>
								))}
							</>
						)}
					</div>
					<div className="flex flex-row justify-center gap-2 my-4 ">
						<button
							className="px-4 py-2  flex items-center justify-center gap-4 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
							disabled={currentPage === 1}
							onClick={() => handlePageChange(currentPage - 1)}
						>
							<i className="fa-solid fa-backward "></i>
							Previous
						</button>
						<button
							className="px-4 py-2 flex items-center justify-center gap-4 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
							disabled={currentPage === totalPages}
							onClick={() => handlePageChange(currentPage + 1)}
						>
							Next
							<i className="fa-solid fa-forward "></i>
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
