import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import Form from "./Form";
import Swal from "sweetalert2";
import OrderReceipt from "./OrderReceipt";
import moment from "moment/moment";
import { toast } from "react-toastify";
import FormModal from "Components/Dropdowns/FormModal";

export default function Orders({ data }) {
	const token = localStorage.getItem("token");

	const url = process.env.REACT_APP_API_URL_LIBRARY;

	const navigate = useNavigate();

	const [form, setForm] = useState(false);
	const [receiptForm, setReceiptForm] = useState(false);
	const [orders, setOrders] = useState([]);
	const [order, setOrder] = useState({});
	const [receipt, setReceipt] = useState({});

	const getOrders = async () => {
		axios({
			method: "get",
			url: url + "/orders",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setOrders(response.data);
			})
			.catch(() => {});
	};

	const pay = async (order) => {
		await axios({
			// Endpoint to send files
			url: url + "/orders/" + order.id + "/pay",
			method: "post",
			data: {
				total: order.total,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {
				setReceipt(response?.data?.order);
				setOrder(response?.data?.order);
				getOrders();
				setReceiptForm(true);
				toast.success("Order paid successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			})
			.catch(() => {});
	};

	const print = async (order) => {
		await axios({
			// Endpoint to send files
			url: url + "/orders/" + order.id + "/receipt",
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {
				setReceipt(response?.data?.receipt);
				setOrder(response?.data?.order);
				setReceiptForm(true);
			})
			.catch(() => {});
	};
	const showForm = ( data) => {
		setOrder(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	const destroy = (id) => {
		Swal.fire({
			title: "Please confirm your action",
			text: "Do you want to cancel the order or delete it?",
			icon: "question",
			showCloseButton:true,
			showDenyButton: true,
			confirmButtonText: "Cancel Order",
			denyButtonText: "Delete Order",
		}).then((result) => {
			if (result.isConfirmed) {
				axios({
					// Endpoint to send files
					url: url + "/orders/" + id,
					method: "put",
					params: { action: "canceled" },
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
					// Handle the response from backend here
					.then((response) => {
						getOrders();
					})
					.catch((error) => {});
			} else if (result.isDenied) {
				axios({
					// Endpoint to send files
					url: url + "/orders/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
					// Handle the response from backend here
					.then((response) => {
						getOrders();
					})
					.catch((error) => {});
			}
		});
	};

	useEffect(() => {
		if (data) {
			setOrders(data);
		} else {
			getOrders();
		}
	}, []);

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Order Form"}>
					<Form  data={order} hide={hide} getOrders={getOrders}></Form>
				</FormModal>
			) : (
				""
			)}
			{receiptForm ? (
				<FormModal
					hide={() => {
						setReceiptForm(false);
					}}
					title={"Pint Order's Receipt"}
				>
					<OrderReceipt
						receipt={receipt}
						order={order}
						hide={() => {
							setReceiptForm(false);
						}}
					/>
				</FormModal>
			) : (
				""
			)}
			<h4 className=" font-bold">Orders</h4>
			<div className="break-words w-full shadow-lg overflow-auto border-0">
				<div className="w-full">
					<table className=" w-full border-collapse ">
						<thead className="sticky top-0 whitespace-nowrap">
							<tr>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Client name</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Phone number</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Total</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Status</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Date</th>

								<th className={" uppercase font-semibold flex justify-around transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
									<Link
										className="w-full ease-linear "
										role={"button"}
										onClick={(event) => {
											event.preventDefault();
											showForm();
										}}
									>
										Add
										<i className="fa-solid fa-plus "></i>
									</Link>
								</th>
							</tr>
						</thead>
						<tbody>
							{orders.map((order, id) => {
								return (
									<tr key={id} className="bg-white hover:bg-slate-100">
										<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
											<span className={"font-bold "}>{order.client.user.name}</span>
										</th>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{order.client.user.phone_number}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{order.total}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{order.status}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{moment(order.created_at).format("MMMM Do YYYY, h:mm")}</td>

										<td className={" whitespace-nowrap border border-slate-200 text-left items-center flex justify-evenly gap-2"}>
											<Link
												role={"button"}
												onClick={(event) => {
													event.preventDefault();
													navigate("/library/orders/" + order.id);
												}}
												className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-info"></i>
											</Link>
											{order.status !== "paid" ? (
													<Link
														role={"button"}
														onClick={(event) => {
															event.stopPropagation();
															pay(order);
														}}
														className="hover:text-green-400 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
													>
														<i className="fa-solid fa-dollar"></i>
													</Link>
											) : (
												""
											)}
											{order.status === "paid" ? (
												<Link
													role={"button"}
													onClick={(event) => {
														event.stopPropagation();
														print(order);
													}}
													className="hover:text-slate-200 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
												>
													<i className="fa-solid fa-receipt"></i>
												</Link>
											) : (
												""
											)}
											{order.status === "pending" ? (
												<Link
													role={"button"}
													onClick={(event) => {
														event.stopPropagation();
														destroy(order.id);
													}}
													className="hover:text-red-600 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
												>
													<i className="fa-solid fa-trash"></i>
												</Link>
											) : (
												""
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
