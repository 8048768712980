import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import GroupSessions from "./GroupSessions";
import { toast } from "react-toastify";
import InfoStudent from "Management/Users/Students/InfoStudent";
import ProgramEditing from "Management/Sessions/ProgramEditing";
import PortalWrapper from "Layouts/PortalWrapper";
import Swal from "sweetalert2";
import FormModal from "Components/Dropdowns/FormModal";
import Loading from "Components/Loaders/Loading";

// components

export default function InfoGroup({ id, hide }) {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();

	const [url, setUrl] = useAtom(urlAtom);
	const role = window.localStorage.getItem("role");
	const [unenrolled_students, setUnenrolledStudents] = useState([]);
	const [option, setOption] = useState("members");
	const [step, setStep] = useState("");
	const [members, setMembers] = useState([]);
	const [group, setGroup] = useState({});
	const [student, setStudent] = useState({});
	const [loading, setLoading] = useState(false);

	const addMember = () => {
		setLoading(true);
		if (members.length >= group.capacity) {
			Swal.fire({
				title: "Max class members",
				text: "This group reached max class members",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#00f23d",
				confirmButtonText: "confirm",
			})
				.then((result) => {
					if (result.isConfirmed) {
						axios({
							// Endpoint to send files
							url: url + "/groups/" + group.id + "/students",
							method: "post",
							data: {
								students: Object.keys(members),
							},
							headers: {
								Accept: "Application/json",
								Authorization: "Bearer " + token,
							},
						})
							// Handle the response from backend here
							.then((response) => {
								toast.success("Group members updated", {
									position: "top-center",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "light",
								});
								getGroup();
								getUnenrolledStudents();
							})
							.catch(() => { })
							.finally(() => {
								setLoading(false);
							});
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			axios({
				// Endpoint to send files
				url: url + "/groups/" + group.id + "/students",
				method: "post",
				data: {
					students: Object.keys(members),
				},
				headers: {
					Accept: "Application/json",
					Authorization: "Bearer " + token,
				},
			})
				// Handle the response from backend here
				.then((response) => {
					toast.success("Group members updated", {
						position: "top-center",
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					getGroup();
					getUnenrolledStudents();
				}).catch(() => { })
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const getGroup = () => {
		axios({
			// Endpoint to send files
			url: url + "/groups/" + id,
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				setGroup(response.data);
				var temp = {};
				response?.data?.students.forEach((member) => {
					temp[member.id] = member;
				});
				setMembers(temp);
			}).catch(() => { });
	};

	const remove_member = (id) => {
		axios({
			// Endpoint to send files
			url: url + "/groups/" + group.id + "/students/" + id,
			method: "delete",
			params: {
				role: role,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Group member removed successful", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getGroup();
				getUnenrolledStudents();
			});
	};

	const getUnenrolledStudents = () => {
		axios({
			// Endpoint to send files
			url: url + "/groups/" + id + "/students/unenrolled",
			method: "get",
			params: {
				role: role,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				setUnenrolledStudents(response.data);
			}).catch(() => { });
	};

	const hideInfo = () => {
		setStep("");
		getUnenrolledStudents();
	};

	useEffect(() => {
		getGroup();
		getUnenrolledStudents();
	}, []);
	return (
		<>
			{step !== "" ? (
				<FormModal hide={hideInfo} title={"Student Info"}>
					<InfoStudent student={student} hide={hideInfo} step={step} setStep={setStep}></InfoStudent>
				</FormModal>
			) : (
				""
			)}

			<div className="flex gap-4 w-full flex-col p-4 ">
				{/* Projects table */}
				<div className="flex flex-row items-center justify-between ">
					<div className="flex flex-row gap-4">
						<Link
							className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700  font-bold uppercase"
							role={"button"}
							onClick={(event) => {
								event.preventDefault()
								setOption("members");
							}}
						>
							Group Members
						</Link>
						<Link
							className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700   font-bold uppercase"
							role={"button"}
							onClick={(event) => {
								event.preventDefault()
								setOption("sessions");
							}}
						>
							Group Sessions
						</Link>
						<Link
							className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700   font-bold uppercase"
							role={"button"}
							onClick={(event) => {
								event.preventDefault()
								setOption("editing_sessions");
							}}
						>
							Edit Group Sessions
						</Link>
					</div>
					<div className="flex flex-row gap-4">
						{option === "add_members" ? (
							<>
								<Link
									className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700  font-bold uppercase"
									role={"button"}
									onClick={(event) => {
										event.preventDefault()
										setOption("members");
									}}
								>
									<div>
										<i class="fa-solid fa-close"></i> cancel
									</div>
								</Link>
								<button
									className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700   font-bold uppercase"
									role={"button"}
									onClick={(event) => {
										event.preventDefault()
										addMember();
										setOption("members");
									}}
									disabled={loading}
								>
									{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}

									<>
										<i class="fa-solid fa-check"></i> Save
									</>
								</button>
							</>
						) : (
							<Link
								className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700   font-bold uppercase"
								role={"button"}
								onClick={(event) => {
									event.preventDefault()
									setOption("add_members");
								}}
							>
								<>
									<i class="fa-solid fa-plus"></i> Add Member
								</>
							</Link>
						)}
					</div>
				</div>
				<div className="overflow-auto p-4 bg-white rounded-lg">
					{option === "add_members" ? (
						<table className="items-center w-full border-collapse">
							<thead>
								<tr>
									<th className={" border  align-middle   uppercase whitespace-nowrap font-semibold text-left   text-slate-500 bg-slate-50 border-slate-100"}>Name</th>
									<th className={"border  align-middle    uppercase whitespace-nowrap font-semibold text-left  text-slate-500 bg-slate-50 border-slate-100"}>Email</th>
									<th className={" border align-middle    uppercase  whitespace-nowrap font-semibold text-left  text-slate-500 bg-slate-50 border-slate-100"}>Phone Number</th>
								</tr>
							</thead>
							<tbody>
								{group?.students?.map((student, id) => {
									return (
										<tr key={id}>
											<th className={"border  align-middle  whitespace-nowrap  text-left bg-white"}>
												<input
													defaultChecked
													type="checkbox"
													value=""
													class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
													onChange={(event) => {
														if (event.target.checked) {
															let temp = members;
															temp[student.id] = student;
															setMembers(temp);
														} else {
															let temp = members;
															delete temp[student.id];
															setMembers(temp);
														}
													}}
												/>
												<span className={"ml-3 font-bold text-slate-600"}>{student.user.name}</span>
											</th>
											<td className={"border  align-middle  whitespace-nowrap  bg-white"}>{student.user.email}</td>
											<td className={"border  align-middle  whitespace-nowrap  bg-white"}>{student.user.phone_number}</td>
										</tr>
									);
								})}
								{unenrolled_students.map((student, id) => {
									return (
										<tr key={id}>
											<th className={"border  align-middle  whitespace-nowrap  text-left bg-white"}>
												<input
													type="checkbox"
													onChange={(event) => {
														if (event.target.checked) {
															let temp = members;
															temp[student.id] = student;
															setMembers(temp);
														} else {
															let temp = members;
															delete temp[student.id];
															setMembers(temp);
														}
													}}
													class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
												/>
												<span className={"ml-3 font-bold text-slate-600"}>{student.user.name}</span>
											</th>
											<td className={"border  align-middle  whitespace-nowrap  bg-white"}>{student.user.email}</td>
											<td className={"border  align-middle  whitespace-nowrap  bg-white"}>{student.user.phone_number}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : option === "members" ? (
						<table className="items-center w-full bg-transparent border-collapse">
							<thead>
								<tr>
									<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Name</th>
									<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Email</th>
									<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Phone Number</th>
									<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>Balance</th>
									<th className={"align-middle uppercase whitespace-nowrap font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"}>status</th>
									<th className={"border border-solid uppercase whitespace-nowrap font-semibold text-left flex justify-around bg-slate-50 text-slate-500 border-slate-100"}>
										actions
									</th>
								</tr>
							</thead>
							<tbody className=" overflow-auto max-h-96">
								{group?.students?.map((student, id) => {
									const balance = student.pivot.nb_session * group.price_per_month /  group.nb_session
									return (
										<tr key={id} className={balance > 0 ? "bg-white hover:bg-slate-50" :balance === 0 ? "bg-amber-100 hover:bg-amber-200" : "bg-rose-100 hover:bg-rose-200"}>
											<th className="border align-middle whitespace-nowrap text-left flex items-center">
												<span className="ml-3 font-bold text-slate-600">{student.user.name}</span>
											</th>
											<td className="border align-middle whitespace-nowrap">{student.user.email}</td>
											<td className="border align-middle whitespace-nowrap">{student.user.phone_number}</td>
											<td className="border align-middle whitespace-nowrap">{balance}</td>
											<td className="border align-middle whitespace-nowrap">{student.pivot.status}</td>
											<td className="border align-middle whitespace-nowrap">
												{student.pivot.debt <= 0 ? (
													" "
												) : (
													<div className="flex justify-around ">
														<Link
															role="button"
															onClick={(event) => {
																event.preventDefault()
																// go to profile
																setStudent(student);
																setStep("groups");
															}}
															className="hover:text-green-600 transition duration-150 ease-in-out"
														>
															<i className="fa-solid fa-check"></i>
														</Link>
														<Link role="button" onClick={() => remove_member(student.id)} className="hover:text-red-600 transition duration-150 ease-in-out">
															<i className="fa-solid fa-trash"></i>
														</Link>
													</div>
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : option === "sessions" ? (
						<GroupSessions group={group} />
					) : option === "editing_sessions" ? (
						<ProgramEditing defaultGroup={group} />
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
}
