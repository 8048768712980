import Navbar from "Components/Navbars/IndexNavbar.js";
import React, { useEffect, useState } from "react";

import Footer from "Components/Footers/Footer.js";
import Products from "./Products.js";
import axios from "config/axios.js";
import { useAtom } from "jotai";
import { formVisibilityAtom, cartProductsAtom, cartCountAtom } from "Components/Atoms/CartFormAtoms.js";

import { Link } from "react-router-dom";
import CartPageForm from "./Components/CartPageForm.js";

export default function CartPage() {
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [products, setProducts] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const getProducts = async () => {
		axios({
			method: "get",
			params: { page: currentPage },
			url: url + "/products",
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setProducts(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => {});
	};

	const handleSearch = (event) => {
		setSearchQuery(event.target.value);
	};

	useEffect(() => {
		getProducts();
	}, []);

	const filteredProducts = products.filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()));

	return (
		<div className="flex flex-col items-center w-full">
			<Navbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>
			{/*search bar section */}

			<main className="w-full min-h-screen bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] px-24 pb-24">
				<div className=" w-full">
					<h2 className=" font-poppins font-semibold text-white text-left w-fit">Numidia Cart </h2>
				</div>
				<CartPageForm />
			</main>
			<hr className="border w-full" />
			<div className="sticky top-0 w-full flex flex-col z-30 ">
				<div className="flex flex-row w-full items-center justify-between bg-slate-50 bg-opacity-50 backdrop-blur-md  gap-4 px-24 py-4 h-fit">
					<div className="flex items-center space-x-4 flex-1 justify-end relative">
						<Link
							to={"/library"}
							className=" transition duration-150 ease-in-out hover:text-white font-semibold text-center relative w-fit p-3 hover:bg-[rgb(1,42,74)]  bg-white text-[rgb(1,42,74)] rounded-full"
						>
							Go To Library
						</Link>
						<div className="relative flex items-center max-w-lg flex-1 ">
							<input type="text" placeholder="Search..." className=" border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1" onChange={handleSearch} />
							<div className="absolute inset-y-0 right-0 flex items-center pointer-events-none mx-4">
								<i className="fa-solid fa-search text-gray-400 "></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<main className="w-full px-24">
				<div className=" w-full ">
					<h2 className=" font-poppins font-semibold bg-gradient-to-r bg-clip-text from-[rgb(1,42,74)] to-[rgb(42,111,150)] text-transparent text-left w-fit">Numidia Library</h2>
				</div>
				<Products products={filteredProducts} />
			</main>
			{/* Cart section */}
			<div className="w-full -z-10 mt-96">
				<Footer />
			</div>
		</div>
	);
}
