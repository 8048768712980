import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import SelectOption from "Components/Dropdowns/SelectOption";

export default function ExpensesFilter({
	hide,
	setCheckouts,
	perPage,
	group_id,
	student_id,
	search,
	sortBy,
	sortDirection,
	setGroupId,
	setStudentId,
	setSearch,
	setSortBy,
	setSortDirection,
	type,
	end_date,
	start_date,
	setType,
	setEndDate,
	setStartDate,
}) {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);

	const [groups, setGroups] = useState([]);
	const [students, setStudents] = useState([]);
	const [studentPage, setStudentPage] = useState(1);
	const [groupPage, setGroupPage] = useState(1);
	const [lastStudentPage, setLastStudentPage] = useState(1);
	const [lastGroupPage, setLastGroupPage] = useState(1);


	const navigate = useNavigate();

	const getCheckouts = async () => {
		let options = {
			method: "get",
			url: url + "/checkouts",
			params: {
				sortBy,
				sortDirection,
				search,
				group_id,
				student_id,
				start_date,
				end_date,
				type,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setCheckouts(response.data);
			})
			.catch(() => { });
	};
	const getStudents = () => {
		axios({
			// Endpoint to send files
			url: url + "/students",
			method: "get",
			params: {
				page: studentPage,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return { ...item, label: item.user.name ? item.user.name + " " : "" };
				});
				setStudents(data);
				setLastStudentPage(response?.data?.last_page);
			})
			.catch(() => { });
	};
	const getGroups = () => {
		axios({
			// Endpoint to send files
			url: url + "/groups/all",
			method: "get",
			params: {
				page: groupPage,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return { ...item, label: item.module ? item.module + " " : "" + item.teacher.user.name ? item.teacher.user.name + " " : "" };
				});
				setLastGroupPage(response?.data?.last_page);

				setGroups(data);
			})
			.catch(() => { });
	};

	useEffect(() => {
		getStudents();
		getGroups();
	}, [groupPage, studentPage]);

	return (
		<div className="flex w-full justify-between flex-col align-middle items-center p-4  gap-4  ">
			<div className="grid grid-cols-2 gap-2 w-full">
				<div className="flex flex-col w-full my-2">
					<label>Search</label>
					<div className="flex flex-row flex-nowrap my-2 p-2 items-center justify-between bg-white rounded-lg  shadow  w-full ease-linear transition-all duration-150  ">
						<input
							className="focus:outline-none"
							type="text"
							value={search}
							onChange={(event) => {
								setSearch(event.target.value);
							}}
						></input>
						<i className="fa-solid fa-search"></i>
					</div>
				</div>
				<div className="flex flex-col my-2 w-full">
					<label>Group Filter:</label>
					<SelectOption
						onChange={(option) => {
							setGroupId(option.id);
						}}
						data={groups}
						page={groupPage}
						setPage={setGroupPage}
						withPagination={true}
						lastPage={lastGroupPage}
					></SelectOption>
				</div>
				<div className="flex flex-col my-2 w-full">
					<label>Student Filter:</label>
					<SelectOption
						onChange={(option) => {
							setStudentId(option.id);
						}}
						data={students}
						page={studentPage}
						setPage={setStudentPage}
						withPagination={true}
						lastPage={lastStudentPage}
					></SelectOption>
				</div>
				<div className="flex flex-col my-2 w-full">
					<label>Per {type}</label>
					<select
						className="bg-white rounded-lg my-2 p-2 shadow ease-linear transition-all duration-150  "
						value={type}
						onChange={(event) => {
							setType(event.target.value);
						}}
					>
						<option value="all">all</option>
						<option value="year">last year</option>
						<option value="month">last month</option>
						<option value="day">last day</option>
					</select>
				</div>
				<div className="flex flex-col w-full my-2 ">
					<label>Start Date</label>
					<div className="flex flex-row flex-nowrap my-2 p-2 justify-between bg-white rounded-lg  shadow  w-full ease-linear transition-all duration-150  ">
						<input
							className="focus:outline-none w-full"
							type="date"
							value={start_date}
							onChange={(event) => {
								setStartDate(event.target.value);
							}}
						></input>
					</div>
				</div>
				<div className="flex flex-col my-2 w-full">
					<label>End Date</label>
					<div className="flex flex-row flex-nowrap my-2 p-2 justify-between bg-white rounded-lg  shadow  w-full ease-linear transition-all duration-150  ">
						<input
							className="focus:outline-none w-full"
							type="date"
							value={end_date}
							onChange={(event) => {
								setEndDate(event.target.value);
							}}
						></input>
					</div>
				</div>
			</div>
			<div className="flex w-full justify-center my-4">
				<button
					className="w-full py-2 px-4 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase    rounded-lg shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
					type="button"
					onClick={(event) => {
						event.preventDefault()
						getCheckouts();
						hide();
					}}
				>
					Save
				</button>
			</div>
		</div>
	);
}
