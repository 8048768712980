import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Footer from "Components/Footers/Footer.js";

export default function GroupDetail() {
	const location = useLocation();
	const navigate = useNavigate();
	const { teacher, group } = location.state;

	return (
		<div className="flex flex-col min-h-screen">
			<IndexNavbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>
			<main className="flex-1 w-full h-full bg-gray-100">
				<div className="sticky top-0 w-full flex flex-col z-20 mb-4">
					<div className="flex flex-row items-center justify-between bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] px-24 py-4 h-fit">
						<div className="flex items-center text-white cursor-pointer" onClick={() => navigate(-1)}>
							<i className="fa-solid fa-arrow-left pr-3"></i>
							<span className="font-semibold ">Back to Teachers</span>
						</div>
						<div className="relative flex items-center max-w-lg flex-1">
							<input type="text" placeholder="Search..." className="border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1" />
							<div className="absolute inset-y-0 right-0 flex items-center pointer-events-none mx-4">
								<i className="fa-solid fa-search text-gray-400"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-md">
					<h1 className="text-3xl font-bold text-gray-800 mb-4">
						{teacher.name} - {group.level} {group.module} ({group.type})
					</h1>
					<div className="w-full flex justify-center items-center my-4">
						<img className="object-cover rounded-full w-60 h-96" src={require("assets/img/program-illustration.png")} alt={teacher.name} />
					</div>
					<p className="text-gray-600 mb-8">{teacher.details}</p>
					<h2 className="text-2xl font-semibold text-gray-800 mb-4">Sessions:</h2>
					<div className="overflow-x-auto">
						<table className="w-full bg-white shadow-md rounded-lg">
							<thead>
								<tr className="bg-gray-100 border-b">
									<th className="text-left p-4 text-gray-600">Day</th>
									<th className="text-left p-4 text-gray-600">Date</th>
									<th className="text-left p-4 text-gray-600">Start Time</th>
									<th className="text-left p-4 text-gray-600">End Time</th>
								</tr>
							</thead>
							<tbody>
								{group.sessions.map((session, index) => (
									<tr key={index} className="border-b hover:bg-gray-50 transition duration-150">
										<td className="p-4 text-gray-700">{session.day}</td>
										<td className="p-4 text-gray-700">{session.date}</td>
										<td className="p-4 text-gray-700">{session.startTime}</td>
										<td className="p-4 text-gray-700">{session.endTime}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</main>
			<div className="h-96 bg-slate-100"></div>
			<Footer />
		</div>
	);
}
