import { useEffect } from "react";
import Barcode from "react-barcode";
import moment from "moment";
import { departmentAtom } from "index";
import { useAtom } from "jotai";

export default function CheckoutReceipt({ receipt }) {
	const [department, setDepartment] = useAtom(departmentAtom);
	useEffect(() => {}, []);
	return (
		<>
			<div className="border border-black flex flex-col bg-white w-full p-2 ">
				<div className="flex flex-row justify-between items-center">
					<img className="w-24 h-24" src={require("assets/img/Numidia Education Group Logo/png/black.png")}></img>
					<div className="flex flex-col items-center">
						<p className=" font-bold ">Checkouts Receipt</p>
						<span className="">{moment().format("MMMM Do YYYY, h:mm")}</span>
					</div>

					{department === "school" ? (
						<img className="w-24 h-24" src={require("assets/img/Numidia School Logo/png/black.png")}></img>
					) : department === "profession" ? (
						<img className="w-24 h-24" src={require("assets/img/Numidia professional instiute Logo/png/black.png")}></img>
					) : department === "academy" ? (
						<img className="w-24 h-24" src={require("assets/img/Numidia Academy Logo/png/black.png")}></img>
					) : (
						""
					)}
				</div>

				<div className="flex flex-row items-center gap-4">
					<label>Student</label>
					<span>{receipt.user?.name}</span>
				</div>
				{receipt.services?.map((service) => {
					return (
						<div className="flex p-2 flex-row items-center justify-between">
							<span className=" w-2/3">{service.text}</span>
							<label className=" whitespace-nowrap">{service.price}.00 DA</label>
						</div>
					);
				})}
				<hr className=" "></hr>
				<div className="flex flex-row items-center justify-end font-bold gap-4">
					<label>Total:</label>
					<span>{receipt.total}.00 DA</span>
				</div>

				<div className="flex flex-row justify-center items-center">
					<Barcode fontSize={18} text={process.env.REACT_APP_URL} height={30} width={1} value={process.env.REACT_APP_URL} />
				</div>
			</div>
			
		</>
	);
}
