import React from "react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import axios from "config/axios.js";
import { formVisibilityAtom, nameAtom, emailAtom, phoneNumAtom, cityAtom, wilayaAtom, cartProductsAtom, cartCountAtom, genderAtom, streetAtom } from "Components/Atoms/CartFormAtoms.js";
import { Link } from "react-router-dom";
import ProductPhotos from "Components/Carousels/ProductPhotos.js";
import { toast } from "react-toastify";
import Loading from "Components/Loaders/Loading";

const CartForm = ({}) => {
	const [form, setForm] = useAtom(formVisibilityAtom);
	const [cartCount, setCartCount] = useAtom(cartCountAtom);
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [loading, setLoading] = useState(false);

	function generateEmail(event) {
		event.preventDefault();
		var formattedName = lastName.toLowerCase().replace(/\s/g, "") + firstName.toLowerCase().replace(/\s/g, "");
		var randomNumber = Math.floor(Math.random() * 9000) + 1000;
		var email = formattedName + randomNumber + "@numidia.com";

		setEmail(email);
	}
	const [gender, setGender] = useAtom(genderAtom);
	const [email, setEmail] = useAtom(emailAtom);
	const [phone_number, setPhone] = useAtom(phoneNumAtom);
	const [city, setCity] = useAtom(cityAtom);
	const [wilaya, setWilaya] = useAtom(wilayaAtom);
	const [street, setStreet] = useAtom(streetAtom);
	const [cartProducts, setCartProducts] = useAtom(cartProductsAtom);
	const handleInputChange = (index, field, value) => {
		value = value.trim();
		if (value === "") {
			value = "0";
		}
		value = parseInt(value, 10);
		if (isNaN(value) || value < 0) {
			return;
		}

		const updatedCartProducts = [...cartProducts];
		updatedCartProducts[index][field] = value;

		const updatedCartCount = updatedCartProducts.reduce((count, product) => count + product.qte, 0);

		setCartProducts(updatedCartProducts);
		setCartCount(updatedCartCount);
	};

	const handleRemoveProduct = (index, qte) => {
		setCartCount(cartCount - qte);
		const updatedProducts = cartProducts.filter((product) => product.id !== index);
		setCartProducts(updatedProducts);
	};
	const create_order = async () => {
		setLoading(true);

		axios({
			method: "post",
			url: url + "/guest-order",
			data: {
				name: lastName + " " + firstName,
				email,
				phone_number,
				gender,
				city,
				wilaya,
				street,
				products: cartProducts,
			},
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setForm(false);
				toast.success("Ordered successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<div className="flex-col lg:flex-row flex gap-6 w-full relative items-start">
				<div className="flex w-full  flex-col lg:w-3/5 z-40">
					{/* <h5 className="w-full font-bold">Products</h5> */}
					{cartProducts && (
						<>
							<div className="flex flex-wrap justify-center gap-6">
								{cartProducts.map((product, index) => (
									<>
										<div className="flex w-full rounded-lg bg-white shadow-md hover:shadow-lg  p-4 gap-4 z-50" key={product.id}>
											<div className="flex  w-64 aspect-square">
												<ProductPhotos product={product} />
											</div>
											<div className="flex flex-col flex-1 justify-between items-start gap-4">
												<Link to={"/products/" + product.id} className="flex justify-between items-start w-full">
													<p className="text-s font-bold text-gray-800">{product.name}</p>
													<p className="text-s font-bold text-sky-600">{product.price}.00 DA</p>
												</Link>

												<div className="flex items-center w-full gap-2">
													<label className=" whitespace-nowrap">Quantity:</label>
													<input
														type="number"
														onWheel={(event) => event.target.blur()}
														value={product.qte}
														onChange={(e) => handleInputChange(index, "qte", e.target.value)}
														className="p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow flex-1"
													/>
													<button
														type="button"
														className="hover:text-red-600 transition duration-150 ease-in-out bg-white rounded-full justify-center shadow-md hover:shadow-lg p-3 flex items-center"
														onClick={() => handleRemoveProduct(product.id, product.qte)}
													>
														<i className="fa-solid fa-trash"></i>
													</button>
												</div>
											</div>
										</div>
									</>
								))}
							</div>
						</>
					)}
				</div>
				<div className="lg:w-2/5 lg:sticky p-6 rounded-lg bg-white  w-full flex top-0 h-full ">
					<div className="w-full flex  flex-row flex-wrap gap-4 justify-center">
						<div className="flex-1  ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Last Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="First Name"
								onChange={(event) => {
									setLastName(event.target.value);
								}}
								value={lastName}
							/>
						</div>
						<div className=" flex-1 ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								First Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="First Name"
								onChange={(event) => {
									setFirstName(event.target.value);
								}}
								value={firstName}
							/>
						</div>
						<div className="w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								phone number
							</label>
							<input
								type="number"
								onWheel={(event) => event.target.blur()}
								className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="phone number"
								onChange={(event) => {
									setPhone(event.target.value);
								}}
							/>
						</div>
						<div className=" w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Email address
							</label>
							<div className="flex my-2 gap-4">
								<input
									type="email"
									className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="Email"
									onChange={(event) => {
										setEmail(event.target.value);
									}}
									value={email}
								/>
								<button className="bg-white rounded-lg shadow p-2 flex items-center justify-center" onClick={generateEmail}>
									<i className="fa-solid fa-gear"></i>{" "}
								</button>
							</div>
						</div>
						<div className="w-full ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Gender
							</label>
							<select
								className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								onChange={(event) => {
									setGender(event.target.value);
								}}
								value={gender}
							>
								<option value="">Select gender</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
							</select>
						</div>

						<div className=" w-full ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								wilaya
							</label>
							<input
								type="text"
								className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="wilaya"
								onChange={(event) => {
									setWilaya(event.target.value);
								}}
								value={wilaya}
							/>
						</div>
						<div className=" w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								city
							</label>
							<input
								type="text"
								className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="city"
								onChange={(event) => {
									setCity(event.target.value);
								}}
								value={city}
							/>
						</div>
						<div className=" w-full">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Street
							</label>
							<input
								type="text"
								className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="city"
								onChange={(event) => {
									setStreet(event.target.value);
								}}
								value={street}
							/>
						</div>

						<div className="flex justify-center w-full my-4">
							<button
								className="px-4 py-2 whitespace-nowrap w-full text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
								type="button"
								onClick={create_order}
								disabled={loading}
							>
								{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
								Order now
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CartForm;
