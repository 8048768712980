import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";
import StudentAnswers from "./StudentAnswers";
import Swal from "sweetalert2";
export default function Exam() {
	const [url, setUrl] = useAtom(urlAtom);
	const [department, setDepartment] = useAtom(departmentAtom);
	const token = window.localStorage.getItem("token");
	const [exam, setExam] = useState({});
	const [student, setStudent] = useState([]);
	const [info, setInfo] = useState(false);

	const { exam_id } = useParams();

	const navigate = useNavigate();

	const getExam = () => {
		axios({
			// Endpoint to send files
			url: url + "/exams/" + exam_id,
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response.data;
				data.questions = data.questions?.map((question, index) => {
					if (question.audio) {
						// Decode base64 content to binary data
						const binaryData = atob(question.audio.content);

						// Convert binary data to array buffer
						const arrayBuffer = new ArrayBuffer(binaryData.length);
						const uint8Array = new Uint8Array(arrayBuffer);
						for (let i = 0; i < binaryData.length; i++) {
							uint8Array[i] = binaryData.charCodeAt(i);
						}

						// Create Blob object from array buffer
						const blob = new Blob([uint8Array], { type: `audio/${question.audio.extension}` });

						// Create object URL
						const audioURL = URL.createObjectURL(blob);

						// Assign the created URL to the question's audio_url property
						question.audio_url = audioURL;
					}
					return question;
				});

				setExam(data);
			}).catch(() => { });
	};
	const showInfo = (student) => {
		setInfo(true);
		setStudent(student);
	};

	useEffect(() => {
		getExam();
	}, []);
	return (
		<>
			{info ? (
				<div className="fixed bg-black bg-opacity-25 top-0 left-0 z-50 w-full h-screen  flex justify-center items-center">
					<div className=" bg-slate-100 w-full rounded-lg max-h-[calc(100vh-3rem)] h-auto  flex flex-col align-middle overflow-auto scrollbar-hide gap-4">
						<div className="flex flex-row items-center  justify-between bg-white rounded-t-lg  ">
							<h6 className="text-slate-700  font-bold capitalize">Student Answers </h6>
							<button
								className=" hover:text-white hover:bg-red-600 text-gray-500 w-8 h-8 rounded-full hover:shadow-lg outline-none  ease-linear transition-all duration-150"
								type="button"
								onClick={(event) => {
									event.preventDefault()
									setInfo(false);
								}}
							>
								<i className="fa-solid fa-close"></i>
							</button>
						</div>
						<StudentAnswers answers={student.answers}></StudentAnswers>
					</div>
				</div>
			) : (
				""
			)}
			<div className="flex flex-col gap-4">
				<div className="flex flex-row justify-between items-center">
					<button
						onClick={(event) => {
							event.stopPropagation();
							navigate("/exams");
						}}
						className="text-white bg-sky-500 hover:bg-sky-600 rounded-lg   flex items-center gap-2 px-4 py-2"
					>
						<i className="fa-solid fa-arrow-left "></i>Return to Exams
					</button>
					<h4 className=" font-bold">{exam.title}</h4>
				</div>{" "}
				<div className="flex-auto">
					<div className="grid md:grid-cols-4 gap-4 grid-cols-2 my-4 ">
						{exam.questions?.map((question, index) => {
							return (
								<div className="w-full flex flex-col gap-4 bg-white shadow-lg rounded-lg p-4 ">
									<h5 className=" font-bold">Question {index + 1}</h5>
									<div className="w-full">{question.content}</div>
									{question.audio ? (
										<div className="w-full">
											<audio controls className="w-full" src={question.audio_url}>
												Your browser does not support the audio element.
											</audio>
										</div>
									) : (
										""
									)}
									<div className="w-full">Answer: {question.answer}</div>
									<label className="block uppercase text-slate-600  font-bold" htmlFor="grid-password">
										Choices
									</label>
									{question.choices?.map((choice, id) => {
										return (
											<div className="flex flex-row gap-4 items-center">
												<label>
													{id + 1}: {choice.content}
												</label>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="break-words w-full shadow-lg overflow-auto my-4  border-0">
				<table className=" w-full border-collapse ">
					<thead className="sticky top-0 whitespace-nowrap">
						<tr>
							<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>Name</th>
							<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Email</th>
							<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Level</th>
							<th className={"  align-middle    uppercase    font-semibold text-left bg-slate-50 text-slate-500 "}>Score</th>
						</tr>
					</thead>
					<tbody>
						{exam.students?.map((student, id) => {
							return (
								<tr
									onClick={(event) => {
										event.preventDefault()
										navigate("/students/" + student.id + "/exams");
									}}
									key={id}
									className=" bg-white hover:bg-slate-100 cursor-grab "
								>
									<th className={" whitespace-nowrap  border border-slate-200  text-left align-middle flex items-center gap-2"}>
										<Link
											role={"button"}
											onClick={(event) => {
												event.stopPropagation();
												showInfo(student);
											}}
											className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
										>
											<i class="fa-solid fa-info"></i>
										</Link>
										<span className={"font-bold "}>{student.user.name}</span>
									</th>
									<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{student.user.email}</td>
									<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
										{student.level.year} {student.level.education} {student.level.specialty}
									</td>
									<td className={" font-bold whitespace-nowrap  border border-slate-200  text-left align-middle"}>
										{(student.answers.reduce((total, answer) => total + answer.score, 0) / exam.nb_question) * 100} %
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
}
