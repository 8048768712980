import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "config/axios";
import Form from "../Orders/Form";
import Swal from "sweetalert2";
import OrderReceipt from "../Orders/OrderReceipt";
import moment from "moment/moment";
import { toast } from "react-toastify";
import FormModal from "Components/Dropdowns/FormModal";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import UserPrint from "Management/Users/Users/UserPrint";
import Orders from "../Orders/Orders";

export default function Client() {
	const token = localStorage.getItem("token");

	const url = process.env.REACT_APP_API_URL_LIBRARY;

	const navigate = useNavigate();

	const [method, setMethod] = useState("add");
	const [form, setForm] = useState(false);
	const [receiptForm, setReceiptForm] = useState(false);
	const [client, setClient] = useState({});
	const [order, setOrder] = useState({});
	const [receipt, setReceipt] = useState({});
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;

	const { id } = useParams();
	const getClient = async () => {
		axios({
			method: "get",
			url: url + "/clients/" + id,
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setClient(response.data);
			})
			.catch(() => {});
	};
	var ClientRef = useRef();
	useEffect(() => {
		getClient();
	}, []);

	return (
		<>
			<div className="hidden">
				<div className="flex flex-col w-full gap-4 " ref={(el) => (ClientRef = el)}>
					<UserPrint user={client} />
				</div>
			</div>

			<div className="flex md:flex-row border flex-col-reverse p-4 bg-white shadow-lg rounded-lg hover:bg-slate-100 justify-between gap-4 w-full ">
				<div className="flex flex-row gap-4">
					<div className="relative w-32 aspect-square">
						<img className="w-full aspect-square rounded-lg shadow object-cover cursor-pointer" src={`${auth_api_url}/files?url=${client?.profile_picture?.url}`}></img>
					</div>

					<div className="flex flex-col font-light text-slate-500 ">
						<h6 className=" text-slate-900 font-medium font-mono capitalize">
							{client.name}
							<ReactToPrint
								trigger={() => {
									return (
										<button className="ml-4 border px-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150">
											<i className="fa-solid fa-print" />
										</button>
									);
								}}
								onAfterPrint={() => {
									toast.success("Printing completed successful", {
										position: "top-center",
										autoClose: 3000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: "light",
									});
								}}
								content={() => ClientRef}
							/>
						</h6>
						<p>
							{client.email} / {client.phone_number}
						</p>
						<p className="capitalize">Client</p>
					</div>
				</div>

				<div className="md:w-32 aspect-square w-full ">{client.id ? <QRCode value={client.id} style={{ height: "100%", width: "100%" }} /> : ""}</div>
			</div>
			{client?.client?.orders && <Orders data={client?.client?.orders}></Orders>}
		</>
	);
}
