import React, { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import moment from 'moment'
import ReactToPrint from 'react-to-print'

export default function EmployeeData({ employee }) {
  var ComponentRef = useRef()
  const auth_api_url = process.env.REACT_APP_API_URL_AUTH
  return (
    <>
      {/* Print The Financials */}
      <div className="hidden">
        <div ref={(el) => (ComponentRef = el)}></div>
      </div>

      {/* Financials */}
      <div className="bg-white p-8 shadow-lg rounded-lg   border">
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-row flex-1 items-end gap-2">
            <img
              alt="..."
              className="w-24 h-24 object-contain rounded-lg shadow-lg border align-middle"
              src={`${auth_api_url}/files?url=${employee.user.profile_picture?.url}`}
            />
            <div className="w-fit flex flex-col gap-1">
              <p className="font-bold text-lg capitalize">
                {employee.user.name}{' '}
              </p>
              <small>{employee.user.email}</small>
              <small>{employee.user.phone_number}</small>
            </div>
          </div>
          <div className="w-fit font-play gap-4 whitespace-nowrap items-center flex">
            <h4>{employee.cumulative_price}.00 DA</h4>
            <ReactToPrint
              trigger={() => {
                return (
                  <button className="border p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150  ">
                    <i className="fa-solid fa-print" />
                  </button>
                )
              }}
              onAfterPrint={() => {
                toast.success('Printing completed successful', {
                  position: 'top-center',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                })
              }}
              content={() => ComponentRef}
            />
          </div>
        </div>
        {employee.employee_receipts.fee_inscription?.length > 0 ? (
          <>
            <h4 className=" my-2">Inscription Fees</h4>
            <div className="break-words w-full shadow-lg overflow-auto   border-0">
              {/* Projects table */}
              <table className=" w-full border-collapse ">
                <thead className="sticky top-0 whitespace-nowrap">
                  <tr>
                    <th
                      className={
                        '    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 '
                      }
                    >
                      date
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Student
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employee.employee_receipts.fee_inscription.map((fee, id) => {
                    return (
                      <tr
                        key={id}
                        className={
                          fee.paid
                            ? ' bg-white hover:bg-slate-100 '
                            : 'bg-rose-100'
                        }
                      >
                        <th
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          <span className={'font-bold '}>
                            {moment(fee.created_at).format('DD-MMMM hh:mm')}
                          </span>
                        </th>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {fee.user.name}
                        </td>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {fee.total}.00 DA
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ''
        )}

        {employee.employee_receipts.debt?.length > 0 ? (
          <>
            <h4 className="my-2">Checkouts</h4>
            <div className="break-words w-full shadow-lg overflow-auto   border-0">
              {/* Projects table */}
              <table className=" w-full border-collapse ">
                <thead className="sticky top-0 whitespace-nowrap">
                  <tr>
                    <th
                      className={
                        '    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 '
                      }
                    >
                      Date
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Student
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employee.employee_receipts.debt.map((checkout, id) => {
                    return (
                      <tr key={id} className="bg-white hover:bg-slate-100 ">
                        <th
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          <span className={'font-bold '}>
                            {moment(checkout.created_at).format(
                              'DD-MMMM hh:mm',
                            )}
                          </span>
                        </th>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {checkout.user.name}
                        </td>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {checkout.total}
                          .00 DA
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ''
        )}

        {employee.employee_receipts.sessions?.length > 0 ? (
          <>
            <h4 className="my-2">Paid Sessions</h4>
            <div className="break-words w-full shadow-lg overflow-auto   border-0">
              {/* Projects table */}
              <table className=" w-full border-collapse ">
                <thead className="sticky top-0 whitespace-nowrap">
                  <tr>
                    <th
                      className={
                        '    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 '
                      }
                    >
                      Date
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Student
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employee.employee_receipts.sessions.map((checkout, id) => {
                    return (
                      <tr key={id} className="bg-white hover:bg-slate-100 ">
                        <th
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          <span className={'font-bold '}>
                            {moment(checkout.created_at).format(
                              'DD-MMMM hh:mm',
                            )}
                          </span>
                        </th>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {checkout.user.name}
                        </td>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {checkout.total}
                          .00 DA
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ''
        )}

        {employee.employee_receipts.deposit?.length > 0 ? (
          <>
            <h4 className="my-2">Deposits</h4>
            <div className="break-words w-full shadow-lg overflow-auto   border-0">
              {/* Projects table */}
              <table className=" w-full border-collapse ">
                <thead className="sticky top-0 whitespace-nowrap">
                  <tr>
                    <th
                      className={
                        '    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 '
                      }
                    >
                      Date
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Student
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employee.employee_receipts.deposit.map((item, id) => {
                    return (
                      <tr key={id} className=" bg-white hover:bg-slate-100 ">
                        <th
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          <span className={'font-bold '}>
                            {moment(item.created_at).format('DD-MMMM hh:mm')}
                          </span>
                        </th>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {item.user.name}
                        </td>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {item.total}
                          .00 DA
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ''
        )}

        {employee.employee_receipts.withdraw?.length > 0 ? (
          <>
            <h4 className="my-2">Withdraws</h4>
            <div className="break-words w-full shadow-lg overflow-auto   border-0">
              {/* Projects table */}
              <table className=" w-full border-collapse ">
                <thead className="sticky top-0 whitespace-nowrap">
                  <tr>
                    <th
                      className={
                        '    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 '
                      }
                    >
                      Date
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Student
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employee.employee_receipts.withdraw.map((item, id) => {
                    return (
                      <tr key={id} className=" bg-white hover:bg-slate-100 ">
                        <th
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          <span className={'font-bold '}>
                            {moment(item.created_at).format('DD-MMMM hh:mm')}
                          </span>
                        </th>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {item.user.name}
                        </td>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {item.total}
                          .00 DA
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ''
        )}

        {employee.expenses?.length > 0 ? (
          <>
            <h4 className=" my-2">Expenses</h4>
            <div className="break-words w-full shadow-lg overflow-auto   border-0">
              {/* Projects table */}
              <table className=" w-full border-collapse ">
                <thead className="sticky top-0 whitespace-nowrap">
                  <tr>
                    <th
                      className={
                        '    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 '
                      }
                    >
                      date
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      amount
                    </th>
                    <th
                      className={
                        '  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  '
                      }
                    >
                      type
                    </th>
                    <th
                      className={
                        '    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 '
                      }
                    >
                      description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employee.expenses.map((expense, id) => {
                    return (
                      <tr key={id} className=" bg-white hover:bg-slate-100 ">
                        <th
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          <span className={'font-bold '}>
                            {moment(expense.created_at).format('DD-MMMM hh:mm')}
                          </span>
                        </th>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {expense.total}
                          .00 DA
                        </td>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {expense.type}
                        </td>
                        <td
                          className={
                            ' whitespace-nowrap  border border-slate-200  text-left align-middle'
                          }
                        >
                          {expense.description}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
