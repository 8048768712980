
import { useState } from "react";
import ProgramCalendar from "./ProgramCalendar";
import ProgramEditing from "./ProgramEditing";
import { Link } from "react-router-dom";
// components

export function Sessions() {
	const [editing, setEditing] = useState(false);
	const role = localStorage.getItem("role");
	return (
		<>
			<div className="flex justify-between items-center  ">
				<h4 className=" font-semibold">Sessions</h4>
				<div className="flex flex-row gap-4">
					<Link
						className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700  font-bold uppercase"
						role={"button"}
						onClick={(event) => {
							event.preventDefault()
							setEditing(true);
						}}
					>
						Edit Sessions
					</Link>
					<Link
						className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700   font-bold uppercase"
						role={"button"}
						onClick={(event) => {
							event.preventDefault()
							setEditing(false);
						}}
					>
						View Sessions
					</Link>
				</div>
			</div>

			{editing ? <ProgramEditing /> : <ProgramCalendar />}
		</>
	);
}
