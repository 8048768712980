import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Barcode from "react-barcode";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";

export default function TicketReceipt({ ticket, hide }) {
	var ReceiptRef = useRef();

	useEffect(() => {}, []);
	return (
		<>
			<div className=" p-4">
				<div className="flex overflow-auto justify-center items-center">
					<div className="border border-black flex flex-col bg-white w-full p-2" ref={(el) => (ReceiptRef = el)}>
						<div className="flex flex-col justify-center items-center">
							<img className="w-36 h-36" src={require("assets/img/Numidia Education Group Logo/png/black.png")}></img>
							<div className="flex flex-col items-center">
								<p className=" font-bold ">Ticket Receipt</p>

								<span className="mb-4">{moment().format("MMMM Do YYYY, h:mm")}</span>
								<div className="text-center mb-4">
									{ticket.title.split("\\n").map((line, index) => (
										<>
											{line}
											<br />
										</>
									))}
								</div>
							</div>
						</div>

						<div className="flex flex-row items-center  justify-between mb-2 gap-4">
							<label>Student</label>
							<span>{ticket.student.user.name}</span>
						</div>
						<div className="flex flex-row items-center justify-between mb-2 gap-4 font-bold ">
							<label>Row - Seat:</label>
							<span>
								{ticket.row} - {ticket.seat}
							</span>
						</div>
						<div className="flex flex-row items-center justify-between mb-2 gap-4 font-bold ">
							<label>Price:</label>
							<span>
								{ticket.price}.00 DA
							</span>
						</div>
						<div className="flex flex-row items-center justify-between mb-2 gap-4 font-bold ">
							<label>Discount:</label>
							<span>
								{ticket.discount}.00 DA
							</span>
						</div>
						<hr></hr>
						<div className="flex flex-row items-center justify-end gap-4 font-bold ">
							<h6>Total:</h6>
							<h6>{ticket.price}.00 DA</h6>
						</div>

						<div className="flex flex-row justify-center items-center">
							<Barcode fontSize={18} text={process.env.REACT_APP_URL} height={30} width={1} value={process.env.REACT_APP_URL} />
						</div>
					</div>
				</div>
				<div className="flex justify-center flex-row gap-4 my-4">
					<ReactToPrint
						trigger={() => {
							return (
								<button
									className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
									type="button"
								>
									Print
								</button>
							);
						}}
						onAfterPrint={() => {
							hide();
							toast.success("Ticket receipt was printed", {
								position: "top-center",
								autoClose: 3000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
							});
						}}
						content={() => ReceiptRef}
					/>
				</div>
			</div>
		</>
	);
}
