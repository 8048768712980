import React from "react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import axios from "config/axios.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import DawaratPhotos from "Components/Carousels/DawaratPhotos.js";
import { toast } from "react-toastify";
import Loading from "Components/Loaders/Loading";
import FooterSmallDark from "Components/Footers/FooterSmallDark";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import { urlAtom } from "index";
import SelectOption from "Components/Dropdowns/SelectOption";
import Swal from "sweetalert2";

const ReserveTicket = ({}) => {
	const [url, setUrl] = useAtom(urlAtom);
	const [gender, setGender] = useState("male");
	const [email, setEmail] = useState("");
	const [phone_number, setPhone] = useState("");
	const [loading, setLoading] = useState(false);
	const [dawarat, setDawarat] = useState({});
	const [loadingDawarat, setLoadingDawarat] = useState(true); // New loading state for dawrat data
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [level_id, setLevelId] = useState("");
	const [levels, setLevels] = useState([]);
	const [levelPage, setLevelPage] = useState(1);
	const [levelLastPage, setLevelLastPage] = useState(1);
	const [levelSearch, setLevelSearch] = useState("");

	const { id } = useParams();
	const navigate = useNavigate();

	const create_ticket = async () => {
		setLoading(true);

		axios({
			method: "post",
			url: url + "/guest-ticket",
			data: {
				name: lastName + " " + firstName,
				email,
				phone_number,
				gender,
				level_id,
				dawarat_id: dawarat.id,
			},
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				Swal.fire({
					title: "Success!",
					text: "Ticket reservation successfully",
					icon: "success",
					showConfirmButton: false,
					timer: 3000, // The popup will close after 3 seconds
					didClose: () => {
						navigate("/"); // Redirect to the homepage after the popup closes
					},
				});
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const getLevels = async () => {
		const response = await axios
			.get(`${url}/levels`, {
				params: {
					page: levelPage,
					search: levelSearch,
				},
				headers: {
					Accept: "Application/json",
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: `${item.education ? item.education + " " : ""}${item.year ? item.year + " " : ""}${item.specialty ? item.specialty : ""}`,
		}));
		setLevels(data);
		setLevelLastPage(response?.data?.last_page);
	};

	const getDawarat = async () => {
		setLoadingDawarat(true); // Set loading state to true before fetching
		axios({
			method: "get",
			url: url + "/groups/" + id + "/all-details",
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				const data = { ...response.data, pictures: response.data.photos };
				setDawarat(data);
			})
			.catch(() => {})
			.finally(() => {
				setLoadingDawarat(false); // Set loading state to false once data is fetched
			});
	};

	useEffect(() => {
		if (id) {
			getDawarat();
		}
	}, [id]);

	useEffect(() => {
		getLevels();
	}, [levelPage, levelSearch]);

	return (
		<div className="flex flex-col bg-gradient-to-r min-h-screen from-[rgb(1,42,74)] to-sky-600 w-full justify-between">
			<IndexNavbar className="z-50 w-full flex items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-sky-600 font-poppins px-12 py-4" />

			<div className="flex  relative z-30 justify-center w-full p-8 m-auto">
				<div className="relative w-full sm:w-4/5 shadow-lg rounded-3xl bg-slate-200 p-6">
					<div className="flex flex-col lg:flex-row gap-10 w-full items-start">
						{/* Left Column: Cart Products */}
						<div className="w-full lg:w-1/2 z-40">
							{loadingDawarat ? (
								<div className="flex justify-center items-center h-full">
									<Loading width="50px" height="50px" color="sky" />
								</div>
							) : (
								<div className="flex flex-col w-full rounded-lg bg-white shadow-md hover:shadow-lg p-4 gap-4 z-50" key={dawarat.id}>
									<div className="aspect-square">
										<DawaratPhotos product={dawarat} />
									</div>
									<div className="flex flex-col flex-1 justify-between items-start gap-4">
										<div className="flex justify-between items-start w-full">
											<p className="text-s font-bold text-gray-800">
												{dawarat.module} {dawarat.teacher.user.name} {dawarat.level.year} {dawarat.level.education} {dawarat.level.specialty}
											</p>
											<p className="text-s font-bold text-sky-600">{dawarat.price_per_month}.00 DA</p>
										</div>
									</div>
								</div>
							)}
						</div>

						{/* Right Column: User Details and Order */}
						<div className="lg:w-1/2 lg:sticky w-full bottom-0 h-fit">
							<h4 className="font-bold mb-4">Reserve Place Now</h4>

							<div className="w-full flex flex-wrap gap-4 justify-center">
								<div className="w-full">
									<label className="block capitalize text-blueGray-600 font-bold">Level</label>
									<SelectOption
										onChange={(option) => {
											setLevelId(option.id);
										}}
										data={levels}
										page={levelPage}
										setPage={setLevelPage}
										withPagination={true}
										lastPage={levelLastPage}
										setSearch={setLevelSearch}
									/>
								</div>
								<div className="flex-1">
									<label className="block uppercase text-slate-600 font-bold">Last Name</label>
									<input
										type="text"
										className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Last Name"
										onChange={(e) => setLastName(e.target.value)}
										value={lastName}
									/>
								</div>
								<div className="flex-1">
									<label className="block uppercase text-slate-600 font-bold">First Name</label>
									<input
										type="text"
										className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="First Name"
										onChange={(e) => setFirstName(e.target.value)}
										value={firstName}
									/>
								</div>
								<div className="w-full">
									<label className="block uppercase text-slate-600 font-bold">Phone Number</label>
									<input
										type="number"
										onWheel={(event) => event.target.blur()}
										className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Phone Number"
										onChange={(e) => setPhone(e.target.value)}
									/>
								</div>
								<div className="flex-1">
									<label className="block uppercase text-slate-600 font-bold">Email</label>
									<input
										type="email"
										className="my-2 p-3 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										placeholder="Email"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
									/>
								</div>
								<div className="flex-1">
									<label className="block uppercase text-slate-600 font-bold">Gender</label>
									<select
										className="my-2 p-3 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full transition-all duration-150"
										onChange={(e) => setGender(e.target.value)}
										value={gender}
									>
										<option value="">Select Gender</option>
										<option value="male">Male</option>
										<option value="female">Female</option>
									</select>
								</div>
								<div className="flex justify-center w-full my-4">
									<button
										className="px-4 py-2 w-full text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md transition-all duration-150"
										type="button"
										onClick={create_ticket}
										disabled={loading}
									>
										{loading ? <Loading width="20px" height="20px" color="white" weight="2px" /> : "Order Ticket Now"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<FooterSmallDark />
		</div>
	);
};

export default ReserveTicket;
