import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
// components

export default function Teacher({ user }) {
	const [url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");
	const [selected_group, setSelectedGroup] = useState({});
	const [teacher, setTeacher] = useState({});
	const [month, setMonth] = useState(moment().month());
	const { t } = useTranslation();

	const navigate = useNavigate();
	const getTeacher = async () => {
		let options = {
			method: "get",
			url: url + "/teachers/" + user.teacher.id,
			params: { month: month + 1 },
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setTeacher(response.data);
			setSelectedGroup(response?.data?.groups[0]);
		}).catch(() => { });
	};
	// var XLSX = require("xlsx");
	const generateCSV = () => {
		let csvContent = t("teacher_data") + ": " + teacher.user.name + "\n";
		csvContent += t("salary_statement_teacher_month") + ": " + parseInt(parseInt(month) + 1) + "\n";
		csvContent += "\n";
		teacher.groups.forEach((group, index) => {
			csvContent += "\n";
			csvContent += `${t("number")}: ${index + 1} ,,` + t("subject") + ": " + teacher.modules + "\n";
			csvContent += ",," + t("level") + ": " + teacher.levels + "\n";
			csvContent += ",," + t("salary") + ": " + group.price_per_month + "\n";
			csvContent += ",," + t("print_time") + ": " + moment().format("HH:MM DD-MM-YYYY") + "\n";
			csvContent += "\n";
			csvContent += t("number") + "," + t("student_name") + ",";

			group.presences.forEach((_, index) => {
				csvContent += `${index + 1}`;
				csvContent += ",";
			});

			csvContent += t("amount") + "," + t("paid_price") + "," + t("discount") + "," + t("teacher_amount") + "," + t("school") + "," + t("notes") + "\n";
			csvContent += ",,";
			group.presences.forEach((session, index) => {
				csvContent += moment(session.starts_at).format("DD-MM-YYYY HH:MM");
				csvContent += ",";
			});
			csvContent += "\n";
			group.students.forEach((student, index) => {
				csvContent += index + 1 + "," + student.user.name;
				csvContent += ",";

				group.presences.forEach((session) => {
					const presenceStatus = session.students.find((s) => s.user_id === student.user_id)?.pivot.status || "";
					csvContent += presenceStatus;
					csvContent += ",";
				});

				console.log(student.checkouts[0]);
				csvContent += student.checkouts[0]?.price || 0;
				csvContent += ",";

				csvContent += student.checkouts[0]?.paid_price || 0;
				csvContent += ",";

				csvContent += student.checkouts[0]?.discount || 0;
				csvContent += ",";

				csvContent += ((student.checkouts[0]?.price - student.checkouts[0]?.discount) * student.checkouts[0]?.teacher_percentage) / 100 || 0;
				csvContent += ",";

				csvContent += ((student.checkouts[0]?.price - student.checkouts[0]?.discount) * (100 - student.checkouts[0]?.teacher_percentage)) / 100 || 0;
				csvContent += ",";

				csvContent += student.checkouts[0]?.notes || "N/A";
				csvContent += ",\n";
			});

			csvContent += t("total_students_count") + ",,";
			group.presences.forEach((session) => {
				csvContent += session.students.filter((s) => s.pivot.status === "present").length;
				csvContent += ",";
			});

			csvContent += ",,,,,,\n";
			csvContent += t("total") + ",,";
			group.presences.forEach((_) => {
				csvContent += ",";
			});

			csvContent += group.students.reduce((acc, student) => acc + student.checkouts[0]?.price, 0) || 0;
			csvContent += ",";

			csvContent += group.students.reduce((acc, student) => acc + student.checkouts[0]?.paid_price, 0) || 0;
			csvContent += ",";

			csvContent += group.students.reduce((acc, student) => acc + student.checkouts[0]?.discount, 0) || 0;
			csvContent += ",";

			csvContent += group.students.reduce((acc, student) => acc + ((student.checkouts[0]?.price - student.checkouts[0]?.discount) * teacher.percentage) / 100, 0) || 0;
			csvContent += ",";

			csvContent += group.students.reduce((acc, student) => acc + ((student.checkouts[0]?.price - student.checkouts[0]?.discount) * (100 - teacher.percentage)) / 100, 0) || 0;
			csvContent += ",\n";
			csvContent += t("debt") + ",,";
			group.presences.forEach((_) => {
				csvContent += ",";
			});
			let debt = group.students.reduce((acc, student) => acc + (student.checkouts[0]?.price - student.checkouts[0]?.discount - student.checkouts[0]?.paid_price), 0) || 0;
			if (!debt) {
				debt = 0;
			}
			csvContent += ",,,," + debt + ",\n";
		});

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.setAttribute("href", url);
		link.setAttribute("download", "monthly_report.csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	useEffect(() => {
		getTeacher();
	}, [month]);
	return (
		<>
			<div className="grid grid-cols-2 gap-4 p-4 bg-white shadow-lg rounded-lg ">
				<div className="flex justify-center w-full my-4 col-span-2">
					<button
						className="w-full px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
						type="button"
						onClick={(event) => {
							event.preventDefault()
							generateCSV();
						}}
					>
						Generate Monthly Teacher Salary (CSV)
					</button>
				</div>
				<div className=" flex flex-col ">
					<div className="flex justify-between items-center">
						<h6 className=" font-semibold dark:text-white">Groups</h6>
						<div className="flex items-center space-x-4 px-4">
							<button
								onClick={(event) => {
									event.preventDefault()
									setMonth((prev) => {
										return (prev - 1) % 12;
									});
								}}
								className="text-slate-400"
							>
								<i className="fa-solid fa-chevron-left text-lg"></i>
							</button>
							<div className="text-lg font-play font-semibold">{moment().month(month).format("MMMM")}</div>
							<button
								onClick={(event) => {
									event.preventDefault()
									setMonth((prev) => {
										return (prev + 1) % 12;
									});
								}}
								className="text-slate-400 "
							>
								<i className="fa-solid fa-chevron-right text-lg"></i>
							</button>
						</div>
					</div>
					<div className="break-words w-full shadow-lg overflow-auto border-0">
						<div className="w-full">
							{/* Projects table */}
							<div className="w-full">
								{/* Projects table */}
								<table className=" w-full border-collapse ">
									<thead className="sticky top-0 whitespace-nowrap">
										<tr>
											<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Module</th>
											<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Type</th>
											<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Level</th>
											<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Capacity</th>
										</tr>
									</thead>
									<tbody>
										{teacher?.groups?.map((group, id) => {
											return (
												<tr
													onClick={(event) => {
														event.preventDefault()
														if (selected_group.id === group.id) {
															setSelectedGroup({});
														} else {
															setSelectedGroup({ ...group });
														}
													}}
													key={id}
													className={selected_group.id === group.id ? " bg-slate-200 hover:bg-slate-100 cursor-grab" : " bg-white hover:bg-slate-100 cursor-grab"}
												>
													<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
														<span className={"font-bold "}>{group.module}</span>
													</th>
													<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{group.type.name}</td>
													<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{group.level.year} {group.level.education} {group.level.specialty}</td>
													<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{group.students.length} / {group.capacity}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className=" flex flex-col ">
					<h6 className=" font-semibold dark:text-white">Students</h6>
					{selected_group != {} && (
						<div className="break-words w-full shadow-lg overflow-auto border-0">
							<div className="w-full">
								<table className=" w-full border-collapse ">
									<thead className="sticky top-0 whitespace-nowrap">
										<tr>
											<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Name</th>
											<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Phone Number</th>
											{selected_group?.presences?.map((_, index) => {
												return (
													<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
														<span className="font-bold">
															{moment()
																.month(_.month - 1)
																.format("MMMM")}{" "}
															/ {_.session_number}
														</span>
													</th>
												);
											})}
										</tr>
									</thead>
									<tbody>
										{selected_group?.students?.map((student, id) => {
											return (
												<tr
													onClick={(event) => {
														event.preventDefault()
														navigate("/users/" + student.user_id);
													}}
													key={id}
													className="bg-white hover:bg-slate-100 cursor-grab "
												>
													<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
														<span className={"font-bold "}>{student.user.name}</span>
													</th>
													<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{student.user?.phone_number}</td>
													{selected_group.presences?.map((session) => {
														const presenceStatus = session.students?.find((s) => s.user_id === student.user_id)?.pivot?.status || "";
														return <td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{presenceStatus}</td>;
													})}
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
