
import EmployeeDashboard from "./EmployeeDashboard";
import ProgramCalendar from "Management/Sessions/ProgramCalendar";
import User from "Management/Users/Users/User";

export function Dashboard() {
	const role = localStorage.getItem("role");
	const id = localStorage.getItem("user_id");
	return (
		<>
			{role === "admin" ? (
				<></>
			) : role === "student" ? (
				<>
					<ProgramCalendar></ProgramCalendar>
					<User userId={id}></User>
				</>
			) : role === "parent" ? (
				<>
					<ProgramCalendar></ProgramCalendar>
					<User userId={id}></User>
				</>
			) : role === "teacher" ? (
				<>
					<ProgramCalendar></ProgramCalendar>
					<User userId={id}></User>
				</>
			) : role === "employee" ? (
				<>
					<EmployeeDashboard></EmployeeDashboard>
				</>
			) : (
				""
			)}
		</>
	);
}
