import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";

export default function MyGroups({ color, title }) {
	const token = window.localStorage.getItem("token");

	const [url, setUrl] = useAtom(urlAtom);
	const role = window.localStorage.getItem("role");
	const [groups, setGroups] = useState([]);

	const navigate = useNavigate();

	const getGroups = () => {
		let options = {
			method: "get",
			url: url + "/user/groups",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setGroups(response.data);
		}).catch(() => { });
	};

	useEffect(() => {
		getGroups();
	}, []);

	return (
		<>
			<div className="break-words w-full shadow-lg overflow-auto border-0">
				<div className="w-full">
					{/* Projects table */}
					<table className=" w-full border-collapse ">
						<thead className="sticky top-0 whitespace-nowrap">
							<tr>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Module</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Capacity</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>teacher</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Level</th>
							</tr>
						</thead>
						<tbody>
							{groups.map((group, id) => {
								return (
									<tr
										onClick={(event) => {
											event.preventDefault()
											navigate("/groups/" + group.id);
										}}
										key={id}
										className=" bg-white hover:bg-slate-100 "
									>
										<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
											<span className={"font-bold "}>{group.module}</span>
										</th>
										<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
											<span className={"font-bold "}>{group.capacity}</span>
										</th>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{group.teacher.user.name}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
											{group.level.year} {group.level.education} {group.level.specialty}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
