import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "config/axios";
import Form from "./Form";
import moment from "moment";
import ProductPhotos from "Components/Carousels/ProductPhotos";
import FormModal from "Components/Dropdowns/FormModal";
import Swal from "sweetalert2";

export default function Product() {
	const token = localStorage.getItem("token");

	const navigate = useNavigate();
	const url = process.env.REACT_APP_API_URL_LIBRARY;
	const [method, setMethod] = useState("edit");
	const [form, setForm] = useState(false);
	const [product, setProduct] = useState({});
	const { id } = useParams();
	const showForm = (action, data) => {
		setMethod(action);
		setProduct(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	const destroy = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to delete this product? This action cannot be undone!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				axios({
					url: url + "/products/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
					.then((response) => {
						getProduct(); // Refresh product list
					})
					.catch(() => {});
			}
		});
	};
	const getProduct = async () => {
		axios({
			method: "get",
			url: url + "/products/" + id,
			headers: {
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setProduct(response.data);
			})
			.catch(() => {});
	};

	useEffect(() => {
		getProduct();
	}, []);

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Product Form"}>
					<Form action={method} data={product} hide={hide} getProducts={getProduct}></Form>
				</FormModal>
			) : (
				""
			)}
			<div className="w-full flex justify-between gap-4 lg:flex-row flex-col  rounded-lg bg-slate-50 shadow-md hover:shadow-2xl p-4 z-10" key={product.id}>
				<div className="lg:w-1/3 w-full">
					{" "}
					<ProductPhotos product={product} />
				</div>
				<div className=" h-auto flex-1 flex justify-between flex-col ">
					<div className="flex justify-between items-start hover:cursor-pointer my-2 gap-4">
						<Link to={"/library/buy/products/"+product.id}>
							<h4 className="text-s font-bold text-gray-800 overflow-hidden overflow-ellipsis">{product.name}</h4>
							<h4 className="text-s font-bold text-sky-600 self-start">{product.price}.00 DA</h4>
						</Link>
						<div className="flex justify-end items-start hover:cursor-pointer my-2 gap-4">
							<Link
								role={"button"}
								onClick={(event) => {
									event.stopPropagation();
									showForm("edit", product);
								}}
								className="hover:text-yellow-400 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg text-center p-2"
							>
								<i class="fa-solid fa-pen-to-square"></i>
							</Link>
							<Link
								role={"button"}
								onClick={(event) => {
									event.stopPropagation();
									destroy(product.id);
								}}
								className="hover:text-red-600 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg text-center p-2"
							>
								<i class="fa-solid fa-trash"></i>
							</Link>
						</div>
					</div>

					<div className="my-4 flex-warp flex flex-row gap-4 h-fit">
						{product.tags?.split(",").map((tag, index) => (
							<>
								<span className="w-fit rounded-lg bg-white-700 text-slate-500 border p-2">{tag.trim()}</span>
							</>
						))}
					</div>
				</div>
			</div>
		</>
	);
}
