import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { dawaratPresenceSheetsAtom, urlAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import PresenceSheet from "./PresenceSheet";
import PresenceForm from "./PresenceForm";
import FormModal from "Components/Dropdowns/FormModal";

// components

export function Attendance() {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();

	const [url, setUrl] = useAtom(urlAtom);

	const [presences, setPresences] = useState([]);
	const [form, setForm] = useState(false);
	const [ids, setIds] = useAtom(dawaratPresenceSheetsAtom);
	const [search, setSearch] = useState("");

	const getPresences = async () => {
		await axios({
			// Endpoint to send files
			url: url + "/dawarat/attendance/presence/sheets",
			method: "get",
			params: {
				ids,
				search,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then(async (response) => {
				setPresences(response.data);
			})
			.catch(() => {});
	};

	useEffect(() => {
		getPresences();
	}, [search, ids]);
	return (
		<>
			{form ? (
				<FormModal
					hide={() => {
						setForm(false);
					}}
					title={"Presence Sessions"}
				>
					<PresenceForm presences={presences} setIds={setIds} ids={ids} />
				</FormModal>
			) : (
				""
			)}

			<div className="flex flex-col gap-4 w-full ">
				<div className="flex flex-row gap-4 bg-white rounded-lg shadow p-4">
					<div className="w-1/2 flex flex-row gap-4">
						<button
							className="my-2 py-2 px-4 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150"
							onClick={(event) => {
								event.preventDefault();
								setForm(true);
							}}
						>
							<i className="fa-solid fa-add" />
						</button>

						<h4 className="font-bold ">Presence Sheets</h4>
					</div>
				</div>
				<div className="w-full flex flex-col">
					<div className="w-full md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 grid-cols-1 grid gap-4">
						{presences.map((presence) => {
							return <PresenceSheet getPresences={getPresences} presence={presence} setIds={setIds} ids={ids} />;
						})}
					</div>
				</div>
			</div>
		</>
	);
}
