import React, { useRef } from "react";

const TicketChair = ({ onClick, row, col, chairs }) => {
	const chairRef = useRef(null);
	return (
		<div
			ref={chairRef}
			onClick={() => onClick(row, col)}
			style={{
				backgroundColor: chairs[row][col].selecting ? "#f7aa02" : chairs[row][col].color,
			}}
			className={`!w-12 !h-12 border text-3xl cursor-pointer rounded flex items-center justify-center select-none ${
				chairs[row][col].selecting ? " text-white" : chairs[row][col].selected ? "" : "bg-gray-100"
			}`}
		>
			{chairs[row][col].selected ? <i className="fa-solid fa-chair" /> : ""}
		</div>
	);
};

export default TicketChair;
