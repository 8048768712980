import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProgramEditing from "../Sessions/ProgramEditing";
import SelectOption from "Components/Dropdowns/SelectOption";
import Loading from "Components/Loaders/Loading";
import imageCompression from "browser-image-compression";

// components

const DawaratForm = ({ action, data, getDawarats, hide }) => {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();
	const [url] = useAtom(urlAtom);
	const [department] = useAtom(departmentAtom);
	const [uploaded_images, setUploadedImages] = useState([]);
	const [formData, setFormData] = useState({
		annex: "",
		module: "",
		modules: [],
		type: "dawarat",
		capacity: "",
		nb_session: "",
		teacher_id: "",
		amphi_id: "",
		level: {},
		price_per_month: "",
		percentage: 0,
	});
	const [steps, setSteps] = useState("1");
	const [dawarat, setDawarat] = useState({});
	const [levels, setLevels] = useState([]);
	const [amphitheaters, setAmphitheaters] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [loading, setLoading] = useState({
		submit: false,
		images: false,
	});
	const [levelPage, setLevelPage] = useState(1);
	const [amphiPage, setAmphiPage] = useState(1);
	const [teacherPage, setTeacherPage] = useState(1);
	const [lastAmphiPage, setLastAmphiPage] = useState(1);
	const [lastLevelPage, setLastLevelPage] = useState(1);
	const [lastTeacherPage, setLastTeacherPage] = useState(1);

	const handleInputChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const compressImage = async (file) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
		};
		await imageCompression(file, options)
			.then((compressedFile) => {
				setUploadedImages((prev) => {
					return [...prev, compressedFile];
				});
			})
			.catch((error) => {});
	};
	const handleFileUpload = async (event) => {
		event.preventDefault();
		setLoading((prev) => {
			return {
				submit: true,
				images: true,
			};
		});
		const files = event.target.files;

		for (let i = 0; i < files.length; i++) {
			await compressImage(files[i]).then(() => {});
		}
		setLoading((prev) => {
			return {
				submit: false,
				images: false,
			};
		});
	};

	const handleRemoveImage = (event, index) => {
		event.preventDefault();
		const updatedImages = [...uploaded_images];
		updatedImages.splice(index, 1);
		setUploadedImages(updatedImages);
	};
	const fetchLevels = async () => {
		const response = await axios
			.get(`${url}/levels`, {
				params: {
					page: levelPage,
				},
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: `${item.education || ""} ${item.year || ""} ${item.specialty || ""}`.trim(),
		}));
		setLevels(data);
		setLastLevelPage(response?.data?.last_page);
	};
	const fetchAmphitheaters = async () => {
		const response = await axios
			.get(`${url}/amphitheaters`, {
				params: {
					page: amphiPage,
				},
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: `${item.name || ""}: ${item.location || ""}`.trim(),
		}));
		setAmphitheaters(data);
		setLastAmphiPage(response?.data?.last_page);
	};
	const fetchTeachers = async () => {
		const response = await axios
			.get(`${url}/teachers`, {
				params: { level: formData.level, page: teacherPage },

				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: item.user.name,
		}));
		setTeachers(data);
		setLastTeacherPage(response?.data?.last_page);
	};

	const addDawarat = async (event) => {
		event.preventDefault();
		setLoading(true);
		const response = await axios({
			url: action === "add" ? `${url}/dawarat` : `${url}/dawarat/${dawarat.id}`,
			method: "post",
			data: {
				capacity: formData.capacity,
				module: formData.module,
				teacher_id: formData.teacher_id,
				level_id: formData.level.id,
				amphi_id: formData.amphi?.id,
				price_per_month: formData.price_per_month,
				nb_session: formData.nb_session,
				type: formData.type,
				annex: formData.annex,
				percentage: formData.percentage,
				uploaded_images,
			},
			headers: {
				"Content-Type": "multipart/form-data",
				Accept: "Application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				toast.success(`Dawarat ${action}ed`, {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setSteps("2");
				setDawarat(response.data);
			})
			.catch(() => {})
			.finally(() => {
				setLoading((prev) => {
					return {
						submit: false,
						images: false,
					};
				});
			});
	};

	useEffect(() => {
		fetchLevels();
		fetchAmphitheaters();
	}, [amphiPage, levelPage]);

	useEffect(() => {
		if (formData.level.id) {
			fetchTeachers();
		}
	}, [formData.level, teacherPage]);

	const convertUrlToFile = async (fileUrl, filename) => {
		try {
			// Fetch the image from the URL
			const response = await axios.get(`${url}/files?url=${fileUrl}`, { responseType: "blob" });

			// Create a File object from the Blob
			const file = new File([response.data], filename, { type: response.data.type });

			return file;
		} catch (error) {
			console.error("Failed to convert URL to File:", error);
			return null;
		}
	};
	useEffect(() => {
		if (action === "edit") {
			setDawarat(data)
			handleInputChange("annex", data.annex);
			handleInputChange("level", data.level);
			handleInputChange("teacher_id", data.teacher_id);
			handleInputChange("module", data.module);
			handleInputChange("type", data.type);
			handleInputChange("nb_session", data.nb_session);
			handleInputChange("capacity", data.capacity);
			handleInputChange("price_per_month", data.price_per_month);
			handleInputChange("amphi", data.amphi);
			const initializeImages = async () => {
				const imageFiles = await Promise.all(
					data.photos.map(async (photo) => {
						const file = await convertUrlToFile(photo.url, `image-${photo.id}.png`);
						return file;
					})
				);
				setUploadedImages(imageFiles.filter((file) => file));
			};

			initializeImages();
		}
	}, []);

	return (
		<>
			{steps === "1" ? (
				<form className="p-4 w-full">
					<div className="grid grid-cols-2 w-full gap-4">
						<div className="w-full col-span-2">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="levels">
								Amphitheaters
							</label>
							<div className="my-2">
								<SelectOption
									onChange={(option) => handleInputChange("amphi", option)}
									data={amphitheaters}
									page={amphiPage}
									setPage={setAmphiPage}
									withPagination={true}
									lastPage={lastAmphiPage}
								/>
							</div>
						</div>
						<div className="flex-1">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="levels">
								Levels
							</label>
							<div className="my-2">
								<SelectOption
									onChange={(option) => handleInputChange("level", option)}
									data={levels}
									page={levelPage}
									setPage={setLevelPage}
									withPagination={true}
									lastPage={lastLevelPage}
								/>
							</div>
						</div>
						<div className="flex-1">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="teachers">
								Teachers
							</label>
							<div className="my-2">
								<SelectOption
									onChange={(option) => {
										handleInputChange("teacher_id", option.id);
										handleInputChange("percentage", option.percentage);
										handleInputChange(
											"modules",
											option.modules?.split("|").map((module) => ({ id: module, label: module }))
										);
									}}
									data={teachers}
									page={teacherPage}
									setPage={setTeacherPage}
									withPagination={true}
									lastPage={lastTeacherPage}
								/>
							</div>
						</div>
						<div className="flex-1">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="module">
								Module
							</label>
							<div className="my-2">
								<SelectOption onChange={(option) => handleInputChange("module", option.id)} data={formData.modules} />
							</div>
						</div>
						<div className="flex-1">
							<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="percentage">
								Percentage
							</label>
							<input
								type="number"
								onWheel={(event) => event.target.blur()}
								className="w-full p-2 text-slate-600 placeholder-slate-400 bg-white rounded-lg shadow focus:outline-none focus:ring"
								onChange={(event) => handleInputChange("percentage", event.target.value)}
								value={formData.percentage}
								placeholder="Enter percentage for this group"
							/>
						</div>
					</div>
					<div className="flex flex-row gap-2 w-full my-2">
						<div className="flex-1">
							<label className="block uppercase  text-slate-600 font-bold" htmlFor="nb_session">
								Number of Sessions
							</label>
							<input
								type="number"
								onWheel={(e) => e.target.blur()}
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								onChange={(event) => handleInputChange("nb_session", event.target.value)}
								value={formData.nb_session}
								placeholder="Enter the number of sessions per 1 month"
							/>
						</div>
						<div className="flex-1">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="capacity">
								Capacity
							</label>
							<input
								type="number"
								onWheel={(e) => e.target.blur()}
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								onChange={(event) => handleInputChange("capacity", event.target.value)}
								value={formData.capacity}
								placeholder="Enter the maximum capacity of the dawarat"
							/>
						</div>
						<div className="flex-1">
							<label className="block uppercase text-slate-600 font-bold" htmlFor="price_per_month">
								Price of Dawarat
							</label>
							<input
								type="number"
								onWheel={(e) => e.target.blur()}
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								onChange={(event) => handleInputChange("price_per_month", event.target.value)}
								value={formData.price_per_month}
								placeholder="Enter Dawarat's price per 1 month"
							/>
						</div>
					</div>
					<div className="my-2 ">
						<label className="block uppercase text-slate-600 font-bold ">Upload Photos</label>
						<div className="flex flex-row w-full overflow-auto gap-4 ">
							<div>
								<label className="text-slate-500 ">
									<div className="w-[200px] h-[120px] rounded-lg border-dashed border-2 border-slate-200 text-slate-500 flex justify-center items-center hover:cursor-pointer hover:border-blue-500 transition-all duration-200 ease-in-out hover:text-blue-500 hover:border-solid">
										{loading.images ? (
											<>
												{" "}
												<Loading width="20px" height={"20px"} color="black" weight={"2px"}></Loading>Uploading Image
											</>
										) : (
											<>
												<i className="fa-solid fa-add "></i>Add Image
											</>
										)}
									</div>
									<input type="file" multiple accept="image/*" onChange={handleFileUpload} className="hidden" />
								</label>
							</div>
							{uploaded_images.map((image, index) => (
								<div key={index} className="relative min-w-fit ">
									<img src={URL.createObjectURL(image)} className="w-[200px] h-[120px] object-cover rounded-lg " alt={`uploaded-image-${index}`} />
									<button
										className="absolute top-0 right-0 rounded-full bg-black bg-opacity-50 text-white !w-8 h-8 hover:text-red-600 transition-all duration-200"
										onClick={(event) => handleRemoveImage(event, index)}
									>
										<i className="fa-solid fa-close"></i>
									</button>
								</div>
							))}
						</div>
					</div>
					<div className="flex justify-center w-full my-4">
						<button
							className="text-white w-full px-4 py-2 bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
							type="button"
							onClick={addDawarat}
							disabled={loading.submit}
						>
							{loading.submit & !loading.images ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}Next
						</button>
					</div>
				</form>
			) : (
				<div className="w-full p-4">
					<div className="flex justify-center w-full my-4">
						<button
							className="w-full px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
							type="button"
							onClick={(event) => {
								event.preventDefault();
								getDawarats();
								hide();
							}}
						>
							Save
						</button>
					</div>
					<ProgramEditing defaultDawarat={dawarat} />
				</div>
			)}
		</>
	);
};

export default DawaratForm;
