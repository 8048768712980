import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import UserForm from "./UserForm";
import { toast } from "react-toastify";
import PortalWrapper from "Layouts/PortalWrapper";
import FormModal from "Components/Dropdowns/FormModal";

export default function CardUsers() {
	const token = window.localStorage.getItem("token");

	const [url, setUrl] = useAtom(urlAtom);

	const [method, setMethod] = useState("add");
	const [form, setForm] = useState(false);
	const [user, setUser] = useState({});
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [search, setSearch] = useState("");
	const [gender, setGender] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");
	const [role, setRole] = useState("");

	const getUsers = async () => {
		let options = {
			method: "get",
			url: url + "/users",
			params: {
				page: currentPage,
				sortBy,
				sortDirection,
				perPage,
				search,
				gender,
				role,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setUsers(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => {});
	};

	const showForm = (action, data) => {
		setMethod(action);
		setUser(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	const destroy = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				axios({
					url: url + "/users/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
					.then((response) => {
						toast.success("User deleted successful", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
						getUsers();
					})
					.catch(() => {});
			}
		});
	};
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	useEffect(() => {
		getUsers();
	}, [currentPage, sortBy, sortDirection, perPage, search, gender, role]);

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Level Form"} width={"50%"}>
					<UserForm action={method} data={user} hide={hide} getUsers={getUsers}></UserForm>
				</FormModal>
			) : (
				""
			)}
			<h4 className=" font-bold">Users</h4>
			<div className="flex w-full justify-between align-middle items-center gap-2">
				<div className="flex flex-row flex-nowrap overflow-auto w-3/4 gap-2 whitespace-nowrap scrollbar-hide ">
					<div className="flex flex-col my-2">
						<label>Role Filter:</label>
						<select
							value={role}
							className="my-2 p-2 bg-white rounded-lg shadow ease-linear transition-all duration-150 "
							onChange={(event) => {
								setRole(event.target.value);
							}}
						>
							<option value="">All</option>
							<option value="teacher">Teacher</option>
							<option value="student">Student</option>
							<option value="admin">Amin</option>
							<option value="sub-admin">Sub-Admin</option>
							<option value="employee">Employee</option>
						</select>
					</div>
					
				</div>
				<div className="flex flex-col my-2">
					<label>Search</label>
					<div className="flex p-2 my-2 items-center flex-row flex-nowrap bg-white rounded-lg shadow w-full ease-linear transition-all duration-150 ">
						<input
							className="focus:outline-none"
							type="text"
							value={search}
							onChange={(event) => {
								setSearch(event.target.value);
							}}
						></input>
						<i className="fa-solid fa-search"></i>
					</div>
				</div>
			</div>
			<div className="break-words w-full shadow-lg overflow-auto border-0">
				<div className="w-full">
					{/* Projects table */}
					<table className=" w-full border-collapse ">
						<thead className="sticky top-0 whitespace-nowrap">
							<tr>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("name");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Name
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("email");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Email
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("role");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Role
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("gender");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Gender
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Phone Number</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Actions</th>

								{/* <th className={" uppercase font-semibold flex justify-around transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
									<Link
										className="w-full ease-linear "
										role={"button"}
										onClick={(event) => {
											event.preventDefault();
											showForm("add");
										}}
									>
										Add
										<i class="fa-solid fa-plus "></i>
									</Link>
								</th> */}
							</tr>
						</thead>
						<tbody>
							{users.map((user, id) => {
								return (
									<tr key={id} className="bg-white hover:bg-slate-100">
										<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
											<span className={"font-bold "}>{user.name}</span>
										</th>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{user.email}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{user.role}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{user.gender}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{user.phone_number}</td>

										<td className={" whitespace-nowrap border border-slate-200 text-left items-center flex justify-evenly gap-2"}>
											<Link
												role={"button"}
												onClick={(event) => {
													event.preventDefault();
													navigate("/users/" + user.id);
												}}
												className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i className="fa-solid fa-info"></i>
											</Link>
											<Link
												role={"button"}
												onClick={(event) => {
													event.stopPropagation();

													showForm("edit", user);
												}}
												className="hover:text-yellow-400 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-pen-to-square"></i>
											</Link>
											{user.role === "employee" ? (
												<Link
													role={"button"}
													onClick={(event) => {
														event.stopPropagation();
														destroy(user.id);
													}}
													className="hover:text-red-600 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
												>
													<i class="fa-solid fa-trash"></i>
												</Link>
											) : (
												""
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="flex flex-row justify-center gap-4 my-4 ">
				<button
					className=" shadow hover:shadow-lg px-4 py-2 gap-4 flex items-center bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === 1}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					<i className="fa-solid fa-backward "></i>
					Previous
				</button>
				<button
					className=" shadow hover:shadow-lg px-4 py-2 gap-4 flex items-center bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === totalPages}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					Next
					<i className="fa-solid fa-forward "></i>
				</button>
			</div>
		</>
	);
}
