import React, { useState, useEffect, useRef } from "react";
import axios from "config/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { XAxis, YAxis, CartesianGrid, Tooltip, Bar, BarChart, ResponsiveContainer, ReferenceLine, Brush, Cell, Line, LineChart } from "recharts";

export default function Transactions() {
	const token = window.localStorage.getItem("token");
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [chart, setChart] = useState("bar");
	const [period, setPeriod] = useState("all");
	const [domain, setDomain] = useState([]);
	const [grouping, setGrouping] = useState("all");
	const [transactions, setTransactions] = useState([]);
	const [loading, setLoading] = useState({ password: false, profile: false, photo: false });
	const navigate = useNavigate();

	const getTransactions = async () => {
		let options = {
			method: "get",
			url: auth_api_url + "/transactions",
			params: { period, grouping },
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setTransactions(response.data);
			})
			.catch(() => {});
	};

	var ComponentRef = useRef();

	useEffect(() => {
		getTransactions();
	}, [grouping, period]);

	return (
		<>
			<div className="hidden">
				{/* Projects table */}
				<div className=" w-full border-collapse " ref={(el) => (ComponentRef = el)}></div>
			</div>
			<div className="flex  gap-4 flex-row">
				<h6>User transactions </h6>
				{chart === "bar" ? (
					<button
						onClick={(event) => {
							event.preventDefault();
							setChart("line");
						}}
						className="bg-white hover:bg-slate-50 rounded-lg shadow-lg px-4 py-2"
					>
						Change To Line Chart
					</button>
				) : (
					<button
						onClick={(event) => {
							event.preventDefault();
							setChart("bar");
						}}
						className="bg-white hover:bg-slate-50 rounded-lg shadow-lg px-4 py-2"
					>
						Change To Bar Chart
					</button>
				)}
			</div>
			<div className=" overflow-x-auto w-full  ">
				<div className="flex flex-nowrap  h-full  w-fit  rounded-lg  bg-white">
					<button
						onClick={(event) => {
							event.preventDefault();
							setPeriod("all");
						}}
						className="w-fit  rounded-l-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						all the time
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setPeriod("1h");
						}}
						className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						past 1 hour
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setPeriod("day");
						}}
						className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						past day
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setPeriod("week");
						}}
						className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						the last week
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setPeriod("10days");
						}}
						className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						past 10 days
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setPeriod("month");
						}}
						className="w-fit rounded-r-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						the last month
					</button>
				</div>
			</div>
			<div className="overflow-x-auto   w-full">
				<div className="flex flex-nowrap whitespace-nowrap h-full  w-fit  rounded-lg  bg-white">
					<button
						onClick={(event) => {
							event.preventDefault();
							setGrouping("all");
						}}
						className="w-fit  rounded-l-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						individually
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setGrouping("1h");
						}}
						className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						by 1 hour
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setGrouping("day");
						}}
						className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						by day
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setGrouping("week");
						}}
						className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						by week
					</button>
					<button
						onClick={(event) => {
							event.preventDefault();
							setGrouping("month");
						}}
						className="w-fit rounded-r-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
					>
						by month
					</button>
				</div>
			</div>
			<div className="w-full h-auto md:aspect-[4/2] aspect-square">
				{chart === "bar" ? (
					<ResponsiveContainer>
						<BarChart data={transactions} margin={{ top: 20, bottom: 20, right: 0, left: 0 }}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date" padding={{ top: 0, bottom: 0, right: 20, left: 20 }} />
							<YAxis domain={domain} />
							<Tooltip />
							<ReferenceLine y={0} stroke="#000" />
							<Brush dataKey="name" height={30} stroke="#3483eb" />
							<Bar dataKey="total">
								{transactions.map((transaction, entry, index) => (
									<Cell key={`cell-${index}`} fill={transaction.total > 0 ? "#3483eb" : "#f71111"} />
								))}
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				) : (
					<ResponsiveContainer>
						<LineChart data={transactions} margin={{ top: 20, bottom: 20, right: 0, left: 0 }}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date" padding={{ top: 0, bottom: 0, right: 20, left: 20 }} />
							<YAxis domain={domain} />
							<Tooltip />
							<ReferenceLine y={0} stroke="#000" />
							<Brush dataKey="name" height={30} stroke="#3483eb" />
							<Line type="monotone" dataKey="total" stroke="#06c416" strokeWidth={3} activeDot={{ r: 8 }} />
						</LineChart>
					</ResponsiveContainer>
				)}
			</div>
		</>
	);
}
