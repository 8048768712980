import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Barcode from "react-barcode";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import Loading from "Components/Loaders/Loading";

export default function Fees({ student, hide }) {
	const [department, setDepartment] = useAtom(departmentAtom);

	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();
	const user_id = localStorage.getItem("user_id");

	const [url, setUrl] = useAtom(urlAtom);
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState(500);

	var ReceiptRef = useRef();

	const pay_inscription_fee = () => {
		setLoading(true);

		axios({
			url: url + "/inscription_fees/pay",
			method: "post",
			data: {
				total: amount,
				type: "inscription fee",
				amount: amount,
				date: moment().format("YYYY-MM-DD"),
				student_id: student.id,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {}, []);
	return (
		<div className="flex-auto h-fit ">
			<div className="block w-full overflow-x-auto">
				<div className="flex flex-row items-center justify-between ">
					<h6 className="text-slate-400 font-bold uppercase">Inscription Fee</h6>
					<div className="flex justify-center flex-row gap-4 my-4">
						<ReactToPrint
							trigger={() => {
								return (
									<button
										className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
										type="button"
										disabled={loading}
									>
										{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
										Pay
									</button>
								);
							}}
							onAfterPrint={() => {
								pay_inscription_fee();
								hide();
								toast.success("Inscription fee paid successful", {
									position: "top-center",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "light",
								});
							}}
							content={() => ReceiptRef}
						/>
					</div>
					<Link
						className=" text-slate-400 ease-linear transition-all duration-150 hover:text-slate-700 font-bold uppercase"
						role={"button"}
						onClick={(event) => {
							event.preventDefault();
							hide();
						}}
					>
						<>
							<i class="fa-solid fa-close"></i> Cancel
						</>
					</Link>
				</div>
				<div className="w-full ">
					<div className=" w-full ">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Amount
						</label>
						<input
							type="number"
							onWheel={(event) => event.target.blur()}
							className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Amount"
							onChange={(event) => {
								setAmount(event.target.value);
							}}
							value={amount}
						/>
					</div>
				</div>
				<div className="flex w-full overflow-auto justify-center items-center">
					<div className="border border-black flex flex-col bg-white w-full p-2" ref={(el) => (ReceiptRef = el)}>
						<div className="flex flex-row justify-between items-center">
							<img className="w-32 h-32" src={require("assets/img/Numidia Education Group Logo/png/black.png")}></img>
							<div className="flex flex-col items-center">
								<p className=" font-bold ">Inscription Fee Receipt</p>
								<span className="">{moment().format("MMMM Do YYYY, h:mm")}</span>
							</div>

							{department === "school" ? (
								<img className="w-32 h-32" src={require("assets/img/Numidia School Logo/png/black.png")}></img>
							) : department === "profession" ? (
								<img className="w-32 h-32" src={require("assets/img/Numidia professional instiute Logo/png/black.png")}></img>
							) : department === "academy" ? (
								<img className="w-32 h-32" src={require("assets/img/Numidia Academy Logo/png/black.png")}></img>
							) : (
								""
							)}
						</div>

						<div className="flex flex-row items-center gap-4">
							<label>Student</label>
							<span>{student.user.name}</span>
						</div>
						<div className="flex flex-row items-center gap-4">
							<label>Level</label>
							<span>
								{student.level.year} {student.level.education} {student.level.specialty}
							</span>
						</div>
						<hr className=" "></hr>
						<div className="flex flex-row items-center justify-end gap-4 font-bold ">
							<label>Total:</label>
							<span>{amount}.00 DA</span>
						</div>

						<div className="flex flex-row justify-center items-center">
							<Barcode fontSize={18} text={process.env.REACT_APP_URL} height={30} width={1} value={process.env.REACT_APP_URL} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
