import React, { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { Link, useBlocker, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import momentDurationFormatSetup from "moment-duration-format";
import Swal from "sweetalert2";
import warningSound from "assets/audio/warning.wav";
import Loading from "Components/Loaders/Loading";

export default function TakeExam() {
	const [url, setUrl] = useAtom(urlAtom);
	const [maxTabSwitch, setMaxTabSwitch] = useState(5);
	const token = window.localStorage.getItem("token");
	const [exam, setExam] = useState({});
	const [viewAll, setView] = useState(false);
	const [examLoaded, setExamLoaded] = useState(false);
	const [blocked, setBlocked] = useState(false);
	const [current_question, setCurrentQuestion] = useState(0);
	const [answers, setAnswers] = useState([]);
	const [remainingTime, setRemainingTime] = useState(0);
	const [loading, setLoading] = useState(false);

	const { exam_id } = useParams();

	const navigate = useNavigate();

	let shouldBlock = useCallback(({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname, []);
	let blocker = useBlocker(shouldBlock);

	const getExam = () => {
		axios({
			// Endpoint to send files
			url: url + "/exams/" + exam_id,
			method: "get",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				setRemainingTime(response?.data?.duration * 60);
				let data = response.data;

				setExam(data);
				setExamLoaded(true);
				setBlocked(true);
				var index = -1;
				let temp = Array.from(
					{
						length: parseInt(response?.data?.nb_question, 10),
					},
					() => {
						index++;
						return {
							answer: "",
							question_id: response?.data?.questions[index]?.id,
							score: 0,
						};
					}
				);
				setAnswers(temp);
			})
			.catch(() => {});
	};
	const submit = async () => {
		setLoading(true);
		await axios({
			// Endpoint to send files
			url: url + "/exams/" + exam_id + "/student-answers",
			method: "post",
			data: {
				answers,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Exam Finished", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				navigate("/exams");
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const next = () => {
		setCurrentQuestion((prev) => (prev < exam.nb_question ? prev + 1 : prev));
	};
	const prev = () => {
		setCurrentQuestion((prev) => (prev > 0 ? prev - 1 : prev));
	};

	useEffect(() => {
		momentDurationFormatSetup(moment);
		getExam();
	}, []);

	useEffect(() => {
		if (blocker.state === "blocked" && blocked) {
			Swal.fire({
				text: "You Cannot take this exam again. Are you sure you want to leave?",
				title: "",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, leave",
				cancelButtonText: "Cancel",
			}).then(async (result) => {
				if (result.isConfirmed) {
					blocker.proceed?.();
				} else {
					await submit();
					blocker.reset?.();
				}
			});
		} else if (blocker.state === "blocked") {
			blocker.proceed?.();
		}
	}, [blocker]);

	useEffect(() => {
		const handleVisibilityChange = async () => {
			if (document.visibilityState === "hidden") {
				if (maxTabSwitch === 0) {
					await submit();
					setBlocked(false);
					document.title = "Numidia";
					Swal.close();
					navigate("/exams");
					return;
				}
				setMaxTabSwitch((prev) => prev - 1);
				document.title = "⚠️ Attention: Exam in Progress";
				var audioContext = new (window.AudioContext || window.webkitAudioContext)();
				var audioElement = new Audio(warningSound);
				audioElement.addEventListener("error", (e) => {
					console.error("Error playing audio:", e);
				});
				audioElement.addEventListener("canplaythrough", () => {
					const audioSource = audioContext.createMediaElementSource(audioElement);
					audioSource.connect(audioContext.destination);
					audioElement.play().catch(() => {
						toast.error("Audio error, please click to grant audio permission", {
							position: "top-center",
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							autoClose: false,
						});
					});
				});
				Swal.fire({
					text: "Switching tabs during the exam is not allowed. Please focus on the exam. After few times it will cause to submitting the exam automatically ",
					title: "",
					icon: "warning",
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
				});
			} else {
				document.title = "Numidia";
			}
		};

		const handleBeforeUnload = (event) => {
			const message = "Are you sure you want to leave? Changes may not be saved.";
			event.returnValue = message;
			return message;
		};

		const preventDefault = (e) => {
			e.preventDefault();
		};
		if (examLoaded) {
			document.addEventListener("copy", preventDefault);
			document.addEventListener("paste", preventDefault);
			document.addEventListener("contextmenu", preventDefault);
			document.addEventListener("visibilitychange", handleVisibilityChange);
			window.addEventListener("beforeunload", handleBeforeUnload);

			return () => {
				// Cleanup: Remove event listeners when the component is unmounted
				document.removeEventListener("copy", preventDefault);
				document.removeEventListener("paste", preventDefault);
				document.removeEventListener("contextmenu", preventDefault);
				document.removeEventListener("visibilitychange", handleVisibilityChange);
				window.removeEventListener("beforeunload", handleBeforeUnload);
			};
		}
	}, [maxTabSwitch, examLoaded]);

	useEffect(() => {
		const countdown = setInterval(() => {
			setRemainingTime((prevTime) => {
				if (exam.duration > 0) {
					if (prevTime <= 0) {
						submit();
						clearInterval(countdown);
						return 0;
					}
					return prevTime - 1;
				}
			});
		}, 1000);

		return () => clearInterval(countdown);
	}, [exam]);

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-row justify-between items-center">
				<button
					onClick={(event) => {
						event.stopPropagation();
						navigate("/exams");
					}}
					className="text-white bg-sky-500 hover:bg-sky-600 rounded-lg  px-4 py-2 flex items-center gap-2"
				>
					<i className="fa-solid fa-arrow-left "></i>Return to Exams
				</button>
				{remainingTime > 0 ? <h4 className="font-light">{moment.duration(remainingTime, "seconds").format("h:mm:ss")}</h4> : ""}

				<h4 className=" font-bold">
					{exam.title}{" "}
					{viewAll ? (
						<i
							onClick={(event) => {
								event.preventDefault();
								setView(false);
							}}
							data-title="click to see one question at time"
							className="fa-solid fa-file text-xl hover:bg-sky-600 hover:text-white cursor-pointer p-2 rounded-lg transition-all duration-150 ease-linear"
						></i>
					) : (
						<i
							onClick={(event) => {
								event.preventDefault();
								setView(true);
							}}
							data-title="click to see all questions"
							className="fa-solid fa-layer-group text-xl hover:bg-sky-600 hover:text-white cursor-pointer p-2 rounded-lg transition-all duration-150 ease-linear"
						></i>
					)}
				</h4>
			</div>{" "}
			<div className="flex-auto">
				<form>
					<div className="flex flex-col w-full gap-4 my-4">
						{exam.questions?.map((question, index) => {
							return (
								<div className={viewAll || current_question === index ? "w-full flex flex-col gap-4 bg-white shadow-lg rounded-lg  p-4" : "hidden"}>
									<h5 className=" font-bold">Question {index + 1}</h5>
									<div className="w-full text-lg capitalize">{question.content}</div>
									{question.audio ? (
										<div className="w-full">
											<audio controls className="w-full" src={`${url}/files?url=${question.audio.url}`}>
												Your browser does not support the audio element.
											</audio>
										</div>
									) : (
										""
									)}
									<label className="block underline uppercase text-slate-600  font-bold" htmlFor="grid-password">
										Choices :
									</label>
									{question.choices?.map((choice, id) => {
										return (
											<div className="flex flex-row gap-4 items-center capitalize px-4">
												<input
													type="radio"
													value={choice.content}
													onChange={(event) => {
														if (event.target.checked) {
															const newAnswers = [...answers];
															newAnswers[index] = {
																answer: id + 1,
																question_id: question.id,
																score: question.answer == id + 1 ? 1 : 0,
															};
															setAnswers(newAnswers);
														}
													}}
													className=" scale-150 cursor-pointer"
													checked={answers[index]?.answer == id + 1}
												/>
												<h6 className="font-light">{choice.content}</h6>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>

					<div className="flex justify-center gap-4 items-center">
						{!viewAll && current_question > 0 ? (
							<button
								className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
								type="button"
								onClick={() => prev()}
							>
								Previous
							</button>
						) : (
							""
						)}
						{!viewAll && current_question < exam.nb_question - 1 ? (
							<button
								className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
								type="button"
								onClick={() => next()}
							>
								Next
							</button>
						) : (
							""
						)}
						{viewAll || current_question === exam.nb_question - 1 ? (
							<button
								className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
								type="button"
								onClick={() => submit()}
								disabled={loading}
							>
								{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
								Finish
							</button>
						) : (
							""
						)}
					</div>
				</form>
			</div>
		</div>
	);
}
