import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NumidiaProgram from "Components/Carousels/NumidiaProgram";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Footer from "Components/Footers/Footer.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const teachers = [
	{
		name: "John Doe",
		image: "../../assets/img/pepole.jpeg",
		details: "PhD in Physics, 10 years of experience",
		groups: [
			{
				type: "special",
				level: "2nd year high school",
				module: "Math",
				sessions: [
					{ day: "Monday", date: "2024-08-05", startTime: "10:00", endTime: "11:00" },
					{ day: "Monday", date: "2024-08-12", startTime: "10:00", endTime: "11:00" },
					{ day: "Monday", date: "2024-08-19", startTime: "10:00", endTime: "11:00" },
					{ day: "Monday", date: "2024-08-26", startTime: "10:00", endTime: "11:00" },
				],
				remark: "open",
			},
			{
				type: "normal",
				level: "3rd year high school",
				module: "Physics",
				sessions: [
					{ day: "Tuesday", date: "2024-08-06", startTime: "09:00", endTime: "10:00" },
					{ day: "Tuesday", date: "2024-08-13", startTime: "09:00", endTime: "10:00" },
					{ day: "Tuesday", date: "2024-08-20", startTime: "09:00", endTime: "10:00" },
					{ day: "Tuesday", date: "2024-08-27", startTime: "09:00", endTime: "10:00" },
				],
				remark: "closed",
			},
			{
				type: "individual",
				level: "1st year high school",
				module: "Chemistry",
				sessions: [
					{ day: "Friday", date: "2024-08-09", startTime: "12:00", endTime: "13:00" },
					{ day: "Friday", date: "2024-08-16", startTime: "12:00", endTime: "13:00" },
					{ day: "Friday", date: "2024-08-23", startTime: "12:00", endTime: "13:00" },
					{ day: "Friday", date: "2024-08-30", startTime: "12:00", endTime: "13:00" },
				],
				remark: "open",
			},
			{
				type: "special",
				level: "2nd year high school",
				module: "Arabic",
				sessions: [
					{ day: "Wednesday", date: "2024-08-07", startTime: "14:00", endTime: "15:00" },
					{ day: "Wednesday", date: "2024-08-14", startTime: "14:00", endTime: "15:00" },
					{ day: "Wednesday", date: "2024-08-21", startTime: "14:00", endTime: "15:00" },
					{ day: "Wednesday", date: "2024-08-28", startTime: "14:00", endTime: "15:00" },
				],
				remark: "open",
			},
		],
	},
	{
		name: "Jane Smith",
		image: "../../assets/img/pepole.jpeg",
		details: "MSc in Biology, 5 years of experience",
		groups: [
			{
				type: "normal",
				level: "1st year middle school",
				module: "Biology",
				sessions: [
					{ day: "Monday", date: "2024-08-05", startTime: "09:00", endTime: "10:00" },
					{ day: "Monday", date: "2024-08-12", startTime: "09:00", endTime: "10:00" },
					{ day: "Monday", date: "2024-08-19", startTime: "09:00", endTime: "10:00" },
					{ day: "Monday", date: "2024-08-26", startTime: "09:00", endTime: "10:00" },
				],
				remark: "open",
			},
			{
				type: "special",
				level: "2nd year middle school",
				module: "Math",
				sessions: [
					{ day: "Tuesday", date: "2024-08-06", startTime: "10:00", endTime: "11:00" },
					{ day: "Tuesday", date: "2024-08-13", startTime: "10:00", endTime: "11:00" },
					{ day: "Tuesday", date: "2024-08-20", startTime: "10:00", endTime: "11:00" },
					{ day: "Tuesday", date: "2024-08-27", startTime: "10:00", endTime: "11:00" },
				],
				remark: "closed",
			},
		],
	},
];

// Extract unique levels
const uniqueLevels = [...new Set(teachers.flatMap((teacher) => teacher.groups.map((group) => group.level)))];

// Extract unique dates and sort them
const uniqueDates = [...new Set(teachers.flatMap((teacher) => teacher.groups.flatMap((group) => group.sessions.map((session) => session.date))))].sort((a, b) => new Date(a) - new Date(b));

export default function Program() {
	const { t } = useTranslation();
	const [selectedLevel, setSelectedLevel] = useState(uniqueLevels[0]);
	const [teacherSearch, setTeacherSearch] = useState("");
	const [selectedDate, setSelectedDate] = useState(null);
	const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

	// Filter teachers based on the selected level and search term
	const filteredTeachers = teachers.filter((teacher) => teacher.groups.some((group) => group.level === selectedLevel) && teacher.name.toLowerCase().includes(teacherSearch.toLowerCase()));

	return (
		<div className="flex justify-center items-center flex-col w-full">
			<IndexNavbar
				className={"z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg text-white bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] font-poppins  px-12 py-4"}
			/>
			<section className="w-full">
				<NumidiaProgram fade={"fade-left"} t={t} />
			</section>
			<main className="w-full bg-white mb-96">
				<div className="sticky top-0 w-full flex flex-col z-20">
					<div className="flex flex-row w-full items-center justify-between bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] gap-4 px-24 py-4 h-fit">
						<div className="flex items-center space-x-4 flex-1 justify-end relative">
							<div className="relative flex items-center max-w-lg flex-1">
								<input
									type="text"
									placeholder="Search by teacher name"
									className="border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1"
									value={teacherSearch}
									onChange={(e) => setTeacherSearch(e.target.value)}
								/>
							</div>
							<div className="relative flex items-center max-w-lg flex-1">
								<select
									className="border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1"
									value={selectedLevel}
									onChange={(e) => setSelectedLevel(e.target.value)}
								>
									<option value="" disabled>
										Select level
									</option>
									{uniqueLevels.map((level) => (
										<option key={level} value={level}>
											{level}
										</option>
									))}
								</select>
							</div>
							<div className="relative flex items-center max-w-lg flex-1">
								<button className="border border-gray-300 rounded-full p-3 focus:outline-none focus:border-blue-500 flex-1 bg-white " onClick={() => setIsDatePickerVisible(true)}>
									{selectedDate ? selectedDate.toDateString() : "Select date"}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col items-center p-8 w-full">
					{filteredTeachers.map((teacher) => (
						<div key={teacher.name} className="border border-gray-300 rounded-lg p-4 mb-4 w-full max-w-4xl">
							<div className="flex">
								<img src={require("assets/img/pepole.jpeg")} alt={teacher.name} className="w-48 mr-4 rounded-lg" />
								<div>
									<h2 className="text-xl font-semibold">{teacher.name}</h2>
									<p>{teacher.details}</p>
								</div>
							</div>
							<div className="mt-4">
								{teacher.groups
									.filter((group) => group.level === selectedLevel)
									.map((group, index) => (
										<div key={index} className="mt-2 p-2 border-t">
											<h3 className="font-semibold">
												Group {index + 1}: {group.type}
											</h3>
											<p className="font-semibold">
												Module: <span className="font-normal">{group.module}</span>
											</p>
											<p className="font-semibold">Sessions:</p>
											<table className="table-auto w-full">
												<thead>
													<tr>
														<th className="px-4 py-2">Day</th>
														<th className="px-4 py-2">Date</th>
														<th className="px-4 py-2">Start Time</th>
														<th className="px-4 py-2">End Time</th>
													</tr>
												</thead>
												<tbody>
													{group.sessions.map((session, i) => (
														<tr key={i} className={session.date === selectedDate?.toISOString().split("T")[0] ? "bg-[rgb(126,181,230)]" : ""}>
															<td className="border px-4 py-2">{session.day}</td>
															<td className="border px-4 py-2">{session.date}</td>
															<td className="border px-4 py-2">{session.startTime}</td>
															<td className="border px-4 py-2">{session.endTime}</td>
														</tr>
													))}
												</tbody>
											</table>
											<p className="mt-2 font-semibold">
												Remark: <span className="font-normal">{group.remark}</span>
											</p>
										</div>
									))}
							</div>
						</div>
					))}
				</div>
			</main>

			{isDatePickerVisible && (
				<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={() => setIsDatePickerVisible(false)}>
					<div className="bg-white p-4 rounded-lg" onClick={(e) => e.stopPropagation()}>
						<DatePicker
							selected={selectedDate}
							onChange={(date) => {
								setSelectedDate(date);
								setIsDatePickerVisible(false);
							}}
							inline
							className="date-picker"
						/>
					</div>
				</div>
			)}

			<Footer />
		</div>
	);
}
