import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Translate = () => {
	const [openOptions, setOpenOptions] = useState(false);
	const { t, i18n } = useTranslation();

	const translate = (language) => {
		if (language === "ar") {
			i18n.changeLanguage("ar");
		} else if (language === "en") {
			i18n.changeLanguage("en");
		} else if (language === "fr") {
			i18n.changeLanguage("fr");
		}
		setOpenOptions(false);
	};

	useEffect(() => { }, []);
	return (
		<div className="relative flex flex-col w-fit">
			<Link
				onClick={() => setOpenOptions(!openOptions)}
				className={
					"flex gap-2 w-full px-4 py-2  hover:bg-white hover:text-[rgb(1,42,74)] text-lg rounded-lg transition-all duration-100 ease-linear whitespace-nowrap bg-transparent items-center"
				}
			>
				{" "}
				{t("language")} {openOptions ? <i className=" fa-solid fa-caret-up text-base " /> : <i className=" fa-solid fa-caret-down text-base " />}
			</Link>
			{openOptions ? (
				<div className="flex flex-col bg-white rounded-lg shadow-lg absolute right-0 top-full z-50 text-slate-900 gap-4 p-4 m-2">
					<a onClick={() => translate("ar")} data-title="Arabic" className="flex flex-row gap-2 items-center hover:cursor-pointer hover:bg-slate-100 ">
						اللغة العربية
					</a>
					<a onClick={() => translate("en")} className="flex flex-row gap-2 items-center hover:cursor-pointer hover:bg-slate-100 " data-title="English">
						English
					</a>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default Translate;
