import React, { useState, useEffect, useRef } from "react";
import axios from "config/axios";
import Swal from "sweetalert2";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import Student from "./Student";
import Teacher from "./Teacher";
import Parent from "./Parent";
import NumidiaReceipt from "../../../Components/Receipts/NumidiaReceipt";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";
import moment from "moment";
import UserPrint from "./UserPrint";
import PortalWrapper from "Layouts/PortalWrapper";
import { XAxis, YAxis, CartesianGrid, Tooltip, Bar, BarChart, ResponsiveContainer, ReferenceLine, Brush, Cell, Line, LineChart } from "recharts";
import FormModal from "Components/Dropdowns/FormModal";
import Fees from "./Fees";
import Loading from "Components/Loaders/Loading";

export default function User({ userId }) {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [department, setDepartment] = useAtom(departmentAtom);
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [user, setUser] = useState({});
	const [receipt, setReceipt] = useState({});
	const [name, setName] = useState("");
	const [phone_number, setPhoneNumber] = useState();
	const [gender, setGender] = useState("male");
	const [option, setOption] = useState("");
	const [info, setInfo] = useState("none");
	const [chart, setChart] = useState("bar");
	const [period, setPeriod] = useState("all");
	const [domain, setDomain] = useState([]);
	const [grouping, setGrouping] = useState("all");
	const [transactions, setTransactions] = useState([]);
	const [loading, setLoading] = useState({ password: false, profile: false, photo: false });

	const { id } = useParams();
	const user_id = id || userId;

	const navigate = useNavigate();
	const getUser = async () => {
		let options = {
			method: "get",
			url: url + "/users/" + user_id,
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setUser(response.data);
			})
			.catch(() => {});
	};
	const update = async (event) => {
		event.preventDefault();
		setLoading((prev) => {
			return { ...prev, profile: true };
		});
		let options = {
			method: "put",
			url: url + "/profile",
			params: {
				name,
				phone_number,
				gender,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				getUser();
			})
			.catch(() => {})
			.finally(() => {
				setLoading((prev) => {
					return { ...prev, profile: false };
				});
			});
	};
	const change_photo = async (photo) => {
		setLoading((prev) => {
			return { ...prev, photo: true };
		});
		let options = {
			method: "post",
			url: auth_api_url + "/picture/change",
			data: {
				profile_picture: photo,
			},
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		};
		axios(options)
			.then((response) => {
				getUser();
			})
			.catch(() => {})
			.finally(() => {
				setLoading((prev) => {
					return { ...prev, photo: false };
				});
			});
	};
	const getTransactions = async () => {
		let options = {
			method: "get",
			url: auth_api_url + "/transactions/" + user_id,
			params: { period, grouping },
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				const totalValues = response?.data?.map((transaction) => parseInt(transaction.total));
				const minValue = Math.min(...totalValues);
				const maxValue = Math.max(...totalValues);
				setDomain([minValue - 100, maxValue + 100]);
				setTransactions(response.data);
			})
			.catch(() => {});
	};
	var ComponentRef = useRef();
	var UserRef = useRef();

	useEffect(() => {
		getUser();
	}, []);
	useEffect(() => {
		getTransactions();
	}, [grouping, period]);
	return (
		<>
			{info === "fees" ? (
				<FormModal
					hide={() => {
						getUser();
						setInfo("none");
					}}
				>
					<Fees
						student={user.student}
						hide={() => {
							getUser();
							setInfo("none");
						}}
					/>
				</FormModal>
			) : (
				""
			)}
			{option != "" ? (
				<FormModal
					hide={() => {
						getUser();
						setOption("");
					}}
				>
					<NumidiaReceipt
						user={user}
						hide={() => {
							getUser();
							setOption("");
						}}
						option={option}
					></NumidiaReceipt>
				</FormModal>
			) : (
				""
			)}
			<div className="hidden">
				{receipt && (
					<div className="border p-2 border-black flex flex-col bg-white w-full " ref={(el) => (ComponentRef = el)}>
						<div className="flex flex-row justify-between items-center">
							<img className="w-32 h-32" src={require("assets/img/Numidia Education Group Logo/png/black.png")}></img>
							<div className="flex flex-col items-center">
								<h5 className=" font-bold capitalize">{receipt.type} Receipt</h5>
								<span className="">{moment().format("MMMM Do YYYY, h:mm")}</span>
							</div>

							{department === "school" ? (
								<img className="w-32 h-32" src={require("assets/img/Numidia School Logo/png/black.png")}></img>
							) : department === "profession" ? (
								<img className="w-32 h-32" src={require("assets/img/Numidia professional instiute Logo/png/black.png")}></img>
							) : department === "academy" ? (
								<img className="w-32 h-32" src={require("assets/img/Numidia Academy Logo/png/black.png")}></img>
							) : (
								""
							)}
						</div>
						<div className=" ">
							<div className="flex flex-row items-center gap-4">
								<label>Student</label>
								<span>{user.name}</span>
							</div>
							<div className="flex flex-row items-center gap-4">
								<label>Level</label>
								<span>
									{user.student?.level?.year} {user.student?.level?.education} {user.student?.level?.specialty}
								</span>
							</div>
						</div>
						{receipt.services?.map((checkout) => {
							return (
								<div className="flex p-2 flex-row items-center justify-between">
									<span className=" w-2/3">{checkout.text}</span>
									<label className=" whitespace-nowrap">
										{checkout.price}
										.00 DA
									</label>
								</div>
							);
						})}
						<hr className=" "></hr>
						<div className="flex flex-row items-center justify-end font-bold gap-4">
							<label>Total:</label>
							<span>{receipt.total}.00 DA</span>
						</div>

						<div className="flex flex-row justify-center items-center">
							<Barcode fontSize={18} text={process.env.REACT_APP_URL} height={30} width={1} value={process.env.REACT_APP_URL} />
						</div>
					</div>
				)}
			</div>

			<div className="flex flex-col w-full gap-4 ">
				<div className="flex gap-4 md:flex-row flex-col">
					<div className="flex flex-col md:w-1/2 w-full gap-4 ">
						<div className="flex md:flex-row flex-col-reverse p-4 bg-white shadow-lg rounded-lg hover:bg-slate-100 justify-between gap-4 w-full ">
							<div className="flex flex-row gap-4">
								<div className="relative w-32 aspect-square">
									<img className="w-full aspect-square rounded-lg shadow object-cover cursor-pointer" src={`${auth_api_url}/files?url=${user?.profile_picture?.url}`}></img>
									<div className="w-full aspect-square rounded-lg bg-black bg-opacity-60 z-10 absolute top-0 flex items-center justify-center">
										{loading.photo ? <Loading width="40px" height={"40px"} color="white" weight={"4px"}></Loading> : <i className="fa-solid fa-camera text-white"></i>}
									</div>
									<input
										type="file"
										accept="image/*"
										onChange={(event) => {
											change_photo(event.target.files[0]);
										}}
										disabled={loading.photo}
										className=" opacity-0 w-full aspect-square absolute top-0 cursor-pointer z-20"
									/>
								</div>

								<div className="flex flex-col font-light text-slate-500 ">
									<h6 className=" text-slate-900 font-medium font-mono capitalize">
										{user.name}
										{!userId && (
											<ReactToPrint
												trigger={() => {
													return (
														<button className="ml-4 border px-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150">
															<i className="fa-solid fa-print" />
														</button>
													);
												}}
												onAfterPrint={() => {
													toast.success("Printing completed successful", {
														position: "top-center",
														autoClose: 3000,
														hideProgressBar: true,
														closeOnClick: true,
														pauseOnHover: true,
														draggable: true,
														progress: undefined,
														theme: "light",
													});
												}}
												content={() => UserRef}
											/>
										)}
									</h6>
									<p>
										{user.email} / {user.phone_number}
									</p>
									<p className="capitalize">{user.role}</p>
									{user.role === "student" ? (
										<p>
											{user.student.level.year} {user.student.level.education} {user.student.level.specialty}
										</p>
									) : (
										""
									)}
								</div>
							</div>

							<div className="md:w-32 aspect-square w-full ">{user.id ? <QRCode value={user.id} style={{ height: "100%", width: "100%" }} /> : ""}</div>
						</div>
						<div className="flex p-4 bg-white shadow-lg rounded-lg  gap-4 w-full h-auto flex-col">
							<div className="flex  gap-4 flex-row">
								<h6>User transactions </h6>
								{chart === "bar" ? (
									<button
										onClick={(event) => {
											event.preventDefault();
											setChart("line");
										}}
										className="bg-white hover:bg-slate-50 rounded-lg shadow-lg px-4 py-2"
									>
										Change To Line Chart
									</button>
								) : (
									<button
										onClick={(event) => {
											event.preventDefault();
											setChart("bar");
										}}
										className="bg-white hover:bg-slate-50 rounded-lg shadow-lg px-4 py-2"
									>
										Change To Bar Chart
									</button>
								)}
							</div>
							<div className=" overflow-x-auto w-full  ">
								<div className="flex flex-nowrap  h-full  w-fit  rounded-lg  bg-white">
									<button
										onClick={(event) => {
											event.preventDefault();
											setPeriod("all");
										}}
										className="w-fit  rounded-l-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										all the time
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setPeriod("1h");
										}}
										className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										past 1 hour
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setPeriod("day");
										}}
										className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										past day
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setPeriod("week");
										}}
										className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										the last week
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setPeriod("10days");
										}}
										className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										past 10 days
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setPeriod("month");
										}}
										className="w-fit rounded-r-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										the last month
									</button>
								</div>
							</div>
							<div className="overflow-x-auto   w-full">
								<div className="flex flex-nowrap whitespace-nowrap h-full  w-fit  rounded-lg  bg-white">
									<button
										onClick={(event) => {
											event.preventDefault();
											setGrouping("all");
										}}
										className="w-fit  rounded-l-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										individually
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setGrouping("1h");
										}}
										className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										by 1 hour
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setGrouping("day");
										}}
										className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										by day
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setGrouping("week");
										}}
										className="w-fit hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										by week
									</button>
									<button
										onClick={(event) => {
											event.preventDefault();
											setGrouping("month");
										}}
										className="w-fit rounded-r-lg hover:bg-slate-50 border  px-4 py-2 capitalize"
									>
										by month
									</button>
								</div>
							</div>
							<div className="w-full h-auto md:aspect-[4/3] aspect-square">
								{chart === "bar" ? (
									<ResponsiveContainer>
										<BarChart data={transactions} margin={{ top: 20, bottom: 20, right: 0, left: 0 }}>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="date" padding={{ top: 0, bottom: 0, right: 20, left: 20 }} />
											<YAxis domain={domain} />
											<Tooltip />
											<ReferenceLine y={0} stroke="#000" />
											<Brush dataKey="name" height={30} stroke="#3483eb" />
											<Bar dataKey="total">
												{transactions.map((transaction, entry, index) => (
													<Cell key={`cell-${index}`} fill={transaction.total > 0 ? "#3483eb" : "#f71111"} />
												))}
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								) : (
									<ResponsiveContainer>
										<LineChart data={transactions} margin={{ top: 20, bottom: 20, right: 0, left: 0 }}>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="date" padding={{ top: 0, bottom: 0, right: 20, left: 20 }} />
											<YAxis domain={domain} />
											<Tooltip />
											<ReferenceLine y={0} stroke="#000" />
											<Brush dataKey="name" height={30} stroke="#3483eb" />
											<Line type="monotone" dataKey="total" stroke="#06c416" strokeWidth={3} activeDot={{ r: 8 }} />
										</LineChart>
									</ResponsiveContainer>
								)}
							</div>
						</div>
					</div>
					<div className="flex flex-col flex-1 gap-4">
						<div className="flex flex-row w-full justify-between ">
							<div
								className={
									user.wallet?.balance >= 0
										? "flex flex-row flex-auto p-4 bg-green-100 shadow-lg rounded-lg gap-4 w-full justify-between items-center"
										: "flex flex-row flex-auto p-4 bg-red-100 shadow-lg rounded-lg gap-4 w-full justify-between items-center"
								}
							>
								<div>
									<h6 className=" ">Your Balance:</h6>
									<h6 className="font-play font-bold">{user.wallet?.balance}.00 DA</h6>
								</div>
								{!userId && (
									<div className="flex flex-col w-fit gap-2">
										<button
											onClick={(event) => {
												event.preventDefault();
												setOption("deposit");
											}}
											className="bg-white px-4 py-2 shadow-lg rounded-lg hover:bg-slate-100 capitalize"
										>
											deposit
										</button>
										<button
											onClick={(event) => {
												event.preventDefault();
												setOption("withdraw");
											}}
											className="bg-white  px-4 py-2 shadow-lg rounded-lg hover:bg-slate-100 capitalize"
										>
											withdraw
										</button>
									</div>
								)}
							</div>
						</div>
						{user.role === "student" && !userId ? (
							<div className="flex flex-row h-fit justify-between ">
								<div
									className={
										user.student.fee_inscription?.paid
											? "p-4 bg-white flex flex-col shadow-lg rounded-lg gap-4 w-full justify-between"
											: "p-4 bg-red-100 flex flex-col shadow-lg rounded-lg gap-4 w-full justify-between"
									}
								>
									<h6 className=" w-full flex justify-between items-center">
										Fee Inscription:{" "}
										{!user.student.fee_inscription?.paid ? (
											<Link
												data-title="Pay the inscription fee"
												role={"button"}
												onClick={(event) => {
													event.stopPropagation();
													setInfo("fees");
												}}
												className="justify-center hover:text-green-500 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 flex items-center"
											>
												<i class="fa-solid fa-check"></i>
											</Link>
										) : (
											""
										)}
									</h6>
									<h6 className="font-play font-bold ">
										{user.student.fee_inscription ? user.student.fee_inscription.total : "00"}
										.00 DA
									</h6>

									{user.student.fee_inscription?.paid ? (
										<h6 className="font-play font-bold ">{user.student.fee_inscription?.pay_date}</h6>
									) : (
										<h6 className="font-play font-bold ">{user.student.fee_inscription?.date}</h6>
									)}
								</div>
							</div>
						) : (
							""
						)}
						<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg hover:bg-slate-100-xl flex-1 justify-center">
							<h4 className=" font-semibold dark:text-white">General information</h4>
							<form action="#">
								<div className=" my-2 flex flex-col gap-6">
									<div className="my-2 w-full">
										<label htmlFor="Name" className="block font-medium text-gray-900 dark:text-white">
											Name
										</label>
										<input
											type="text"
											name="Name"
											id="Name"
											className="shadow-sm my-2 p-2 bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
											placeholder="Name"
											required
											value={name}
											onChange={(event) => {
												setName(event.target.value);
											}}
										/>
									</div>
									<div className="my-2 w-full">
										<label htmlFor="gender" className="block font-medium text-gray-900 dark:text-white">
											Gender
										</label>
										<input
											type="gender"
											name="gender"
											id="gender"
											className="my-2 p-2 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
											placeholder="example@company.com"
											required
											value={gender}
											onChange={(event) => {
												setGender(event.target.value);
											}}
										/>
									</div>
									<div className="my-2 w-full">
										<label htmlFor="phone_number" className="block font-medium text-gray-900 dark:text-white">
											Phone Number
										</label>
										<input
											type="number"
											onWheel={(event) => event.target.blur()}
											name="phone_number"
											id="phone_number"
											className="my-2 p-2 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm: rounded-lg focus:ring-primary-500 focus:border-primary-500 w-full .5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
											placeholder="Phone number"
											required
											onChange={(event) => {
												setPhoneNumber(event.target.value);
											}}
										/>
									</div>
									<div className="flex w-full items-center justify-center">
										<button
											onClick={(event) => {
												update(event);
											}}
											className=" font-medium px-4 py-2 text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
										>
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				{user.role === "student" ? <Student student={user.student} /> : user.role === "teacher" ? <Teacher user={user} /> : user.role === "supervisor" ? <Parent user={user} /> : ""}
				<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg hover:bg-slate-100-xl">
					<h4 className=" font-semibold dark:text-white">Receipts</h4>
					<div className="break-words w-full shadow-lg overflow-auto border-0">
						<div className="w-full">
							{/* Projects table */}
							<table className=" w-full border-collapse ">
								<thead className="sticky top-0 whitespace-nowrap">
									<tr>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Amount</th>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>date</th>
										<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Type</th>
									</tr>
								</thead>
								<tbody>
									{user.receipts?.map((item, id) => {
										return (
											<tr key={id} className=" bg-white hover:bg-slate-100 ">
												<th
													className={"flex items-center gap-4 whitespace-nowrap border border-slate-200 text-left align-middle"}
													onMouseEnter={() => {
														setReceipt(item);
													}}
												>
													{!userId && (
														<ReactToPrint
															trigger={() => {
																return (
																	<button className="border placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150">
																		<i className="fa-solid fa-print" />
																	</button>
																);
															}}
															onAfterPrint={() => {
																toast.success("Printing completed successful", {
																	position: "top-center",
																	autoClose: 3000,
																	hideProgressBar: true,
																	closeOnClick: true,
																	pauseOnHover: true,
																	draggable: true,
																	progress: undefined,
																	theme: "light",
																});
															}}
															content={() => ComponentRef}
														/>
													)}
													<span className={"font-bold "}>{item.total}.00 DA</span>
												</th>
												<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
													<span className={"font-bold "}>{moment(item.date).format("DD MMMM, YYYY h:mm")}</span>
												</th>
												<td className={" border border-slate-200 text-left align-middle"}>{item.type}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div className="hidden">
				<div className="flex flex-col w-full gap-4 " ref={(el) => (UserRef = el)}>
					<UserPrint user={user} />
				</div>
			</div>
		</>
	);
}
