import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Barcode from "react-barcode";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";

export default function OrderReceipt({ receipt, order, hide }) {
	var ReceiptRef = useRef();

	useEffect(() => { }, []);
	return (
		<div>
			<div className=" p-4">
				<div className="flex overflow-auto justify-center items-center">
					<div className="border border-black flex flex-col bg-white w-full p-2" ref={(el) => (ReceiptRef = el)}>
						<div className="flex flex-row justify-center gap-4 items-center">
							<img className="w-32 h-32" src={require("assets/img/Numidia Education Group Logo/png/black.png")}></img>
							<div className="flex flex-col items-center">
								<p className=" font-bold ">Order Receipt</p>
								<span className="">{moment().format("MMMM Do YYYY, h:mm")}</span>
							</div>
						</div>

						<div className="flex flex-row items-center gap-4">
							<label className="text-lg font-semibold">Client</label>
							<span className="text-lg">{order.client.user.name}</span>
						</div>
						<div className="my-2">
							<div className="flex my-2 flex-row items-center justify-between gap-4 font-bold ">
								<span>Label</span>
								<span>Quantity</span>
								<span>Price</span>
							</div>
							{order.products.map((product, id) => {
								return (
									<div key={id} className="flex flex-row items-center justify-between gap-4 font-bold ">
										<label>Product:</label>
										<span>{product.qte}</span>
										<span>{product.price}.00 DA</span>
									</div>
								);
							})}
						</div>

						<hr className=" "></hr>
						<div className="flex flex-row text-lg items-center justify-end gap-4 font-bold ">
							<label className="text-lg">Total:</label>
							<span className="text-lg">{receipt.total}.00 DA</span>
						</div>

						<div className="flex flex-row justify-center items-center">
							<Barcode fontSize={18} displayValue={false} height={30} width={1} value={receipt.id} />
						</div>
					</div>
				</div>
				<div className="flex justify-center flex-row gap-4 my-4">
					<ReactToPrint
						trigger={() => {
							return (
								<button
									className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
									type="button"
								>
									Print
								</button>
							);
						}}
						onAfterPrint={() => {
							hide();
							toast.success("Order Receipt Printed", {
								position: "top-center",
								autoClose: 3000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
							});
						}}
						content={() => ReceiptRef}
					/>
				</div>
			</div>
		</div>
	);
}
