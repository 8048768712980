import { memo, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

function FormModal({ children, hide, title, width, height }) {
	const modalRef = useRef(null);


	return (
		<PortalWrapper>
			<div
				className="fixed z-50 bg-black bg-opacity-50 top-0 p-8 left-0 w-full h-screen flex justify-center items-center"
				// onClick={(event) => {
				// 	if (event.currentTarget === event.target) {
				// 		hide();
				// 	}
				// }}
			>
				<div data-aos="fade-up" ref={modalRef} className="p-4 max-h-full h-fit overflow-auto rounded-lg bg-white bg-opacity-90 backdrop-blur-lg  shadow-lg w-fit xl:max-w-6xl flex flex-col z-50 gap-4">
					<div className="flex flex-row justify-between items-center ">
						<h6 className="capitalize font-bold">{title}</h6>
						<div
							className="h-fit cursor-pointer flex justify-center items-center text-center content-center aspect-square rounded-full p-2 hover:text-white text-gray-500  hover:bg-red-500"
							onClick={() => hide()}
						>
							<i className="fa-solid fa-xmark"></i>
						</div>
					</div>
					<div className="w-full h-full overflow-auto p-2">{children}</div>
				</div>
			</div>
		</PortalWrapper>
	);
}

function PortalWrapper({ children }) {
	const portalContainer = document.createElement("div");

	useEffect(() => {
		document.body.appendChild(portalContainer);

		return () => {
			document.body.removeChild(portalContainer);
		};
	}, [portalContainer]);

	return ReactDOM.createPortal(<>{children}</>, portalContainer);
}

FormModal.defaultProps = {
	height: "fit-content",
	width: "fit-content",
};

export default memo(FormModal);
