import axios from "axios";
import Swal from "sweetalert2";



axios.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response) {
			if (error.response.status === 401) {
				Swal.fire({
					title: "Please sign in",
					text: "You are not signed in",
					icon: "error",
				}).then(() => {
					window.localStorage.clear();
					window.sessionStorage.clear(); // Clear session storage
					window.location.href = "/login";
				});
			} else if (error.response.status === 403) {
				Swal.fire({
					title: "Please verify your email",
					text: "You are not verified press ok to verify your email",
					icon: "error",
				}).then(() => {
					window.location.href = "/email.verify";
				});
			} else if (error.response.status === 404) {
				Swal.fire({
					title: "Not Found",
					text: "The requested resource was not found",
					icon: "error",
				});
			} else if (error.response.status === 500) {
				Swal.fire({
					title: "Server Down",
					text: "Internal Server Down",
					icon: "error",
				});
			} else {
				console.log(error.response);
				Swal.fire({
					title: error.response.statusText,
					text: error.response?.data?.message,
					icon: "error",
				});
			}
		} else if (error.request) {
			Swal.fire({
				title: "Network Error",
				text: "Please check your internet connection and try again later",
				icon: "error",
			});
		} else {
			Swal.fire({
				title: "Something Went Wrong",
				text: "An unexpected error occurred, please try again later",
				icon: "error",
			});
		}

		return Promise.reject(error);
	}
);

export default axios;
