import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "config/axios";
import Form from "./Form";
import ProductPhotos from "Components/Carousels/ProductPhotos";
import moment from "moment/moment";

export default function Order() {
	const token = localStorage.getItem("token");

	const url = process.env.REACT_APP_API_URL_LIBRARY;

	const navigate = useNavigate();
	const { id } = useParams();
	const [order, setOrder] = useState({});

	const getOrder = async () => {
		axios({
			method: "get",
			url: url + "/orders/" + id,
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		})
			.then((response) => {
				setOrder(response.data);
			})
			.catch(() => {});
	};

	useEffect(() => {
		getOrder();
	}, []);

	return (
		<div className="flex flex-col gap-6">
			{/* Order details */}
			<h4>Order Details</h4>
			<div className="flex flex-row flex-wrap gap-4 border bg-white p-4 rounded-lg shadow-lg hover:bg-gray-100">
				<div className="flex-1">
					Client:
					<p className="font-bold text-gray-800">{order.client?.user?.name}</p>
				</div>
				<div className="flex-1">
					Phone Number:
					<p className="text-gray-700">{order.client?.user?.phone_number}</p>
				</div>
				<div className="flex-1">
					Total:
					<p className="font-bold text-gray-800 text-lg"> {order.total}.00 DZD</p>
				</div>
				<div className="flex-1">
					Status:
					<p className={`font-medium ${order.status === "paid" ? "text-green-600" : "text-red-600"}`}>{order.status}</p>
				</div>
				<div className="flex-1">
					Date:
					<p className="text-gray-600">{moment(order.created_at).format("YYYY-MMMM-DD HH:MM")}</p>
				</div>
			</div>

			{/* Products section */}
			<div className="grid lg:grid-cols-4 md:grid-cols-2 w-full gap-6 z-10">
				{order.products && (
					<>
						{order.products?.map((product) => (
							<div className="w-full rounded-md border bg-white shadow-md hover:shadow-lg p-4 z-10" key={product.id}>
								<ProductPhotos product={product} />
								<div className="flex justify-between items-start hover:cursor-pointer my-2">
									<Link to={"/library/buy/products/" + product.id} className="flex-1">
										<p className="text-sm font-bold text-gray-800 overflow-hidden overflow-ellipsis h-[3.35em]">{product.name}</p>
									</Link>
									<p className="text-sm font-bold text-sky-600 self-start">{product.price}.00 DA</p>
								</div>

								<div className="my-4 flex flex-wrap gap-2">
									{product.tags?.split(",").map((tag, index) => (
										<span key={index} className="w-fit rounded-lg bg-gray-100 text-slate-500 border border-gray-300 p-2">
											{tag.trim()}
										</span>
									))}
								</div>
							</div>
						))}
					</>
				)}
			</div>
		</div>
	);
}
