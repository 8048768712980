import { urlAtom } from "index";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
const DawaratPhotos = ({ product }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [isHoveredLeft, setIsHoveredLeft] = useState(false);
	const [isHoveredRight, setIsHoveredRight] = useState(false);
	const [url,setUrl] = useAtom(urlAtom);
	useEffect(() => {
		let timeout;

		if (isHoveredRight) {
			timeout = setTimeout(() => {
				setCurrentImage((prevImage) => (prevImage === product?.pictures?.length - 1 ? prevImage : prevImage + 1));
			}, 1000); // Change image every 2 seconds (adjust as needed)
		} else if (isHoveredLeft) {
			timeout = setTimeout(() => {
				setCurrentImage((prevImage) => (prevImage === 0 ? prevImage : prevImage - 1));
			}, 500); // Change image every 2 seconds (adjust as needed)
		}

		return () => clearTimeout(timeout);
	}, [isHoveredLeft, isHoveredRight, currentImage, product.pictures]);

	return (
		<div className="relative transition-all duration-150 ease-linear hover:scale-105">
			{product?.pictures?.length > 0 ? (
				<>
					{" "}
					<img
						src={`${url}/files?url=${product?.pictures[currentImage].url}`}
						alt=""
						className="object-cover aspect-square h-full w-full rounded-lg transition-all duration-150 ease-linear -z-10"
					/>
					<div
						className="absolute right-0 top-0 w-1/2 h-full cursor-grab"
						onMouseEnter={() => {
							setIsHoveredRight((prevHover) => !prevHover);
						}}
						onMouseLeave={() => {
							setIsHoveredRight((prevHover) => !prevHover);
						}}
					></div>
					<div
						onMouseEnter={() => {
							setIsHoveredLeft((prevHover) => !prevHover);
						}}
						onMouseLeave={() => {
							setIsHoveredLeft((prevHover) => !prevHover);
						}}
						className="absolute left-0 top-0 w-1/2 h-full cursor-grab"
					></div>
					<div className="absolute bottom-0 py-2 flex flex-row items-center justify-center w-full gap-2">
						{product.pictures.map((_, i) => {
							return (
								<div
									className={
										i === currentImage
											? "bg-white w-10 h-4 rounded-full transition-all duration-200 ease-linear"
											: "bg-gray-600 w-4 h-4 rounded-full transition-all duration-200 ease-linear"
									}
								></div>
							);
						})}
					</div>
				</>
			) : (
				<p className="h-full w-full content-center aspect-square border rounded-lg text-center text-slate-500">Error: Image data not available</p>
			)}
		</div>
	);
};
export default DawaratPhotos;
