import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import { toast } from "react-toastify";

import FeesFilter from "../../Components/Filters/FeesFilter";
import moment from "moment";
import ReactToPrint from "react-to-print";
import PortalWrapper from "Layouts/PortalWrapper";
import CheckoutsFilter from "Components/Filters/CheckoutsFilter";
import FormModal from "Components/Dropdowns/FormModal";

export default function Checkouts() {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [group_id, setGroupId] = useState("");
	const [student_id, setStudentId] = useState("");
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");
	const [start_date, setStartDate] = useState("");
	const [end_date, setEndDate] = useState("");
	const [type, setType] = useState("all");
	const [filter, openFilter] = useState(false);
	const navigate = useNavigate();

	const [checkouts, setCheckouts] = useState([]);

	const getFees = async () => {
		let options = {
			method: "get",
			url: url + "/checkouts",
			params: {
				sortBy,
				sortDirection,
				search,
				group_id,
				student_id,
				start_date,
				end_date,
				type,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setCheckouts(response.data);
		}).catch(() => { });
	};

	var ComponentRef = useRef();

	useEffect(() => {
		getFees();
	}, [sortBy, sortDirection, search, student_id]);

	return (
		<>
			{filter ? (
				<FormModal
					hide={() => {
						openFilter(false);
					}}
					title={"Level Form"}
				>
					<CheckoutsFilter
						setCheckouts={setCheckouts}
						group_id={group_id}
						student_id={student_id}
						search={search}
						sortBy={sortBy}
						sortDirection={sortDirection}
						setGroupId={setGroupId}
						setStudentId={setStudentId}
						setSearch={setSearch}
						setSortBy={setSortBy}
						setSortDirection={setSortDirection}
						type={type}
						end_date={end_date}
						start_date={start_date}
						setType={setType}
						setEndDate={setEndDate}
						setStartDate={setStartDate}
						hide={() => {
							openFilter(false);
						}}
					/>
				</FormModal>
			) : (
				""
			)}
			<div className="hidden">
				{/* Projects table */}
				<div className=" w-full border-collapse " ref={(el) => (ComponentRef = el)}></div>
			</div>
			<div className="bg-white shadow-lg rounded-lg  my-8 p-8 border ">
				<h4 className=" font-bold">Checkouts</h4>

				<div className="flex w-full justify-between align-middle items-center  gap-2 ">
					<div></div>{/* <button className="flex flex-row gap-2 items-center px-4 py-2 bg-white shadow-md rounded-lg  " onClick={() => openFilter(true)}>
						Filter
						<i className="fa-solid fa-filter"></i>
					</button> */}
					<div className="flex flex-row gap-4 items-center">
						<div className="flex flex-row flex-nowrap items-center p-2  justify-between bg-white rounded-lg  shadow  w-full ease-linear transition-all duration-150  ">
							<input
								className="focus:outline-none"
								type="text"
								value={search}
								onChange={(event) => {
									setSearch(event.target.value);
								}}
								placeholder="Search"
							></input>
							<i className="fa-solid fa-search"></i>
						</div>
						<ReactToPrint
							trigger={() => {
								return (
									<button className="border p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150">
										<i className="fa-solid fa-print" />
									</button>
								);
							}}
							onAfterPrint={() => {
								toast.success("Printing completed successful", {
									position: "top-center",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "light",
								});
							}}
							content={() => ComponentRef}
						/>
					</div>
				</div>

				<div className="break-words w-full my-4 shadow-lg h-fit  overflow-auto   border-0">
					<div className="w-full">
						{/* Projects table */}
						<table className=" w-full border-collapse ">
							<thead className="sticky top-0 whitespace-nowrap">
								<tr>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>
										<button
											className=""
											onClick={(event) => {
												event.preventDefault()
												setSortBy("date");
												if (sortDirection === "asc") {
													setSortDirection("desc");
												} else {
													setSortDirection("asc");
												}
											}}
										>
											<i className="fa-solid fa-filter"></i>
										</button>
										date
									</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>price</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>paid price</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>pay date</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Student</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Group</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Status</th>
								</tr>
							</thead>
							<tbody>
								{checkouts.map((checkout, id) => {
									return (
										<tr key={id} className={checkout.paid_price - checkout.price + checkout.discount >= 0 ? " bg-white hover:bg-slate-100 " : "bg-rose-100"}>
											<th className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
												<span className={"font-bold "}>{checkout.created_at ? moment(checkout.created_at).format("DD-MMMM hh:mm") : ""}</span>
											</th>

											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{checkout.price - checkout.discount}.00 DA</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{checkout.paid_price}.00 DA</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
												{checkout.pay_date ? moment(checkout.pay_date).format("DD-MMMM hh:mm") : "N/A"}
											</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{checkout.student.user.name}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{checkout.group.module} {checkout.group.name}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{checkout.status}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
