import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios.js";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import ExpensesFilter from "../../Components/Filters/ExpensesFilter.js";
import ExpenseForm from "./ExpenseForm.js";
import moment from "moment";
import ReactToPrint from "react-to-print";
import PortalWrapper from "Layouts/PortalWrapper.js";
import FormModal from "Components/Dropdowns/FormModal.js";

export default function Expenses() {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [expense, setExpense] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [group_id, setGroupId] = useState("");
	const [student_id, setStudentId] = useState("");
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");
	const [start_date, setStartDate] = useState("");
	const [end_date, setEndDate] = useState("");
	const [type, setType] = useState("all");
	const [filter, openFilter] = useState(false);
	const navigate = useNavigate();

	const [expenses, setCheckouts] = useState([]);

	const getExpenses = async () => {
		let options = {
			method: "get",
			url: url + "/expenses",
			params: {
				sortBy,
				sortDirection,
				search,
				group_id,
				student_id,
				start_date,
				end_date,
				type,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setCheckouts(response.data);
			})
			.catch(() => {});
	};

	const [method, setMethod] = useState("add");
	const [form, setForm] = useState(false);
	const destroy = (id) => {
		axios({
			// Endpoint to send files
			url: url + "/expenses/" + id,
			method: "delete",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Expense deleted successful", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getExpenses();
			})
			.catch(() => {});
	};
	const showForm = (action, data) => {
		setMethod(action);
		setExpense(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	var ComponentRef = useRef();

	useEffect(() => {
		getExpenses();
	}, [sortBy, sortDirection, group_id, search, student_id]);
	const print = () => {};

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Expense Form"}>
					<ExpenseForm action={method} data={expense} hide={hide} getExpenses={getExpenses}></ExpenseForm>
				</FormModal>
			) : (
				""
			)}
			{filter ? (
				<FormModal
					hide={() => {
						openFilter(false);
					}}
					title={"Expense Filter"}
				>
					<ExpensesFilter
						setCheckouts={setCheckouts}
						group_id={group_id}
						student_id={student_id}
						search={search}
						sortBy={sortBy}
						sortDirection={sortDirection}
						setGroupId={setGroupId}
						setStudentId={setStudentId}
						setSearch={setSearch}
						setSortBy={setSortBy}
						setSortDirection={setSortDirection}
						type={type}
						end_date={end_date}
						start_date={start_date}
						setType={setType}
						setEndDate={setEndDate}
						setStartDate={setStartDate}
						hide={() => {
							openFilter(false);
						}}
					/>
				</FormModal>
			) : (
				""
			)}
			<div className="bg-white shadow-lg rounded-lg p-8 border ">
				<h4 className=" font-bold">Expenses</h4>
				<div className="flex w-full justify-between align-middle items-center  gap-2 ">
					<div></div>{" "}
					{/* <button className="flex flex-row gap-2 px-4 py-2 items-center bg-white shadow-md rounded-lg  " onClick={() => openFilter(true)}>
						Filter
						<i className="fa-solid fa-filter"></i>
					</button> */}
					<div className="flex flex-row gap-4 items-center">
						<div className="flex flex-row flex-nowrap items-center justify-between p-2 bg-white rounded-lg  shadow  w-full ease-linear transition-all duration-150  ">
							<input
								className="focus:outline-none"
								type="text"
								value={search}
								onChange={(event) => {
									setSearch(event.target.value);
								}}
								placeholder="Search"
							></input>
							<i className="fa-solid fa-search"></i>
						</div>
						<ReactToPrint
							trigger={() => {
								return (
									<button className="border p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow hover:bg-slate-100 w-fit ease-linear transition-all duration-150">
										<i className="fa-solid fa-print" />
									</button>
								);
							}}
							onAfterPrint={() => {
								toast.success("Printing completed successful", {
									position: "top-center",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: "light",
								});
							}}
							content={() => ComponentRef}
						/>
					</div>
				</div>
				<div className="hidden">
					{/* Projects table */}

					<table className=" w-full border-collapse " ref={(el) => (ComponentRef = el)}>
						<thead className="sticky top-0 whitespace-nowrap">
							<tr>
								<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>date</th>
								<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>type</th>
								<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>description</th>
								<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Amount</th>
								<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>User</th>
							</tr>
						</thead>
						<tbody>
							{expenses.map((expense, id) => {
								return (
									<tr key={id} className=" bg-white hover:bg-slate-100 ">
										<th className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
											<span className={"font-bold "}>{moment(expense.date).format("DD-MMMM hh:mm")}</span>
										</th>

										<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{expense.type}</td>
										<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{expense.description}</td>
										<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{expense.total}.00 DA</td>
										<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{expense.user.name}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="break-words my-4 w-full shadow-lg h-fit overflow-auto   border-0">
					<div className="w-full">
						{/* Projects table */}
						<table className=" w-full border-collapse ">
							<thead className="sticky top-0 whitespace-nowrap">
								<tr>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>
										<button
											className=""
											onClick={(event) => {
												event.preventDefault();
												setSortBy("date");
												if (sortDirection === "asc") {
													setSortDirection("desc");
												} else {
													setSortDirection("asc");
												}
											}}
										>
											<i className="fa-solid fa-filter"></i>
										</button>
										date
									</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>type</th>
									<th className={"    align-middle   uppercase   font-semibold text-left bg-slate-50 text-slate-500 "}>description</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>Amount</th>
									<th className={"  align-middle    uppercase  font-semibold text-left bg-slate-50 text-slate-500  "}>User</th>
									<th className={"    uppercase  font-semibold flex justify-around   transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
										<Link
											className="w-full ease-linear   "
											role={"button"}
											onClick={(event) => {
												event.preventDefault();
												showForm("add");
											}}
										>
											Add
											<i class="fa-solid fa-plus  "></i>
										</Link>
									</th>
								</tr>
							</thead>
							<tbody>
								{expenses.map((expense, id) => {
									return (
										<tr key={id} className=" bg-white hover:bg-slate-100 ">
											<th className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>
												<span className={"font-bold "}>{moment(expense.date).format("DD-MMMM hh:mm")}</span>
											</th>

											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{expense.type}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{expense.description}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{expense.total}.00 DA</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left align-middle"}>{expense.user.name}</td>
											<td className={" whitespace-nowrap  border border-slate-200  text-left items-center flex justify-evenly gap-2"}>
												<Link
													role={"button"}
													onClick={(event) => {
														event.preventDefault();
														showForm("edit", expense);
													}}
													className="hover:text-yellow-400 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
												>
													<i class="fa-solid fa-pen-to-square"></i>
												</Link>
												<Link
													role={"button"}
													onClick={() => destroy(expense.id)}
													className="hover:text-red-600 transition duration-150 ease-in-out  bg-white rounded-lg shadow-md hover:shadow-lg w-8 h-8 text-center"
												>
													<i class="fa-solid fa-trash"></i>
												</Link>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
