import React from "react";
import ReactDOM from "react-dom/client";
import "index.css";
import App from "App";
import { atom } from "jotai";
import { ScrollRestoration } from "react-router-dom";

export const urlAtom = atom(process.env.REACT_APP_API_URL_SCHOOL);
export const departmentAtom = atom("school");
export const dawaratPresenceSheetsAtom = atom([]);
export const groupsPresenceSheetsAtom = atom([]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

