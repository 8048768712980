import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "Components/Loaders/Loading";

export default function Logout() {
	const url = process.env.REACT_APP_API_URL_AUTH;

	const token = window.localStorage.getItem("token");
	let navigate = useNavigate();

	const logout = () => {
		axios
			.create()({
				// Endpoint to send files
				url: url + "/logout",
				method: "get",
				headers: {
					Authorization: "Bearer " + token,
					Accept: "Application/json",
				},
			})
			// Handle the response from backend here
			.then((response) => {})
			.catch((error) => {})
			.finally(() => {
				window.localStorage.clear();
				window.sessionStorage.clear();
				navigate("/login");
			});
	};

	useEffect(() => {
		logout();
	}, []);

	return (
		<container className="flex  h-screen w-full justify-center  align-middle items-center flex-row">
			<h1 className=" text-3xl">Loading...</h1>
			<Loading width="35px" height="35px" color="black" />
		</container>
	);
}
