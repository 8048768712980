import React from "react";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import QRCode from "react-qr-code";
import moment from "moment";

export default function UserPrint({ user }) {
	const [department, setDepartment] = useAtom(departmentAtom);
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	return (
		<>
			<div className="flex flex-row p-4 bg-white shadow-lg rounded-lg hover:bg-slate-100 justify-between gap-4 w-full ">
				<div className="flex flex-row gap-4">
					<div className="relative">
						<img
							className="w-32 aspect-square rounded-lg shadow object-cover cursor-pointer"
							src={`${auth_api_url}/files?url=${user?.profile_picture?.url}`}
						></img>
					</div>

					<div className="flex flex-col font-light text-slate-500 ">
						<h6 className=" text-slate-900 font-medium font-mono capitalize">{user.name}</h6>
						<p>
							{user.email} / {user.phone_number}
						</p>
						<p className="capitalize">{user.role}</p>
						{user.role === "student" ? (
							<p>
								{user.student.level.year} {user.student.level.education} {user.student.level.specialty}
							</p>
						) : (
							""
						)}
					</div>
				</div>

				<div className="w-32 aspect-square ">{user.id ? <QRCode value={user.id} style={{ height: "100%", width: "100%" }} /> : ""}</div>
			</div>
		</>
	);
}
