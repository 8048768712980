import React from "react";
import { Link } from "react-router-dom";

export default function FooterSmallDark() {
	return (
		<footer className="relative z-20 flex w-full">
			<img className="absolute bottom-0 w-full min-h-[400px] lg:min-h-max  h-max object-cover " src={require("assets/img/auth-bg.png")} />
			<div className="flex flex-col  z-50 w-full  lg:px-24 px-8 py-8">
				<hr className="  border-b-1 border-slate-800 my-4" />
				<div className="flex flex-wrap items-center md:justify-between justify-center">
					<div className="w-full md:w-4/12">
						<div className=" text-slate-500 font-semibold  text-center md:text-left">
							Copyright © {new Date().getFullYear()}{" "}
							<a href="https://hoceyne.me" target="_blank" className="text-slate-800 hover:text-slate-900  font-semibold ">
								Souala El-Houssine
							</a>
						</div>
					</div>
					<div className="w-full md:w-8/12">
						<ul className="flex flex-wrap list-none md:justify-end  justify-center gap-4">
							<li>
								<Link to="/about" className="text-slate-800  font-semibold   ">
									<i className="fa-solid fa-info"></i>
								</Link>
							</li>
							<li>
								<Link to="contact" className="text--slate-800  font-semibold   ">
									<i className="fa-solid fa-phone"></i>
								</Link>
							</li>
							<li>
								<Link to="/about" className="text-slate-800  font-semibold   ">
									<i className="fa-brands fa-facebook"></i>
								</Link>
							</li>
							<li>
								<Link to="contact" className="text--slate-800  font-semibold   ">
									<i className="fa-brands fa-instagram"></i>
								</Link>
							</li>
							<li>
								<Link to="contact" className="text--slate-800  font-semibold   ">
									<i className="fa-brands fa-youtube"></i>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
}
