import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import EmployeeData from "./EmployeeData";

export default function EmployeeFinancials() {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [search, setSearch] = useState("");

	const navigate = useNavigate();

	const [employees, setEmployees] = useState([]);
	var ComponentRef = useRef();

	const getEmployees = async () => {
		let options = {
			method: "get",
			url: url + "/statistics/employees/financials",
			params: {
				search,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setEmployees(response.data);
		}).catch(() => { });
	};
	const print_employee = () => { };

	useEffect(() => {
		getEmployees();
	}, [search]);

	return (
		<>
			<div className="flex flex-row justify-between items-center my-4">
				<h4 className="my-2 font-bold">Financials Per Employee</h4>

				<div className="flex flex-row flex-nowrap items-center p-2 justify-between bg-white rounded-lg  shadow ease-linear transition-all duration-150  ">
					<input
						className="focus:outline-none"
						type="text"
						value={search}
						onChange={(event) => {
							setSearch(event.target.value);
						}}
						placeholder="Search"
					></input>
					<i className="fa-solid fa-search"></i>
				</div>
			</div>
			<div className=" ">
				{employees.map((employee, id) => {
					return <EmployeeData employee={employee} />;
				})}
			</div>
		</>
	);
}
