import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import FooterSmallDark from "Components/Footers/FooterSmallDark";

import ReactCodeInput from "react-code-input";
import Loading from "Components/Loaders/Loading";

export default function EmailVerification() {
	const url = process.env.REACT_APP_API_URL_AUTH;

	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [loading, setLoading] = useState(false);
	const token = window.localStorage.getItem("token");

	let navigate = useNavigate();

	const verify = () => {
		setLoading(true);
		let data = {
			email,
			code,
		};
		axios({
			// Endpoint to send files
			url: url + "/email/verify",
			method: "POST",
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
				"Access-Control-Allow-Origin": "*",
			},

			// Attaching the form data
			data: data,
		})
			// Handle the response from backend here
			.then((response) => {
				Swal.fire({
					title: "Go to dashboard",
					text: "Your email is verified.",
					icon: "success",

					iconColor: "#3dc00c",
				}).then(async () => {
					navigate("/dashboard");
				});
			})
			.catch(() => { })
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => { }, []);

	return (
		<div className="flex flex-col bg-gradient-to-r min-h-screen from-[rgb(1,42,74)] to-sky-600 w-full h-fit justify-between">
			<div className="flex content-center  justify-center z-50 h-full m-auto p-8">
				<div className="w-full lg:w-1/3 my-24">
					<div className="relative flex flex-col break-words w-full  shadow-lg rounded-3xl bg-slate-200 p-6">
						<Link to="/" className="absolute w-24 h-24 left-1/2 -translate-x-1/2  -translate-y-2/3  rounded-full bg-white z-50 shadow-lg ">
							<img className="object-contain w-full h-full" src={require("assets/img/auth-logo.png")} />
						</Link>
						<div className="flex-auto mt-12">
							<div className="text-center">
								<h6 className="  font-bold">Verify Your Email</h6>
							</div>
							<div className="text-slate-600 text-center">
								<small>
									If you didn't receive an email please click here to{" "}
									<Link className="text-blue-600 underline" to="/email.resent.code">
										resend verification code
									</Link>
									.
								</small>
							</div>
							<form>
								<div className="relative w-full my-2">
									<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
										Email
									</label>
									<input
										type="email"
										value={email}
										className="p-3  my-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
										placeholder="Email"
										onChange={(event) => {
											setEmail(event.target.value);
										}}
									/>
								</div>
								<div className="relative w-full my-2">
									<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
										Code
									</label>

									<div className="flex justify-center my-2">
										<ReactCodeInput
											type="text"
											value={code}
											className="!flex justify-between flex-row"
											fields={6}
											inputStyle={{
												width: "14%",
												height: "40px",
												fontSize: "30px",
												textAlign: "center",
												padding: "5px",
												borderRadius: "10px",
												boxShadow: "0px 1px 3px 0.5px rgba(0,0,0,0.2)",
											}}
											onChange={(pinCode) => {
												setCode(pinCode);
											}}
										/>
									</div>
								</div>
								<div className="text-center my-4  flex flex-row">
									<button
										disabled={loading}
										className="bg-[rgb(10,58,92)] text-white active:bg-slate-600 py-2  font-bold uppercase   rounded-lg shadow hover:shadow-lg outline-none focus:outline-none   w-full ease-linear transition-all duration-150"
										type="button"
										onClick={(event) => verify(event)}
									>
										{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
										Verify
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<FooterSmallDark />
		</div>
	);
}
