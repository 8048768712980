import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Checkouts from "Management/Users/Users/Checkouts";

export default function MyCheckouts() {
	const token = window.localStorage.getItem("token");

	const [url, setUrl] = useAtom(urlAtom);
	const role = window.localStorage.getItem("role");
	const [student, setStudent] = useState({});
	const getStudent = async () => {
		let options = {
			method: "get",
			url: url + "/user/checkouts",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setStudent(response?.data?.student);
		}).catch(() => { });
	};
	useEffect(() => {
		getStudent();
	}, []);

	return (
		<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg">
			<h3 className=" font-semibold dark:text-white">Checkouts</h3>
			<Checkouts student={student} />
		</div>
	);
}
