import React, { useEffect, useRef, useState } from "react";
import Chair from "./Chair";
import axios from "config/axios";
import chroma from "chroma-js";
import Loading from "Components/Loaders/Loading";
import { urlAtom } from "index";
import { useAtom } from "jotai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AmphiForm = ({ hide, data, action }) => {
	const [url, setUrl] = useAtom(urlAtom);
	const token = window.localStorage.getItem("token");
	const [name, setName] = useState("");
	const [location, setLocation] = useState("");
	const [capacity, setCapacity] = useState();
	const [nbRows, setNbRows] = useState(10);
	const [nbColumns, setNbColumns] = useState(20);
	const [loading, setLoading] = useState(false);
	const [sections, setSections] = useState([]);
	const [chairs, setChairs] = useState(
		Array.from({ length: nbRows }, () =>
			Array.from({ length: nbColumns }, () => {
				return { selected: false, selecting: false, color: null };
			})
		)
	);

	const navigate = useNavigate();
	const isSelecting = useRef(false);
	const selectionStart = useRef({ row: null, col: null });

	const handleMouseDown = (row, col) => {
		isSelecting.current = true;
		selectionStart.current = { row, col };
		toggleChair(row, col);
	};

	const handleMouseEnter = (row, col, chairRef) => {
		if (isSelecting.current) {
			selectChairsInRange(selectionStart.current, { row, col });
			chairRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
		}
	};
	const create_amphi = () => {
		setLoading(true);
		axios({
			// Endpoint to send files
			url: url + "/amphitheaters",
			method: "post",
			data: {
				name,
				nb_columns: nbColumns,
				nb_rows: nbRows,
				location,
				capacity,
				sections,
			},
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Amphi created successfully", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				hide();
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};
	const handleMouseUp = (row, col) => {
		if (isSelecting.current) {
			if (checkSection(row, col)) {
				isSelecting.current = false;
			} else {
				let updated_sections = [...sections];
				updated_sections.push({
					ending_row: Math.max(row, selectionStart.current.row),
					ending_column: Math.max(col, selectionStart.current.col),
					starting_row: Math.min(row, selectionStart.current.row),
					starting_column: Math.min(col, selectionStart.current.col),
				});
				setSections(updated_sections);
				isSelecting.current = false;
			}
			let updatedChairs = [...chairs];
			updatedChairs = updatedChairs.map((row, rowIndex) =>
				row.map((_, colIndex) => {
					return {
						selecting: false,
						selected: chairs[rowIndex][colIndex].selected,
						color: chairs[rowIndex][colIndex].color,
					};
				})
			);

			setChairs(updatedChairs);
		}
	};

	const toggleChair = (row, col) => {
		const updatedChairs = [...chairs];
		updatedChairs[row][col].selecting = true;
		setChairs(updatedChairs);
	};

	const checkSection = (row, col) => {
		for (let i = Math.min(row, selectionStart.current.row); i <= Math.max(row, selectionStart.current.row); i++) {
			for (let j = Math.min(col, selectionStart.current.col); j <= Math.max(col, selectionStart.current.col); j++) {
				if (chairs[i][j].selected) {
					return true;
				}
			}
		}
		return false;
	};

	const selectChairsInRange = (start, end) => {
		const updatedChairs = chairs.map((row, rowIndex) =>
			row.map((_, colIndex) => {
				return {
					selecting:
						rowIndex >= Math.min(start.row, end.row) && rowIndex <= Math.max(start.row, end.row) && colIndex >= Math.min(start.col, end.col) && colIndex <= Math.max(start.col, end.col),
					selected: chairs[rowIndex][colIndex].selected,
					color: chairs[rowIndex][colIndex].color,
				};
			})
		);
		setChairs(updatedChairs);
	};

	const clear = () => {
		setChairs(
			Array.from({ length: nbRows }, () =>
				Array.from({ length: nbColumns }, () => {
					return { selected: false, selecting: false, color: null };
				})
			)
		);
		setSections([]);
		isSelecting.current = false;
		selectionStart.current = { row: null, col: null };
	};
	useEffect(() => {
		let updatedChairs = Array.from({ length: nbRows }, () =>
			Array.from({ length: nbColumns }, () => {
				return { selected: false, selecting: false, color: null };
			})
		);
		setSections((prev) => {
			return prev
				.map((section) => {
					// Check if the section is outside the limits
					const isOutside = section.starting_row >= nbRows || section.ending_row >= nbRows || section.starting_column >= nbColumns || section.ending_column >= nbColumns;
					if (isOutside) {
						// Filter out the sections completely outside the grid
						if (section.starting_row > nbRows || section.starting_column > nbColumns) {
							return null; // Mark for removal
						}
						// Update the sections partially within the grid boundaries
						return {
							...section,
							ending_row: Math.min(section.ending_row, nbRows - 1),
							ending_column: Math.min(section.ending_column, nbColumns - 1),
						};
					}
					// Return the section unchanged if it is within bounds
					return section;
				})
				.filter((section) => section !== null); // Remove null values
		});

		setChairs(updatedChairs);
	}, [nbColumns, nbRows]);
	useEffect(() => {
		let updatedChairs = Array.from({ length: nbRows }, () =>
			Array.from({ length: nbColumns }, () => {
				return { selected: false, selecting: false, color: null };
			})
		);
		const randomHue = Math.random() * (230 - 120) + 120;
		sections.forEach((section) => {
			let c = chroma.hsl(randomHue, 0.8, 0.45).hex().toUpperCase();
			for (let i = section.starting_row; i <= section.ending_row; i++) {
				for (let j = section.starting_column; j <= section.ending_column; j++) {
					if (i < nbRows && j < nbColumns) {
						if (chairs[i][j].color) {
							updatedChairs[i][j].color = chairs[i][j].color;
						} else {
							updatedChairs[i][j].color = c;
						}
						updatedChairs[i][j].selected = true;
					}
				}
			}
		});

		setChairs(updatedChairs);
	}, [sections]);

	useEffect(() => {
		if (action === "edit") {
			setName(data?.name);
			setCapacity(data?.capacity);
			setLocation(data?.location);
			setSections(data?.sections);
		}
	}, []);

	return (
		<div className="flex flex-col items-center px-8">
			<div className="my-2 w-full ">
				<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
					Name
				</label>
				<input
					className="my-2 p-3   text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					onChange={(event) => {
						setName(event.target.value);
					}}
					value={name}
					placeholder="Name"
					type="text"
				></input>
			</div>
			<div className="my-2 w-full">
				<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
					Location
				</label>
				<input
					className="my-2 p-3   text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					onChange={(event) => {
						setLocation(event.target.value);
					}}
					value={location}
					placeholder="Location"
					type="text"
				></input>
			</div>
			<div className="my-2 w-full">
				<label className="block uppercase text-slate-600  font-bold " htmlFor="grid-password">
					Capacity
				</label>
				<input
					className="my-2 p-3   text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
					onChange={(event) => {
						setCapacity(event.target.value);
					}}
					value={capacity}
					placeholder="Capacity"
					type="number"
					onWheel={(event) => event.target.blur()}
				></input>
			</div>
			<div className="flex flex-row items-center gap-4">
				<label className=" ">Number of Rows:</label>
				<input type="number" onWheel={(event) => event.target.blur()} min={1} value={nbRows} onChange={(e) => setNbRows(parseInt(e.target.value))} className="w-16 border" />
				<label className=" ">Number of Columns:</label>
				<input type="number" onWheel={(event) => event.target.blur()} min={1} value={nbColumns} onChange={(e) => setNbColumns(parseInt(e.target.value))} className="w-16 border" />
				<label className=" ">Clear</label>

				<button onClick={clear} className="w-16 border">
					X
				</button>
			</div>
			<div className="overflow-auto w-full my-4 h-full">
				<div className="flex scroll-auto m-auto flex-col flex-nowrap overflow-auto w-fit ">
					{chairs.map((row, rowIndex) => (
						<div key={rowIndex} className="flex">
							{row.map((col, colIndex) => {
								return (
									<Chair
										key={colIndex}
										row={rowIndex}
										col={colIndex}
										onClick={() => {}}
										onMouseDown={handleMouseDown}
										onMouseEnter={handleMouseEnter}
										onMouseUp={handleMouseUp}
										chairs={chairs}
									/>
								);
							})}
						</div>
					))}
				</div>
			</div>
			<div className="flex justify-center my-2 w-full">
				<button
					className=" text-white w-full py-2 bg-sky-600 active:bg-sky-700 font-bold uppercase  px-4  rounded-lg shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
					type="button"
					onClick={() => create_amphi()}
					disabled={loading}
				>
					{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
					Save
				</button>
			</div>
		</div>
	);
};

export default AmphiForm;
