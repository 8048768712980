import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom } from "index";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import Loading from "Components/Loaders/Loading";

// components

export default function PostForm({ action, data, getPosts, className, hide }) {
	const token = window.localStorage.getItem("token");
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const method = action === "add" ? "post" : "put";
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [uploaded_images, setUploadedImages] = useState([]);
	const [department, setDepartment] = useAtom(departmentAtom);
	const [loading, setLoading] = useState({
		submit: false,
		images: false,
	});

	const navigate = useNavigate();
	const compressImage = async (file) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
		};
		await imageCompression(file, options)
			.then((compressedFile) => {
				setUploadedImages((prev) => {
					return [...prev, compressedFile];
				});
			})
			.catch((error) => {});
	};
	const add_post = async () => {
		setLoading((prev) => {
			return {
				submit: true,
				images: false,
			};
		});

		axios({
			// Endpoint to send files
			url: auth_api_url + "/posts",
			method: method,
			data: {
				title,
				content,
				department,
				uploaded_images,
			},
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + token,
			},
		})
			// Handle the response from backend here
			.then((response) => {
				toast.success("Post " + action + "ed", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getPosts();
				hide();
			})
			.catch(() => {})
			.finally(() => {
				setLoading((prev) => {
					return {
						submit: false,
						images: false,
					};
				});
			});
	};

	const handleFileUpload = async (event) => {
		setLoading((prev) => {
			return {
				submit: true,
				images: true,
			};
		});
		event.preventDefault();
		const files = event.target.files;

		for (let i = 0; i < files.length; i++) {
			await compressImage(files[i]).then(() => {});
		}
		setLoading((prev) => {
			return {
				submit: false,
				images: false,
			};
		});
	};

	const handleRemoveImage = (event, index) => {
		event.preventDefault();
		const updatedImages = [...uploaded_images];
		updatedImages.splice(index, 1);
		setUploadedImages(updatedImages);
	};
	const convertUrlToFile = async (fileUrl, filename) => {
		try {
			// Fetch the image from the URL
			const response = await axios.get(`${auth_api_url}/files?url=${fileUrl}`, { responseType: "blob" });

			// Create a File object from the Blob
			const file = new File([response.data], filename, { type: response.data.type });

			return file;
		} catch (error) {
			console.error("Failed to convert URL to File:", error);
			return null;
		}
	};
	useEffect(() => {
		if (action === "edit") {
			setTitle(data.title);
			setContent(data.content);
			const initializeImages = async () => {
				const imageFiles = await Promise.all(
					data.photos.map(async (photo) => {
						const file = await convertUrlToFile(photo.url, `image-${photo.id}.png`);
						return file;
					})
				);
				setUploadedImages(imageFiles.filter((file) => file));
			};

			initializeImages();
		}
	}, []);
	return (
		<div className="flex-auto p-4">
			<form>
				<div className="flex flex-wrap">
					<div className="w-full ">
						<div className=" w-full my-2 ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Title
							</label>
							<input
								type="title"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full my-2 ease-linear transition-all duration-150"
								placeholder="Title"
								onChange={(event) => {
									setTitle(event.target.value);
								}}
								value={title}
							/>
						</div>
					</div>
					<div className="w-full ">
						<div className=" w-full my-2 ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Content
							</label>
							<textarea
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 h-20"
								placeholder="Content"
								onChange={(event) => {
									setContent(event.target.value);
								}}
								value={content}
							/>
						</div>
					</div>
				</div>
				<div className=" ">
					<label className="block uppercase text-slate-600 font-bold ">Upload Photos</label>
					<div className="flex flex-row w-full my-2 overflow-auto gap-4">
						<div>
							<label className="text-slate-500 ">
								<div className="w-[200px] h-[120px] rounded-lg border-dashed border-2 border-slate-200px text-slate-500 flex justify-center items-center hover:cursor-pointer hover:border-blue-500 transition-all duration-200 ease-in-out hover:text-blue-500 hover:border-solid">
									{loading.images ? (
										<>
											{" "}
											<Loading width="20px" height={"20px"} color="black" weight={"2px"}></Loading>Uploading Image
										</>
									) : (
										<>
											<i className="fa-solid fa-add "></i>Add Image
										</>
									)}
								</div>
								<input type="file" multiple accept="image/*" onChange={handleFileUpload} className="hidden" />
							</label>
						</div>
						{uploaded_images.map((image, index) => (
							<div key={index} className="relative min-w-fit ">
								<img src={URL.createObjectURL(image)} className="w-[200px] h-[120px] object-cover rounded-lg " alt={`uploaded-image-${index}`} />
								<button
									className="absolute top-0 right-0 rounded-full bg-black bg-opacity-50 text-white !w-8 h-8 hover:text-red-600 transition-all duration-200"
									onClick={(event) => handleRemoveImage(event, index)}
								>
									<i className="fa-solid fa-close"></i>
								</button>
							</div>
						))}
					</div>
				</div>
				<div className="flex justify-center my-4 w-full">
					<button
						className="w-full py-2 px-4 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
						type="button"
						onClick={() => add_post()}
						disabled={loading.submit}
					>
						{loading.submit & !loading.images ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
						Save
					</button>
				</div>
			</form>
		</div>
	);
}
