import { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterSmallDark from "Components/Footers/FooterSmallDark";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import Loading from "Components/Loaders/Loading";
import { toast } from "react-toastify";
import PortalWrapper from "Layouts/PortalWrapper";
import { userAtom } from "Components/Atoms/AuthAtoms";

export default function Login() {
	const [url, setUrl] = useAtom(urlAtom);
	const { annex } = useParams();
	const [department, setDepartment] = useAtom(departmentAtom);
	const [user, setUser] = useAtom(userAtom);
	const [form, setForm] = useState(false);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [location, setLocation] = useState("unknown");
	const [userAgent, setUserAgent] = useState("");
	const [loading, setLoading] = useState(false);

	const token = window.localStorage.getItem("token");

	let navigate = useNavigate();

	const loginProvider = (email, provider, id, token) => {
		let data = {
			email,
			id,
			token,
		};
		axios({
			// Endpoint to send files
			url: url + "/auth/" + provider + "/login",
			method: "POST",
			headers: {
				Accept: "Application/json",
				"Access-Control-Allow-Origin": "*",
			},

			// Attaching the form data
			data: data,
		})
			// Handle the response from backend here
			.then((response) => {
				setLoading(false);
				toast.success("Logged in successful", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				const imgUrl = `${auth_api_url}/files?url=${response?.data?.profile_picture?.url}`;
				window.localStorage.setItem("profile_picture_url", imgUrl);
				window.localStorage.setItem("token", response?.data?.token);
				window.localStorage.setItem("user_id", response?.data?.id);
				window.localStorage.setItem("role", response?.data?.role);
				setUser(response.data);
				navigate("/dashboard");
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const login = () => {
		let data = {
			email,
			password,
			location,
			userAgent,
		};
		axios({
			// Endpoint to send files
			url: url + "/login",
			method: "POST",
			headers: {
				Accept: "Application/json",
				"Access-Control-Allow-Origin": "*",
			},

			// Attaching the form data
			data: data,
		})
			// Handle the response from backend here
			.then((response) => {
				const imgUrl = `${auth_api_url}/files?url=${response?.data?.profile_picture?.url}`;

				window.localStorage.setItem("profile_picture_url", imgUrl);
				window.localStorage.setItem("token", response?.data?.token);
				window.localStorage.setItem("user_id", response?.data?.id);
				window.localStorage.setItem("role", response?.data?.user.role);
				window.localStorage.setItem("access_token_id", response?.data?.access_token_id);
				setUser(response.data);

				navigate("/dashboard");
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (annex == "school") {
			setUrl(process.env.REACT_APP_API_URL_SCHOOL);
			setDepartment("school");
		} else if (annex == "profession") {
			setUrl(process.env.REACT_APP_API_URL_PROFESSION);
			setDepartment("profession");
		} else if (annex == "academy") {
			setUrl(process.env.REACT_APP_API_URL_ACADEMY);
			setDepartment("academy");
		} else {
			setUrl(process.env.REACT_APP_API_URL_SCHOOL);
			setDepartment("school");
		}
		var options;
		setUserAgent(navigator.userAgent);
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					options = {
						method: "GET",
						url:
							"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
							position.coords.latitude +
							"," +
							position.coords.longitude +
							"&key=" +
							process.env.REACT_APP_GOOGLE_API_KEY_GEOLOCATION,
					};
					await fetch(options)
						.then((response) => {
							console.log(response);
						})
						.catch(() => {
							setLocation("unknown");
						});
				},

				(error) => {
					setLocation("unknown");
				}
			);
		} else {
			setLocation("unknown");
		}
	}, []);
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const verifyToken = () => {
		if (token) {
			fetch(auth_api_url + "/verify-token", {
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
				.then((response) => {
					navigate("/dashboard");
				})
				.catch(() => {
					window.localStorage.clear();
					window.sessionStorage.clear();
				});
		}
	};

	useEffect(() => {
		verifyToken();
	}, []);
	return (
		<>
			{form ? (
				<PortalWrapper>
					<div onClick={() => setForm(false)} className="fixed bg-black bg-opacity-25 top-0 left-0 z-50 w-full h-screen flex justify-center items-center p-24">
						<div
							onClick={(event) => event.stopPropagation()}
							data-aos="zoom-in-down"
							className=" bg-slate-50 bg-opacity-60 backdrop-blur-lg w-fit rounded-lg h-fit max-h-full  flex flex-col align-middle overflow-auto scrollbar-hide p-8 xl:p-24"
						>
							<div className="justify-center flex flex-wrap">
								<div className="w-full ">
									<h4 className="text-[rgb(1,42,74)] font-semibold w-full text-center my-4 font-poppins capitalize">Please select a department:</h4>
									<div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
										<div className="w-full ">
											<h5 className=" font-semibold  text-center my-4">Numidia School</h5>
											<button
												onClick={(event) => {
													event.stopPropagation();
													setUrl(process.env.REACT_APP_API_URL_SCHOOL);
													setDepartment("school");
													setForm(false);
												}}
												className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
											>
												<img alt="..." className="align-middle rounded-lg" src={require("assets/img/school.jpg")} />
											</button>
										</div>

										<div className="w-full">
											<h5 className=" font-semibold  text-center my-4">Numidia Academy</h5>
											<button
												onClick={(event) => {
													event.stopPropagation();
													setUrl(process.env.REACT_APP_API_URL_ACADEMY);
													setDepartment("academy");
													setForm(false);
												}}
												className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
											>
												<img alt="..." className="align-middle rounded-lg" src={require("assets/img/academy.jpg")} />
											</button>
										</div>

										<div className="w-full">
											<h5 className=" font-semibold  text-center my-4">Numidia Profession</h5>
											<button
												onClick={(event) => {
													event.stopPropagation();
													setUrl(process.env.REACT_APP_API_URL_PROFESSION);
													setDepartment("profession");
													setForm(false);
												}}
												className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
											>
												<img alt="..." className="align-middle rounded-lg" src={require("assets/img/profession.jpg")} />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</PortalWrapper>
			) : (
				""
			)}
			<div className="flex flex-col bg-gradient-to-r min-h-screen from-[rgb(1,42,74)] to-sky-600 w-full h-fit justify-between">
				<div className="flex content-center  justify-center z-50 m-auto p-8 w-full ">
					<div className="w-full lg:w-1/2 xl:1/3 my-24">
						<div className="relative flex flex-col break-words w-full  shadow-lg rounded-3xl bg-slate-200 p-6">
							<Link to="/" className="absolute w-24 h-24 left-1/2 -translate-x-1/2 -translate-y-2/3 rounded-full bg-white z-50 shadow-lg ">
								<img className="object-contain w-full h-full" src={require("assets/img/auth-logo.png")} />
							</Link>
							<div className="flex-auto mt-12">
								<div className="flex flex-row items-center justify-center gap-4">
									<Link to={"/login"} className="text-slate-900 bg-slate-300 rounded-full  px-6 py-2 bold font-semibold">
										Login
									</Link>
									<Link to={"/register"} className="text-slate-900 bg-white rounded-full px-6 py-2   bold hover:shadow-lg transition-all duration-300 ease-in-out font-semibold">
										Sign Up
									</Link>
								</div>
								<form>
									<div className="relative w-full my-2 ">
										<label className="block capitalize text-blueGray-600  font-bold " htmlFor="grid-password">
											Email
										</label>
										<input
											type="email"
											className="my-2 p-3   placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											placeholder="Email"
											onChange={(event) => {
												setEmail(event.target.value);
											}}
										/>
									</div>

									<div className="relative w-full my-2 ">
										<label className="block capitalize text-blueGray-600 font-bold " htmlFor="grid-password">
											Password
										</label>
										<input
											type="password"
											className="my-2 p-3  placeholder-blueGray-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
											placeholder="Password"
											onChange={(event) => {
												setPassword(event.target.value);
											}}
										/>
										<div className="flex flex-wrap relative w-full">
											<Link to={"/forgot.password"} className="text-slate-600">
												<small>Forgot password?</small>
											</Link>
										</div>
									</div>

									<div className="text-center my-4">
										<button
											className="bg-[rgb(10,58,92)] text-white active:bg-slate-600  font-bold capitalize  py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none   w-full align-middle flex justify-center"
											type="submit"
											onClick={(event) => {
												event.preventDefault();
												setLoading(true);
												login();
											}}
											disabled={loading}
										>
											{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
											Sign In
										</button>
									</div>
								</form>

								<hr className=" border-b-1 border-slate-300" />
								<div className="text-slate-600 text-center my-2 ">
									<small>Or sign in with</small>
								</div>
								<div className=" flex flex-row text-center justify-center gap-4">
									<LoginSocialFacebook
										appId={process.env.REACT_APP_FACEBOOK_APP_ID}
										fieldsProfile={"id,email"}
										redirect_uri={process.env.REACT_APP_URL + "/login"}
										onResolve={({ data }) => {
											let email = data.email;
											let id = data.id;
											let provider = "facebook";
											let token = data.accessToken;
											loginProvider(email, provider, id, token);
										}}
										onReject={(err) => {
											setLoading(false);
										}}
									>
										<button
											className="bg-white active:bg-blueGray-50 text-slate-700 gap-4 p-2  rounded-full outline-none focus:outline-none   capitalize shadow hover:shadow-md inline-flex items-center font-bold  ease-linear transition-all duration-150"
											onClick={() => setLoading(true)}
										>
											<img alt="..." className="w-10 aspect-square " src={require("assets/img/Social Media/fb-logo.png")} />
											<span className="px-4">Facebook</span>
										</button>
									</LoginSocialFacebook>

									<LoginSocialGoogle
										client_id={process.env.REACT_APP_GOOGLE_APP_ID}
										redirect_uri={process.env.REACT_APP_URL + "/login"}
										scope="openid profile email"
										discoveryDocs="claims_supported"
										access_type="online"
										onResolve={({ data }) => {
											let email = data.email;
											let id = data.sub;
											let token = data.access_token;
											let provider = "google";
											loginProvider(email, provider, id, token);
										}}
										onReject={() => {
											setLoading(false);
										}}
									>
										<button
											className="bg-white active:bg-blueGray-50 text-blueGray-700 p-2 gap-4  rounded-full outline-none focus:outline-none    capitalize shadow hover:shadow-md inline-flex items-center font-bold  ease-linear transition-all duration-150"
											onClick={() => setLoading(true)}
										>
											<img alt="..." className="w-10 aspect-square " src={require("assets/img/Social Media/google-icon.png")} />
											<span className="px-4">Google</span>
										</button>
									</LoginSocialGoogle>
								</div>

								<div className="my-4">
									Current department is Numidia {department} click on to{" "}
									<Link className="text-blue-600 underline" onClick={() => setForm(true)}>
										change department.
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FooterSmallDark />
			</div>
		</>
	);
}
