import React from "react";
import Checkouts from "./Checkouts";
import EmployeeFinancials from "./EmployeeFinancials";
import Expenses from "./Expenses";
import InscriptionFees from "./InscriptionFees";
import PaidSessions from "./PaidSessions";
import Transactions from "./Transactions";

// components

export default function Financials() {
	return (
		<>
			<EmployeeFinancials />
		</>
	);
}
