import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function NumidiaTeachers() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation();

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };
    const next = () => {
        setCurrentIndex((prev) => (prev + 1) % 3);
    };
    const prev = () => {
        setCurrentIndex((prev) => (prev - 1) % 3);
    };
    useEffect(() => {
        const autoPlayTimer = setInterval(() => {
            next();
        }, 3000);

        return () => {
            clearInterval(autoPlayTimer);
        };
    }, [currentIndex]);
    return (
        <div className="relative w-full bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)] h-auto overflow-hidden px-24">
            {/* Item 1 */}
            {currentIndex === 0 && (
                <div className="flex text-white w-full transition-opacity duration-300 ease-in-out flex-wrap items-center">
                    <div className="w-full md:w-1/2 flex flex-col justify-evenly items-start h-fit">
                        <div className="flex flex-col items-start justify-around ">
                            <h3 className=" font-semibold leading-normal w-full md:text-2">{t("teachersTitle")}</h3>
                            <h6 className=" font-light leading-relaxed w-full ">{t("teachersSubtitle")}</h6>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 flex justify-center items-center aspect-square">
                        <img className="object-contain mx-auto my-auto aspect-square" src={require("assets/img/teacher1_illustration.png")} alt="Book" />
                    </div>
                </div>
            )}

            {/* Item 2 */}
            {currentIndex === 1 && (
                <div className="flex text-white w-full transition-opacity duration-300 ease-in-out flex-wrap items-center">
                    <div className="w-full md:w-1/2 flex flex-col justify-evenly items-start h-fit">
                        <div className="flex flex-col items-start justify-around ">
                            <h3 className=" font-semibold leading-normal w-full md:text-2">{t("teachersTitle")}</h3>
                            <h6 className=" font-light leading-relaxed w-full ">{t("teachersSubtitle")}</h6>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 flex justify-center items-center aspect-square">
                        <img className="object-contain mx-auto my-auto aspect-square" src={require("assets/img/teacher2_illustration.png")} alt="Book" />
                    </div>
                </div>
            )}

            {/* Item 3 */}
            {currentIndex === 2 && (
                <div className="flex text-white w-full transition-opacity duration-300 ease-in-out flex-wrap items-center">
                    <div className="w-full md:w-1/2 flex flex-col justify-evenly items-start h-fit">
                        <div className="flex flex-col items-start justify-around ">
                            <h3 className=" font-semibold leading-normal w-full md:text-2">{t("teachersTitle")}</h3>
                            <h6 className=" font-light leading-relaxed w-full ">{t("teachersSubtitle")}</h6>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 flex justify-center items-center aspect-square">
                        <img className="object-contain mx-auto my-auto aspect-square" src={require("assets/img/teacher3_illustration.png")} alt="Book" />
                    </div>
                </div>
            )}

            {/* Navigation Dots */}
            <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex items-center space-x-2">
                {[0, 1, 2].map((index) => (
                    <button
                        onClick={(event) => {
                            event.preventDefault()
                            setCurrentIndex(index);
                        }}
                        className={
                            index === currentIndex ? "w-16 h-4 bg-white rounded-full transition-all duration-300 ease-in-out" : "w-4 h-4 bg-white rounded-full transition-all duration-300 ease-in-out"
                        }
                    ></button>
                ))}
            </div>
        </div>
    );
}
