import React, { useEffect, useRef, useState } from "react";
import CardLevels from "./CardLevels";

// components

export default function Level() {
	return (
		<>
			<CardLevels data-title="All levels" />
		</>
	);
}
