import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProgramEditing from "Management/Sessions/ProgramEditing";
import SelectOption from "Components/Dropdowns/SelectOption";
import Loading from "Components/Loaders/Loading";

// components

const GroupForm = ({ action, data, getGroups, hide }) => {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();
	const [url] = useAtom(urlAtom);
	const [department] = useAtom(departmentAtom);

	const method = action === "add" ? "post" : "put";
	const [formData, setFormData] = useState({
		annex: "",
		module: "",
		modules: [],
		type: "",
		capacity: "",
		nb_session: "",
		teacher_id: "",
		level: {},
		price_per_month: "",
		search: "",
		percentage: 0,
	});
	const [steps, setSteps] = useState("1");
	const [group, setGroup] = useState({});
	const [levels, setLevels] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [levelPage, setLevelPage] = useState(1);
	const [teacherPage, setTeacherPage] = useState(1);
	const [lastLevelPage, setLastLevelPage] = useState(1);
	const [lastTeacherPage, setLastTeacherPage] = useState(1);
	const [levelSearch, setLevelSearch] = useState("");
	const [teacherSearch, setTeacherSearch] = useState("");

	const handleInputChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const fetchLevels = async () => {
		const response = await axios
			.get(`${url}/levels`, {
				params: {
					page: levelPage,
					search: levelSearch,
				},
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: `${item.education || ""} ${item.year || ""} ${item.specialty || ""}`.trim(),
		}));
		setLevels(data);
		setLastLevelPage(response?.data?.last_page);
	};

	const fetchTeachers = async () => {
		const response = await axios
			.get(`${url}/teachers`, {
				params: { level: formData.level, page: teacherPage, search: teacherSearch },
				headers: {
					Accept: "Application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.catch(() => {});
		const data = response?.data?.data.map((item) => ({
			...item,
			label: item.user.name,
		}));
		setTeachers(data);
		setLastTeacherPage(response?.data?.last_page);
	};

	const addGroup = async (event) => {
		event.preventDefault();

		setLoading(true);
		await axios({
			url: action === "add" ? `${url}/groups` : `${url}/groups/${group.id}`,
			method,
			data: {
				capacity: formData.capacity,
				module: formData.module,
				teacher_id: formData.teacher_id,
				level_id: formData.level.id,
				price_per_month: formData.price_per_month,
				nb_session: formData.nb_session,
				type: formData.type,
				annex: formData.annex,
				percentage: formData.percentage,
			},
			headers: {
				Accept: "Application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				toast.success(`Group ${action}ed`, {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setGroup(response.data);
				setSteps("2");
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchLevels();
	}, [levelSearch, levelPage]);

	useEffect(() => {
		fetchTeachers();
	}, [teacherPage, teacherSearch]);

	useEffect(() => {
		if (action === "edit") {
			handleInputChange("annex", data.annex);
			handleInputChange("level", data.level);
			handleInputChange("teacher_id", data.teacher_id);
			handleInputChange("module", data.module);
			handleInputChange("type", data.type);
			handleInputChange("nb_session", data.nb_session);
			handleInputChange("capacity", data.capacity);
			handleInputChange("price_per_month", data.price_per_month);
			setGroup(data)
		}
	}, []);

	return (
		<>
			{steps === "1" ? (
				<form className="px-4 w-full">
					<div className="flex w-full flex-wrap gap-4">
						{/* Annex & Group Name Fields (Same Line) */}
						<div className="flex gap-4 w-full">
							<div className="w-full md:w-1/2">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="annex">
									Annex
								</label>
								<input
									list="annexOptions"
									className="w-full p-2 text-slate-600 placeholder-slate-400 bg-white rounded-lg shadow focus:outline-none focus:ring"
									onChange={(event) => handleInputChange("annex", event.target.value)}
									value={formData.annex}
									placeholder="Select or type an annex"
								/>
								<datalist id="annexOptions">
									<option value="city center">Annex City Center (middle of Wilaya)</option>
									<option value="street 500">Annex Street 500</option>
								</datalist>
							</div>
							<div className="w-full md:w-1/2">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="teachers">
									Teachers
								</label>
								<SelectOption
									onChange={(option) => {
										handleInputChange("teacher_id", option.id);
										handleInputChange("percentage", option.percentage);
										setLevelSearch(option.levels);
										handleInputChange(
											"modules",
											option.modules?.split("|").map((module) => ({ id: module, label: module }))
										);
									}}
									data={teachers}
									page={teacherPage}
									setPage={setTeacherPage}
									withPagination={true}
									setSearch={setTeacherSearch}
									lastPage={lastTeacherPage}
								/>
							</div>
						</div>

						{/* Teachers & Levels Fields (Same Line) */}
						<div className="flex gap-4 w-full">
							<div className="w-full md:w-1/2">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="module">
									Module
								</label>
								<SelectOption onChange={(option) => handleInputChange("module", option.id)} data={formData.modules} />
							</div>
							<div className="w-full md:w-1/2">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="levels">
									Levels
								</label>
								<SelectOption
									setSearch={setLevelSearch}
									lastPage={lastLevelPage}
									onChange={(option) => handleInputChange("level", option)}
									data={levels}
									page={levelPage}
									setPage={setLevelPage}
									withPagination={true}
								/>
							</div>
						</div>

						{/* Module & Type Fields (Same Line) */}
						<div className="flex gap-4 w-full">
							<div className="w-full md:w-1/2">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="type">
									Type
								</label>
								<select
									className="w-full p-2 text-slate-600 placeholder-slate-400 bg-white rounded-lg shadow focus:outline-none focus:ring"
									onChange={(event) => handleInputChange("type", event.target.value)}
									value={formData.type}
									id="type"
								>
									<option value="" disabled>
										Select a type
									</option>
									<option value="normal">Normal</option>
									<option value="special">Special</option>
									<option value="individual">Individual</option>
									<option value="revision">Revision</option>
								</select>
							</div>

							<div className="w-full md:w-1/2">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="percentage">
									Percentage
								</label>
								<input
									type="number"
									onWheel={(event) => event.target.blur()}
									className="w-full p-2 text-slate-600 placeholder-slate-400 bg-white rounded-lg shadow focus:outline-none focus:ring"
									onChange={(event) => handleInputChange("percentage", event.target.value)}
									value={formData.percentage}
									placeholder="Enter percentage for this group"
								/>
							</div>
						</div>

						{/* Number of Sessions, Capacity, and Price Fields (Three Columns) */}
						<div className="flex gap-4 w-full">
							<div className="w-full md:w-1/3">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="nb_session">
									Number of Sessions
								</label>
								<input
									type="number"
									onWheel={(event) => event.target.blur()}
									className="w-full p-2 text-slate-600 placeholder-slate-400 bg-white rounded-lg shadow focus:outline-none focus:ring"
									onChange={(event) => handleInputChange("nb_session", event.target.value)}
									value={formData.nb_session}
									placeholder="Enter number of sessions"
								/>
							</div>

							<div className="w-full md:w-1/3">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="capacity">
									Capacity
								</label>
								<input
									type="number"
									onWheel={(event) => event.target.blur()}
									className="w-full p-2 text-slate-600 placeholder-slate-400 bg-white rounded-lg shadow focus:outline-none focus:ring"
									onChange={(event) => handleInputChange("capacity", event.target.value)}
									value={formData.capacity}
									placeholder="Enter capacity"
								/>
							</div>

							<div className="w-full md:w-1/3">
								<label className="block uppercase text-slate-600 font-bold mb-2" htmlFor="price_per_month">
									Price Per Month
								</label>
								<input
									type="number"
									onWheel={(event) => event.target.blur()}
									className="w-full p-2 text-slate-600 placeholder-slate-400 bg-white rounded-lg shadow focus:outline-none focus:ring"
									onChange={(event) => handleInputChange("price_per_month", event.target.value)}
									value={formData.price_per_month}
									placeholder="Enter price per month"
								/>
							</div>
						</div>
					</div>

					{/* Submit Button */}
					<div className="w-full flex justify-center mt-4">
						<button
							className="w-full bg-sky-600 hover:bg-sky-700 text-white font-bold uppercase py-2 px-6 rounded-lg shadow focus:outline-none focus:ring active:bg-sky-800 transition duration-150"
							type="button"
							onClick={addGroup}
							disabled={loading}
						>
							{loading ? <Loading width="20px" height="20px" color="white" weight="2px" /> : "Next"}
						</button>
					</div>
				</form>
			) : (
				<div className="w-full p-4">
					<div className="flex justify-center w-full my-2">
						<button
							className="w-full px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
							type="button"
							onClick={(event) => {
								event.preventDefault();
								getGroups();
								hide();
							}}
						>
							Save
						</button>
					</div>
					<ProgramEditing defaultGroup={group} />
				</div>
			)}
		</>
	);
};

export default GroupForm;
