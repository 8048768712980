import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "config/axios";
import { Link, useNavigate } from "react-router-dom";
import notificationSound from "assets/audio/notification-1.wav";
import { toast } from "react-toastify";
import logo from "assets/img/Numidia Education Group Logo/jpg/primary.jpg";
import useEcho from "config/echo";
import AOS from "aos";

const NotificationDropdown = () => {
	const echo = useEcho();
	const [dropDown, setDropdown] = useState(false);
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [notifications, setNotifications] = useState([]);
	const dropdownRef = useRef(null);
	const navigate = useNavigate();
	const token = window.localStorage.getItem("token");
	const showDropDown = () => {
		setDropdown(!dropDown);
	};
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdown(false); // Hide dropdown if click outside
		}
	};
	const getNotifications = async () => {
		let options = {
			method: "get",
			url: auth_api_url + "/notifications",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then(async (response) => {
				setNotifications(response?.data?.notifications);
				if (response?.data?.notify) {
					var audioContext = new (window.AudioContext || window.webkitAudioContext)();
					var audioElement = new Audio(notificationSound);
					audioElement.addEventListener("error", (e) => {
						console.error("Error playing audio:", e);
						// Display a toast with a button to request audio permission here.
					});
					audioElement.addEventListener("canplaythrough", () => {
						const audioSource = audioContext.createMediaElementSource(audioElement);
						audioSource.connect(audioContext.destination);
						audioElement.play().catch(() => {
							toast.error("Audio error, please click to grant audio permission", {
								position: "top-center",
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
								autoClose: false,
							});
						});
					});
					toast.info("You have new Notifications", {
						position: "top-right",
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					const permission = await Notification.requestPermission();

					if (permission === "granted") {
						new Notification("You Got New Notifications", {
							icon: logo,
						});
					}
				}
			})
			.catch(() => {});
	};
	useEffect(() => {
		getNotifications();
	}, []);
	useEffect(() => {
		if (dropDown) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropDown]);
	useEffect(() => {
		const user_id = localStorage.getItem("user_id");
		if (user_id && echo) {
			console.log(user_id);
			const channel = echo.private(`notifications.${user_id}`).listen("NewNotifications", async () => {
				getNotifications();
			});

			// Cleanup when the component unmounts or profileUser changes
			return () => {
				channel.stopListening("NewNotifications");
				echo.disconnect();
			};
		}
	}, [echo]);
	useLayoutEffect(() => {
		AOS.init();
	}, [dropDown]);
	return (
		<>
			<div className="dropdown relative">
				{dropDown && (
					<div ref={dropdownRef} data-aos="fade-up" className="bg-white  z-50 float-left list-none text-left rounded-lg shadow-lg dropdown-menu absolute top-full right-0 w-96 my-2 -mx-2">
						<div className=" font-bold w-full bg-white z-30 flex justify-between items-center p-4 rounded-t-lg">
							<label className="text-xl font-bold">Notifications</label>
							<Link to="/inbox" className={"  font-medium px-4 py-2  flex w-fit gap-2 items-center justify-center text-white bg-sky-500 hover:bg-sky-700  rounded-full "}>
								See All
								<i className="fa-solid fa-chevron-right font-base "></i>
							</Link>
						</div>
						<hr className=" w-full" />

						<div className="flex flex-col p-4 gap-2 max-h-80 overflow-auto relative w-full">
							{notifications.map((notification, id) => {
								return notification.type === "warning" ? (
									<div key={id} className="flex flex-row gap-2  items-center rounded-lg  shadow-lg bg-yellow-50 p-2">
										<Link key={id} className={"   font-small w-full flex flex-1 flex-col  bg-transparent text-slate-700"}>
											<p className=" font-medium flex gap-2 items-center">
												<i className="fa-solid fa-exclamation text-lg text-yellow-500 "></i>
												{notification.title}
											</p>
											{notification.content}
										</Link>
										<hr />
									</div>
								) : notification.type === "info" ? (
									<div className="flex flex-row gap-2  items-center rounded-lg  shadow-lg bg-white p-2">
										<Link key={id} className={"   font-small w-full flex flex-1 flex-col  bg-transparent text-slate-700"}>
											<p className=" font-medium flex gap-2 items-center">
												<i className="fa-solid fa-info text-lg text-blue-500 "></i>
												{notification.title}
											</p>
											{notification.content}
										</Link>
										<hr />
									</div>
								) : notification.type === "success" ? (
									<div className="flex flex-row gap-2  items-center rounded-lg  shadow-lg bg-white p-2">
										<Link key={id} className={"   font-small w-full flex flex-1 flex-col  bg-transparent text-slate-700"}>
											<p className=" font-medium flex gap-2 items-center">
												<i className="fa-solid fa-check text-lg text-green-500 "></i>
												{notification.title}
											</p>
											{notification.content}
										</Link>
										<hr />
									</div>
								) : notification.type === "danger" ? (
									<div className="flex flex-row gap-2  items-center rounded-lg  shadow-lg bg-red-50 p-2">
										<Link key={id} className={"   font-small w-full flex flex-1 flex-col  bg-transparent text-slate-700"}>
											<p className=" font-medium flex gap-2 items-center">
												<i className="fa-solid fa-xmark text-lg text-red-500 "></i>
												{notification.title}
											</p>
											{notification.content}
										</Link>
										<hr />
									</div>
								) : (
									""
								);
							})}
						</div>
					</div>
				)}
				<a className="text-slate-500   cursor-pointer dropdown-toggle" onClick={() => showDropDown()}>
					<div className="items-center flex text-white text-3xl relative">
						<i className="fa-solid fa-bell text-xl"></i>
						{notifications.length > 0 ? <div className="rounded-full p-2 bg-red-500  absolute top-0 right-0 z-20 -translate-y-1 translate-x-1/2"></div> : ""}
					</div>
				</a>
			</div>
		</>
	);
};

export default NotificationDropdown;
