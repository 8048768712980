import React, { useEffect, useRef, useState } from "react";
import CardUsers from "./CardUsers";

// components

export default function Users() {
	return (
		<>
			<CardUsers data-title="All Users" />
		</>
	);
}
