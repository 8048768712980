import React, { useEffect, useState } from "react";
import axios from "config/axios.js";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import CardLink from "./CardLink.js";
import CardLevels from "Management/Levels/CardLevels.js";
import CardUsers from "Management/Users/Users/CardUsers.js";
import CardStudents from "Management/Users/Students/CardStudents.js";
import CardTeachers from "Management/Users/Teachers/CardTeachers.js";
import CardParents from "Management/Users/Parents/CardParents.js";
import CardGroups from "Management/Groups/CardGroups.js";
import Posts from "Pages/News/Posts.js";
import Sessions from "Management/Sessions/Sessions.js";
import Financials from "Management/Financials/Financials.js";
import Departments from "Components/Departments.js";
import Attendance from "Management/Sessions/Attendance.js";
import Exams from "Management/Exams/Exams.js";

import { useNavigate } from "react-router-dom";
import Dawarats from "Pages/Dawarat/Dawarat/Dawarats.js";
import { Dashboard } from "Pages/Dawarat/Dashboard.js";

export default function Cards() {
	const [modalId, setModalId] = useState(null);
	const [department, setDepartment] = useAtom(departmentAtom);
	const [title, setTitle] = useState("financials");

	const [financials, setFinancials] = useState("0.00 DA");
	const [departments, setDepartments] = useState("Numidia Departments");
	const [levels, setLevels] = useState(0);
	const [exam, setExams] = useState("Student Evaluation");

	const [users, setUsers] = useState(0);
	const [students, setStudents] = useState(0);
	const [parents, setParents] = useState(0);
	const [teachers, setTeachers] = useState(0);

	const [groups, setGroups] = useState(0);
	const [sessions, setSessions] = useState("Groups Sessions Control");
	const [news, setNews] = useState("Get The Latest News");
	const [dawarat, setDawarat] = useState("Dawarats Management");
	const [attendance, setAttendance] = useState("Presence Control");

	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);

	const navigate = useNavigate();

	const getStats = () => {
		let options = {
			method: "get",
			url: url + "/statistics/dashboard",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				const data = response.data;

				setFinancials(data.financials.balance + ".00 DA");
				setLevels(data.levels);
				setUsers(data.users);
				setStudents(data.students);
				setParents(data.parents);
				setTeachers(data.teachers);
				setGroups(data.groups);
			})
			.catch(() => {});
	};

	useEffect(() => {
		getStats();
		setModalId(null);
	}, [department]);

	return (
		<>
			{modalId && (
				<div className="fixed bg-black bg-opacity-25 top-0 left-0 z-50 w-full h-screen  flex justify-center items-center px-12">
					<div
						data-aos="zoom-in"
						data-aos-duration={250}
						layoutId={modalId}
						className="relative bg-slate-50 bg-opacity-90 backdrop-blur-lg w-full rounded-lg h-[calc(100vh-3rem)]   max-w-7xl  flex flex-col align-middle overflow-auto scrollbar-hide gap-4 p-4"
					>
						<div className="flex sticky top-0 right-0 flex-row-reverse items-center  justify-between ">
							<button
								className="text-center hover:text-white hover:bg-red-600 text-gray-500 w-8 h-8 rounded-full hover:shadow-lg outline-none flex items-center justify-center ease-linear transition-all duration-150"
								type="button"
								onClick={(event) => {
									event.preventDefault();
									getStats();
									setModalId(null);
								}}
							>
								<i className="fa-solid fa-close text-base"></i>
							</button>
						</div>
						<div className="mb-8 p-4">
							{title === "financials" ? (
								<Financials />
							) : title === "departments" ? (
								<Departments
									hide={() => {
										setModalId(null);
										getStats();
									}}
								/>
							) : title === "levels" ? (
								<CardLevels />
							) : title === "Exams" ? (
								<Exams />
							) : title === "users" ? (
								<CardUsers />
							) : title === "students" ? (
								<CardStudents />
							) : title === "parents" ? (
								<CardParents />
							) : title === "teachers" ? (
								<CardTeachers />
							) : title === "groups" ? (
								<CardGroups />
							) : title === "sessions" ? (
								<Sessions />
							) : title === "news" ? (
								<Posts />
							) : title === "attendance" ? (
								<Attendance />
							) : title === "dawarats" ? (
								<Dashboard />
							) :(
								""
							)}
						</div>
					</div>
				</div>
			)}
			{/* Header */}
			<div className="flex flex-col">
				<div className="bg-sky-600 py-8 px-12 w-full">
					<div className="grid grid-cols-2 xl:grid-cols-4 gap-8">
						<div
							layoutId={2}
							onClick={(event) => {
								event.preventDefault();
								setTitle("departments");
								setModalId(2);
							}}
						>
							<CardLink statSubtitle="departments" statTitle={departments} statIconName="fa-solid fa-school" statIconColor="bg-blue-500" />
						</div>
						<div
							layoutId={6}
							onClick={(event) => {
								event.preventDefault();
								setTitle("students");
								setModalId(6);
							}}
						>
							<CardLink statSubtitle="students" statTitle={students} statIconName="fa-solid fa-users" statIconColor="bg-blue-900" />
						</div>
						<div
							layoutId={10}
							onClick={(event) => {
								event.preventDefault();
								setTitle("sessions");
								setModalId(10);
							}}
						>
							<CardLink statSubtitle="sessions" statTitle={sessions} statIconName="fa-solid fa-users-rectangle" statIconColor="bg-red-500" />
						</div>
						<div
							layoutId={11}
							onClick={(event) => {
								event.preventDefault();
								setTitle("attendance");
								setModalId(11);
							}}
						>
							<CardLink statSubtitle="attendance" statTitle={attendance} statIconName="fa-solid fa-clipboard-list" statIconColor="bg-yellow-500" />
						</div>
					</div>
				</div>
				<div className="px-12  py-8 w-full">
					<div className="grid grid-cols-2 xl:grid-cols-4 gap-8">
						<div
							layoutId={8}
							onClick={(event) => {
								event.preventDefault();
								setTitle("teachers");
								setModalId(8);
							}}
						>
							<CardLink statSubtitle="Teachers" statTitle={teachers} statIconName="fa-solid fa-chalkboard-user" statIconColor="bg-sky-500" />
						</div>
						<div
							layoutId={9}
							onClick={(event) => {
								event.preventDefault();
								setTitle("groups");
								setModalId(9);
							}}
						>
							<CardLink statSubtitle="groups" statTitle={groups} statIconName="fa-solid fa-user-group" statIconColor="bg-cyan-500" />
						</div>
						<div
							layoutId={13}
							onClick={(event) => {
								event.preventDefault();
								setTitle("dawarats");
								setModalId(13);
							}}
						>
							<CardLink statSubtitle="Dawarat" statTitle={dawarat} statIconName="fa-solid fa-book" statIconColor="bg-indigo-800" />
						</div>
						<div
							layoutId={3}
							onClick={(event) => {
								event.preventDefault();
								setTitle("levels");
								setModalId(3);
							}}
						>
							<CardLink statSubtitle="levels" statTitle={levels} statIconName="fa-solid fa-window-restore" statIconColor="bg-slate-500" />
						</div>
					</div>
				</div>
				<div className="px-12  py-8 w-full">
					<div className="grid grid-cols-2 xl:grid-cols-4 gap-8">
						<div
							layoutId={1}
							onClick={(event) => {
								event.preventDefault();
								setTitle("financials");
								setModalId(1);
							}}
						>
							<CardLink statSubtitle="financials" statTitle={financials} statIconName="fa-solid fa-hand-holding-dollar" statIconColor="bg-green-500" />
						</div>
						<div
							layoutId={5}
							onClick={(event) => {
								event.preventDefault();
								setTitle("users");
								setModalId(5);
							}}
						>
							<CardLink statSubtitle="users" statTitle={users} statIconName="fa-solid fa-users-gear" statIconColor="bg-green-900" />
						</div>

						<div
							layoutId={7}
							onClick={(event) => {
								event.preventDefault();
								setTitle("parents");
								setModalId(7);
							}}
						>
							<CardLink statSubtitle="parents" statTitle={parents} statIconName="fa-solid fa-person" statIconColor="bg-slate-700" />
						</div>
						<div
							layoutId={4}
							onClick={(event) => {
								event.preventDefault();
								setTitle("Exams");
								setModalId(4);
							}}
						>
							<CardLink statSubtitle="Exams" statTitle={exam} statIconName="fa-solid fa-book" statIconColor="bg-indigo-800" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
