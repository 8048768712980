import { useAtom } from "jotai";
import { urlAtom } from "index";
import { departmentAtom } from "index";
import { useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useEffect, useState } from "react";
import PortalWrapper from "Layouts/PortalWrapper";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import FormModal from "./Dropdowns/FormModal";

export default function Departments({ hide }) {
	const [url, setUrl] = useAtom(urlAtom);
	const [department, setDepartment] = useAtom(departmentAtom);
	const token = window.localStorage.getItem("token");
	const user_id = window.localStorage.getItem("user_id");
	const user_role = window.localStorage.getItem("role");
	const [form, setForm] = useState(false);
	const [user, setUser] = useState({});

	const navigate = useNavigate();

	const change_department = (department) => {
		if (department === "school") {
			let options = {
				method: "get",
				url: process.env.REACT_APP_API_URL_SCHOOL + "/user.verify/" + user_id,
				headers: {
					Authorization: "Bearer " + token,
					Accept: "Application/json",
				},
			};
			axios(options)
				.then((response) => {
					if (response?.data?.message == "found") {
						setUrl(process.env.REACT_APP_API_URL_SCHOOL);
						setDepartment("school");
						navigate("/dashboard");
					} else if (user_role === "parent" || user_role === "student" || user_role === "client") {
						setForm(true);
					} else {
						toast.warning(" Please contact the administration to register in this department", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
					}
				})
				.catch(() => { });
		} else if (department === "academy") {
			let options = {
				method: "get",
				url: process.env.REACT_APP_API_URL_ACADEMY + "/user.verify/" + user_id,
				headers: {
					Authorization: "Bearer " + token,
					Accept: "Application/json",
				},
			};
			axios(options)
				.then((response) => {
					if (response?.data?.message == "found") {
						setUrl(process.env.REACT_APP_API_URL_ACADEMY);
						setDepartment("academy");
						navigate("/dashboard");
					} else if (user_role === "parent" || user_role === "student" || user_role === "client") {
						setForm(true);
					} else {
						toast.warning(" Please contact the administration to register in this department", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
					}
				})
				.catch(() => { });
		} else if (department === "profession") {
			let options = {
				method: "get",
				url: process.env.REACT_APP_API_URL_PROFESSION + "/user.verify/" + user_id,
				headers: {
					Authorization: "Bearer " + token,
					Accept: "Application/json",
				},
			};
			axios(options)
				.then((response) => {
					if (response?.data?.message == "found") {
						setUrl(process.env.REACT_APP_API_URL_PROFESSION);
						setDepartment("profession");
						navigate("/dashboard");
					} else if (user_role === "parent" || user_role === "student" || user_role === "client") {
						setForm(true);
					} else {
						toast.warning(" Please contact the administration to register in this department", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
					}
				})
				.catch(() => { });
		}
	};


	const getUser = async () => {
		let options = {
			method: "get",
			url: url + "/users/" + user_id,
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setUser(response.data);
			})
			.catch(() => { });
	};

	useEffect(() => {
		getUser();
	}, []);
	return (
		<>
			{form ? (
				<FormModal hide={() => hide} title={"Import Data to Department: " + department}>
					{" "}
				</FormModal>
			) : (
				""
			)}
			<div className="justify-center flex flex-wrap">
				<div className="w-full  ">
					<div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
						<div className="w-full ">
							<h5 className=" font-semibold  text-center my-4">Numidia School</h5>
							<button
								onClick={() => change_department("school")}
								className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
							>
								<img alt="..." className="align-middle rounded-lg" src={require("assets/img/school.jpg")} />
							</button>
						</div>

						<div className="w-full">
							<h5 className=" font-semibold  text-center my-4">Numidia Academy</h5>
							<button
								onClick={() => change_department("academy")}
								className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
							>
								<img alt="..." className="align-middle rounded-lg" src={require("assets/img/academy.jpg")} />
							</button>
						</div>

						<div className="w-full">
							<h5 className=" font-semibold  text-center my-4">Numidia Profession</h5>
							<button
								onClick={() => change_department("profession")}
								className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
							>
								<img alt="..." className="align-middle rounded-lg" src={require("assets/img/profession.jpg")} />
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
