import { useEffect, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterSmallDark from "Components/Footers/FooterSmallDark";
import PortalWrapper from "Layouts/PortalWrapper";
import Loading from "Components/Loaders/Loading";
import SelectOption from "Components/Dropdowns/SelectOption";

export default function Register() {
	const { annex } = useParams();
	const [department, setDepartment] = useAtom(departmentAtom);
	const [url, setUrl] = useAtom(urlAtom);
	const [email, setEmail] = useState("");
	const [form, setForm] = useState(false);
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [role, setRole] = useState("student");
	const [gender, setGender] = useState("male");
	const [phone_number, setPhoneNumber] = useState("");
	const [password, setPassword] = useState("");
	const [password_confirmation, setPasswordConfirmation] = useState("");
	const [step, setStep] = useState(true);
	const [levels, setLevels] = useState([]);
	const [page, setPage] = useState(1);
	const [lastPage, setLastPage] = useState(1);
	const [levelSearch, setLevelSearch] = useState("");
	const [level_id, setLevelId] = useState("");
	const [loading, setLoading] = useState(false);

	let navigate = useNavigate();

	const next = (event) => {
		event.preventDefault();
		setStep(false);
	};
	const back = (event) => {
		event.preventDefault();
		setStep(true);
	};

	const register = (event) => {
		event.preventDefault();
		setLoading(true);
		let data = {
			email: email,
			name: firstname + " " + lastname,
			role,
			gender,
			phone_number,
			password_confirmation: password_confirmation,
			password,
			level_id,
		};
		axios({
			// Endpoint to send files
			url: url + "/register",
			method: "POST",

			headers: {
				Accept: "Application/json",
				"Access-Control-Allow-Origin": "*",
			},

			data: data,
		})
			.then(async (response) => {
				Swal.fire({
					title: "You successfuly registred",
					text: "please check your email for confirmation",
					icon: "success",
					iconColor: "#3dc00c",
				}).then(async () => {
					navigate("/login");
				});
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};
	const getLevels = () => {
		axios({
			// Endpoint to send files
			url: url + "/levels",
			method: "get",
			params: {
				page,
				search: levelSearch,
			},
			headers: {
				Accept: "Application/json",
			},
		})
			// Handle the response from backend here
			.then((response) => {
				let data = response?.data?.data.map((item) => {
					return { ...item, label: (item.year ? item.year + " " : "") + (item.education ? item.education + " " : "") + (item.specialty ? item.specialty : "") };
				});
				setLastPage(response?.data?.last_page);
				setLevels(data);
			})
			.catch(() => {});
	};

	useEffect(() => {
		if (annex == "school") {
			setUrl(process.env.REACT_APP_API_URL_SCHOOL);
			setDepartment("school");
		} else if (annex == "profession") {
			setUrl(process.env.REACT_APP_API_URL_PROFESSION);
			setDepartment("profession");
		} else if (annex == "academy") {
			setUrl(process.env.REACT_APP_API_URL_ACADEMY);
			setDepartment("academy");
		} else {
			setForm(true);
		}
	}, []);
	useEffect(() => {
		getLevels();
	}, [url, page, levelSearch]);

	return (
		<>
			{form ? (
				<PortalWrapper>
					<div onClick={() => setForm(false)} className="fixed bg-black bg-opacity-25 top-0 left-0 z-50 w-full h-screen flex justify-center items-center p-24">
						<div
							onClick={(event) => event.stopPropagation()}
							data-aos="zoom-in-down"
							className=" bg-slate-50 bg-opacity-60 backdrop-blur-lg w-fit rounded-lg h-fit max-h-full  flex flex-col align-middle overflow-auto scrollbar-hide p-8 xl:p-24"
						>
							<div className="justify-center flex flex-wrap">
								<div className="w-full ">
									<h4 className="text-[rgb(1,42,74)] font-semibold w-full text-center my-4 font-poppins capitalize">Please select a department:</h4>
									<div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
										<div className="w-full ">
											<h5 className=" font-semibold  text-center my-4">Numidia School</h5>
											<button
												onClick={(event) => {
													event.stopPropagation();
													setUrl(process.env.REACT_APP_API_URL_SCHOOL);
													setDepartment("school");
													setForm(false);
												}}
												className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
											>
												<img alt="..." className="align-middle rounded-lg" src={require("assets/img/school.jpg")} />
											</button>
										</div>

										<div className="w-full">
											<h5 className=" font-semibold  text-center my-4">Numidia Academy</h5>
											<button
												onClick={(event) => {
													event.stopPropagation();
													setUrl(process.env.REACT_APP_API_URL_ACADEMY);
													setDepartment("academy");
													setForm(false);
												}}
												className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
											>
												<img alt="..." className="align-middle rounded-lg" src={require("assets/img/academy.jpg")} />
											</button>
										</div>

										<div className="w-full">
											<h5 className=" font-semibold  text-center my-4">Numidia Profession</h5>
											<button
												onClick={(event) => {
													event.stopPropagation();
													setUrl(process.env.REACT_APP_API_URL_PROFESSION);
													setDepartment("profession");
													setForm(false);
												}}
												className="hover:-mt-4  flex flex-col break-words bg-white w-full  shadow-lg rounded-lg ease-linear transition-all duration-150"
											>
												<img alt="..." className="align-middle rounded-lg" src={require("assets/img/profession.jpg")} />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</PortalWrapper>
			) : (
				""
			)}
			<div className="flex flex-col bg-gradient-to-r min-h-screen from-[rgb(1,42,74)] to-sky-600 w-full h-fit justify-between">
				<div className="flex content-center  justify-center z-50 w-full p-8 m-auto">
					<div className="w-full lg:w-1/2 xl:1/3 my-24">
						<div className="relative flex flex-col break-words w-full  shadow-lg rounded-3xl bg-slate-200 p-6 ">
							<Link to="/" className="absolute w-24 h-24 left-1/2 -translate-x-1/2 -translate-y-2/3 rounded-full bg-white z-50 shadow-lg ">
								<img className="object-contain w-full h-full" src={require("assets/img/auth-logo.png")} />
							</Link>

							<div className="flex-auto mt-12">
								<div className="flex flex-row items-center justify-center gap-4">
									<Link to={"/login"} className="text-slate-900 bg-white rounded-full px-4 py-2   bold hover:shadow-lg transition-all duration-300 ease-in-out font-semibold">
										Login
									</Link>
									<Link to={"/register"} className="text-slate-900 bg-slate-300 rounded-full  px-4 py-2 bold font-semibold">
										Sign Up
									</Link>
								</div>
								<form>
									{step ? (
										<div className="transition-all">
											<div className="relative w-full flex gap-2 justify-between items-center flex-row my-2">
												<div className="w-full">
													<label className="block capitalize text-blueGray-600  font-bold ">Sign Up As</label>
													<select
														className="my-2 p-3 placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
														default="student"
														onChange={(event) => {
															setRole(event.target.value);
														}}
													>
														<option value="student">Student</option>
														<option value="supervisor">Parent</option>
													</select>
												</div>
												{role === "student" ? (
													<div className="w-full">
														<label className="block capitalize text-blueGray-600  font-bold ">Level</label>
														<SelectOption
															onChange={(option) => {
																setLevelId(option.id);
															}}
															data={levels}
															page={page}
															setPage={setPage}
															withPagination={true}
															lastPage={lastPage}
															setSearch={setLevelSearch}
														></SelectOption>
													</div>
												) : (
													""
												)}
											</div>
											<div className="relative w-full flex flex-row gap-2 my-2">
												<div className=" w-1/2 ">
													<label className="block capitalize text-blueGray-600  font-bold ">First Name</label>
													<input
														type="text"
														className="my-2 p-3   placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
														placeholder="firstname"
														value={firstname}
														onChange={(event) => {
															setFirstname(event.target.value);
														}}
													/>
												</div>

												<div className=" w-1/2">
													<label className="block capitalize text-blueGray-600  font-bold ">Family Name</label>
													<input
														type="text"
														className="p-3 my-2   placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
														placeholder="Lastname"
														value={lastname}
														onChange={(event) => {
															setLastname(event.target.value);
														}}
													/>
												</div>
											</div>

											<div className="relative w-full flex flex-row gap-2 my-2">
												<div className=" w-2/3">
													<label className="block capitalize text-blueGray-600  font-bold ">Phone Number</label>
													<input
														type="number"
														onWheel={(event) => event.target.blur()}
														className="my-2 p-3  placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
														placeholder="Phone Number"
														onChange={(event) => {
															setPhoneNumber(event.target.value);
														}}
													/>
												</div>

												<div className=" w-1/3">
													<label className="block capitalize text-blueGray-600  font-bold ">Gender</label>
													<select
														className="my-2 p-3  placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
														default="male"
														onChange={(event) => {
															setGender(event.target.value);
														}}
													>
														<option value="male">Male</option>
														<option value="female">Female</option>
													</select>
												</div>
											</div>
											<div className="text-center  my-4 ">
												<button
													className="bg-[rgb(10,58,92)] flex items-center justify-center w-full text-white active:bg-slate-600  font-bold capitalize py-2 gap-2  rounded-lg shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
													type="button"
													onClick={(event) => next(event)}
												>
													Next
													<i className="fa-solid fa-forward  "></i>
												</button>
											</div>
										</div>
									) : (
										<div className="transition-all">
											<div className="relative w-full my-2">
												<label className="block capitalize text-blueGray-600  font-bold ">Email</label>
												<input
													type="email"
													value={email}
													className="my-2 p-3  placeholder-blueGray-300 text-blueGray-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
													placeholder="Email"
													onChange={(event) => {
														setEmail(event.target.value);
													}}
												/>
											</div>

											<div className="relative w-full my-2">
												<label className="block capitalize text-blueGray-600  font-bold " htmlFor="grid-password">
													Password
												</label>
												<input
													type="password"
													className="my-2 p-3  placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
													placeholder="Password"
													value={password}
													onChange={(event) => {
														setPassword(event.target.value);
													}}
												/>
											</div>
											<div className="relative w-full my-2">
												<label className="block capitalize text-blueGray-600  font-bold " htmlFor="grid-password">
													Password Confirmation
												</label>
												<input
													type="password"
													value={password_confirmation}
													className="my-2 p-3  placeholder-blueGray-300 text-slate-600 bg-white rounded-lg  shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
													placeholder="Password Confirmation"
													onChange={(event) => {
														setPasswordConfirmation(event.target.value);
													}}
												/>
											</div>

											<div className="text-center gap-2 my-4  flex flex-row">
												<button
													className="bg-[rgb(10,58,92)] text-white active:bg-slate-600 justify-center  flex items-center gap-2 py-2 font-bold capitalize   rounded-lg shadow hover:shadow-lg outline-none focus:outline-none   w-1/2 ease-linear transition-all duration-150"
													type="button"
													onClick={(event) => back(event)}
												>
													<i className="fa-solid fa-backward "></i>
													Back
												</button>
												<button
													className="bg-[rgb(10,58,92)] text-white active:bg-slate-600 text-center font-bold py-2 capitalize   rounded-lg shadow hover:shadow-lg outline-none focus:outline-none    w-1/2 ease-linear transition-all duration-150"
													type="submit"
													onClick={(event) => register(event)}
													disabled={loading}
												>
													{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
													Sign Up
												</button>
											</div>
										</div>
									)}
									<div>
										By signing up you agree to the Numidia{" "}
										<Link className="text-blue-600 underline" to="/contact.us">
											Terms of Service
										</Link>{" "}
										and Numidia{" "}
										<Link className="text-blue-600 underline" to="/contact.us">
											Privacy Policy
										</Link>
										.
									</div>
									<div>
										Current department is Numidia {department} click on to{" "}
										<Link className="text-blue-600 underline" onClick={() => setForm(true)}>
											change department.
										</Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<FooterSmallDark />
			</div>
		</>
	);
}
