import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Numidia from "./Numidia";
import NumidiaAcademy from "./NumidiaAcademy";
import NumidiaProfession from "./NumidiaProfession";
import NumidiaSchool from "./NumidiaSchool";
import { useTranslation } from "react-i18next";

export function Index() {
	const { t } = useTranslation();

	const [current_slide, setCurrentSlide] = useState([true, false, false, false]);
	const [fade, setAnimation] = useState("fade-left");

	const prev = () => {
		setAnimation("fade-right");
		const shiftedArray = [...current_slide];
		const firstElement = shiftedArray.shift();
		shiftedArray.push(firstElement);
		setCurrentSlide(shiftedArray);
	};
	const next = () => {
		setAnimation("fade-left");
		const shiftedArray = [...current_slide];
		const lastElement = shiftedArray.pop();
		shiftedArray.unshift(lastElement);
		setCurrentSlide(shiftedArray);
	};
	useEffect(() => {
		const autoPlayTimer = setInterval(() => {
			next();
		}, 3000);

		return () => {
			clearInterval(autoPlayTimer);
		};
	}, [current_slide]);

	return (
		<>
			<section className="flex justify-center items-center flex-col w-full relative bg-gradient-to-r from-[rgb(1,42,74)] to-[rgb(42,111,150)]">
				<button onClick={() => prev()} className="absolute z-50 left-0 h-full w-16 cursor-pointer flex flex-row items-center opacity-0 hover:opacity-100 transition-all duration-200 ">
					<img className="object-contain " src={require("assets/img/left-slide.svg").default} />
				</button>
				<div className="flex flex-col relative px-24 w-full gap-8">
					<div className="flex flex-row">
						{current_slide[0] ? (
							<Numidia fade={fade} t={t} />
						) : current_slide[1] ? (
							<NumidiaAcademy fade={fade} t={t} />
						) : current_slide[2] ? (
							<NumidiaProfession fade={fade} t={t} />
						) : current_slide[3] ? (
							<NumidiaSchool fade={fade} t={t} />
						) : (
							""
						)}
					</div>

					<div className="w-full z-50 flex flex-row justify-center items-center gap-4 ">
						<button
							onClick={(event) => {
								event.preventDefault()
								setCurrentSlide([true, false, false, false]);
							}}
							className={
								current_slide[0] ? "w-16 h-4 bg-white rounded-full transition-all duration-300 ease-in-out" : "w-4 h-4 bg-white rounded-full transition-all duration-300 ease-in-out"
							}
						></button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setCurrentSlide([false, true, false, false]);
							}}
							className={
								current_slide[1] ? "w-16 h-4 bg-white rounded-full transition-all duration-300 ease-in-out" : "w-4 h-4 bg-white rounded-full transition-all duration-300 ease-in-out"
							}
						></button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setCurrentSlide([false, false, true, false]);
							}}
							className={
								current_slide[2] ? "w-16 h-4 bg-white rounded-full transition-all duration-300 ease-in-out" : "w-4 h-4 bg-white rounded-full transition-all duration-300 ease-in-out"
							}
						></button>
						<button
							onClick={(event) => {
								event.preventDefault()
								setCurrentSlide([false, false, false, true]);
							}}
							className={
								current_slide[3] ? "w-16 h-4 bg-white rounded-full transition-all duration-300 ease-in-out" : "w-4 h-4 bg-white rounded-full transition-all duration-300 ease-in-out"
							}
						></button>
					</div>
				</div>

				<button onClick={() => next()} className="absolute z-50 right-0 top-0 h-full w-16 cursor-pointer flex flex-row items-center opacity-0 hover:opacity-100 transition-all duration-200">
					<img className="object-contain transform -scale-x-100" src={require("assets/img/left-slide.svg").default} />
				</button>
			</section>
			<div className="relative w-full h-24">
				<img className="w-full absolute top-0" src={require("assets/img/index-header.png")}></img>
			</div>
		</>
	);
}
