import CardParents from "./CardParents";

// components

export default function Parents() {
	return (
		<>
			<CardParents data-title="All parents" />
		</>
	);
}
