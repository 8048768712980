// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "assets/json/en.json";
import arTranslation from "assets/json/ar.json";

const resources = {
	en: { translation: enTranslation },
	ar: { translation: arTranslation },
};

i18n.use(initReactI18next).init({
	resources,
	lng: "en", // Default language
	keySeparator: false, // Allow nested translations
	interpolation: {
		escapeValue: false, // React already escapes values
	},
});

export default i18n;
