import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useAtom } from "jotai";
import { urlAtom } from "index";
import Swal from "sweetalert2";
import ParentForm from "./ParentForm";
import { toast } from "react-toastify";
import PortalWrapper from "Layouts/PortalWrapper";
import FormModal from "Components/Dropdowns/FormModal";

export default function CardParents({ color, title }) {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [form, setForm] = useState(false);
	const [parent, setParent] = useState({});
	const [parents, setParents] = useState([]);
	const [method, setMethod] = useState("add");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState("5");
	const [search, setSearch] = useState("");
	const [gender, setGender] = useState("");
	const [sortBy, setSortBy] = useState("created_at");
	const [sortDirection, setSortDirection] = useState("desc");

	const showForm = (action, data) => {
		setMethod(action);
		setParent(data);
		setForm(true);
	};
	const hide = () => {
		setForm(false);
	};
	const destroy = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				axios({
					// Endpoint to send files
					url: url + "/users/" + id,
					method: "delete",
					headers: {
						Accept: "Application/json",
						Authorization: "Bearer " + token,
					},
				})
					// Handle the response from backend here
					.then((response) => {
						toast.success("Parent deleted successful", {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
						});
						getParents();
					})
					.catch(() => {});
			}
		});
	};
	const navigate = useNavigate();

	const getParents = async () => {
		let options = {
			method: "get",
			url: url + "/parents",
			params: {
				page: currentPage,
				sortBy,
				sortDirection,
				perPage,
				search,
				gender,
			},
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options)
			.then((response) => {
				setParents(response?.data?.data);
				setTotalPages(response?.data?.last_page);
			})
			.catch(() => {});
	};
	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	useEffect(() => {
		getParents();
	}, [currentPage, sortBy, sortDirection, perPage, search, gender]);

	return (
		<>
			{form ? (
				<FormModal hide={hide} title={"Parent Form"}>
					<ParentForm action={method} data={parent} hide={hide} getParents={getParents}></ParentForm>
				</FormModal>
			) : (
				""
			)}
			<h4 className=" font-bold">Parents</h4>
			<div className="flex w-full justify-between align-middle items-center gap-2">
				<div className="flex flex-row flex-nowrap overflow-auto w-3/4 py-4 gap-2 whitespace-nowrap scrollbar-hide ">
					<div className="flex flex-col ">
						<label>Students Per Page:</label>
						<select
							className="bg-white p-2 rounded-lg shadow ease-linear transition-all duration-150 "
							value={perPage}
							onChange={(event) => {
								setPerPage(event.target.value);
							}}
						>
							<option value={totalPages * perPage}>All</option>
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="50">50</option>
						</select>
					</div>
					<div className="flex flex-col">
						<label>Gender Filter:</label>
						<select
							value={gender}
							className="bg-white p-2 rounded-lg shadow ease-linear transition-all duration-150 "
							onChange={(event) => {
								setGender(event.target.value);
							}}
						>
							<option value="">All</option>
							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>
				</div>
				<div className="flex flex-col my-4 ">
					<label>Search</label>
					<div className="flex flex-row flex-nowrap p-2 items-center bg-white rounded-lg shadow w-full ease-linear transition-all duration-150 ">
						<input
							className="focus:outline-none"
							type="text"
							value={search}
							onChange={(event) => {
								setSearch(event.target.value);
							}}
						></input>
						<i className="fa-solid fa-search"></i>
					</div>
				</div>
			</div>
			<div className="break-words w-full shadow-lg overflow-auto border-0">
				<div className="w-full">
					{/* Projects table */}
					<table className=" w-full border-collapse ">
						<thead className="sticky top-0 whitespace-nowrap">
							<tr>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("name");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Name
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("email");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Email
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>
									<button
										className=""
										onClick={(event) => {
											event.preventDefault();
											setSortBy("gender");
											if (sortDirection === "asc") {
												setSortDirection("desc");
											} else {
												setSortDirection("asc");
											}
										}}
									>
										<i className="fa-solid fa-filter"></i>
									</button>
									Gender
								</th>
								<th className={" align-middle uppercase font-semibold text-left bg-slate-50 text-slate-500 "}>Phone Number</th>

								<th className={" uppercase font-semibold flex justify-around transition-all duration-150 hover:bg-sky-900 text-white bg-blue-500"}>
									<Link
										className="w-full ease-linear "
										role={"button"}
										onClick={(event) => {
											event.preventDefault();
											showForm("add");
										}}
									>
										Add
										<i class="fa-solid fa-plus "></i>
									</Link>
								</th>
							</tr>
						</thead>
						<tbody>
							{parents.map((parent, id) => {
								return (
									<tr key={id} className="bg-white ">
										<th className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>
											<span className={"font-bold "}>{parent.user.name}</span>
										</th>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{parent.user.email}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{parent.user.gender}</td>
										<td className={" whitespace-nowrap border border-slate-200 text-left align-middle"}>{parent.user.phone_number}</td>

										<td className={" whitespace-nowrap border border-slate-200 text-left items-center flex justify-evenly gap-2"}>
											<Link
												role={"button"}
												onClick={(event) => {
													event.preventDefault();
													navigate("/users/" + parent.user_id);
												}}
												className="hover:text-blue-500 transition duration-150 ease-in-out  bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i className="fa-solid fa-info"></i>
											</Link>
											<Link
												role={"button"}
												onClick={(event) => {
													event.stopPropagation();
													showForm("edit", parent.user);
												}}
												className="hover:text-yellow-400 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-pen-to-square"></i>
											</Link>
											<Link
												role={"button"}
												onClick={(event) => {
													event.stopPropagation();
													destroy(parent.user.id);
												}}
												className="hover:text-red-600 transition duration-150 ease-in-out bg-white rounded-full shadow-md hover:shadow-lg w-8 h-8 text-center"
											>
												<i class="fa-solid fa-trash"></i>
											</Link>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="flex flex-row justify-center gap-2 my-4">
				<button
					className=" flex items-center justify-center gap-4 px-4 py-2 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === 1}
					onClick={() => handlePageChange(currentPage - 1)}
				>
					<i className="fa-solid fa-backward "></i>
					Previous
				</button>
				<button
					className="flex items-center justify-center gap-4 px-4 py-2 shadow hover:shadow-lg bg-white rounded-lg cursor-pointer disabled:cursor-default disabled:bg-slate-100"
					disabled={currentPage === totalPages}
					onClick={() => handlePageChange(currentPage + 1)}
				>
					Next
					<i className="fa-solid fa-forward "></i>
				</button>
			</div>
		</>
	);
}
