import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Barcode from "react-barcode";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";

export default function NumidiaReceipt({ user, option, hide }) {
	const token = window.localStorage.getItem("token");
	const navigate = useNavigate();
	const user_id = localStorage.getItem("user_id");

	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const [department, setDepartment] = useAtom(departmentAtom);

	const [amount, setAmount] = useState(500);

	var ReceiptRef = useRef();

	const withdraw = () => {
		axios({
			url: auth_api_url + "/wallet/add",
			method: "post",
			data: {
				amount: -amount,
				date: moment().format("YYYY-MM-DD"),
				user: user,
				type: "withdraw",
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {})
			.catch(() => {});
	};

	const deposit = () => {
		axios({
			url: auth_api_url + "/wallet/add",
			method: "post",
			data: {
				amount: amount,
				type: "deposit",
				date: moment().format("YYYY-MM-DD"),
				user: user,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {})
			.catch(() => {});
	};

	useEffect(() => {}, []);
	return (
		<>
			<div className="flex flex-wrap p-4">
				<div className="w-full ">
					<div className=" w-full ">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Amount
						</label>
						<input
							type="number"
							onWheel={(event) => event.target.blur()}
							className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Amount"
							onChange={(event) => {
								setAmount(event.target.value);
							}}
							value={amount}
						/>
					</div>
				</div>
			</div>
			<div className=" p-4">
				<div className="flex overflow-auto justify-center items-center">
					<div className="border border-black flex flex-col bg-white w-full p-2" ref={(el) => (ReceiptRef = el)}>
						<div className="flex flex-row justify-between items-center">
							<img className="w-24 h-24" src={require("assets/img/Numidia Education Group Logo/png/black.png")}></img>
							<div className="flex flex-col items-center">
								<p className=" font-bold ">{option} Receipt</p>
								<span className="">{moment().format("MMMM Do YYYY, h:mm")}</span>
							</div>
							{department === "school" ? (
								<img className="w-24 h-24" src={require("assets/img/Numidia School Logo/png/black.png")}></img>
							) : department === "profession" ? (
								<img className="w-24 h-24" src={require("assets/img/Numidia professional instiute Logo/png/black.png")}></img>
							) : department === "academy" ? (
								<img className="w-24 h-24" src={require("assets/img/Numidia Academy Logo/png/black.png")}></img>
							) : (
								""
							)}
						</div>

						<div className="flex flex-row items-center gap-4">
							<label>User</label>
							<span>{user.name}</span>
						</div>
						<div className="flex flex-row items-center gap-4">
							<label>Type</label>
							<span>{option}</span>
						</div>
						<hr className=" "></hr>
						<div className="flex flex-row items-center gap-4 font-bold ">
							<label>Total:</label>
							<span>{amount}.00 DA</span>
						</div>

						<div className="flex flex-row justify-center items-center">
							<Barcode fontSize={18} text={process.env.REACT_APP_URL} height={30} width={1} value={process.env.REACT_APP_URL} />
						</div>
					</div>
				</div>
				<div className="flex justify-center flex-row gap-4 my-4">
					<ReactToPrint
						trigger={() => {
							return (
								<button
									className="px-4 py-2 text-white bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
									type="button"
								>
									{option}
								</button>
							);
						}}
						onAfterPrint={() => {
							if (option === "withdraw") {
								withdraw();
							} else {
								deposit();
							}
							hide();
							toast.success("New " + option + " receipt", {
								position: "top-center",
								autoClose: 3000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
							});
						}}
						content={() => ReceiptRef}
					/>
				</div>
			</div>
		</>
	);
}
