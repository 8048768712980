import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
// components

export default function Inbox() {
	const auth_api_url = process.env.REACT_APP_API_URL_AUTH;
	const navigate = useNavigate();
	const [notifications, setNotifications] = useState([]);
	const token = window.localStorage.getItem("token");

	const mark_seen_all = async () => {
		let options = {
			method: "put",
			url: auth_api_url + "/notifications/seen/all",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			getNotifications();
		}).catch(() => { });
	};
	const clear_all = async () => {
		let options = {
			method: "delete",
			url: auth_api_url + "/notifications/clear",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			getNotifications();
		}).catch(() => { });
	};
	const mark_as_seen = async (id) => {
		let options = {
			method: "put",
			url: auth_api_url + "/notifications/seen/" + id,
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			getNotifications();
		}).catch(() => { });
	};
	const getNotifications = async () => {
		let options = {
			method: "get",
			url: auth_api_url + "/notifications/all",
			headers: {
				Authorization: "Bearer " + token,
				Accept: "Application/json",
			},
		};
		axios(options).then((response) => {
			setNotifications(response.data);
		}).catch(() => { });
	};
	useEffect(() => {
		getNotifications();
	}, []);
	return (
		<div className="p-4 flex flex-col bg-white shadow-lg rounded-lg">
			<div className="flex flex-col">
				<div className="flex flex-row justify-between items-center">
					<h4 className="font-bold">Notifications</h4>
					<div className="flex gap-4 w-fit">
						<button onClick={() => mark_seen_all()} className="px-4 py-2 font-medium rounded-lg bg-white hover:bg-slate-100 border border-gray-300">
							Mark all seen
						</button>
						<button onClick={() => clear_all()} className="px-4 py-2 font-medium rounded-lg bg-white hover:bg-slate-100 border border-gray-300">
							Clear all
						</button>
					</div>
				</div>
				<div className="w-full flex flex-col gap-4">
					{notifications.map((notification, id) => {
						return (
							<>
								{notification.type === "warning" ? (
									<div className="flex flex-row justify-between items-center rounded-lg shadow w-full p-4 bg-yellow-50">
										<Link key={id} className={" font-small w-full flex flex-1 flex-col bg-transparent text-slate-700"}>
											<h6 className=" font-medium flex gap-2 items-center">
												{notification.title}
												<i className="fa-solid fa-exclamation text-yellow-500 "></i>
											</h6>
											{notification.content}
										</Link>
										{!notification.displayed ? (
											<button
												className=" rounded-lg px-4 py-2 bg-white hover:bg-slate-100 border border-gray-300"
												onClick={(event) => {
													event.preventDefault()
													mark_as_seen(notification.id);
												}}
											>
												mark as seen
											</button>
										) : (
											""
										)}
									</div>
								) : notification.type === "success" ? (
									<div className="flex flex-row justify-between items-center rounded-lg shadow w-full p-4 bg-white">
										<Link key={id} className={" font-small w-full flex flex-1 flex-col bg-transparent text-slate-700"}>
											<h6 className=" font-medium flex gap-2 items-center">
												{notification.title}
												<i className="fa-solid fa-check text-green-500 "></i>
											</h6>
											{notification.content}
										</Link>
										{!notification.displayed ? (
											<button
												className="px-4 py-2 rounded-lg bg-white hover:bg-slate-100 border border-gray-300"
												onClick={(event) => {
													event.preventDefault()
													mark_as_seen(notification.id);
												}}
											>
												mark as seen
											</button>
										) : (
											""
										)}
									</div>
								) : notification.type === "danger" ? (
									<div className="flex flex-row  p-4 justify-between items-center rounded-lg shadow w-full bg-red-50">
										<Link key={id} className={" font-small w-full flex flex-1 flex-col bg-transparent text-slate-700"}>
											<h6 className=" font-medium flex gap-2 items-center">
												{notification.title}
												<i className="fa-solid fa-xmark  text-red-500 "></i>
											</h6>
											{notification.content}
										</Link>
										{!notification.displayed ? (
											<button
												className=" rounded-lg px-4 py-2 bg-white hover:bg-slate-100 border border-gray-300"
												onClick={(event) => {
													event.preventDefault()
													mark_as_seen(notification.id);
												}}
											>
												mark as seen
											</button>
										) : (
											""
										)}
									</div>
								) : (
									<div className="flex flex-row justify-between items-center rounded-lg shadow w-full p-4 bg-white">
										<Link key={id} className={" font-small w-full flex flex-1 flex-col bg-transparent text-slate-700"}>
											<h6 className=" font-medium flex gap-2 items-center">
												{notification.title}
												<i className="fa-solid fa-info text-blue-500 "></i>
											</h6>
											{notification.content}
										</Link>
										{!notification.displayed ? (
											<button
												className=" rounded-lg px-4 py-2 bg-white hover:bg-slate-100 border border-gray-300"
												onClick={(event) => {
													event.preventDefault()
													mark_as_seen(notification.id);
												}}
											>
												mark as seen
											</button>
										) : (
											""
										)}
									</div>
								)}
							</>
						);
					})}
				</div>
			</div>
		</div>
	);
}
