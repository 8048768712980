import React, { useEffect, useRef, useState } from "react";
import axios from "config/axios";
import { useAtom } from "jotai";
import { departmentAtom, urlAtom } from "index";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "Components/Loaders/Loading";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
// components

export default function UserForm({ action, data, getUsers, hide }) {
	const token = window.localStorage.getItem("token");
	const [url, setUrl] = useAtom(urlAtom);
	const [department, setDepartment] = useAtom(departmentAtom);
	const method = action === "add" ? "post" : "put";
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [permissions, setPermissions] = useState([]);
	const [gender, setGender] = useState("male");
	const [phone_number, setPhoneNumber] = useState("");
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [loading, setLoading] = useState(false);

	function generateEmail(event) {
		event.preventDefault();
		var formattedName = lastName.toLowerCase().replace(/\s/g, "") + firstName.toLowerCase().replace(/\s/g, "");
		var randomNumber = Math.floor(Math.random() * 9000) + 1000;
		var email = formattedName + randomNumber + "@numidia.com";

		setEmail(email);
	}
	const add_user = () => {
		setLoading(true);

		axios({
			url: action === "add" ? url + "/users" : url + "/users/" + data.id,
			method: method,
			data: {
				role: "employee",
				permissions,
				email,
				name: action === "add" ? lastName + " " + firstName : name,
				phone_number,
				gender,
			},
			headers: {
				Accept: "Application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {
				hide();
				toast.success("User " + action + "ed", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				getUsers();
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (action === "edit") {
			setName(data.name);
			setGender(data.gender);
			setPhoneNumber(data.phone_number);
			setEmail(data.email);
		}
	}, []);
	return (
		<div className="w-full p-4 ">
			<form>
				<div className="flex flex-wrap gap-4">
					<div className="my-2 w-full ">
						<label className="block  uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Permissions
						</label>
						<Select
							className="my-2 p-2 placeholder-slate-300  border-0 bg-white rounded-lg shadow  w-full"
							closeMenuOnSelect={false}
							isSearchable={true}
							components={animatedComponents}
							isMulti
							options={[
								{ value: "financials", label: "Financials" },
								{ value: "statistics", label: "Statistics" },
								{ value: "news", label: "News" },
								{ value: "user-management", label: "User Management" },
								{ value: "users", label: "Users" },
								{ value: "delete-users", label: "Delete Users" },
								{ value: "edit-users", label: "Edit Users" },
								{ value: "create-users", label: "Create Users" },
								{ value: "teachers", label: "Teachers" },
								{ value: "delete-teachers", label: "Delete Teachers" },
								{ value: "edit-teachers", label: "Edit Teachers" },
								{ value: "create-teachers", label: "Create Teachers" },
								{ value: "students", label: "Students" },
								{ value: "delete-students", label: "Delete Students" },
								{ value: "edit-students", label: "Edit Students" },
								{ value: "create-students", label: "Create Students" },
								{ value: "parents", label: "Parents" },
								{ value: "delete-parents", label: "Delete Parents" },
								{ value: "edit-parents", label: "Edit Parents" },
								{ value: "create-parents", label: "Create Parents" },
								{ value: "department-management", label: "Department Management" },
								{ value: "groups", label: "Groups" },
								{ value: "delete-groups", label: "Delete Groups" },
								{ value: "edit-groups", label: "Edit Groups" },
								{ value: "create-groups", label: "Create Groups" },
								{ value: "levels", label: "Levels" },
								{ value: "delete-levels", label: "Delete Levels" },
								{ value: "edit-levels", label: "Edit Levels" },
								{ value: "create-levels", label: "Create Levels" },
								{ value: "sessions", label: "Sessions" },
								{ value: "delete-sessions", label: "Delete Sessions" },
								{ value: "edit-sessions", label: "Edit Sessions" },
								{ value: "create-sessions", label: "Create Sessions" },
								{ value: "attendance", label: "Attendance" },
								{ value: "delete-attendance", label: "Delete Attendance" },
								{ value: "edit-attendance", label: "Edit Attendance" },
								{ value: "create-attendance", label: "Create Attendance" },
								{ value: "exams", label: "Exams" },
								{ value: "delete-exams", label: "Delete Exams" },
								{ value: "edit-exams", label: "Edit Exams" },
								{ value: "create-exams", label: "Create Exams" },
								{ value: "library-management", label: "Library Management" },
								{ value: "library", label: "Library" },
								{ value: "clients", label: "Clients" },
								{ value: "delete-clients", label: "Delete Clients" },
								{ value: "edit-clients", label: "Edit Clients" },
								{ value: "create-clients", label: "Create Clients" },
								{ value: "orders", label: "Orders" },
								{ value: "delete-orders", label: "Delete Orders" },
								{ value: "edit-orders", label: "Edit Orders" },
								{ value: "create-orders", label: "Create Orders" },
								{ value: "products", label: "Products" },
								{ value: "delete-products", label: "Delete Products" },
								{ value: "edit-products", label: "Edit Products" },
								{ value: "create-products", label: "Create Products" },
								{ value: "dawarat-management", label: "Dawarat Management" },
								{ value: "dawarat", label: "Dawarat" },
								{ value: "amphis", label: "Amphis" },
								{ value: "delete-amphis", label: "Delete Amphis" },
								{ value: "edit-amphis", label: "Edit Amphis" },
								{ value: "create-amphis", label: "Create Amphis" },
								{ value: "dawarat-groups", label: "Dawarat Groups" },
								{ value: "delete-dawarat-groups", label: "Delete Dawarat Groups" },
								{ value: "edit-dawarat-groups", label: "Edit Dawarat Groups" },
								{ value: "create-dawarat-groups", label: "Create Dawarat Groups" },
								{ value: "dawarat-sessions", label: "Dawarat Sessions" },
								{ value: "delete-dawarat-sessions", label: "Delete Dawarat Sessions" },
								{ value: "edit-dawarat-sessions", label: "Edit Dawarat Sessions" },
								{ value: "create-dawarat-sessions", label: "Create Dawarat Sessions" },
								{ value: "tickets", label: "Tickets" },
								{ value: "delete-tickets", label: "Delete Tickets" },
								{ value: "edit-Tickets", label: "Edit Tickets" },
								{ value: "create-Tickets", label: "Create Tickets" },
								{ value: "dawarat-attendance", label: "Dawarat Attendance" },
								{ value: "delete-dawarat-attendance", label: "Delete Dawarat Attendance" },
								{ value: "edit-dawarat-attendance", label: "Edit Dawarat Attendance" },
								{ value: "create-dawarat-attendance", label: "Create Dawarat Attendance" },
								{ value: "waiting-list", label: "Waiting List" },
								{ value: "delete-waiting-list", label: "Delete Waiting List" },
								{ value: "edit-waiting-list", label: "Edit Waiting List" },
								{ value: "create-waiting-list", label: "Create Waiting List" },
							]}
							onChange={(event) => setPermissions(event.map((_) => _.value))}
						/>
					</div>
					{action === "edit" ? (
						<div className="w-full  ">
							<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
								Name
							</label>
							<input
								type="text"
								className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Full Name"
								onChange={(event) => {
									setName(event.target.value);
								}}
								value={name}
							/>
						</div>
					) : (
						<>
							<div className="flex-1  ">
								<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
									Last Name
								</label>
								<input
									type="text"
									className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="Last Name"
									onChange={(event) => {
										setLastName(event.target.value);
									}}
									value={lastName}
								/>
							</div>
							<div className=" flex-1 ">
								<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
									First Name
								</label>
								<input
									type="text"
									className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
									placeholder="First Name"
									onChange={(event) => {
										setFirstName(event.target.value);
									}}
									value={firstName}
								/>
							</div>
						</>
					)}
					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Gender
						</label>
						<select
							className="my-2 p-2 placeholder-blueGray-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							default="male"
							onChange={(event) => {
								setGender(event.target.value);
							}}
						>
							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>
					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Phone Number
						</label>
						<input
							type="number"
							onWheel={(event) => event.target.blur()}
							className="my-2 p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
							placeholder="Phone Number"
							onChange={(event) => {
								setPhoneNumber(event.target.value);
							}}
						/>
					</div>

					<div className=" w-full">
						<label className="block uppercase text-slate-600 font-bold " htmlFor="grid-password">
							Email address
						</label>
						<div className="flex my-2 gap-4">
							<input
								type="email"
								className=" p-2 placeholder-slate-300 text-slate-600 bg-white rounded-lg shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
								placeholder="Email"
								onChange={(event) => {
									setEmail(event.target.value);
								}}
								value={email}
							/>
							<button className="bg-white rounded-lg shadow p-2 flex items-center justify-center" onClick={generateEmail}>
								<i className="fa-solid fa-gear"></i>{" "}
							</button>
						</div>
					</div>
				</div>
				<div className="flex justify-center my-4 w-full">
					<button
						className=" text-white w-full px-4 py-2 bg-sky-600 active:bg-sky-700 font-bold uppercase rounded-lg shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
						type="button"
						onClick={() => add_user()}
						disabled={loading}
					>
						{loading ? <Loading width="20px" height={"20px"} color="white" weight={"2px"}></Loading> : ""}
						Save
					</button>
				</div>
			</form>
		</div>
	);
}
